import { Cell } from '@table-library/react-table-library/table';
import numeral from 'numeral';

export const COLUMNS = (default_image) => [
    {
        label: 'Title',
        key: 'title',
        data: 'title',
        Component: Cell,
        renderCell: ({ item, csvDownload }) => {
            let title = item.title;

            if (!item.title) {
                title = item.page;
            }

            if (csvDownload) return title;

            return (
                <div>
                    <div className="d-flex align-items-center">
                        <div style={{ float: 'left', marginRight: '10px', width: '100px' }}>
                            <img
                                onError={(event) => (event.target.src = default_image)}
                                src={item.image ?? default_image}
                                style={{
                                    width: '100px',
                                    display: 'inline-block',
                                    minHeight: '60px',
                                    background: '#BBB',
                                }}
                                alt="page_image"
                                loading="lazy"
                            />
                        </div>
                        <strong>
                            <a
                                href={
                                    `/domains/${window.info.id}/pages/page?page=` +
                                    encodeURIComponent(item.page)
                                }
                            >
                                {title.length > 64 ? title.substr(0, 64) + '...' : title}
                            </a>
                            <a
                                target="_blank"
                                className="link-website d-inline-flex align-items-center justify-content-center"
                                href={item.page}
                                rel="noreferrer"
                            >
                                <i className="icon-external-link"></i>
                            </a>
                        </strong>
                    </div>
                </div>
            );
        },
    },
    {
        label: 'CTR',
        key: 'ctr',
        data: 'ctr',
        Component: Cell,
        renderCell: ({ item, csvDownload }) => {
            const ctrValue = item.clicks/item.impressions;
            if (csvDownload) return ctrValue;
            return <strong>{numeral(ctrValue).format('0.0%')}</strong>;
        },
    },
    {
        label: 'Impressions',
        key: 'impressions',
        data: 'impressions',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;
            return <strong>{numeral(data).format('0.0a')}</strong>;
        },
    },
    {
        label: 'Clicks',
        key: 'clicks',
        data: 'clicks',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;
            return <strong>{numeral(data).format('0.0a')}</strong>;
        },
    },
];
