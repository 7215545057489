import { COLUMNS } from './keys';
import { query } from './query';
import DataTable from '../../../../components/Datatable';
import Component from '../../../../components/Component';
import { getDifference } from '../../../../helpers/showDelta';
import { noDataMessage } from '../../../../helpers/noDataMessage';
import Loading from '../../../../components/Loading';
import ExtraButtons from '../extraBtns';
import { scrollForTable } from '../../../../components/Datatable/utils';

class Directories extends Component {
    constructor(props) {
        super();
        if (!props.data) this.query = query(props.dates);

        this.chooseFilter = this.chooseFilter.bind(this);
        this.url = new URL(window.location);
    }

    searchFunction(data, search) {
        return data.filter((item) =>
            item?.first_directory?.toLowerCase().includes(search.toLowerCase()),
        );
    }

    async componentDidMount() {
        const params = new URL(document.location).searchParams;
        const filter = params.get("defaultFilter");
        if(filter ) {
            this.chooseFilter(filter);
        }else{
            this.query.bind(this.setState.bind(this));
            await this.query.update();
        }
    }


    componentDidUpdate(prevProps, prevState) {
        if (JSON.stringify(prevProps.dates) !== JSON.stringify(this.props.dates)) {
            this.query = query({ ...this.props.dates, filter: this.state.filter });
            this.query.bind(this.setState.bind(this));
            this.query.update();
            this.props.updateDates({ emptyAll: true });
        }
        if (this.state.data && JSON.stringify(this.state.data) !== JSON.stringify(prevState.data)) {
            this.props.updateDates({ key: 'directories', value: this.state.data });
        }
    }

    chooseFilter(filter) {
        if(filter){
            this.url.searchParams.set('defaultFilter', filter);
        }else{
            this.url.searchParams.delete('defaultFilter');
        }
        history.pushState({}, '', this.url);
        const newFilter = this.state.filter === filter ? null : filter;
        this.setState({
            filter: newFilter,
        });
        this.query = query({ ...this.props.dates, filter: newFilter });
        this.query.bind(this.setState.bind(this));
        this.query.update();
    }

    render() {
        const data = this.state.data?.length ? this.state.data : this.props.data;
        if ((!data || !data.length) && this.state.loading && !this.props.data) return <Loading />;

        const sortFns = {
            cluster_name: (array) =>
                array.sort((a, b) => a.cluster_name.localeCompare(b.cluster_name)),
            impressions: (array) => array.sort((a, b) => a.impressions - b.impressions),
            avgCTR: (array) => array.sort((a, b) => a.clicks/a.impressions - b.clicks/b.impressions),
            avgPosition: (array) => array.sort((a, b) => a.avgPosition - b.avgPosition),
            clicks: (array) => array.sort((a, b) => a.clicks - b.clicks),
            impressions_delta: (array) =>
                array.sort(
                    (a, b) =>
                        getDifference(a.impressions, a.impressionsPrior) -
                        getDifference(b.impressions, b.impressionsPrior),
                ),
            clicks_delta: (array) =>
                array.sort(
                    (a, b) =>
                        getDifference(a.clicks, a.clicksPrior) -
                        getDifference(b.clicks, b.clicksPrior),
                ),
            avgPosition_delta: (array) =>
                array.sort(
                    (a, b) =>
                        getDifference(a.avgPosition, a.avgPositionPrior) -
                        getDifference(b.avgPosition, b.avgPositionPrior),
                ),
            avgCTR_delta: (array) =>
                array.sort(
                    (a, b) =>
                        getDifference(a.avgCTR, a.avgCTRPrior) -
                        getDifference(b.avgCTR, b.avgCTRPrior),
                ),
        };

        return (!data || !data.length) && !this.state.filter ? (
            <div>{noDataMessage}</div>
        ) : (
            <DataTable
                nodes={data}
                sortFns={sortFns}
                COLUMNS={COLUMNS}
                uniqKey={'first_directory'}
                fileName="Directories"
                searchFunction={this.searchFunction}
                forceRun={this.forceRun}
                extraBtns={() => (
                    <ExtraButtons filter={this.state.filter} chooseFilter={this.chooseFilter} />
                )}
                additionalStyles={{
                    Table: `
                                --data-table-library_grid-template-columns : minmax(120px, 2fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) !important;
                               ${scrollForTable}
                            `,
                    BaseCell: ` font-family: 'Raleway';
                                        font-weight: 500;
                                        font-size: 16px;
                                        line-height: 19px;
                                        padding: 16px 5px;
                                        color: #0D182C;
                                        border-bottom: 1px solid rgba(13, 24, 44, 0.1);
                                    
                                        &.underline {
                                        text-decoration: underline;
                                        }`,
                }}
            />
        );
    }
}

export default Directories;
