import React, { Component } from 'react';
import Loading from '../Loading';
import { sendPromoCodeQuery } from './query';
import _ from 'lodash';
import { isTrue } from '../../helpers/isTrue';

const PLAN_INTERVALS = {
    Monthly: { interval: 'month', interval_count: 1, period: 'Monthly' },
    'Quarterly (-10%)': { interval: 'month', interval_count: 3, period: 'Quarterly' },
    'Annual (-15%)': { interval: 'year', interval_count: 1, period: 'Annual' },
};

class ChangePlanComponent extends Component {
    constructor(props) {
        super();

        this.updatePromotionCode = this.updatePromotionCode.bind(this);
        this.onChangePlanInterval = this.onChangePlanInterval.bind(this);
        this.onChangePlanNames = this.onChangePlanNames.bind(this);
        this.updatePromoCode = this.updatePromoCode.bind(this);
        this.sendPromoCode = this.sendPromoCode.bind(this);
        this.updateSelectedPlan = this.updateSelectedPlan.bind(this);
        this.setPromoCode = this.setPromoCode.bind(this);
        this.setError = this.setError.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

        this.currentPlanName =
            isTrue(props.without_basic) && props.current_plan?.product_name === 'Basic Plan'
                ? props.plan_names[0]
                : props.current_plan?.product_name ?? props.plan_names[0];

        this.state = {
            promotion_code: props.coupon.name ?? '',
            selectedPlanInterval:
                props.current_plan?.current_plan_interval ?? props.plan_intervals[0],
            selectedPlanName: this.currentPlanName,
            coupon: props.coupon ?? null,
            selectedPlanObj: null,
            responseError: null,
        };
    }

    componentDidMount() {
        if (this.props.current_plan) {
            return this.updateSelectedPlan({
                selectedPlanInterval: this.props.current_plan?.current_plan_interval,
                selectedPlanName: this.currentPlanName,
            });
        }
        this.updateSelectedPlan({});
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            JSON.stringify(this.state.selectedPlanObj) !==
                JSON.stringify(prevState.selectedPlanObj) ||
            JSON.stringify(this.state.coupon) !== JSON.stringify(prevState.coupon)
        )
            this.props.onChangePlan &&
                this.props.onChangePlan({
                    ...this.state.selectedPlanObj,
                    promotion_code:
                        this.state.coupon?.promotion?.id ?? this.state.coupon?.promotion_code,
                });
    }

    updatePromotionCode(value) {
        this.setState({ promotion_code: value });
    }

    onChangePlanInterval(event) {
        this.setState({
            selectedPlanInterval: event.target.value,
        });
        const selectedPlanObj = this.updateSelectedPlan({
            selectedPlanInterval: event.target.value,
        });

        if (!_.isEmpty(this.state.coupon)) this.sendPromoCode(selectedPlanObj.fields.amount);
    }

    onChangePlanNames(event) {
        this.setState({
            selectedPlanName: event.target.value,
        });
        const selectedPlanObj = this.updateSelectedPlan({ selectedPlanName: event.target.value });
        if (!_.isEmpty(this.state.coupon)) this.sendPromoCode(selectedPlanObj.fields.amount);
    }

    updatePromoCode(event) {
        this.setState({
            promotion_code: event.target.value,
        });
    }

    setPromoCode(coupon, message) {
        this.setState({ coupon, message });
    }

    onSubmitPromotionForm(e, amount) {
        e.preventDefault();
        this.sendPromoCode(amount, true);
    }

    sendPromoCode(amount, fromForm) {
        const codeFromCoupon = this.state.coupon?.promotion
            ? this.state.coupon.promotion.code
            : this.state.coupon?.id;
        const promotion_code = fromForm ? this.state.promotion_code : codeFromCoupon;
        const args = {
            endpoint_url: this.props.endpoint_url,
            promotion_code,
            amount,
            setPromoCode: this.setPromoCode,
        };
        sendPromoCodeQuery(args);
    }

    updateSelectedPlan({ selectedPlanInterval, selectedPlanName }) {
        const plan_interval = selectedPlanInterval ?? this.state.selectedPlanInterval;
        const plan_name = selectedPlanName ?? this.state.selectedPlanName;

        let selectedPlanObj = this.props.pl.find(function (item) {
            return (
                item.fields.interval_count === PLAN_INTERVALS[plan_interval].interval_count &&
                item.fields.interval === PLAN_INTERVALS[plan_interval].interval &&
                item.fields.name === plan_name
            );
        });
        this.setState({ selectedPlanObj });
        return selectedPlanObj;
    }

    setError(value) {
        this.setState({ responseError: value });
    }

    onSubmit() {
        this.props.onSubmit({
            price_id: this.state.selectedPlanObj?.fields?.plan_id,
            promotion_code: this.state.coupon?.promotion?.id,
            setError: this.setError,
        });
    }

    render() {
        console.log("this.state", this.state)
        return (
            <div className="add-payment-method-wrapper">
                <div
                    className="card rounded-main table-main"
                    style={this.props.showOneBorder ? { border: 'none' } : {}}
                >
                    <div className="row">
                        <div>
                            <div
                                className="plans-area"
                                style={!this.props.showOneBorder ? { border: 'none' } : {}}
                            >
                                <div className="card-header" style={{ borderRadius: '10px' }}>
                                    <div className="card-title text-dark fw-bold">Plans</div>
                                </div>
                                <div className="card-body">
                                    <ul className="plans-list">
                                        {this.props.showCurrentPlan && (
                                            <li>
                                                <strong>Current Plan</strong>
                                                {_.isEmpty(this.props.current_plan) ? (
                                                    '-'
                                                ) : (
                                                    <div>
                                                        <span>
                                                            {this.props.current_plan?.product_name}{' '}
                                                            - ${this.props.current_plan?.amount} /{' '}
                                                            {this.props.current_plan.plan_period}
                                                            {!_.isEmpty(this.props.coupon) && (
                                                                <span className="d-flex align-center">
                                                                    {' '}
                                                                    (discount :{' '}
                                                                    {this.props.current_plan
                                                                        .discount_type ==
                                                                    'amount' ? (
                                                                        <span>
                                                                            $
                                                                            {
                                                                                this.props
                                                                                    .current_plan
                                                                                    .amount
                                                                            }
                                                                        </span>
                                                                    ) : (
                                                                        <span>
                                                                            {
                                                                                this.props
                                                                                    .current_plan
                                                                                    .discount_value
                                                                            }{' '}
                                                                            %
                                                                        </span>
                                                                    )}
                                                                    )
                                                                </span>
                                                            )}
                                                        </span>
                                                        {!this.state.selectedPlanObj &&
                                                            !_.isEmpty(this.props.current_plan) && (
                                                                <div className="error-message">
                                                                    Your current plan is no longer
                                                                    available. Please select a plan
                                                                    below.
                                                                </div>
                                                            )}
                                                    </div>
                                                )}
                                            </li>
                                        )}
                                        <li>
                                            <strong>Billing Cycle</strong>

                                            <ul className="radio-btns">
                                                {this.props.plan_intervals.map((plan_interval) => {
                                                    return (
                                                        <li key={plan_interval}>
                                                            <label className="radio-btn-item">
                                                                <input
                                                                    type="radio"
                                                                    className="btn-check"
                                                                    name="plan_interval"
                                                                    id={`interval_${plan_interval}`}
                                                                    autoComplete="off"
                                                                    onChange={
                                                                        this.onChangePlanInterval
                                                                    }
                                                                    value={plan_interval}
                                                                    checked={
                                                                        this.state
                                                                            .selectedPlanInterval ===
                                                                        plan_interval
                                                                    }
                                                                />
                                                                <span>{plan_interval}</span>
                                                            </label>
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        </li>
                                        <li>
                                            <strong>Plan</strong>
                                            <div className="plan-types">
                                                <ul className="radio-btns">
                                                    {this.props.plan_names.map((plan_name) => {
                                                        return (
                                                            <li key={plan_name}>
                                                                <label className="radio-btn-item">
                                                                    <input
                                                                        type="radio"
                                                                        className="btn-check"
                                                                        name="plan_name"
                                                                        id={`plan_name_${plan_name}`}
                                                                        autoComplete="off"
                                                                        value={plan_name}
                                                                        onChange={
                                                                            this.onChangePlanNames
                                                                        }
                                                                        checked={
                                                                            this.state
                                                                                .selectedPlanName ===
                                                                            plan_name
                                                                        }
                                                                    />
                                                                    <span>{plan_name}</span>
                                                                </label>
                                                            </li>
                                                        );
                                                    })}
                                                </ul>
                                                <ul className="plan-details">
                                                    <li>
                                                        Domain Variations -{' '}
                                                        <span className="domain-count">
                                                            {
                                                                this.state.selectedPlanObj?.fields
                                                                    ?.domain_variations
                                                            }
                                                        </span>{' '}
                                                        Domain
                                                    </li>
                                                    <li>
                                                        Discover Monthly Pages -{' '}
                                                        <span className="daily-pages-count">
                                                            {this.state.selectedPlanObj?.fields
                                                                ?.monthly_discover_pages
                                                                ?? ''}
                                                        </span>{' '}
                                                        Pages
                                                    </li>
                                                    <li>
                                                        Discover Monthly Search records -{' '}
                                                        <span className="daily-pages-count">
                                                            {
                                                                this.state.selectedPlanObj?.fields
                                                                    ?.monthly_search_records
                                                            }
                                                        </span>{' '}
                                                        Records
                                                    </li>
                                                    <li>
                                                        Seats -{' '}
                                                        <span className="login-count">
                                                            {
                                                                this.state.selectedPlanObj?.fields
                                                                    ?.seats
                                                            }
                                                        </span>{' '}
                                                        Login
                                                    </li>
                                                    <li>
                                                        Discover Entities Tracking -{' '}
                                                        <span className="months-count">
                                                            {
                                                                this.state.selectedPlanObj?.fields
                                                                    ?.number_of_entities
                                                            }
                                                        </span>{' '}
                                                    </li>
                                                    <li>
                                                        Historical Backfill Data -{' '}
                                                        <span className="days-count">
                                                            {
                                                                this.state.selectedPlanObj?.fields
                                                                    ?.historical_backfill_data
                                                            }
                                                        </span>{' '}
                                                        Days
                                                    </li>
                                                    <li>
                                                        Content analysis -{' '}
                                                        <span className="days-count">
                                                            {
                                                                this.state.selectedPlanObj?.fields
                                                                    ?.number_of_ai_analysis
                                                            }
                                                        </span>{' '}
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                        <li>
                                            <strong>Coupon code</strong>
                                            <div className="mb-3 promo-code">
                                                <div
                                                    className="coupon-code-container change-plan-body"
                                                    style={{ marginTop: '0' }}
                                                >
                                                    <form
                                                        className="coupon-code"
                                                        onSubmit={(e) =>
                                                            this.onSubmitPromotionForm(
                                                                e,
                                                                this.state.selectedPlanObj?.fields
                                                                    ?.amount,
                                                            )
                                                        }
                                                    >
                                                        <div className="text-field">
                                                            <input
                                                                type="hidden"
                                                                name="product_id"
                                                                value="{{ price.id }}"
                                                            />
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="promotion_code"
                                                                onChange={this.updatePromoCode}
                                                                value={this.state.promotion_code}
                                                                placeholder="Coupon Code"
                                                                disabled={this.state.loading}
                                                            />
                                                            <button
                                                                type="submit"
                                                                className="button button-filled button-primary"
                                                                id="coupon-code-submit"
                                                                disabled={
                                                                    !this.state.selectedPlanObj
                                                                }
                                                            >
                                                                Add
                                                            </button>
                                                        </div>
                                                    </form>
                                                    {this.state.message && (
                                                        <div className="error-message">
                                                            {this.state.message}
                                                        </div>
                                                    )}
                                                    {!_.isEmpty(this.state.coupon) && (
                                                        <div className="success-message">
                                                            Promo code added successfully
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <strong></strong>
                                            <div className="row" id="change-summary">
                                                <div className="change-plan-body">
                                                    <div>
                                                        <p>
                                                            Plan amount: $
                                                            <span className="selected-plan-price">
                                                                {
                                                                    this.state.selectedPlanObj
                                                                        ?.fields?.amount
                                                                }
                                                            </span>
                                                        </p>
                                                        <p>
                                                            Discount:{' '}
                                                            {this.state?.coupon?.amount_off ? (
                                                                <span>
                                                                    $
                                                                    {this.state?.coupon?.amount_off}
                                                                </span>
                                                            ) : (
                                                                <span>
                                                                    {this.state?.coupon
                                                                        ?.percent_off ?? 0}{' '}
                                                                    %
                                                                </span>
                                                            )}
                                                        </p>
                                                        <p>
                                                            <strong>
                                                                Total: $
                                                                <span className="selected-plan-total-price">
                                                                    {this.state.coupon?.discount_price?.toFixed(
                                                                        2,
                                                                    ) ??
                                                                        (this.props.discount ||
                                                                            this.state
                                                                                .selectedPlanObj
                                                                                ?.fields?.amount)}
                                                                </span>{' '}
                                                                /{' '}
                                                                <span className="selected-plan-period">
                                                                    {
                                                                        PLAN_INTERVALS[
                                                                            this.state
                                                                                .selectedPlanInterval
                                                                        ].period
                                                                    }
                                                                </span>
                                                            </strong>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        {this.props.showBtns && (
                                            <li>
                                                <strong></strong>
                                                <div className="row mt-4">
                                                    <div className="col-sm-12 col-md-2"></div>
                                                    <div className="change-plan-body">
                                                        {isTrue(this.props.trialing) && (
                                                            <div
                                                                style={{
                                                                    fontSize: '12px',
                                                                    marginBottom: '10px',
                                                                }}
                                                            >
                                                                <i
                                                                    className="icon-info"
                                                                    style={{ marginRight: '4px' }}
                                                                ></i>{' '}
                                                                By "Activating" your plan, your
                                                                trial period will stop and you will
                                                                be charged for the first billing
                                                                period immediately.
                                                            </div>
                                                        )}
                                                        <div className="d-flex align-items-center justify-content-center">
                                                            {this.props.onCancel && (
                                                                <button
                                                                    className="link-dark btn mt-0"
                                                                    onClick={this.props.onCancel}
                                                                >
                                                                    Cancel
                                                                </button>
                                                            )}
                                                            <button
                                                                className="btn btn-sm text-white bg-purple text-capitalize table-button d-flex"
                                                                onClick={this.onSubmit}
                                                                disabled={
                                                                    this.props.loading ||
                                                                    !this.state.selectedPlanObj
                                                                }
                                                            >
                                                                <span
                                                                    style={{ marginRight: '5px' }}
                                                                >
                                                                    {this.props.submitName}
                                                                </span>
                                                                {this.props.loading && (
                                                                    <Loading onlyPreloader />
                                                                )}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                {this.state.responseError && (
                                                    <div className="row mt-4">
                                                        <div
                                                            className="error-message"
                                                            style={{ textAlign: 'center' }}
                                                        >
                                                            {this.state.responseError}
                                                        </div>
                                                    </div>
                                                )}
                                            </li>
                                        )}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ChangePlanComponent;
