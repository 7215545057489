import moment from "moment";
import { Component } from "react";

class FilterDates extends Component{
    render(){
        return (
            <small className="shown-date-under-title d-block card-sub-title text-muted fs-7 text-capitalize py-2">{`From ${moment(window?.filters?.filters?.dates?.values?.start_date?.value).format('YYYY-MM-DD')} to ${moment(window?.filters?.filters?.dates?.values?.end_date?.value).format('YYYY-MM-DD')}`}</small>
        )
    }
} 

export default FilterDates