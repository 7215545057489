import List from '../../components/List';
import Loading from '../../components/Loading';
import LoadMoreComponent from '../../components/LoadMoreComponent';
import query from './query';
import colors from '../../lib/colors';
// import keysFunction from './keys';
import CheckData from '../../components/CatchError';
import DataTable from '../../components/Datatable';
import { COLUMNS } from './keys';
import { linReg } from '../../lib/reduce';
import { scrollForTable } from '../../components/Datatable/utils';

class CategoryTopEntities extends LoadMoreComponent {
    constructor() {
        super();
        this.url = new URL(window.location);
        this.entity = this.url.searchParams.get('entities:in');
        this.query = query({entity: this.entity});


        this.loadMore = this.loadMore.bind(this);
        this.offsetStep = 100;
    }

    searchFunction(data, search) {
        return data.filter((item) => {
            return item.entity_name.toLowerCase().includes(search.toLowerCase());
        });
    }

    render() {
        // if we get less than offsetStep it means we don't have more data
        const showLoadMoreBtn = this.state.data?.length === this.offsetStep;

        const attr = {
            query,
            offsetStep: this.offsetStep,
            entity: this.entity
        };

        const sortFns = {
        
            entity_name: (array) =>
                array.sort((a, b) => {
                    return a.entity_name.localeCompare(b.entity_name);
                }),
            pages: (array) => array.sort((a, b) => a.pages - b.pages),
            total_impressions: (array) => array.sort((a, b) => a.total_impressions - b.total_impressions),
            total_clicks: (array) => array.sort((a, b) => a.total_clicks - b.total_clicks),
            ctr: (array) => array.sort((a, b) => a.total_clicks/a.total_impressions - b.total_clicks/b.total_impressions),
        };

        if (this.state.loading && !this.state.loadMore) {
            return <Loading padding={10} />;
        }

        if(this.state.allData?.length === 0) return <div style={{padding: '10px'}}>There is no data</div>;
        
        return (
            <>
                <CheckData
                    content_id={this.props.content_id}
                    parent_id={this.props.parent_id}
                    data={this.state.allData}
                    loading={this.state.loading}
                    loadMore={this.state.loadMore}
                >
                    <DataTable
                        nodes={
                            this.props.shorted_view
                                ? this.state.allData.slice(0, 10)
                                : this.state.allData
                        }
                        sortFns={sortFns}
                        COLUMNS={COLUMNS(this.props.default_image)}
                        fileName="Pages"
                        forceRun={this.forceRun}
                        searchFunction={this.searchFunction}
                        defaultSortKey="total_impressions"
                        customColumnsForExport={[
                            ...COLUMNS(this.props.default_image),
                        ]}
                        additionalStyles={{
                            Table: `
                                --data-table-library_grid-template-columns : minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) !important;
                               ${scrollForTable}
                            `,
                            BaseCell: `font-family: 'Raleway';
                                        font-weight: 500;
                                        font-size: 16px;
                                        line-height: 19px;
                                        padding: 16px 5px;
                                        color: #0D182C;
                                        border-bottom: 1px solid rgba(13, 24, 44, 0.1);
                                    
                                        &.underline {
                                            text-decoration: underline;
                                        }
                                        
                                        `,
                        }}
                    />
                </CheckData>
                {(this.state.loadMore ? (
                        <Loading padding={10} />
                    ) : (
                        showLoadMoreBtn && (
                            <div className="d-flex align-items-center justify-content-center pt-3 load-more-section">
                                <button
                                    onClick={() => this.loadMore(attr)}
                                    disabled={this.state.loading}
                                    className="load-more-btn"
                                >
                                    Load More
                                </button>
                            </div>
                        )
                    ))}
            </>
        );
    }
}
export default CategoryTopEntities;
