import * as d3 from 'd3';
import 'd3-scale-chromatic';
import numeral from 'numeral';
import colors from '../../lib/colors';

function chart(data, opts) {
    var n = 0;

    var margin = { top: 10, right: 40, bottom: 0, left: 0 },
        width = opts.width - margin.left - margin.right,
        height = opts.height - margin.top - margin.bottom;

    var offset = opts.height * 0.5;

    var bubble = opts.keys.filter((d) => {
        return d.bubble ? true : false;
    })[0];

    var svg = d3
        .select(`#${opts.id}`)
        .append('svg')
        .attr('class', 'circles')
        .attr('width', width + margin.left + margin.right)
        .attr('height', height + margin.top + margin.bottom)
        .append('g')
        .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

    var x = d3
        .scaleBand()
        .range([0, width])
        .domain(
            data.map((d) => {
                return d[bubble.key];
            }),
        )
        .padding(0.2);

    var r = d3
        .scaleLinear()
        .domain([
            0,
            d3.max(data, (d) => {
                return d[bubble.value];
            }),
        ])
        .range([3, height * 0.45]);

    var c = d3
        .scaleOrdinal()
        .range(colors)
        .domain(
            data.map(function (d, i) {
                return i;
            }),
        );

    svg.selectAll('.bubbles')
        .data(data)
        .enter()
        .append('circle')
        .attr('class', 'bubble')
        .attr('cx', function (d) {
            return x(d[bubble.key]) + x(data[0][bubble.key]);
        })
        .attr('cy', offset)
        .attr('r', function (d) {
            return r(d[bubble.value]) > 0 ? r(d[bubble.value]) : 2;
        })
        .attr('fill', (d) => {
            return '#1193F0';
        })
        .attr('opacity', 0.8);

    svg.append('g')
        .attr('class', 'x-axis meta')
        .attr('transform', 'translate(0,' + offset + ')')
        .call(d3.axisBottom(x))
        .selectAll('text')
        .attr('class', 'label')
        .attr('transform', 'translate(-22, -22)rotate(-45)')
        .style('text-anchor', 'start')
        .text((d, i) => {
            return d.replace('/', '');
        });

    let off = 12;

    for (let key of opts.keys) {
        if (key.bubble) {
            continue;
        }

        svg.append('g')
            .attr('class', 'x-axis')
            .attr('transform', 'translate(0,' + offset + ')')
            .call(d3.axisBottom(x))
            .selectAll('text')
            .attr('class', 'metric')
            .attr('transform', `translate(${-22 + off}, ${-22 + off})rotate(-45)`)
            .style('text-anchor', 'start')
            .text((d, i) => {
                return (
                    key.label +
                    ': ' +
                    (key.format ? numeral(data[i][key.key]).format(key.format) : data[i][key.key])
                );
            });

        off += 12;
    }
}

export default chart;
