import Loading from '../../../components/Loading';

import query from './query';
import { COLUMNS } from './keys';
import CheckData from '../../../components/CatchError';
import DataTable from '../../../components/Datatable';
import LoadMoreComponent from '../../../components/LoadMoreComponent';
import { getPercent } from '../../../helpers/showDelta';
import ManualClusterSelect from '../../../components/manualClusterModal';
import { scrollForTable } from '../../../components/Datatable/utils';

class GSCReportKeywordDetailTable extends LoadMoreComponent {
    constructor(props) {
        super();
        this.query = query({
            offset: 0,
            keyword: props.keyword,
            last_week: props.last_week,
            prior_week: props.prior_week,
        });
        this.offsetStep = 1000;
        this.onSelect = this.onSelect.bind(this);
        this.onRemoveAllSelected = this.onRemoveAllSelected.bind(this);
    }

    searchFunction(data, search) {
        return data.filter((item) => item.url.toLowerCase().includes(search.toLowerCase()));
    }

    onRemoveAllSelected(bool) {
        this.setState({
            removeAllSelected: bool,
            selectedItems: [],
        });
    }

    onSelect(action, state) {
        if (action.type === 'REMOVE_ALL') return this.setState({ selectedItems: [] });
        this.setState({ selectedItems: state.ids, removeAllSelected: false });
    }

    render() {
        const attr = {
            query,
            offsetStep: this.offsetStep,
            keyword: this.props.keyword,
            last_week: this.props.last_week,
            prior_week: this.props.prior_week,
        };

        const sortFns = {
            url: (array) => array.sort((a, b) => a.url.localeCompare(b.url)),
            clicks: (array) => array.sort((a, b) => a.clicks - b.clicks),
            clicks_delta: (array) =>
                array.sort(
                    (a, b) =>
                        getPercent(a.clicks, a.clicksPrior) - getPercent(b.clicks, b.clicksPrior),
                ),
            impressions: (array) => array.sort((a, b) => a.impressions - b.impressions),
            impressions_delta: (array) =>
                array.sort(
                    (a, b) =>
                        getPercent(a.impressions, a.impressionsPrior) -
                        getPercent(b.impressions, b.impressionsPrior),
                ),
            position: (array) => array.sort((a, b) => a.position - b.position),
            position_delta: (array) =>
                array.sort(
                    (a, b) =>
                        getPercent(a.position, a.positionPrior) -
                        getPercent(b.position, b.positionPrior),
                ),
            ctr: (array) => array.sort((a, b) => a.ctr - b.ctr),
            ctr_delta: (array) =>
                array.sort((a, b) => getPercent(a.ctr, a.ctrPrior) - getPercent(b.ctr, b.ctrPrior)),
        };

        const showLoadMoreBtn = this.state.data?.length === this.offsetStep;

        return (
            <>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <div>
                        <ManualClusterSelect
                            current_url={this.props.current_url}
                            csrf_token={this.props.csrf_token}
                            selectedItems={this.state.selectedItems?.map((id) => ({
                                id,
                                url: this.state.allData.find((item) => item.id === id).url,
                            }))}
                            header="Add To URLs List"
                            cluster_type="url"
                            onRemoveAllSelected={this.onRemoveAllSelected}
                        />
                    </div>
                </div>
                <CheckData
                    content_id={this.props.content_id}
                    parent_id={this.props.parent_id}
                    data={this.state.allData}
                    loading={this.state.loading}
                    loadMore={this.state.loadMore}
                >
                    <DataTable
                        nodes={this.state.allData}
                        sortFns={sortFns}
                        COLUMNS={COLUMNS}
                        fileName="Keywords Details"
                        header="Keywords Details"
                        searchFunction={this.searchFunction}
                        defaultSortKey="clicks"
                        removeAllSelected={this.state.removeAllSelected}
                        hasSelect={true}
                        forceRun={this.forceRun}
                        onSelect={this.onSelect}
                        additionalStyles={{
                            Table: `
                                    --data-table-library_grid-template-columns :minmax(60px, 0.5fr) minmax(200px, 3fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) !important;
                                   ${scrollForTable}
                                `,
                            BaseCell: `  font-family: 'Raleway';
                                        font-weight: 500;
                                        font-size: 16px;
                                        line-height: 19px;
                                        padding: 16px 5px !important;
                                        color: #0D182C;
                                        border-bottom: 1px solid rgba(13, 24, 44, 0.1);
                                    
                                        &.underline {
                                            text-decoration: underline;
                                        }
                                        `,
                        }}
                    />
                </CheckData>
                {this.state.loadMore ? (
                    <Loading padding={10} />
                ) : (
                    showLoadMoreBtn && (
                        <div className="d-flex align-items-center justify-content-center pt-3 load-more-section">
                            <button
                                onClick={() => this.loadMore(attr)}
                                disabled={this.state.loading}
                                className="load-more-btn"
                            >
                                Load More
                            </button>
                        </div>
                    )
                )}
            </>
        );
    }
}

export default GSCReportKeywordDetailTable;
