import Query from '../../../../components/Report';

export const query = () =>
    new Query('opportunities-search-directories');

function addfilter(filter) {
    switch (filter) {
        case 'growth':
            return ' where t1.clicks > 0 and (t2.clicks / t1.clicks - 1) * 100 > 0';
        case 'decline':
            return ' where t1.clicks > 0 and (t2.clicks / t1.clicks - 1) * 100 < 0';
        default:
            return '';
    }
}
