import React, { Component } from 'react';
import Select from 'react-select';

class ReactSelect extends Component {
    constructor() {
        super();

        this.handleSelect = this.handleSelect.bind(this);
    }

    handleSelect(data) {
        this.props.onChange(data.value);
    }

    render() {
        const controlCustoStyles = this.props.controlStyles ?? {};
        const themeCustoStyles = this.props.themeStyles ?? {};
        return (
            <div className={this.props.classname ?? 'mx-15'}>
                {this.props.label && (
                    <label style={{ margin: '0 0 10px' }}>{this.props.label}</label>
                )}
                <Select
                    options={this.props.options}
                    defaultValue={this.props.defaultValue}
                    menuPortalTarget={document.querySelector('body')}
                    menuPosition={'fixed'}
                    styles={{
                        control: (baseStyles) => ({
                            ...baseStyles,
                            boxShadow: 'none',
                            padding: '1px 3px',
                            fontSize: '13px',
                            minWidth: '295px',
                            zIndex: 10,
                            ...controlCustoStyles,
                        }),
                        menuPortal: (provided) => ({ ...provided, zIndex: 999999999 }),
                        menu: (provided) => ({ ...provided, zIndex: 999999999 }),
                        multiValue: (provided) => ({ ...provided, display: 'flex' }),
                    }}
                    theme={(theme) => ({
                        ...theme,
                        height: '45px',
                        borderRadius: '7px',
                        borderColor: '#d9d9d9',
                        transition: 'all 0.2s',
                        zIndex: 10,
                        colors: {
                            ...theme.colors,
                            primary: '#888adb',
                        },
                        ...themeCustoStyles,
                    })}
                    onChange={this.props.isMulti ? this.props.onChange : this.handleSelect}
                    value={this.props.value}
                    placeholder={this.props.placeholder}
                    isSearchable={this.props.isSearchable}
                    onFocus={(e) => {
                        if (this.props.isSearchable) {
                            const parent = e.nativeEvent.target.parentNode.parentNode;
                            const selectedValue = parent.children[0];
                            selectedValue.style.display = 'none';
                        }

                        this.props.onFocus && this.props.onFocus();
                    }}
                    onBlur={(e) => {
                        if (this.props.isSearchable) {
                            const parent = e.nativeEvent.target.parentNode.parentNode;
                            const selectedValue = parent.children[0];
                            selectedValue.style.display = 'inline-grid';
                        }

                        this.props.onBlur && this.props.onBlur();
                    }}
                    blurInputOnSelect={this.props.blurInputOnSelect}
                    isMulti={this.props.isMulti}
                />
            </div>
        );
    }
}

export default ReactSelect;
