import { Cell } from '@table-library/react-table-library/table';
import numeral from 'numeral';
import { linReg } from '../../lib/reduce';

export const COLUMNS = [
    {
        label: 'Concept',
        key: 'name',
        data: 'name',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return (
                <div>
                    <a
                        href={
                            `/domains/${window.info.id}/entities/entity?entities:in=` +
                            encodeURIComponent(data)
                        }
                    >
                        {data}
                    </a>
                </div>
            );
        },
    },
    {
        label: 'Type',
        key: 'type',
        data: 'type',
        Component: Cell,
    },
    {
        label: 'No. of Pages \n (Content Production)',
        key: 'pages',
        data: 'pages',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return numeral(data).format('0.00');
        },
    },
    {
        label: 'Salience',
        key: 'salience',
        data: 'salience',
        Component: Cell,
        renderCell: ({ item, csvDownload }) => {
            if (csvDownload) return item.salience;

            let c = linReg(item.salienceL);
            let cr = c.slope >= 0 ? '+' : '-';

            return (
                <div>
                    <strong>{numeral(item.salience).format('0.00')}</strong>
                    <br />
                    {cr}
                    {numeral(Math.abs(c.slope)).format('0.0')}%
                </div>
            );
        },
    },
    {
        label: 'Attr. Imp. \n (Authority)',
        key: 'impressionsL',
        data: 'impressionsL',
        infoLink: '/support/attributed-impressions',
        info: "Attributed Impressions is own unique metric to measure entity authority on site. Click on the icon to find out more.",
        Component: Cell,
        renderCell: ({ data, item, csvDownload }) => {
            if (csvDownload) return item.impressions;

            let c = linReg(data);
            let cr = c.slope >= 0 ? '+' : '-';

            return (
                <div>
                    <strong>{numeral(item.impressions).format('0.0a')}</strong>
                    <br />
                    {cr}
                    {numeral(Math.abs(c.slope)).format('0.0')}%
                </div>
            );
        },
    },
    {
        label: 'Attr. Imp. per Page',
        key: 'impressions_per_page',
        data: 'impressions_per_page',
        infoLink: '/support/attributed-impressions',
        info: "Attributed Impressions is own unique metric to measure entity authority on site. Click on the icon to find out more.",
        Component: Cell,
        renderCell: ({ item, csvDownload }) => {
            if (csvDownload) return item.impressions / item.pages;

            return (
                <div>
                    <strong>{numeral(item.impressions / item.pages).format('0.0a')}</strong>
                </div>
            );
        },
    },
    {
        label: 'CTR \n (Engagement)',
        key: 'ctr',
        data: 'ctr',
        Component: Cell,
        renderCell: ({ data, item, csvDownload }) => {
            if (csvDownload) return data;

            let c = linReg(item.ctrL);
            let cr = c.slope >= 0 ? '+' : '-';
            return (
                <div>
                    <strong>{numeral(data).format('0.0%')}</strong>
                    <br />
                    {cr}
                    {numeral(Math.abs(c.slope)).format('0.0')}%
                </div>
            );
        },
    },
];
