import React from 'react';
import ReactModal from '../Modal';
import ManualClusterSelect from '../manualClusterModal';
import { v4 as uuid } from 'uuid';
import DatePicker from 'react-datepicker'
import { gsc_report_query, query } from '../FilterOptions/query';
import moment from 'moment';

class CustomDateModal extends React.Component {
    constructor(props) {
        super();

        this.onChange = this.onChange.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        
        this.query = props.gsc_report ? gsc_report_query : query;
        
        const filters = window?.filters?.filters;
        const filterId = filters?.dates?.id;
        const defaultValues = {
            start:
                filterId === 'custom'
                    ? new Date(moment(filters?.dates?.values?.start_date?.value).format('YYYY-MM-DD'))
                    : null,
            end:
                filterId === 'custom'
                    ? new Date(moment(filters?.dates?.values?.end_date?.value).format('YYYY-MM-DD') )
                    : null,
        };

        this.state = {
            dates: {
                startDate: defaultValues.start,
                endDate: defaultValues.end,
            }
        };
    }

    async componentDidMount() {
        if (this.query) {
            this.query.bind(this.setState.bind(this));
            await this.query.update();
        }
    }
    
    onChange(e) {
        this.setState({
            value: e.target.value,
        });
    }

    handleSelect(date, key){
        this.setState({
            dates:{
                ...this.state.dates,
                [key]: date
            }
        })
    }

    onSubmit(){
        const choice = {
            label: 'custom',
            key: 'dates',
            id: 'custom',
            values: {
                start_date: { value: this.state.dates.startDate },
                end_date: { value: this.state.dates.endDate },
            },
        };
        this.props.onChange(choice);
        this.props.onClose()
    }

    render() {
        let [maxdate, mindate] = [null, null];
        const dates = this.state.data?.[0];

        if (dates) {
            maxdate = dates.maxdate ? new Date(dates.maxdate.value) : null;
            mindate = dates.mindate ? new Date(dates.mindate.value) : null;
        } else {
            maxdate = new Date();
            const now = new Date() 
            const oneYearAgo =  now.setFullYear(now.getFullYear() - 1);
            mindate = oneYearAgo
        }

        return (
            <>
                <ReactModal
                    modalState={this.props.isOpen}
                    className="width-sm"
                    onClose={this.props.onClose}
                >
                    <h2>Custom Date</h2>
                    <div className='d-flex justify-content-between my-3 custom-date-filter'>
                        <div >
                            <div>From</div>
                            <DatePicker
                                selected={this.state.dates.startDate}
                                onChange={(date) => this.handleSelect(date, 'startDate')}
                                maxDate={this.state.dates.endDate || maxdate}
                                minDate={mindate}                
                                />
                        </div>
                        <div>
                            <div>To</div>
                            <DatePicker
                                selected={this.state.dates.endDate}
                                onChange={(date) => this.handleSelect(date, 'endDate')}
                                minDate={this.state.dates.startDate || mindate}
                                maxDate={maxdate}
                            />
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <button
                            type="button"
                            className="button button-filled button-primary width-sm btn"
                            onClick={this.onSubmit}
                            style={{ lineHeight: 'unset', marginRight: "10px" }}
                        >
                            Save
                        </button>
                        <button
                            type="button"
                            className="button button-outline button-primary width-sm btn"
                            onClick={this.props.onClose}
                            style={{ lineHeight: 'unset' }}
                        >
                            Close
                        </button>
                    </div>
                </ReactModal>
            </>
        );
    }
}

export default CustomDateModal;
