import React from 'react';
import onResize from '../lib/onResize';
import { addToHidenElements } from '../helpers/window';

class Chart extends React.Component {
    constructor() {
        super();
        this.id = 'chart-' + Math.random().toString().substr(2, 8);

        this.showError = this.showError.bind(this);
        this.checkIsEmptyAll = this.checkIsEmptyAll.bind(this);
        this.renderChart = this.renderChart.bind(this);

        onResize(
            this.setState.bind(this, { loading: true }),
            this.setState.bind(this, { loading: false }),
        );
    }

    async componentDidMount() {
        this.renderChart(this.id, this.props.data);
    }

    async componentDidUpdate() {
        this.renderChart(this.id, this.props.data);
    }

    checkIsEmptyAll(list) {
        for (let element of list) {
            if (!element.classList.contains('hide-component')) return false;
        }
        return true;
    }

    showError(show = true) {
        if (this.props.content_id && this.props.parent_id) {
            const warningBar = document.getElementById('warning-container');
            const content = document.getElementById(this.props.content_id);
            const parent = document.getElementById(this.props.parent_id);

            const childrens = parent.children;

            if (!show) {
                content.classList.remove('hide-component');
                warningBar.style.display = 'none';
                parent.style.display = 'block';

                return <></>;
            }
            content.classList.add('hide-component');
            addToHidenElements(content);

            const isEmptyAll = this.checkIsEmptyAll(childrens);

            if (isEmptyAll) {
                warningBar.style.display = 'flex';
                parent.style.display = 'none';
                addToHidenElements(parent);
            }
        }

        return <></>;
    }

    renderChart(id, data) {
        let el = document.querySelector(`#${this.id}`);
        if (!el || !data || !data[0]) {
            return;
        }

        let svg = document.querySelector(`#${this.id} svg`);
        svg && svg.remove();

        let opts = {
            id: this.id,
            width: el.offsetWidth,
            height: el.offsetHeight || 250,
            keys: this.props.keys || [],
        };

        this.chart(data, opts);
    }

    render() {
        if (!this.props.data || !this.props.data[0]) {
            return this.showError();
        } else {
            this.showError(false);
        }

        return (
            <div
                className="Chart"
                style={{ height: '100%', width: '100%', position: 'relative' }}
                id={this.id}
            ></div>
        );
    }
}

export default Chart;
