import Component from '../../components/Component';
import Loading from '../../components/Loading';
import ReactSelect from '../../components/ReactSelect';
import ReportComparisonItems from './items';
import { query } from './query';

class AlgoComparison extends Component {
    constructor() {
        super();
        this.query = query;

        this.handleDisable = this.handleDisable.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.showContent = this.showContent.bind(this);
        this.setDates = this.setDates.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            !!this.state.data &&
            this.state.data.length !== 0 &&
            JSON.stringify(prevState.data) !== JSON.stringify(this.state.data)
        ) {
            const toWeek = this.state.data.at(-1);
            const fromWeek =
                this.state.data.length > 5 ? this.state.data.at(-5) : this.state.data[0];
            const dates = {
                fromEnd: fromWeek?.end_date.value,
                fromStart: fromWeek?.start_date.value,
                toStart: toWeek?.start_date.value,
                toEnd: toWeek?.end_date.value,
            };
            this.setState({ ...dates, appliedDates: dates });
        }
    }

    handleChange(type, value) {
        this.setState({
            [type]: value,
        });
    }

    setDates() {
        const dates = {
            fromEnd: this.state.fromEnd,
            fromStart: this.state.fromStart,
            toStart: this.state.toStart,
            toEnd: this.state.toEnd,
        };
        this.setState({ appliedDates: dates });
    }

    showContent() {
        const { appliedDates } = this.state;
        if (!appliedDates) return false;

        for (let key in appliedDates) {
            if (!appliedDates[key]) return false;
        }

        return true;
    }

    handleDisable() {
        const { fromStart, fromEnd, toStart, toEnd } = this.state;

        const condition =
            !fromStart ||
            !fromEnd ||
            !toStart ||
            !toEnd ||
            new Date(fromStart) > new Date(fromEnd) ||
            new Date(toStart) <= new Date(fromEnd) ||
            new Date(this.state.toStart) > new Date(this.state.toEnd);

        if (condition) return true;

        return false;
    }

    render() {
        if (!this.state.data) return <Loading />;

        const startDates = this.state.data
            .map((x) => ({
                label: x.start_date.value,
                value: x.start_date.value,
            }))
            .sort((a, b) => new Date(b.value) - new Date(a.value));

        const endDates = this.state.data
            .map((x) => ({
                label: x.end_date.value,
                value: x.end_date.value,
            }))
            .sort((a, b) => new Date(b.value) - new Date(a.value));

        const { fromStart, fromEnd, toStart, toEnd, appliedDates } = this.state;

        const disableAllowBtn = this.handleDisable();

        return (
            <>
                <span
                    className={
                        (this.props.className || '') + ' dropdown list-group-item-action mb-10'
                    }
                >
                    <div className="show filter-options">
                        <div className="period-filter">
                            <div className="period-filter-item">
                                <ReactSelect
                                    options={startDates}
                                    value={startDates.find((x) => x.value === fromStart)}
                                    label="Start Date"
                                    isSearchable={false}
                                    placeholder="Start Date"
                                    onChange={(value) => this.handleChange('fromStart', value)}
                                    blurInputOnSelect={true}
                                    classname="mr-10"
                                />
                                <span></span>
                            </div>
                            <div className="period-filter-item">
                                <ReactSelect
                                    options={endDates}
                                    value={endDates.find((x) => x.value === fromEnd)}
                                    label="End Date"
                                    isSearchable={false}
                                    placeholder="End Date"
                                    onChange={(value) => this.handleChange('fromEnd', value)}
                                    classname="m-0"
                                />
                                {fromStart &&
                                    fromEnd &&
                                    new Date(fromStart) > new Date(fromEnd) && (
                                        <div className="error-message mx-15">
                                            The end date must be greater than the start date
                                        </div>
                                    )}
                            </div>
                        </div>
                        <div className="mb-10">vs.</div>
                        <div className="period-filter">
                            <div className="period-filter-item">
                                <ReactSelect
                                    label="Start Date"
                                    options={startDates}
                                    isSearchable={false}
                                    value={startDates.find((x) => x.value === toStart)}
                                    placeholder="Start Date"
                                    onChange={(value) => this.handleChange('toStart', value)}
                                    blurInputOnSelect={true}
                                    classname="mr-10"
                                />
                                {fromEnd && new Date(toStart) <= new Date(fromEnd) && (
                                    <div className="error-message">
                                        This value must be greater than {fromEnd}
                                    </div>
                                )}
                            </div>
                            <div className="period-filter-item">
                                <ReactSelect
                                    label="End Date"
                                    options={endDates}
                                    value={endDates.find((x) => x.value === toEnd)}
                                    isSearchable={false}
                                    placeholder="End Date"
                                    onChange={(value) => this.handleChange('toEnd', value)}
                                    classname="m-0"
                                />
                                {toStart && toEnd && new Date(toStart) > new Date(toEnd) && (
                                    <div className="error-message mx-15">
                                        The end date must be greater than the start date
                                    </div>
                                )}
                            </div>
                        </div>
                        <div>
                            <button
                                className="apply-button bg-purple"
                                onClick={this.setDates}
                                disabled={disableAllowBtn}
                            >
                                Apply
                            </button>
                        </div>
                    </div>
                </span>
                {this.showContent() && (
                    <ReportComparisonItems
                        author_message={this.props.author_message}
                        is_authors_disabled={this.props.is_authors_disabled}
                        dates={appliedDates}
                    />
                )}
            </>
        );
    }
}

export default AlgoComparison;
