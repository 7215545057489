import React from 'react';

export default class LoadingSkeleton extends React.Component {
    constructor() {
        super();

        this.state = {
            opacity: 0.3,
            in: true,
        };

        this.glow = this.glow.bind(this);
        this.timeId = window.setInterval(this.glow, 50);
    }

    componentWillUnmount() {
        clearInterval(this.timeId);
    }

    glow() {
        let newState = { ...this.state };
        if (this.state.in) {
            newState.opacity += 0.1;
            newState.in = newState.opacity > 1 ? false : true;
        } else {
            newState.opacity -= 0.1;
            newState.in = newState.opacity < 0 ? true : false;
        }

        this.setState(newState);
    }

    render() {
        let styles = {
            padding: this.props.padding || 0 + 'px',
        };

        return (
            <div className="card rounded-main overflow-hidden ">
                <div className="card-body">
                    <div className="Loading" style={styles}>
                        {this.props.type === 'chart' && (
                            <img
                                src="/static/img/skeleton_charts.png"
                                style={{
                                    opacity: this.state.opacity,
                                    width: '100%',
                                    height: '400px',
                                }}
                            />
                        )}
                        {this.props.type === 'table' && (
                            <img
                                src="/static/img/table-skeleton.png"
                                style={{
                                    opacity: this.state.opacity,
                                    width: '100%',
                                    height: '400px',
                                }}
                            />
                        )}
                    </div>
                </div>
            </div>
        );
    }
}
