import { Component } from 'react';
import RecommendedConceptsContainer from '../RecommendedConceptsContainer';
import RecommendedTopicsContainer from '../RecommendedTopicsContainer';
import RecommendedPages from '../RecommendedPages';
import LowCTR from '../LowCTR';
import { tags } from './keys';
import AnalyzeContent from '../AnalyzeContent';

class Summary extends Component {
    constructor() {
        super();
        this.onClick = this.onClick.bind(this);
        this.showElement = this.showElement.bind(this);
        this.addActive = this.addActive.bind(this);

        this.state = {
            types: ['analyze'],
        };
    }

    onClick(id) {
        if (id === 'all') {
            return this.setState({
                types: tags.map((tag) => tag.id),
            });
        }

        this.setState({
            types: [id],
        });
    }

    showElement(id) {
        return !this.state.types.length || this.state.types.includes(id);
    }

    addActive(id) {
        if (this.state.types.includes('all')) {
            if (id === 'all') return true;
            return false;
        }

        return this.state.types.includes(id);
    }

    render() {
        return (
            <>
                <div className="tags-container">
                    {tags.map((tag) => (
                        <span
                            className={`tab-item ${this.addActive(tag.id) && 'active-tag'}`}
                            key={tag.id}
                            onClick={() => this.onClick(tag.id)}
                        >
                            {tag.label}
                        </span>
                    ))}
                </div>
                <div
                    className="row"
                    style={{
                        display: this.showElement('analyze') ? 'flex' : 'none',
                    }}
                >
                    <AnalyzeContent
                        csrf_token={this.props.csrf_token}
                        is_superuser={this.props.is_superuser}
                        domain_id={this.props.domain_id}
                        report_name="pages"
                        all={true}
                    />
                </div>
                <div id="recommendations-content">
                    <div
                        className="row card-row"
                        id="recommendations-page"
                        style={{
                            display: this.showElement('pages') ? 'flex' : 'none',
                        }}
                    >
                        <div className="col-12">
                            <div className="card table-main rounded-main overflow-hidden">
                                <div className="card-header">
                                    <p className="card-title">Consider refreshing these Articles</p>
                                    <small className="d-block card-sub-title text-muted fs-7 text-capitalize py-2">
                                        The following pages drove traffic for 10+ days. If they are
                                        evergreen articles, consider refreshing periodically
                                    </small>
                                    <small className="d-block card-sub-title text-muted fs-7 text-capitalize py-2">
                                        {' '}
                                        <i className="icon-lightbulb-solid lightbulb-styles"></i>
                                        The articles below have great lifespan but haven't refreshed
                                        for awhile. Consider refreshing
                                    </small>
                                </div>
                                <div className="card-body">
                                    <RecommendedPages
                                        default_image={this.props.default_image}
                                        domain={this.props.domain}
                                        content_id="recommendations-page"
                                        parent_id="recommendations-content"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        id="recommended-topics-container"
                        style={{
                            display: this.showElement('entities') ? 'block' : 'none',
                        }}
                    >
                        <RecommendedTopicsContainer
                            domain={this.props.domain}
                            parent_id="recommendations-content"
                        />
                    </div>
                    <div
                        id="recommended-concepts-container"
                        style={{
                            display: this.showElement('concepts') ? 'block' : 'none',
                        }}
                    >
                        <RecommendedConceptsContainer
                            domain={this.props.domain}
                            parent_id="recommendations-content"
                        />
                    </div>
                    <div
                        className="row card-row"
                        id="recommendations-low-ctr"
                        style={{
                            display: this.showElement('lowCTR') ? 'flex' : 'none',
                        }}
                    >
                        <div className="col-12">
                            <LowCTR
                                domain={this.props.domain}
                                content_id="recommendations-low-ctr"
                                parent_id="recommendations-content"
                                default_image={this.props.default_image}
                            />
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Summary;
