import { pieChartKeys } from "./keys"

export const createDataForModule2 = (data) => {
    const sumData = data.reduce((acc, curr) => {
        for(let key in curr){
            if(key.includes('click') || key.includes('position') || key.includes('impression')){
                acc[key] = acc[key] ? acc[key] + curr[key] : curr[key]
            }
        }
        return acc
    }, {})
    
    const collectedData = pieChartKeys.map((item) => {
        const restData = item.keys.reduce((acc, curr) => {
            acc[curr.chartKey] = sumData[curr.key]
            return acc
        } , {})

        return ({
            label: item.label,
            ...restData
        })
    })

    return collectedData
}