import Component from '../../components/Component';

class Steps extends Component {
    constructor(props) {
        super();
        console.log(props.url)
    }

    render() {
        return (
            <div className="col-12">
                <div className="card table-main rounded-main overflow-hidden " id="google-discover">
                    <div className="card-header">
                        <div className="card-title text-dark fw-bold">4-Step Google Discover Optimization</div>
                    </div>
                    <div>
                    <div className="card-body row">
                        <div className="col">
                            <div className="row">
                                <div className="col-6 google-discover-steps-container"> 
                                    <div>
                                        <i className="icon-categories"></i>
                                    </div>
                                    <div className="google-discover-steps-container-link">
                                        <a
                                        target="_blank"
                                        href={`${this.props.url}/categories/`}
                                        rel="noreferrer"
                                        >
                                            <strong>Categories</strong> 
                                        </a>
                                        <div className="google-discover-steps-container-description">Identify Top performing and striking distance categories  </div>
                                    </div>
                                </div>
                                <div className="col-6 google-discover-steps-container"> 
                                    <div>
                                        <i className="icofont-like"></i> 
                                    </div>
                                    <div className="google-discover-steps-container-link">
                                        <a
                                        target="_blank"
                                        href={`${this.props.url}/entities/`}
                                        rel="noreferrer"
                                        >
                                            <strong>Entities</strong> 
                                        </a>
                                        <div className="google-discover-steps-container-description">Identify Top performing and striking distance entities</div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6 google-discover-steps-container"> 
                                    <div>
                                        <i className="icofont-users-alt-2"></i> 
                                    </div>
                                    <div className="google-discover-steps-container-link">
                                        <a
                                        target="_blank"
                                        href={`${this.props.url}/authors/`}
                                        rel="noreferrer"
                                        >
                                            <strong>Authors</strong> 
                                        </a>
                                        <div className="google-discover-steps-container-description">Identify Top performing and striking distance authors</div>
                                    </div>
                                </div>
                                <div className="col-6 google-discover-steps-container"> 
                                    <div>
                                        <i className="icon-pages"></i>
                                    </div>
                                    <div className="google-discover-steps-container-link">
                                        <a
                                        target="_blank"
                                        href={`${this.props.url}/opportunities/recommendations/`}
                                        rel="noreferrer"
                                        >
                                            <strong>Content</strong> 
                                        </a>
                                        <div className="google-discover-steps-container-description">Identify top performing content characteristics</div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Steps;
