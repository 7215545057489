import moment from 'moment';
import getDataRange from '../helpers/getDataRange';
import { chooseLast60Days } from '../helpers/last60DaysChoice';
import { showElements } from '../helpers/window';

function queryParams() {
    const out = {};
    const urlParams = new URLSearchParams(window.location.search);

    for (const [key, value] of urlParams) {
        if (key === 'last60' && value === 'true') {
            out.dates = chooseLast60Days;
            continue;
        }

        let cmp = '=';
        let karr = key.split(':');
        let cmps = { eq: '=', lt: '<', gt: '>', in: 'in' };
        if (karr[1]) {
            cmp = cmps[karr[1]];
        }

        out[karr[0]] = { key: karr[0], value: value, cmp: cmp };
    }

    return out;
}

class Filters {
    constructor() {
        let filters = window.localStorage.getItem('filters');
        let parsed = filters ? JSON.parse(filters) : {};
        const isTool = window.location.pathname.split('/')[1] === 'tools';

        if (!parsed?.dates?.id || (!isTool && parsed?.dates?.id === 'current_day')) {
            window.localStorage.removeItem('filters');
            parsed = {};
        }

        let defaultFilters = isTool
            ? {
                  dates: {
                      id: 'current_day',
                      key: 'dates',
                      label: 'Today',
                      values: getDataRange('current_day'),
                  },
                  device: 'MOBILE',
              }
            : {
                  dates: {
                      id: '90_days',
                      key: 'dates',
                      label: '90 Days',
                      values: getDataRange({ id: '90_days', days: 90 }),
                  },
                  device: 'MOBILE',
              };

        this.filters = Object.assign({}, defaultFilters, parsed, queryParams(), {
            domain: {
                key: 'domain',
                value: window.domain,
            },
        });
        this.update = this.update.bind(this);
        this.fns = [];
    }

    bind(fn) {
        this.fns.push(fn);
        return this.fns.length - 1;
    }

    get() {
        return this.filters;
    }

    map(old, neu) {
        for (let n in this.filter) {
            if (this.filters[n].key == old) {
                this.filters[n].key = neu;
            }
        }
    }

    sql(fmap) {
        let filters = JSON.parse(
            JSON.stringify(
                Object.assign(
                    {},
                    this.filters && this.filters.filters
                        ? this.filters.filters
                        : this.filters || {},
                ),
            ),
        );

        delete filters.device;

        for (let f in filters) {
            if (fmap) {
                filters[f] = fmap(filters[f]);
            }
        }

        let base = [];

        for (let d in filters) {
            if (filters[d].values) {
                let values = filters[d].values;
                for (let z in values) {
                    let v = values[z];
                    let key = v.key;
                    let value = typeof v.value == 'string' ? ` '${v.value}'` : v.value;

                    if (v.cmp == 'in') {
                        base.push(`${value} IN UNNEST(${key})`);
                    } else {
                        base.push(`${key} ${v.cmp || '='} ${value}`);
                    }
                }
            } else {
                let key = filters[d].key || d;
                let value =
                    typeof filters[d].value == 'string'
                        ? ` '${filters[d].value}'`
                        : filters[d].value;

                if (filters[d].cmp == 'in') {
                    base.push(`${value} IN UNNEST(${key})`);
                } else {
                    base.push(`${key} ${filters[d].cmp || '='} ${value}`);
                }
            }
        }

        return base.join(' AND ');
    }
    update(filter, opts) {
        let filters = {};
        let storedFilters = {};

        if (!opts) {
            filters = filter.key ? { [filter.key]: filter } : filter;
        } else {
            filters[filter] = { key: filter, value: opts.value || opts, cmp: opts.cmp || '=' };
        }

        for (let key in filters) {
            this.filters[key] = filters[key];
        }

        Object.assign(storedFilters, this.filters);

        for (let key in storedFilters) {
            if (['dates', 'domain', 'device'].indexOf(key) === -1) {
                delete storedFilters[key];
            }
        }

        window.localStorage.setItem('filters', JSON.stringify(storedFilters));

        this.fns.forEach((fn) => fn({ ...this.filters, alreadyInQueue: true }));
        showElements();
    }
}

let filters = window.filters || [];
window.filters = new Filters();

const start_date = window?.filters?.filters?.dates?.values?.start_date
const end_date= window?.filters?.filters?.dates?.values?.end_date
const shownDays = document.querySelectorAll('.shown-date-under-title')
shownDays?.forEach(item => {
    const customDates = item.getAttribute("custom")
    if(customDates){
        const range = getDataRange({days: customDates})
        item.innerHTML = `From ${moment(range.start_date?.value).format('YYYY-MM-DD')} to ${moment(range.end_date?.value).format('YYYY-MM-DD')}`  
    } else {
        item.innerHTML = `From ${moment(start_date?.value).format('YYYY-MM-DD')} to ${moment(end_date?.value).format('YYYY-MM-DD')}`  
    }
})
filters.forEach((d) => {
    window.filters.update(d);
});

export default window.filters; 
