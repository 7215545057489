import { Cell } from '@table-library/react-table-library/table';
import Sparkline from '../../../components/Sparklines';
import numeral from 'numeral';
import IgnoreButton from './ignoreButton';
import { dataWithouDomain } from '../../../helpers/collectUrl';
import { getPercent } from '../../../helpers/showDelta';
import { collectCell } from '../../../helpers/showPriorData';

export const COLUMNS = ({ endpoint_url, csrf_token, deleteIgnoredItem }) => [
    {
        label: '',
        key: 'clicksL',
        data: 'clicksL',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return null;
            const val = getPercent(data.at(-1), data[0]);

            return val <= -25 ? (
                <i
                    className="icon-200"
                    style={{ fontSize: '30px' }}
                    title="Optimization recommended"
                ></i>
            ) : null;
        },
    },
    {
        label: 'URL',
        key: 'url',
        data: 'url',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return (
                <div className="table-main d-flex justify-content-between">
                    <div className="d-flex align-items-center">
                        <strong className="table-url-strong">
                            <div>
                                <a
                                    href={`/domains/${window.info.id}/gsc-reports/urls/url/?url=${data}`}
                                    className="word-break"
                                    title={data}
                                >
                                    {dataWithouDomain(data)}
                                </a>
                            </div>
                        </strong>
                    </div>
                    <div className="d-flex align-items-center">
                        <a
                            target="_blank"
                            className="link-website d-inline-flex align-items-center justify-content-center"
                            href={data}
                            rel="noreferrer"
                        >
                            <i className="icon-external-link"></i>
                        </a>
                    </div>
                </div>
            );
        },
    },
    {
        label: 'Top Keyword (clicks)',
        key: 'kw_clicks',
        data: 'kw_clicks',
        Component: Cell,
        renderCell: ({ data, item, csvDownload }) => {
            if (csvDownload) return data;

            return (
                <div>
                    <strong>{numeral(data).format('0.0a')}</strong>
                    <div style={{ whiteSpace: 'pre-line' }}>
                        <a
                            href={`/domains/${
                                window.info.id
                            }/gsc-reports/keywords/keyword?keyword=${encodeURIComponent(
                                item.keyword,
                            )}`}
                            className="d-inline-flex align-items-cente"
                            rel="noreferrer"
                        >
                            {item.keyword}
                        </a>
                    </div>
                </div>
            );
        },
    },
    {
        label: 'First Seen -> Last Seen',
        key: 'seen',
        data: 'seen',
        Component: Cell,
        renderCell: ({ item, csvDownload }) => {
            if (csvDownload) return `${item.firstSeen.value} -> ${item.firstSeen.value}`;

            return (
                <div>
                    <strong>{item.firstSeen.value}</strong>
                    <div className="delta-number">{item.lastSeen.value}</div>
                </div>
            );
        },
    },
    {
        label: 'Max Clicks',
        key: 'maxClicks',
        data: 'maxClicks',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return <strong>{numeral(data).format('0.0a')}</strong>;
        },
    },
    {
        label: 'Avg. Weekly Clicks',
        key: 'avgClicks',
        data: 'avgClicks',
        Component: Cell,
        renderCell: ({ data, item, csvDownload }) => {
            if (csvDownload) return data;

            return collectCell(data, item.clicksL);
        },
    },
    {
        label: 'Trend',
        key: 'sparkline',
        data: 'sparkline',
        Component: Cell,
        renderCell: ({ item, csvDownload }) => {
            if (csvDownload) return '-';

            return (
                <Sparkline
                    dataL={item.clicksL}
                    keyName="Clicks"
                    labels={item.weeksL.map((x) => x.value)}
                />
            );
        },
    },
    {
        label: 'Keywords',
        key: 'avgKeywords',
        data: 'avgKeywords',
        Component: Cell,
        renderCell: ({ data, item, csvDownload }) => {
            if (csvDownload) return data;

            return collectCell(data, item.keywordsL);
        },
    },
    {
        label: 'Position',
        key: 'avgPosition',
        data: 'avgPosition',
        Component: Cell,
        renderCell: ({ data, item, csvDownload }) => {
            if (csvDownload) return data;

            return collectCell(data, item.positionL);
        },
    },
    {
        label: 'Impressions',
        key: 'impressions',
        data: 'impressions',
        Component: Cell,
        renderCell: ({ data, item, csvDownload }) => {
            if (csvDownload) return data;

            return collectCell(data, item.impressionsL);
        },
    },
    {
        label: 'CTR',
        key: 'avgCTR',
        data: 'avgCTR',
        Component: Cell,
        renderCell: ({ item, csvDownload }) => {
            const ctrValue = item.kw_clicks/item.impressions;
            if (csvDownload) return ctrValue;

            return collectCell(ctrValue, item.ctrL, '0.00%');
        },
    },
    {
        label: 'Actions',
        key: 'actions',
        data: 'actions',
        Component: Cell,
        renderCell: ({ item, csvDownload }) => {
            if (csvDownload) return null;

            return (
                <IgnoreButton
                    url={item.url}
                    endpoint_url={endpoint_url}
                    csrf_token={csrf_token}
                    deleteIgnoredItem={deleteIgnoredItem}
                />
            );
        },
    },
];

export const keywordItem = {
    label: 'Keyword',
    key: 'keyword',
    data: 'keyword',
    Component: Cell,
    renderCell: ({ item }) => {
        return item.keyword;
    },
};
