import { Component } from 'react';
import ReactSelect from '../../components/ReactSelect';
import DatePicker from 'react-datepicker';
import { getAccountForDomain } from './query';
import {
    createResource,
    createResourceDeviceTraffic,
    createResourceSearch,
    createResourceSearchAppearance,
    createResourceTrafficPerCountry,
    createResourceTrafficPerCountry2,
} from './config';
import TrafficTimeline from './components/Traffic';
import SearchQueries from './components/SearchQueries';
import DeviceTraffic from './components/DeviceTraffic';
import SearchAppearance from './components/SearchAppearance';
import TrafficByCountry from './components/TrafficByCountry';
import LoadingSkeleton from '../../components/LoadingSkeleton';
import moment from 'moment';

class LiveUrlComponent extends Component {
    constructor(props) {
        super();
        this.domains = JSON.parse(props.domainsfordropdown);

        this.onChangeInput = this.onChangeInput.bind(this);
        this.getData = this.getData.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.handleDateSelect = this.handleDateSelect.bind(this);
        this.getDataForTraffic = this.getDataForTraffic.bind(this);
        this.getDataForSearchQueries = this.getDataForSearchQueries.bind(this);

        this.url = new URL(window.location);

        this.domain = this.url.searchParams.get('domain');
        this.startDate = this.url.searchParams.get('start_date');
        this.endDate = this.url.searchParams.get('end_date');
        this.page = this.url.searchParams.get('url');

        const defaultDate = {
            startDate: moment(new Date()).subtract(30, 'days').format('YYYY-MM-DD'),
            endDate: moment(new Date()).format('YYYY-MM-DD'),
        };

        this.state = {
            domain_url: this.domain ?? '',
            domain_id: '',
            url: this.page ?? '',
            results: {},
            loading: false,
            dates: {
                startDate: new Date(this.startDate ?? defaultDate.startDate),
                endDate: new Date(this.endDate ?? defaultDate.endDate),
            },
        };
    }

    componentDidMount() {
        try {
            if (this.domain && this.page) {
                const optionList = this.domains
                    ?.filter((item) => item.active)
                    ?.map((domain) => ({ value: domain.url, label: domain.url, id: domain.id }));
                const selectedDomain = optionList.find((item) => item.value === this.domain);

                if (!selectedDomain) {
                    return this.setState({
                        error: {
                            select: this.domain + ' domain does not belong to you.',
                        },
                    });
                }
                this.onSubmit();
            }
        } catch (err) {
            console.log(err);
        }
    }

    onChangeInput(e) {
        try {
            this.setState({
                [e.target.name]: e.target.value,
            });
        } catch (err) {
            console.log(err);
        }
    }

    handleSelectChange(type, value) {
        this.setState({
            [type]: value,
        });
    }

    async getData(queries, account) {
        try {
            const response = await fetch(
                'https://us-central1-eseos-apps.cloudfunctions.net/gscTestingQuery',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        account,
                        queries,
                    }),
                },
            );
            const data = await response.json();
            const results = data?.results;
            if (data?.status === 200 && results) {
                this.setState({
                    results: results,
                    loading: false,
                });
            } else {
                this.setState({
                    error: {
                        ...this.state.error,
                    },
                    loading: false,
                });
            }
        } catch (err) {
            console.log(err);
            this.setState({ loading: false });
        }
    }

    getDataForTraffic(startDate, endDate, url, domain_url) {
        const queries = [];
        const webResource = createResource({ startDate, endDate, url, type: 'WEB' });
        const webQuery = {
            resource: webResource,
            domain: domain_url,
            key: 'traffic_web',
        };
        queries.push(webQuery);

        const discoverResource = createResource({ startDate, endDate, url, type: 'DISCOVER' });
        const discoverQuery = {
            resource: discoverResource,
            domain: domain_url,
            key: 'traffic_discover',
        };
        queries.push(discoverQuery);

        const googlenewsResource = createResource({ startDate, endDate, url, type: 'GOOGLENEWS' });
        const googlenewsQuery = {
            resource: googlenewsResource,
            domain: domain_url,
            key: 'traffic_googlenews',
        };
        queries.push(googlenewsQuery);

        const imageResource = createResource({ startDate, endDate, url, type: 'IMAGE' });
        const imageResourceQuery = {
            resource: imageResource,
            domain: domain_url,
            key: 'traffic_image',
        };
        queries.push(imageResourceQuery);

        const videoResource = createResource({ startDate, endDate, url, type: 'VIDEO' });
        const videoResourceQuery = {
            resource: videoResource,
            domain: domain_url,
            key: 'traffic_video',
        };
        queries.push(videoResourceQuery);

        const newsResource = createResource({ startDate, endDate, url, type: 'NEWS' });
        const newsQuery = {
            resource: newsResource,
            domain: domain_url,
            key: 'traffic_news',
        };
        queries.push(newsQuery);
        return queries;
    }

    getDataForSearchQueries(startDate, endDate, url, domain_url) {
        const resource = createResourceSearch({ startDate, endDate, url });
        const searchQueriesQuery = {
            resource,
            domain: domain_url,
            key: 'searchQueries',
        };

        return searchQueriesQuery;
    }

    getDataForDeviceTraffic(startDate, endDate, url, domain_url) {
        const resource = createResourceDeviceTraffic({ startDate, endDate, url });

        const deviceTrafficQuery = {
            resource,
            domain: domain_url,
            key: 'deviceTrafficQueries',
        };
        return deviceTrafficQuery;
    }

    getDataForSearchAppearance(startDate, endDate, url, domain_url) {
        const resource = createResourceSearchAppearance({ startDate, endDate, url });

        const searchAppearanceQuery = {
            resource,
            domain: domain_url,
            key: 'searchAppearance',
        };
        return searchAppearanceQuery;
    }

    getDataForTrafficPerCountry(startDate, endDate, url, domain_url) {
        const resource = createResourceTrafficPerCountry({ startDate, endDate, url });
        const resource2 = createResourceTrafficPerCountry2({ startDate, endDate, url });

        const countryQuery = {
            resource,
            domain: domain_url,
            key: 'country',
        };
        const countryQuery2 = {
            resource: resource2,
            domain: domain_url,
            key: 'country_2',
        };
        return [countryQuery, countryQuery2];
    }

    pushAllToUrl() {
        const {
            domain_url,
            url,
            dates: { startDate, endDate },
        } = this.state;

        this.url.searchParams.set('domain', domain_url);
        this.url.searchParams.set('url', url);
        this.url.searchParams.set('start_date', moment(startDate).format('YYYY-MM-DD'));
        this.url.searchParams.set('end_date', moment(endDate).format('YYYY-MM-DD'));

        history.pushState({}, '', this.url);
    }

    async onSubmit() {
        const {
            domain_url,
            url,
            dates: { startDate, endDate },
        } = this.state;
        this.setState({
            error: {},
        });
        try {
            if (!domain_url || !url || !startDate || !endDate)
                return this.setState({
                    error: { ...this.state.error, select: 'You need to fill all values' },
                });
            this.pushAllToUrl();

            this.setState({ loading: true, results: {} });
            const getAccountResponse = await getAccountForDomain(
                { domain_url },
                this.props.csrf_token,
            );
            const getAccount = await getAccountResponse.json();
            const account = getAccount?.account_data;
            if (!account)
                return this.setState({
                    error: { ...this.state.error, textarea: 'No Account for this domain.' },
                });

            const trafficQueries = this.getDataForTraffic(
                startDate,
                endDate,
                url,
                domain_url,
                account,
            );
            const searchQuery = this.getDataForSearchQueries(
                startDate,
                endDate,
                url,
                domain_url,
                account,
            );
            const deviceQuery = this.getDataForDeviceTraffic(
                startDate,
                endDate,
                url,
                domain_url,
                account,
            );
            const searchAppearanceQuery = this.getDataForSearchAppearance(
                startDate,
                endDate,
                url,
                domain_url,
                account,
            );
            const trafficPerCountryQueries = this.getDataForTrafficPerCountry(
                startDate,
                endDate,
                url,
                domain_url,
                account,
            );

            const queries = [
                ...trafficQueries,
                searchQuery,
                deviceQuery,
                searchAppearanceQuery,
                ...trafficPerCountryQueries,
            ];

            this.getData(queries, account);
        } catch (err) {
            console.log(err);
        }
    }

    handleDateSelect(date, key) {
        this.setState({
            dates: {
                ...this.state.dates,
                [key]: date,
            },
        });
    }

    render() {
        const optionList = this.domains
            ?.filter((item) => item.active)
            ?.map((domain) => ({ value: domain.url, label: domain.url, id: domain.id }));

        const data = this.state.results;
        const selectedDomain = optionList.find((item) => item.value === this.state.domain_url);

        const searchLink = `/domains/${selectedDomain?.id}/gsc-reports/urls/url/?url=${this.state.url}`;
        const discoverLink = `/domains/${selectedDomain?.id}/pages/page/?page=${this.state.url}`;
        return (
            <>
                <div className="card rounded-main table-main">
                    <div className="card-body">
                        <div className="form-live-url">
                            <label htmlFor="number_of_keywords ">
                                <strong>Number of keywords to analyze</strong>
                            </label>
                            <ReactSelect
                                options={optionList}
                                value={selectedDomain}
                                isSearchable={true}
                                onChange={(value) => this.handleSelectChange('domain_url', value)}
                                blurInputOnSelect={true}
                                classname="form-live-url-select"
                            />

                            <label htmlFor="url">
                                <strong>URL</strong>
                            </label>
                            <input
                                name="url"
                                type="text"
                                id="url"
                                className="form-live-url-input"
                                onChange={this.onChangeInput}
                                value={this.state.url}
                            />

                            <div className="d-flex justify-content-between my-3 live-url-date-filter">
                                <div className="w-50 mr-10">
                                    <div>Start Date</div>
                                    <DatePicker
                                        selected={this.state.dates.startDate}
                                        onChange={(date) =>
                                            this.handleDateSelect(date, 'startDate')
                                        }
                                        maxDate={this.state.dates.endDate}
                                    />
                                </div>
                                <div className="w-50">
                                    <div>End Date</div>
                                    <DatePicker
                                        selected={this.state.dates.endDate}
                                        onChange={(date) => this.handleDateSelect(date, 'endDate')}
                                        minDate={this.state.dates.startDate}
                                    />
                                </div>
                            </div>
                            {this.state?.error?.select && (
                                <div className="error-message">{this.state.error.select}</div>
                            )}
                            <div className="d-flex justify-content-end align-items-center">
                                <button
                                    className="btn btn-sm text-white bg-purple d-inline-block text-capitalize table-button"
                                    disabled={this.state.loading}
                                    onClick={this.onSubmit}
                                >
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.loading && (
                    <>
                        <LoadingSkeleton type={'chart'} />
                        <LoadingSkeleton type={'chart'} />
                        <LoadingSkeleton type={'table'} />
                        <LoadingSkeleton type={'table'} />
                        <LoadingSkeleton type={'table'} />
                        <LoadingSkeleton type={'table'} />
                    </>
                )}
                {data.traffic_discover && (
                    <TrafficTimeline
                        traffic_discover={data?.traffic_discover}
                        traffic_googlenews={data?.traffic_googlenews}
                        traffic_image={data?.traffic_image}
                        traffic_news={data?.traffic_news}
                        traffic_video={data?.traffic_video}
                        traffic_web={data?.traffic_web}
                    />
                )}
                {data.traffic_discover && selectedDomain && this.state.url && (
                    <div className="row card-row d-flex">
                        <div className="col-12">
                            <div className="card rounded-main table-main overflow-hidden ">
                                <div className="card-header">
                                    <div className="card-title text-dark fw-bold">Analysis</div>
                                </div>
                                <div className="card-body d-flex">
                                    <a
                                        className="button button-filled button-primary width-sm btn d-flex align-items-center justify-content-center mr-10"
                                        rel="noreferrer"
                                        target="_blank"
                                        href={discoverLink}
                                    >
                                        Discover Analysis
                                    </a>
                                    <a
                                        className="button button-filled button-primary width-sm btn d-flex align-items-center justify-content-center"
                                        rel="noreferrer"
                                        href={searchLink}
                                        target="_blank"
                                    >
                                        Search Analysis
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {data?.searchQueries?.length > 0 && <SearchQueries data={data?.searchQueries} />}
                {data?.deviceTrafficQueries?.length > 0 && (
                    <DeviceTraffic data={data?.deviceTrafficQueries} />
                )}
                {data?.searchAppearance?.length > 0 && (
                    <SearchAppearance data={data?.searchAppearance} />
                )}
                {data?.country?.length > 0 && (
                    <TrafficByCountry searchData={data?.country} discoverData={data?.country_2} />
                )}
            </>
        );
    }
}

export default LiveUrlComponent;
