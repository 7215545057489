import { COLUMNS } from './keys';
import { query } from './query';
import DataTable from '../../../../components/Datatable';
import { getDifference } from '../../../../helpers/showDelta';
import { noDataMessage } from '../../../../helpers/noDataMessage';
import Loading from '../../../../components/Loading';
import ExtraButtons from './extraBtns';
import LoadMoreComponent from '../../../../components/LoadMoreComponent';
import { v4 as uuid } from 'uuid';
import { scrollForTable } from '../../../../components/Datatable/utils';

class URLs extends LoadMoreComponent {
    constructor(props) {
        super();
        if (!props.data) this.query = query(props.dates);
        this.chooseFilter = this.chooseFilter.bind(this);

        this.offsetStep = 1000;
        this.url = new URL(window.location);
    }

    searchFunction(data, search) {
        return data.filter((item) =>
            item.url?.toLowerCase()?.includes(search?.toLowerCase()),
        );
    }

    async componentDidMount() {
        this.query.bind(this.setState.bind(this));
        await this.query.update();
    }

    componentDidUpdate(prevProps, prevState) {
        const filters = localStorage.getItem('filters');
        const data = this.state.data?.map((x) => ({ id: uuid(), ...x }));

        if (prevState.filters !== filters && !this.state.loading) {
            return this.setState({ allData: data, filters });
        }
        if (
            JSON.stringify(this.state.data) !== JSON.stringify(prevState.data) &&
            !this.state.loading
        ) {
            this.setState({ allData: [...this.state.allData, ...data] });
        }

        if (JSON.stringify(prevProps.dates) !== JSON.stringify(this.props.dates)) {
            this.query = query({ ...this.props.dates, filter: this.state.filter });
            this.query.bind(this.setState.bind(this));
            this.query.update();
            this.props.updateDates({ emptyAll: true });
        }
        if (
            this.state.allData &&
            JSON.stringify(this.state.allData) !== JSON.stringify(prevState.allData)
        ) {
            this.props.updateDates({ key: 'urls', value: this.state.allData });
        }
    }

    chooseFilter(filter) {
        if(filter){
            this.url.searchParams.set('defaultFilter', filter);
        }else{
            this.url.searchParams.delete('defaultFilter');
        }
        history.pushState({}, '', this.url);
        
        const newFilter = this.state.filter === filter ? null : filter;
        this.setState({
            filter: newFilter,
        });

        if(!newFilter){
            return  this.setState({
                filteredData : []
            })
        }

        let data = [];
        
        switch(filter){
            case 'growth': {
                data = this.state.allData.filter(x => x.clicks_2 > x.clicks_1 && x.clicks_1 > 0 && x.clicks_2 > 0)
                break;
            }
            case 'decline': {
                data = this.state.allData.filter(x => x.clicks_2 < x.clicks_1 && x.clicks_1 > 0 && x.clicks_2 > 0)
                break;
            }
            case 'new': {
                data = this.state.allData.filter(x => x.clicks_2 > 0 && !x.clicks_1 )
                break;
            }
            case 'lost': {
                data = this.state.allData.filter(x => x.clicks_1 > 0 && !x.clicks_2)
                break;
            }
            default: null
        }
        this.setState({
            filteredData : data
        })
    }

    render() {
        let data = this.state.allData?.length ? this.state.allData : this.props.data;
        if(this.state.filteredData?.length > 0){
            data = this.state.filteredData
        }
        if ((!data || !data.length) && this.state.loading && !this.props.data) return <Loading />;

        const attr = {
            query,
            ...this.props.dates,
            filter: this.state.filter ?? null,
            offsetStep: this.offsetStep,
        };

        const sortFns = {
            url: (array) =>
                array.sort((a, b) => a.url?.localeCompare(b?.url)),
                impressions_2: (array) => array.sort((a, b) => a.impressions_2 - b.impressions_2),
            ctr_period2: (array) => array.sort((a, b) => a.clicks_2/a.impressions_2 - b.clicks_2/b.impressions_2),
            position_2: (array) => array.sort((a, b) => a.position_2 - b.position_2),
            clicks_2: (array) => array.sort((a, b) => a.clicks_2 - b.clicks_2),
            impressions_delta: (array) =>
                array.sort(
                    (a, b) =>
                        getDifference(a.impressions_2, a.impressions_1) -
                        getDifference(b.impressions_2, b.impressions_1),
                ),
            clicks_delta: (array) =>
                array.sort(
                    (a, b) =>
                        getDifference(a.clicks_2, a.clicks_1) -
                        getDifference(b.clicks_2, b.clicks_1),
                ),
            avgPosition_delta: (array) =>
                array.sort(
                    (a, b) =>
                        getDifference(a.position_2, a.position_1) -
                        getDifference(b.position_2, b.position_1),
                ),
            avgCTR_delta: (array) =>
                array.sort(
                    (a, b) =>{
                        const a_ctrValue_2 = a.clicks_2/a.impressions_2;
                        const a_ctrValue_1 = a.clicks_1/a.impressions_1;
                        
                        const b_ctrValue_2 = b.clicks_2/b.impressions_2;
                        const b_ctrValue_1 = b.clicks_1/b.impressions_1;
                        return getDifference(a_ctrValue_2, a_ctrValue_1) -
                        getDifference(b_ctrValue_2, b_ctrValue_1)
                    }
                ),
        };
        const showLoadMoreBtn = this.state.data?.length === this.offsetStep;
        return (!data || !data.length) && !this.state.filter ? (
            <div>{noDataMessage}</div>
        ) : (
            <>
                <DataTable
                    nodes={data}
                    sortFns={sortFns}
                    COLUMNS={COLUMNS}
                    fileName="URLs"
                    defaultSortKey="clicks"
                    searchFunction={this.searchFunction}
                    forceRun={this.forceRun}
                    extraBtns={() => (
                        <ExtraButtons filter={this.state.filter} chooseFilter={this.chooseFilter} />
                    )}
                    additionalStyles={{
                        Table: `
                                --data-table-library_grid-template-columns : minmax(120px, 2fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) !important;
                               ${scrollForTable}
                            `,
                        BaseCell: ` font-family: 'Raleway';
                                        font-weight: 500;
                                        font-size: 16px;
                                        line-height: 19px;
                                        padding: 16px 5px;
                                        color: #0D182C;
                                        border-bottom: 1px solid rgba(13, 24, 44, 0.1);
                                    
                                        &.underline {
                                        text-decoration: underline;
                                        }`,
                    }}
                />
                {this.state.loadMore ? (
                    <Loading padding={10} />
                ) : (
                    showLoadMoreBtn && (
                        <div className="d-flex align-items-center justify-content-center pt-3 load-more-section">
                            <button
                                onClick={() => this.loadMore(attr)}
                                disabled={this.state.loading}
                                className="load-more-btn"
                            >
                                Load More
                            </button>
                        </div>
                    )
                )}
            </>
        );
    }
}

export default URLs;
