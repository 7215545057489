import React from 'react';
import ReactModal from '../Modal';
import Loading from '../Loading';
import _ from 'lodash';
import DeviceDropdown from '../../modules/DeviceDropdown';

class EditGSCDataModal extends React.Component {
    constructor(props) {
        super();

        this.updateInput = this.updateInput.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.updateSelect = this.updateSelect.bind(this);

        this.state = {
            best_translation: props.selectedItem?.best_translation,
            branded_terms: props.selectedItem?.branded_terms,
            evergreen_weeks: props.selectedItem?.evergreen_weeks,
            domain_url: props.selectedItem?.domain_url,
            deviyesce: props.selectedItem.device,
            questions: props.selectedItem.questions,
        };
    }

    updateInput(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    onSubmit(e) {
        e.preventDefault();

        this.props.onSubmit({ ...this.state });
    }

    updateSelect(device) {
        this.setState({ device });
    }

    render() {
        return (
            <ReactModal modalState={this.props.isOpen} className="width-sm">
                <form onSubmit={this.onSubmit}>
                    <div>
                        <div className="text-field mb-3">
                            <label htmlFor="exampleInputEmail1" className="form-label">
                                Best Translation
                            </label>
                            <input
                                name="best_translation"
                                className="form-control"
                                onChange={this.updateInput}
                                value={this.state.best_translation}
                            />
                        </div>
                        <div className="text-field mb-3">
                            <label htmlFor="exampleInputEmail1" className="form-label">
                                Branded Terms
                            </label>
                            <input
                                name="branded_terms"
                                className="form-control"
                                onChange={this.updateInput}
                                value={this.state.branded_terms}
                            />
                        </div>
                        <div className="text-field mb-3">
                            <label htmlFor="exampleInputEmail1" className="form-label">
                                Evergreen Weeks
                            </label>
                            <input
                                name="evergreen_weeks"
                                className="form-control"
                                type="number"
                                onChange={this.updateInput}
                                value={this.state.evergreen_weeks}
                            />
                        </div>
                        <div className="text-field mb-3">
                            <label htmlFor="exampleInputEmail1" className="form-label">
                                Questions Translation
                            </label>
                            <input
                                name="questions"
                                className="form-control"
                                onChange={this.updateInput}
                                value={this.state.questions}
                            />
                        </div>
                        <div className="text-field mb-3">
                            <label htmlFor="exampleInputEmail1" className="form-label">
                                GSC Data Import{' '}
                                <i
                                    className="icon-info cursor-pointer"
                                    style={{ marginLeft: '7px' }}
                                    title={
                                        ' Choose if you like to analyze GSC data for Mobile, Desktop, Tablet or All. Selecting "All" may double your monthly charges as it doubles the amount of data we collect and analyze.'
                                    }
                                ></i>
                            </label>
                            <DeviceDropdown
                                device={this.state.device}
                                updateSelect={this.updateSelect}
                            />
                        </div>
                    </div>
                    <div className="btn-wrapper">
                        <button
                            type="button"
                            className="button button-outline button-primary width-sm btn"
                            onClick={this.props.onClose}
                            style={{ lineHeight: 'unset' }}
                        >
                            Cancel
                        </button>
                        <button
                            className="button button-filled button-primary width-sm btn btn-with-loader"
                            disabled={this.props.isLoading}
                        >
                            Save
                            {this.props.isLoading && <Loading onlyPreloader />}
                        </button>
                    </div>
                </form>
            </ReactModal>
        );
    }
}

export default EditGSCDataModal;
