import numeral from 'numeral';

export const celebrityKeys = [
    {
        key: (d) => {
            return d.relevancy;
        },
        label: 'relevancy',
        change: true,
        classes: 'col-2',
        disableArrow: true,
    },
    {
        key: (d) => {
            let type = d.type.replace(/_/g, ' ');
            type = type[0].toUpperCase() + type.slice(1).toLowerCase();

            return (
                <div className="col-12 no-wrap ms-3">
                    <a
                        href={
                            `/domains/${window.info.id}/entities/entity?entities:in=` +
                            encodeURIComponent(d.name)
                        }
                    >
                        {d.name} ({type})
                    </a>
                </div>
            );
        },
        label: 'Celebrity',
        type: 'label',
        classes: 'no-wrap col-10',
    },
    {
        key: 'impressions',
        label: 'Impressions',
        format: '0.0a',
        hidden: true,
    },
    {
        key: 'ctr',
        label: 'CTR',
        format: '0.00%',
        render: (data) => numeral(data * 100).format('0.00'),
        hidden: true,
    },
    {
        key: (d, data) => {
            let imp = d.impressions / data.reduce((e, f) => e + f.impressions, 0);
            let ctr = d.ctr / (data.reduce((e, f) => e + f.ctr, 0) / data.length);

            return (imp + ctr) / 2;
        },
        label: 'Rank',
        bar: true,
        hidden: true,
    },
];

export const ConceptsSkeys = [
    {
        key: (d) => {
            return d.relevancy;
        },
        label: 'relevancy',
        change: true,
        classes: 'col-2',
        disableArrow: true,
    },
    {
        key: (d) => {
            return (
                <div className="col-12 no-wrap ms-3">
                    <a
                        href={
                            `/domains/${window.info.id}/entities/entity?entities:in=` +
                            encodeURIComponent(d.name)
                        }
                    >
                        {d.name}
                    </a>
                </div>
            );
        },
        label: 'Category',
        type: 'label',
        classes: 'col-10',
    },
    {
        key: 'impressions',
        label: 'Impressions',
        format: '0.0a',
        hidden: true,
    },
    {
        key: 'ctr',
        label: 'CTR',
        format: '0.00%',
        render: (data) => numeral(data * 100).format('0.00'),
        hidden: true,
    },
    {
        key: (d, data) => {
            let imp = d.impressions / data.reduce((e, f) => e + f.impressions, 0);
            let ctr = d.ctr / (data.reduce((e, f) => e + f.ctr, 0) / data.length);

            return (imp + ctr) / 2;
        },
        label: 'Rank',
        bar: true,
        hidden: true,
    },
];
