import React from 'react';
import Loading from '../../../components/Loading';

import { COLUMNS } from './keys';
import {query} from './query';
import { scrollForTable } from '../../../components/Datatable/utils';
import Component from '../../../components/Component';
import DataTable from '../../../components/Datatable';
import { noDataMessage } from '../../../helpers/noDataMessage';
import { isTrue } from '../../../helpers/isTrue';
import { getDifference } from '../../../helpers/showDelta';

class Authors extends Component {
    constructor(props) {
        super();
        if (!props.data) this.query = query(props.dates);
    }

    componentDidUpdate(prevProps, prevState) {
        if (JSON.stringify(prevProps.dates) !== JSON.stringify(this.props.dates)) {
            this.query = query(this.props.dates);
            this.query.bind(this.setState.bind(this));
            this.query.update();
            this.props.updateDates({ emptyAll: true });
        }
        if (this.state.data && JSON.stringify(this.state.data) !== JSON.stringify(prevState.data)) {
            this.props.updateDates({ key: 'authors', value: this.state.data });
        }
    }

    searchFunction(data, search) {
        return data.filter((item) => item.author.toLowerCase().includes(search.toLowerCase()));
    }
   

    render() {
        if(isTrue(this.props.is_authors_disabled)) return <div>{this.props.author_message}</div>

        const data = this.state.data?.length ? this.state.data : this.props.data;
        if ((!data || !data.length) && this.state.loading && !this.props.data) return <Loading />;

        const sortFns = {
            author: (array) => array.sort((a, b) => a.author.localeCompare(b.author)),
            impressions: (array) => array.sort((a, b) => a.impressions - b.impressions),
            clicks: (array) => array.sort((a, b) => a.clicks - b.clicks),
            lifespan: (array) => array.sort((a, b) => a.lifespan - b.lifespan),
            impressions_delta: (array) =>
                array.sort(
                    (a, b) =>
                        getDifference(a.impressions, a.impressions_prior) -
                        getDifference(b.impressions, b.impressions_prior),
                ),
            clicks_delta: (array) =>
                array.sort(
                    (a, b) =>
                        getDifference(a.clicks, a.clicks_prior) -
                        getDifference(b.clicks, b.clicks_prior),
                ),
            lifespan_delta: (array) =>
                array.sort(
                    (a, b) =>
                        getDifference(a.lifespan, a.lifespan_prior) -
                        getDifference(b.lifespan, b.lifespan_prior),
                ),
            ctr: (array) =>
                array.sort(
                    (a, b) => a.clicks / a.impressions - b.clicks / b.impressions,
                    ),
            ctr_delta: (array) =>
                array.sort(
                    (a, b) => {
                        const ctrValueA = a.clicks/a.impressions;
                        const ctrValuePriorA = a.clicks_prior/a.impressions_prior;
                        const ctrValueB = b.clicks/b.impressions;
                        const ctrValuePriorB = b.clicks_prior/b.impressions_prior;
                        
                        return getDifference(ctrValueA, ctrValuePriorA) -
                        getDifference(ctrValueB, ctrValuePriorB)
                    }
                ),
        };

        return !data || !data.length ? (
            <div>{noDataMessage}</div>
        ) : (
            <DataTable
                nodes={data}
                sortFns={sortFns}
                COLUMNS={COLUMNS}
                fileName="Authors"
                searchFunction={this.searchFunction}
                onlyTable
                forceRun={this.forceRun}
                additionalStyles={{
                    Table: `
                                --data-table-library_grid-template-columns : minmax(120px, 2fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) !important;
                               ${scrollForTable}
                            `,
                    BaseCell: ` font-family: 'Raleway';
                                font-weight: 500;
                                font-size: 16px;
                                line-height: 19px;
                                padding: 16px 5px;
                                color: #0D182C;
                                border-bottom: 1px solid rgba(13, 24, 44, 0.1);
                            
                                &.underline {
                                text-decoration: underline;
                                }`,
                }}
            />
        );
    }
}

export default Authors;
