import React from 'react';
import ReactModal from '../Modal';
import ManualClusterSelect from '../manualClusterModal';
import { v4 as uuid } from 'uuid';

class KeywordsModal extends React.Component {
    constructor() {
        super();

        this.onChange = this.onChange.bind(this);
        this.setValues = this.setValues.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

        this.state = {
            value: '',
        };
    }

    onChange(e) {
        this.setState({
            value: e.target.value,
        });
    }

    setValues(clusterId, callback){
        this.setState({
            clusterId,
            addList: callback
        })
    }

    onSubmit(){
        const data = {
            domain_url: window.filters.get().domain.value,
            cluster_type: 'keyword',
            values: this.state.value?.split('\n').filter((item) => item),
            cluster_id: this.state.clusterId,
        };

        this.state.addList(data)
        this.setState({
            loading: true
        })
    }

    render() {
        const values = this.state.value?.split('\n').filter((item) => item);
        return (
            <>
                <ReactModal
                    modalState={this.props.isOpen}
                    className="width-sm"
                    onClose={this.props.onClose}
                >
                    <h2>Keywords</h2>
                    <div className="urls-modal-container">
                        <label>You need to write each Kewyord on a new line</label>
                        <textarea onChange={this.onChange} value={this.state.value} />
                    </div>
                    <div className="mb-10">
                        <ManualClusterSelect
                            current_url={this.props.current_url}
                            csrf_token={this.props.csrf_token}
                            selectedItems={values.map((item) => ({
                                id: uuid(),
                                keyword: item,
                            }))}
                            cluster_type="keyword"
                            header="Add To Keywords List"
                            onRemoveAllSelected={this.onRemoveAllSelected}
                            onSaveList={this.props.onSaveKeywordsList}
                            fromManualList
                            setValues={this.setValues}
                        />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <button
                            type="button"
                            className="button button-filled button-primary width-sm btn mr-10"
                            onClick={this.onSubmit}
                            style={{ lineHeight: 'unset' }}
                            disabled={this.state.loading || !this.state.value || !this.state.clusterId}
                        >
                            Save
                        </button>
                        <button
                            type="button"
                            className="button button-outline button-primary width-sm btn"
                            onClick={this.props.onClose}
                            style={{ lineHeight: 'unset' }}
                        >
                            Close
                        </button>
                    </div>
                </ReactModal>
            </>
        );
    }
}

export default KeywordsModal;
