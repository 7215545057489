import Component from '../../../components/Component';
import Timeline from '../../../components/Timeline';
import Loading from '../../../components/Loading';

import query from './query';
import keys, { tooltips } from './keys';
import { getLabel } from '../../../helpers/date';
import { getRandomColor, labelColors } from '../../../lib/colors';

class Summary extends Component {
    constructor() {
        super();
        this.query = query;
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            this.state.data?.length &&
            JSON.stringify(prevState.data) !== JSON.stringify(this.state.data)
        ) {
            const tags = this.state.data
                ?.map((item) => item.searchtype)
                .filter((x, i, arr) => arr.indexOf(x) === i);
            tags.unshift('All');

            const allWeeks = [...new Set(this.state.data?.map((x) => x.week))].map((item) => {
                const dates = this.state.data.find((state) => state.week === item);
                return { week: item, start_date: dates.start_date, end_date: dates.end_date };
            });

            let collectedData = this.state.data?.reduce((acc, curr) => {
                const isExist = acc.find((x) => x.searchtype === curr.searchtype);
                if (isExist) {
                    acc = acc.map((item) => {
                        if (item.searchtype === curr.searchtype) {
                            item.elements.push(curr);
                        }
                        return item;
                    });
                } else {
                    acc.push({ searchtype: curr.searchtype, elements: [curr] });
                }

                return acc;
            }, []);

            collectedData = collectedData.map((item) => {
                if (item.elements?.length !== allWeeks?.length) {
                    allWeeks.forEach((state, i) => {
                        if (state.week !== item.elements[i]?.week) {
                            item.elements.splice(i, 0, state);
                        }
                    });
                }
                return item;
            });

            this.setState({
                type: 'All',
                collectedData,
                tags,
            });
        }
    }

    onChangeTags(tag_id) {
        this.setState({
            type: tag_id,
        });
    }

    render() {
        if (this.state.loading) {
            return <Loading />;
        }

        let chartData = {};
        if (this.state.type === 'All' || !this.state.type) {
            chartData = {
                labels: this.state.collectedData
                    ?.find((x) => x.searchtype === 'WEB')
                    ?.elements?.map((item) => [
                        getLabel(item?.start_date?.value),
                        getLabel(item?.end_date?.value),
                    ]),
                datasets: this.state.collectedData?.map((item, i) => {
                    const color = labelColors[i] || getRandomColor();
                    return {
                        label: item.searchtype,
                        type: 'line',
                        data: item.elements?.map((state) => state.clicks),
                        borderColor: color,
                        backgroundColor: color,
                        borderRadius: 4,
                        tension: 0.4,
                    };
                }),
            };
        } else {
            const selectedItem = this.state.collectedData?.find(
                (x) => x.searchtype === this.state.type,
            );
            chartData = {
                labels: selectedItem?.elements
                    ?.filter((x) => x.start_date)
                    .map((item) => [
                        getLabel(item?.start_date?.value),
                        getLabel(item?.end_date?.value),
                    ]),
                datasets: keys.map((item, i) => {
                    const color = labelColors[i] || getRandomColor();
                    return {
                        label: item.label,
                        type: 'line',
                        data: selectedItem?.elements?.map((state) =>
                            item.render ? item.render(state?.[item.key], state) : state?.[item.key],
                        ),
                        borderColor: color,
                        backgroundColor: color,
                        yAxisID: item.yAxisID || 'y',
                        hidden: item.hidden || false,
                        borderRadius: 4,
                        tension: 0.4,
                    };
                }),
            };
        }   

        return (
            <>
                <h3 className="custom-title">Google Surfaces Performance</h3>
                <div className="tags-container">
                    {this.state.tags?.map((tag) => (
                        <span
                            className={`tab-item extra-styles ${
                                tag === this.state.type && 'active-tag'
                            }`}
                            key={tag}
                            onClick={() => this.onChangeTags(tag)}
                            title={tooltips[tag]}
                        >
                            {tag}
                        </span>
                    ))}
                </div>
                <Timeline
                    chartSize="lg"
                    metricType="primary"
                    data={this.state.data}
                    error={this.state.error}
                    keys={keys}
                    parent_id={this.props.parent_id}
                    content_id={this.props.content_id}
                    forceRun={this.forceRun}
                    hasRightAxis
                    rightAxisLabel={
                        this.state.type === 'All' || !this.state.type ? '' : 'CTR (%) / Positions'
                    }
                    chartData={chartData}
                    xAxisTitle="Week"
                    xAxisKey="start_date"
                    leftAxisLabel={
                        this.state.type === 'All' || !this.state.type
                            ? 'Clicks'
                            : 'Clicks / Impressions'
                    }
                    gsc_report
                    showGoogleFeeds
                    dontShowMetrics
                />
            </>
        );
    }
}

export default Summary;
