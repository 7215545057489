import { Component } from 'react';
import Loading from '../../components/Loading';

import { getEntity } from './query';
import TrackedEntityReportEntity from '../TrackedEntityReportEntity';
import TrackEntityTopDomainsTimeline from '../TrackEntityTopDomainsTimeline';
import TrackedEntityReportDomains from '../TrackedEntityReport';
import TrackedEntityReportStories from '../TrackedEntityReportStories';
import TrackedEntityReportVideos from '../TrackedEntityReportVideos';
import Message from '../Message';
import SaveCollectionModal from '../../components/Modals/SaveCollectionModal';

class Summary extends Component {
    constructor() {
        super();

        this.urlParams = new URLSearchParams(window.location.search);

        this.is_one = this.urlParams.get('is_one');
        this.entity_ids = this.urlParams.get('entity_ids');
        this.language = this.urlParams.get('language');
        this.country = this.urlParams.get('country');
        this.base_domain = this.urlParams.get('base_domain');
        this.collection = this.urlParams.get('collection');

        this.state = {
            data: [],
            loading: true,
            isOpenSaveCollectionModal: false,
        };

        this.deleteMessage = this.deleteMessage.bind(this);
        this.getData = this.getData.bind(this);
        this.onCloseSaveCollectionModal = this.onCloseSaveCollectionModal.bind(this);
        this.onOpenSaveCollectionModal = this.onOpenSaveCollectionModal.bind(this);
        this.onOpenMessage = this.onOpenMessage.bind(this);

        this.url = new URL(window.location);
        this.urlParams = new URLSearchParams(this.url.search);
        this.urlParams.delete('base_domain');
        this.url.search = this.urlParams.toString();
        this.url = this.url.toString();
    }

    onOpenSaveCollectionModal() {
        this.setState({
            isOpenSaveCollectionModal: true,
        });
    }

    onCloseSaveCollectionModal() {
        this.setState({
            isOpenSaveCollectionModal: false,
        });
    }

    async getData(id) {
        const params = {
            entity_id: id,
            language: this.language,
            country: this.country,
        };
        const entity = await getEntity(this.props.endpoint_url, this.props.csrf_token, params);
        const response = await entity.json();
        if (entity.status === 200) {
            this.setState({
                data: [...this.state.data, response.result],
                loading: false,
            });
        } else {
            this.setState({
                message: response?.message,
                data: null,
                loading: false,
            });
        }
    }

    async componentDidMount() {
        try {
            this.setState({
                loading: true,
            });

            const ids = this.entity_ids.split(',');
            ids.forEach((item) => {
                this.getData(item);
            });
        } catch (err) {
            console.log('error', err);
        }
    }

    searchFunction(data, search) {
        return data;
    }

    onCloseModal() {
        this.setState({
            isOpen: false,
            image: null,
        });
    }

    onOpen(image) {
        this.setState({
            isOpen: true,
            image,
        });
    }

    deleteMessage() {
        this.setState({
            message: '',
        });
    }

    onOpenMessage(message_tag, message) {
        this.setState({
            message,
            message_tag,
        });
    }

    render() {
        if (this.state.loading) {
            return <Loading padding={10} />;
        }

        return (
            <>
                {this.state.data && (
                    <>
                        <div className="save-collection-container">
                            {this.base_domain && <a href={this.url}>Back To Full Report</a>}
                            {!this.is_one && !this.collection && (
                                <button onClick={this.onOpenSaveCollectionModal}>
                                    Save Collection
                                </button>
                            )}
                        </div>
                        <div id="data-entity-container">
                            <div
                                className="card table-main rounded-main overflow-hidden"
                                id="single-entity"
                            >
                                <TrackedEntityReportEntity
                                    className="row"
                                    default_image={this.props.default_image}
                                    endpoint_url={this.props.endpoint_url}
                                    csrf_token={this.props.csrf_token}
                                    data={this.state.data}
                                />
                            </div>
                            <div className="card rounded-main overflow-hidden " id="domain-chart">
                                <div className="card-body">
                                    <TrackEntityTopDomainsTimeline
                                        content_id="domain-chart"
                                        parent_id="data-entity-container"
                                    />
                                </div>
                            </div>
                            <div id="data-entity-domains">
                                <div>
                                    <TrackedEntityReportDomains
                                        default_image={this.props.default_image}
                                        endpoint_url={this.props.endpoint_url}
                                        csrf_token={this.props.csrf_token}
                                        content_id="data-entity-domains"
                                        parent_id="data-entity-container"
                                    />
                                </div>
                            </div>
                            <div
                                id="data-entity-stories"
                                className="card table-main rounded-main overflow-hidden"
                            >
                                <div className="card-header">
                                    <p className="card-title">Most Visible Pages</p>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <TrackedEntityReportStories
                                            default_image={this.props.default_image}
                                            endpoint_url={this.props.endpoint_url}
                                            csrf_token={this.props.csrf_token}
                                            content_id="data-entity-stories"
                                            parent_id="data-entity-container"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div
                                id="data-entity-videos"
                                className="card table-main rounded-main overflow-hidden"
                            >
                                <div className="card-header">
                                    <p className="card-title">Top Videos</p>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <TrackedEntityReportVideos
                                            default_image={this.props.default_image}
                                            endpoint_url={this.props.endpoint_url}
                                            csrf_token={this.props.csrf_token}
                                            content_id="data-entity-videos"
                                            parent_id="data-entity-container"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <SaveCollectionModal
                            isOpen={this.state.isOpenSaveCollectionModal}
                            csrf_token={this.props.csrf_token}
                            onOpenMessage={this.onOpenMessage}
                            onClose={this.onCloseSaveCollectionModal}
                            entityIds={this.state.data?.map((item) => item.id)}
                        />
                    </>
                )}
                {this.state.message && (
                    <div className="messages">
                        <Message
                            message_tags={this.state.message_tag ?? 'error'}
                            message={this.state.message}
                            deleteMessage={this.deleteMessage}
                        />
                    </div>
                )}
            </>
        );
    }
}

export default Summary;
