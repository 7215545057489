import moment from 'moment';
import { Cell } from '@table-library/react-table-library/table';
import numeral from 'numeral';

export const keysFunction = (default_image) => [
    {
        key: (d) => {
            return d.title || d.page;
        },
        render: (d) => {
            let title = d.entity;

            return (
                <div style={{ width: '100%' }} className="list-col">
                    <div
                        className="w-100 d-flex align-items-center"
                        style={{ paddingTop: 10, paddingBottom: 10 }}
                    >
                        <div style={{ marginRight: '10px', width: '100px' }}>
                            <img
                                onError={(event) => (event.target.src = default_image)}
                                src={d.image_url ?? default_image}
                                style={{
                                    width: '100px',
                                    display: 'inline-block',
                                    minHeight: '60px',
                                    background: '#BBB',
                                }}
                                loading="lazy"
                            />
                        </div>
                        <div>
                            <p className="text-title">
                                {title}{' '}
                                <a
                                    target="_blank"
                                    className="link-website d-inline-flex align-items-center justify-content-center"
                                    href={d.entity_url}
                                    rel="noreferrer"
                                >
                                    <i className="icon-external-link"></i>
                                </a>
                            </p>
                            <div className="additional-info align-center d-flex align-items-center">
                                <strong>
                                    Created: {moment(d?.created_at).format('YYYY-MM-DD') ?? '-'}
                                </strong>
                                <strong>
                                    Last Crawled:{' '}
                                    {moment(d?.last_crawl_date_time).format('YYYY-MM-DD') ?? '-'}
                                </strong>
                                <strong>Entity ID: {d.kg_id}</strong>
                                <strong>Entity Type: {d.entity_type}</strong>
                            </div>
                        </div>
                    </div>
                </div>
            );
        },
        label: 'Page',
        type: 'label',
        classes: 'col-6 d-flex align-items-center justify-content-center',
    },
];


export const COLUMNS = [
    {
        label: 'Entity',
        key: 'entity',
        data: 'entity',
        Component: Cell,
        renderCell: ({data, item, csvDownload}) => {
            if(csvDownload) return data;
            return <div>
                <strong>{data}</strong>
                <a
                    target="_blank"
                    className="link-website d-inline-flex align-items-center justify-content-center"
                    href={item.entity_url}
                    rel="noreferrer"
                >
                    <i className="icon-external-link"></i>
                </a>
            </div>
        }
    },
    {
        label: 'Created',
        key: 'created_at',
        data: 'created_at',
        Component: Cell,
        renderCell: ({data}) => {
            return moment(data).format('YYYY-MM-DD')
        }
    },
    {
        label: 'Last Crawled',
        key: 'last_crawl_date_time',
        data: 'last_crawl_date_time',
        Component: Cell,
        renderCell: ({data}) => {
            return moment(data).format('YYYY-MM-DD')
        }
    },
    {
        label: 'Entity ID',
        key: 'kg_id',
        data: 'kg_id',
        Component: Cell,
    },
    {
        label: 'Entity Type',
        key: 'entity_type',
        data: 'entity_type',
        Component: Cell,
    },
];
