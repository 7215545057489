import React from 'react';
import CustomPicker from './CustomPicker';
import getDataRange from '../../helpers/getDataRange';
import DeviceDropdown from '../../modules/DeviceDropdown';
import FilterDropdown from '../../modules/FilterDropdown';
import { isTrue } from '../../helpers/isTrue';
import moment from 'moment';

class FilterOptions extends React.Component {
    constructor(props) {
        super();

        let label = props.default.label || 'Select';

        this.onChange = this.onChange.bind(this);
        this.updateSelect = this.updateSelect.bind(this);
        this.updateFilter = this.updateFilter.bind(this);

        this.state = {
            label: label,
            open: false,
            device: window?.filters?.filters?.device ?? 'MOBILE',
            dateFilterValue: window?.filters?.filters?.dates?.id ?? 'last_week',
        };
    }

    onChange(choice) {
        this.setState({
            dateFilterValue: choice.id === 'custom' ? 'custom' : choice.value,
            label: choice.label,
            open: false,
        });
        const choiceValue = getDataRange(choice);
        const start_date = choiceValue.start_date
        const end_date = choiceValue.end_date
        const shownDays = document.querySelectorAll('.shown-date-under-title')
        shownDays?.forEach(item => {
            item.innerHTML = `From ${moment(start_date?.value).format('YYYY-MM-DD')} to ${moment(end_date?.value).format('YYYY-MM-DD')}`  
        })
        const selectedChoice = { ...choice, values: choiceValue };
        return (
            (choice.onSelect &&
                choice.onSelect(this.props.name, selectedChoice.value, selectedChoice)) ||
            (this.props.onChange && this.props.onChange(this.props.name, selectedChoice))
        );
    }

    updateSelect(device) {
        this.setState({ device });
        window.filters.update({ device });
    }

    updateFilter(choice) {
        this.onChange(choice);
    }

    render() {
        return (
            <span
                className={(this.props.className || '') + ' dropdown list-group-item-action'}
                style={{ marginBottom: '10px' }}
            >
                <div
                    className="show d-flex filter-options align-items-center flex-wrap"
                    aria-labelledby=""
                >
                    {this.props.gsc_report && (
                        <div style={{ maxWidth: '300px', margin: '0px 10px 10px 0px' }}>
                            <DeviceDropdown
                                device={this.state.device}
                                updateSelect={this.updateSelect}
                                padding={'2px 5px'}
                                show_all={this.props.show_all}
                            />
                        </div>
                    )}
                    {!isTrue(this.props.dont_show_date_filter) && (
                        <div style={{ maxWidth: '300px', margin: '0px 10px 10px 0px' }}>
                            <FilterDropdown
                                updateSelect={this.updateFilter}
                                options={this.props.choices}
                                dateFilterValue={this.state.dateFilterValue}
                                gsc_report={this.props.gsc_report}
                            />
                        </div>
                    )}
                </div>
            </span>
        );
    }
}

export default FilterOptions;
