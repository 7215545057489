import Query from '../../../components/Report';
import { getWeeks } from '../../../helpers/weeks';

const query = ({ offset = 0, current_url, last_week, prior_week }) =>
    new Query('search-urls-urls-details-table', {
        offset,
        current_url,
        last_week,
        prior_week,
    });
// const query = ({ offset = 0, current_url, last_week, prior_week }) =>
//     new Query(
//         `
//         select t1.*, t2.clicks clicksPrior, t2.impressions impressionsPrior, t2.position positionPrior, t2.ctr ctrPrior
//         from
//         (SELECT domain, week, keyword, start_date, end_date, sum(clicks) clicks, sum(impressions) impressions, avg(position) position, avg(ctr) ctr
//         FROM eseos-apps.data.gsc_keywords_urls
//         where domain = '{{{domain}}}'
//         and week = '${last_week}'
//         and url = '${current_url}'
//         group by 1,2,3,4,5
//         order by clicks desc) t1
//         left join
//         (SELECT domain, week, keyword, start_date, end_date, sum(clicks) clicks, sum(impressions) impressions, avg(position) position, avg(ctr) ctr
//         FROM eseos-apps.data.gsc_keywords_urls
//         where domain = '{{{domain}}}'
//         and week = '${prior_week}'
//         and url = '${current_url}'
//         group by 1,2,3,4,5
//         order by clicks desc) t2
//         on t1.keyword = t2.keyword
//         order by t1.clicks desc
//         limit 1000
//         offset ${offset}
//     `,
//         {
//             get_weeks: () => getWeeks(),
//             get_domain: true,
//         },
//     );

export default query;
