import React from 'react';

class Component extends React.Component {
    constructor() {
        super();

        this.forceRun = this.forceRun.bind(this);
        this.state = {
            loading: true,
        };
    }

    async componentDidMount() {
        if (this.query) {
            this.query.bind(this.setState.bind(this));
            await this.query.update();
        }
    }

    forceRun() {
        if (this.query) this.query.update({ force: true, alreadyInQueue: true });
    }
}

export default Component;
