import React, { Component } from 'react';
import DataTable from '../../components/Datatable';
import { scrollForTable } from '../../components/Datatable/utils';

class Table extends Component {
    constructor() {
        super();
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (JSON.stringify(this.props.data) !== JSON.stringify(nextProps.data)) {
            return true;
        } else {
            return false;
        }
    }

    render() {
        return (
            this.props.data?.length !== 0 && (
                <DataTable
                    nodes={this.props.data}
                    sortFns={() => {}}
                    COLUMNS={this.props.columns}
                    hasSort={false}
                    fileName="GSC Test Data"
                    searchFunction={this.props.searchFunction}
                    additionalStyles={{
                        Table: `
                    --data-table-library_grid-template-columns :${this.props.columns
                        .map((item) => ' minmax(120px, 1fr)')
                        .join('')} !important;
                   ${scrollForTable}
                `,
                        BaseCell: `  font-family: 'Raleway';
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 19px;
                        padding: 16px 5px !important;
                        color: #0D182C;
                        border-bottom: 1px solid rgba(13, 24, 44, 0.1);
                    
                        &.underline {
                            text-decoration: underline;
                        }
                        `,
                    }}
                />
            )
        );
    }
}

export default Table;
