import { chart_query } from './query';
import { TAGS, chart_keys } from './keys';
import { noDataMessage } from '../../../../helpers/noDataMessage';
import Component from '../../../../components/Component';
import Loading from '../../../../components/Loading';
import Timeline from '../../../../components/Timeline';
import { getLabel } from '../../../../helpers/date';

class OverallPerformanceChart extends Component {
    constructor(props) {
        super();
        if (!props.data) this.query = chart_query(props.dates);
    }

    componentDidUpdate(prevProps, prevState) {
        if (JSON.stringify(prevProps.dates) !== JSON.stringify(this.props.dates)) {
            this.query = chart_query(this.props.dates);
            this.query.bind(this.setState.bind(this));
            this.query.update();
            this.props.updateDates({ emptyAll: true });
        }
        if (this.state.data && JSON.stringify(this.state.data) !== JSON.stringify(prevState.data)) {
            this.props.updateDates({ key: 'overallPerformanceChart', value: this.state.data });
        }
    }

    onChangeTags(tag) {
        this.setState({
            tag: tag,
        });
    }

    render() {
        const data = this.state.data?.length ? this.state.data : this.props.data;
        if ((!data || !data.length) && this.state.loading) return <Loading />;
        const selectedTag = this.state.tag ?? 'MOBILE';
        const choosedData = data?.find((item) => item.device === selectedTag);

        const chartData = choosedData
            ? {
                  labels: choosedData?.dateL.map((item, i) => [
                      getLabel(item?.value),
                      getLabel(choosedData?.dateL2?.[i]?.value),
                  ]),
                  datasets: chart_keys.map((item) => ({
                      label: item.label,
                      type: item.type || 'line',
                      data: choosedData?.[item.key],
                      borderColor: item.labelColor,
                      backgroundColor: item.labelColor,
                      yAxisID: item.yAxisID || 'y',
                      hidden: item.hidden || false,
                      borderRadius: 4,
                      tension: 0.4,
                  })),
              }
            : {};
        return (
            <>
                <div className="tags-container">
                    {TAGS.map((tag) => (
                        <span
                            className={`tab-item extra-styles ${
                                tag === selectedTag && 'active-tag'
                            }`}
                            key={tag}
                            onClick={() => this.onChangeTags(tag)}
                        >
                            {tag}
                        </span>
                    ))}
                </div>
                {!data || !data.length || !choosedData ? (
                    <div>{noDataMessage}</div>
                ) : (
                    <>
                        <Timeline
                            metricType="primary"
                            data={choosedData?.dateL.map((item) => ({ date: item.value }))}
                            error={this.state.error}
                            keys={chart_keys}
                            forceRun={this.forceRun}
                            chartData={chartData}
                            dontShowMetrics
                            showGoogleFeeds
                            hasRightAxis
                            rightAxisLabel="CTR (%)"
                            rightSecondAxisLabel="Positions"
                            hasSecondRightAxis
                            secondRightAxisReversed
                        />
                    </>
                )}
            </>
        );
    }
}

export default OverallPerformanceChart;
