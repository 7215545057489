import React, { Component } from 'react';
import ReactSelect from '../../components/ReactSelect';
import { purchaseQuery } from './query';
import Loading from '../../components/Loading';
import Message from '../Message';
import BuyMoreEntitiesModal from './modal';

class PurchaseMoreEntity extends Component {
    constructor() {
        super();

        this.handleChange = this.handleChange.bind(this);
        this.onBuy = this.onBuy.bind(this);
        this.deleteMessage = this.deleteMessage.bind(this);
        this.calculateTotalPrice = this.calculateTotalPrice.bind(this);
        this.onChageCustomValue = this.onChageCustomValue.bind(this);
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);

        this.state = {
            selectedOption: 1,
            openCustomInput: false,
            customValue: 0,
            isLoading: false,
            plan: '',
            message: '',
            current_plan_interval: '',
            message_tags: '',
            isContentLoading: true,
            isOpenModal: false,
        };
    }

    openModal() {
        this.setState({
            isOpenModal: true,
        });
    }

    closeModal() {
        this.setState({
            isOpenModal: false,
        });
    }

    async onBuy() {
        try {
            this.setState({
                isLoading: true,
            });
            const params = {
                quantity: Number(this.state.customValue) || this.state.selectedOption,
                object_name: this.props.item.object_name,
            };

            const response = await purchaseQuery(params, this.props.csrf_token);
            this.closeModal();
            const data = await response.json();
            if (data.status === 400) {
                this.setState({
                    message_tags: 'error',
                    message: data.message,
                    isLoading: false,
                });
            } else {
                this.setState({
                    message_tags: 'success',
                    message: 'Your purchase has been successfully completed.',
                    isLoading: false,
                    selectedOption: 1,
                    customValue: 0,
                    openCustomInput: false,
                });
            }
        } catch (err) {
            this.setState({
                message_tags: 'error',
                message: 'Something went wrong',
                isLoading: false,
            });
            console.log(err);
        }
    }

    handleChange(value) {
        if (value === 'custom') {
            this.setState({
                selectedOption: '',
                openCustomInput: true,
            });
        } else {
            this.setState({
                selectedOption: value,
                openCustomInput: false,
                customValue: 0,
            });
        }
    }

    onChageCustomValue(e) {
        this.setState({
            customValue: e.target.value,
        });
    }

    deleteMessage() {
        this.setState({ message: '' });
    }

    calculateTotalPrice(selectedAmount) {
        const price = this.props.item.additional_price.find((item, i, arr) => {
            if (i === 0 && selectedAmount > item?.up_to) return false;
            if (i === 0 && selectedAmount <= item?.up_to) return true;
            if (i === arr.length - 1) return true;
            if (selectedAmount > arr[i - 1]?.up_to && selectedAmount <= item.up_to) return true;

            return false;
        })?.unit_amount;

        return price ? price / 100 : 0;
    }

    hasMoreThanThreeDecimals(num) {
        var decimalPart = (num % 1).toString();
        return decimalPart.length > 4;
    }

    toFix(num) {
        return this.hasMoreThanThreeDecimals(num) ? num.toFixed(2) : num;
    }

    getQuantity(item, i, arr) {
        const firstNumber = i === 0 ? 1 : arr[i - 1]?.up_to + 1;
        const secondNumber = item?.up_to ?? '...';
        let intro = '';
        if (i === 0) {
            intro = 'For the first';
        } else if (i === arr.length - 1) {
            intro = 'For the rest';
            return intro;
        } else {
            intro = 'For the next';
        }
        return (
            <div>
                {intro}{' '}
                <strong>
                    {firstNumber} to {secondNumber}
                </strong>
            </div>
        );
    }

    getObjectName() {
        switch (this.props.item.object_name) {
            case 'content_analysis':
                return 'ANALYSIS';
            case 'seats':
                return 'SEATS';
            default:
                return 'ENTITY';
        }
    }

    render() {
        const options = [
            { label: '1', value: 1 },
            { label: '2', value: 2 },
            { label: '5', value: 5 },
            { label: '10', value: 10 },
            { label: '20', value: 20 },
            { label: '50', value: 50 },
            {
                label: (
                    <div className="align-items-center">
                        <button className="dropdown-custom-btn">Custom Value</button>
                    </div>
                ),
                value: 'custom',
            },
        ];
        const selectedAmount = Number(this.state.customValue || this.state.selectedOption);
        const price = this.calculateTotalPrice(selectedAmount);
        return (
            <>
                <div>
                    <>
                        <div className="purchase-new-entity-container">
                            <div>
                                <ReactSelect
                                    options={options}
                                    value={options.find(
                                        (x) => x.value === this.state.selectedOption,
                                    )}
                                    label="Select Quantity"
                                    isSearchable={false}
                                    placeholder="Select Quantity"
                                    onChange={this.handleChange}
                                    blurInputOnSelect={true}
                                    classname="m-0 bold-label"
                                />
                                {this.state.openCustomInput && (
                                    <input
                                        type="number"
                                        className="entity-input mt-10"
                                        value={this.state.customValue}
                                        onChange={this.onChageCustomValue}
                                        min={0}
                                    />
                                )}
                            </div>
                            <div className="my-10">
                                <strong>Entity Pricing </strong>
                                {this.props.item.additional_price?.length !== 0 && (
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>QUANTITY</th>
                                                <th>PRICE PER {this.getObjectName()}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.props.item.additional_price?.map(
                                                (item, i, arr) => {
                                                    const quantity = this.getQuantity(item, i, arr);
                                                    return (
                                                        <tr key={item.up_to}>
                                                            <td>{quantity}</td>
                                                            <td>$ {item?.unit_amount / 100}</td>
                                                        </tr>
                                                    );
                                                },
                                            )}
                                        </tbody>
                                    </table>
                                )}
                            </div>
                        </div>
                        <div className="my-10">
                            <strong>Total Price -</strong> $ {this.toFix(selectedAmount * price)} /{' '}
                            {this.props.item.current_plan_interval}
                        </div>
                        <small className="d-block card-sub-title text-muted fs-7 py-2">
                            Please note, the entities tracking cost will be added to your{' '}
                            {this.props.item?.current_plan_interval} subscription invoice.
                        </small>
                        <button
                            className="button button-filled button-primary btn d-flex align-items-center justify-content-center mt-10 px-35"
                            onClick={this.openModal}
                            disabled={
                                !this.props.item.additional_price || !price || this.state.isLoading
                            }
                        >
                            Buy
                        </button>
                        {this.state.isLoading && <Loading padding={10} />}
                    </>
                </div>

                {this.state.message && (
                    <div className="messages">
                        <Message
                            message_tags={this.state.message_tags}
                            message={this.state.message}
                            deleteMessage={this.deleteMessage}
                        />
                    </div>
                )}
                <BuyMoreEntitiesModal
                    isOpen={this.state.isOpenModal}
                    onClose={this.closeModal}
                    entityCount={selectedAmount}
                    onSubmit={this.onBuy}
                    totalPrice={this.toFix(selectedAmount * price)}
                    period={this.props.item.current_plan_interval}
                />
            </>
        );
    }
}

export default PurchaseMoreEntity;
