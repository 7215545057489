import { Cell } from '@table-library/react-table-library/table';
import numeral from 'numeral';

export const COLUMNS = (default_image) => [
    {
        label: 'Domain',
        key: 'base_domain',
        data: 'base_domain',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if(csvDownload) return data;
            return (
                <div>
                    <div className="d-flex align-items-center">
                        <div style={{ float: 'left', marginRight: '10px' }}>
                            <img
                                src={`https://www.google.com/s2/favicons?domain=${data}`}
                                onError={(event) => (event.target.src = default_image)}
                                loading="lazy"
                                sizes="16px"
                                decoding="async"
                                height="16"
                            />
                        </div>
                        <strong className="wripe">
                            <a
                                href={`${window.location.href}&base_domain=${encodeURIComponent(
                                    data,
                                )}`}
                                className="wrap-text"
                            >
                                {data}
                            </a>
                        </strong>
                    </div>
                </div>
            );
        },
    },
    {
        label: 'Score',
        key: 'domainScore',
        data: 'domainScore',
        Component: Cell,
        info: "Score measures how often and prominently a domain appears in Discover based on Number of Appearances, Image Size and position in Google Discover feeds. Higher scores often mean better visibility and potential user engagement."
    },
    {
        label: 'Unique URLs',
        key: 'uniqueURLs',
        data: 'uniqueURLs',
        Component: Cell,
    },
    {
        label: 'Avg. Ranking',
        key: 'avgPosition',
        data: 'avgPosition',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return numeral(data).format('0.0');
        },
    },
    {
        label: 'Appearances',
        key: 'appearances',
        data: 'appearances',
        Component: Cell,
        info: "Appearances is The number of times a domain's content appears in the Google Discover feed within the selected time period. More appearances generally indicate higher visibility"
    },
    {
        label: 'Video appearances',
        key: 'hasVideos',
        data: 'hasVideos',
        Component: Cell,
    },
    {
        label: 'Small Videos',
        key: 'smallV',
        data: 'smallV',
        Component: Cell,
    },
    {
        label: 'Medium Videos',
        key: 'medV',
        data: 'medV',
        Component: Cell,
    },
    {
        label: 'Large Videos',
        key: 'largeV',
        data: 'largeV',
        Component: Cell,
    },
];
