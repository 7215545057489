import React from 'react';
import DataTable from '../../components/Datatable';
import { COLUMNS } from './keys';
import { updateDefaultPaymentMethod } from './query';
import { scrollForTable } from '../../components/Datatable/utils';

class PaymentMethods extends React.Component {
    constructor() {
        super();

        this.searchFunction = this.searchFunction.bind(this);
        this.setAsDefault = this.setAsDefault.bind(this);
    }

    searchFunction(data, search) {
        return data.filter((item) =>
            item.billing_details.name.toLowerCase().includes(search.toLowerCase()),
        );
    }

    setAsDefault(token) {
        const params = {
            existing_card: true,
            token,
            make_default: true,
        };

        updateDefaultPaymentMethod(this.props.endpoint_url, params, this.props.csrf_token)
            .then((res) => res.json())
            .then((data) => {
                const pathName = window.location.pathname.split('/');
                pathName.splice(2, 1, data.redirect_url);
                const newPath = pathName.join('/');
                window.location.pathname = newPath;
            })
            .catch((err) => console.log(err));
    }

    render() {
        const payment_methods = JSON.parse(this.props.payment_methods);
        const columns = COLUMNS({
            defaultMethod: this.props.default_payment_method,
            setAsDefault: this.setAsDefault,
        });
        return (
            <div className="payment-method-wrapper">
                <DataTable
                    nodes={payment_methods.data}
                    sortFns={() => {}}
                    COLUMNS={columns}
                    searchFunction={this.searchFunction}
                    onlyTable
                    hasSort={false}
                    additionalStyles={{
                        Table: `
                            --data-table-library_grid-template-columns :  minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) !important;
                           ${scrollForTable}
                        `,
                    }}
                />
            </div>
        );
    }
}

export default PaymentMethods;
