import { Component } from 'react';
import Loading from '../../../components/Loading';
import { setIgnoreKeywords } from './query';

class IgnoreButton extends Component {
    constructor() {
        super();

        this.onIgnoreKeywords = this.onIgnoreKeywords.bind(this);

        this.state = {
            loading: false,
        };
    }

    onIgnoreKeywords() {
        const params = {
            domain_url: window.filters.get().domain.value,
            ignore_type: 'keyword',
            ignore_value: this.props.keyword,
            report_type: 'best-keywords',
        };
        this.setState({ loading: true });

        setIgnoreKeywords(this.props.endpoint_url, params, this.props.csrf_token)
            .then((res) => res.json())
            .then((data) => {
                this.props.deleteIgnoredItem(this.props.keyword);
            })
            .catch((err) => console.log(err))
            .finally(() => this.setState({ loading: false }));
    }

    render() {
        return (
            <div>
                {this.state.loading ? (
                    <Loading onlyPreloader padding={10} />
                ) : (
                    <button
                        className="ignor-icon-btn"
                        onClick={this.onIgnoreKeywords}
                        title="Ignore Keyword"
                    >
                        <i className="icon-ignor"></i>
                    </button>
                )}
            </div>
        );
    }
}

export default IgnoreButton;
