import { Component } from 'react';
import Directories from './Directories';
import { TAGS } from './keys';
import SearchDistance from './StrikingDistance';
import KeywordClusters from './KeywordClusters';
import YearKeywords from './YearKeywords';
import moment from 'moment';
import Evergreen from './Evergreen';

class GSCReportComparisonItems extends Component {
    constructor() {
        super();
        this.onChangeTags = this.onChangeTags.bind(this);
        this.showElement = this.showElement.bind(this);
        this.addActive = this.addActive.bind(this);
        this.updateDatas = this.updateDatas.bind(this);

        this.url = new URL(window.location);
        let view = this.url.searchParams.get('view');

        if (view === 'all') {
            view = TAGS.map((tag) => tag.id);
        } else {
            view = [view];
        }

        this.state = {
            types: view,
            datas: {},
        };
    }

    onChangeTags(id) {
        this.url.searchParams.set('view', id);
        history.pushState({}, '', this.url);

        if (id === 'all') {
            return this.setState({
                types: TAGS.map((tag) => tag.id),
            });
        }

        this.setState({
            types: [id],
        });
    }

    showElement(id) {
        return !this.state.types.length || this.state.types.includes(id);
    }

    addActive(id) {
        if (this.state.types.includes('all')) {
            if (id === 'all') return true;
            return false;
        }

        return this.state.types.includes(id);
    }

    updateDatas({ key, value, emptyAll = false }) {
        if (emptyAll) {
            return this.setState({
                datas: {},
            });
        }

        this.setState({
            datas: {
                ...this.state.datas,
                [key]: value,
            },
        });
    }

    render() {
        const currentYear = moment().year();
        const lastYear = moment().subtract(1, 'years').year();
        return (
            <>
                <div className="tags-container">
                    {TAGS.map((tag) => (
                        <span
                            className={`tab-item ${this.addActive(tag.id) && 'active-tag'}`}
                            key={tag.id}
                            onClick={() => this.onChangeTags(tag.id)}
                        >
                            {tag.label}
                        </span>
                    ))}
                </div>
                {this.showElement(TAGS[0].id) && (
                    <div id="overall-table-content">
                        <div className="row card-row" id="overall-table">
                            <div className="col-12">
                                <div className="card table-main rounded-main overflow-hidden">
                                    <div className="card-header">
                                        <p className="card-title">Overall Performance</p>
                                        <small className="d-block card-sub-title text-muted fs-7 text-capitalize py-2">
                                            Optimize keywords ranking position 6 - 20 to maximize
                                            traffic. We have selected top 100 most impacted keywords
                                            over the past 90 days.
                                        </small>
                                    </div>
                                    <div className="card-body">
                                        <SearchDistance
                                            data={this.state.datas.strikingDistance}
                                            updateDatas={this.updateDatas}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {this.showElement(TAGS[1].id) && (
                    <div id="keywordClusters-content">
                        <div className="row card-row" id="keywordClusters-page">
                            <div className="col-12">
                                <div className="card table-main rounded-main overflow-hidden">
                                    <div className="card-header">
                                        <p className="card-title">Keyword Clusters</p>
                                        <small className="d-block card-sub-title text-muted fs-7 text-capitalize py-2">
                                            Optimize keywords clusters that have been declining over
                                            the past few weeks to maximize traffic. We have selected
                                            the 100 most impacted keyword clusters over the past 90
                                            days.
                                        </small>
                                    </div>
                                    <div className="card-body">
                                        <KeywordClusters
                                            data={this.state.datas.keywordClusters}
                                            updateDatas={this.updateDatas}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {this.showElement(TAGS[2].id) && (
                    <div id="directories-content">
                        <div className="row card-row" id="directories-page">
                            <div className="col-12">
                                <div className="card table-main rounded-main overflow-hidden">
                                    <div className="card-header">
                                        <p className="card-title">Directories</p>
                                        <small className="d-block card-sub-title text-muted fs-7 text-capitalize py-2">
                                            Optimize directories that have been declining over the
                                            past few weeks. We have selected the top 100 impacted
                                            directories over the past 90 days.
                                        </small>
                                    </div>
                                    <div className="card-body">
                                        <Directories
                                            data={this.state.datas.directories}
                                            updateDatas={this.updateDatas}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {this.showElement(TAGS[3].id) && (
                    <div id="directories-content">
                        <div className="row card-row" id="directories-page">
                            <div className="col-12">
                                <div className="card table-main rounded-main overflow-hidden">
                                    <div className="card-header">
                                        <p className="card-title">Evergreen</p>
                                        <small className="d-block card-sub-title text-muted fs-7 text-capitalize py-2">
                                            Optimize evergreen articles that have been declining
                                            over the past few weeks. We have selected the top 100
                                            evergreen articles over the past 365 days.
                                        </small>
                                    </div>
                                    <div className="card-body">
                                        <Evergreen
                                            data={this.state.datas.evergreen}
                                            updateDatas={this.updateDatas}
                                            ignored_urls={this.props.ignored_urls}
                                            csrf_token={this.props.csrf_token}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {this.showElement(TAGS[4].id) && (
                    <div id="directories-content">
                        <div className="row card-row" id="directories-page">
                            <div className="col-12">
                                <div className="card table-main rounded-main overflow-hidden">
                                    <div className="card-header">
                                        <p className="card-title">"Year" Keywords</p>
                                        <small className="d-block card-sub-title text-muted fs-7 text-capitalize py-2">
                                            Optimize Year Keywords that ranked for last year (
                                            {lastYear}) but no longer for current year (
                                            {currentYear}).
                                        </small>
                                    </div>
                                    <div className="card-body">
                                        <YearKeywords
                                            data={this.state.datas.yearKeywords}
                                            updateDatas={this.updateDatas}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </>
        );
    }
}

export default GSCReportComparisonItems;
