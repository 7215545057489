import Timeline from '../../../components/Timeline';
import Component from '../../../components/Component';
import Loading from '../../../components/Loading';

import query from './query';
import { keywordsKeys, urlsKeys } from './keys';
import { getLabel } from '../../../helpers/date';

class Summary extends Component {
    constructor() {
        super();
        this.query = query;
    }

    collectData(keys, labels) {
        const data = {
            labels: labels,
            datasets: keys.map((item) => ({
                label: item?.label,
                type: item.type ?? 'line',
                data: this.state.data.map((data) =>
                    item.render ? item.render(data[item.key]) : data[item.key],
                ),

                backgroundColor: [item.labelColor, item.labelColor],
                borderColor: item.labelColor,
                yAxisID: item.yAxisID ?? 'y',
            })),
        };

        return data;
    }

    render() {
        if (this.state.loading) {
            return (
                <div className='col-12'>
                    <div className="card rounded-main">
                        <div className="card-body">
                            <Loading />
                        </div>
                    </div>
                </div>
            );
        }

        // last version is week
        const labels = this.state.data.map((item) => [
            getLabel(item?.start_date?.value),
            getLabel(item?.end_date?.value),
        ]);

        const keywordChartData = this.collectData(keywordsKeys, labels);
        const urlsChartData = this.collectData(urlsKeys, labels);

        return (
            <>
                <div className="col-12 col-xl-12 col-xxl-6" id="content-lifespan" style={{paddingRight: '20px'}}>
                    <div className="card rounded-main">
                        <div className="card-body">
                            <Timeline
                                data={this.state.data}
                                error={this.state.error}
                                chartData={keywordChartData}
                                forceRun={this.forceRun}
                                parent_id="domain-page"
                                content_id="domain-charts"
                                xAxisTitle="Week"
                                keys={keywordsKeys}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-12 col-xl-12 col-xxl-6" id="per-content-perf">
                    <div className="card rounded-main">
                        <div className="card-body">
                            <Timeline
                                data={this.state.data}
                                error={this.state.error}
                                chartData={urlsChartData}
                                forceRun={this.forceRun}
                                parent_id="domain-page"
                                content_id="domain-charts"
                                xAxisTitle="Week"
                                keys={urlsKeys}
                            />
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Summary;
