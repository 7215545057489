import React, { Component } from 'react';
import ReportExplainer from '../index' 

class ReportExplainerTools extends Component {
    constructor(props) {
        super();
    }

    render() {
        const text = (
            <div className="report-explainer-custom">
                <div>
                    <strong>Why my site doesn't appear in the results?</strong>
                </div>
                If you're tracking a topic (entity) on GDdash and don't see your domain in the results, even though you expect it to be there, it could be due to a couple of reasons:
                <ul>
                    <li>Timing: GDdash tracks Google Discover feeds every 30 minutes. It's possible your content hasn't appeared in the feed yet or doesn't appear that frequently, so it wouldn't show up in the tracking results.</li>
                    <li>Nature of the Tracking: GDdash provides an overview of the most visible domains and pages in Google Discover. It's not an exhaustive list, so some of your content might not be included, even if it's relevant to the tracked keyword.</li>
                </ul>
                Think of GDdash as a compass, not a map. It gives you a general direction of what types of content perform well in Google Discover, but it doesn't capture every single piece of content.
                <div>
                    <strong>What is the score metric?</strong>
                </div>
                The Score metric in the GDdash Google Discover Tracking report is a composite value that measures the overall visibility and potential engagement of a domain's content in Google Discover. It's calculated based on several factors:
                <ul>
                    <li>Appearances: The number of times a domain's content appears in the Google Discover feed within the selected time period. More appearances generally indicate higher visibility.</li>
                    <li>Position: The average position of a domain's content in the Google Discover feed. Higher positions (closer to the top) are considered more valuable as they are more likely to be seen and clicked on by users.</li>
                    <li>Image Size: Whether a domain's content features a large or small image. Larger images have been shown to result in higher click-through rates (CTR), indicating greater user interest and engagement.</li>
                </ul>
                The Score metric is designed to give you a quick and easy way to assess how well a domain is performing in Google Discover, taking into account both visibility and potential engagement factors. A higher Score generally indicates better performance, although it's important to consider other factors like the relevance and quality of the content itself. 
            </div>
        )
        return(
            <ReportExplainer isNotText textcontent={text} title="GDdash Academy"/>
        );
    }
};

export default ReportExplainerTools;