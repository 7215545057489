import { COLUMNS } from './keys';
import { query } from './query';
import DataTable from '../../../../components/Datatable';
import Component from '../../../../components/Component';
import { noDataMessage } from '../../../../helpers/noDataMessage';
import Loading from '../../../../components/Loading';
import { scrollForTable } from '../../../../components/Datatable/utils';

class OverallPerformance extends Component {
    constructor(props) {
        super();
        if (!props.data) this.query = query(props.dates);
    }

    componentDidUpdate(prevProps, prevState) {
        if (JSON.stringify(prevProps.dates) !== JSON.stringify(this.props.dates)) {
            this.query = query(this.props.dates);
            this.query.bind(this.setState.bind(this));
            this.query.update();
            this.props.updateDates({ emptyAll: true });
        }
        if (this.state.data && JSON.stringify(this.state.data) !== JSON.stringify(prevState.data)) {
            this.props.updateDates({ key: 'overallPerformance', value: this.state.data });
        }
    }

    searchFunction(data, search) {
        return data.filter((item) => item.device.toLowerCase().includes(search.toLowerCase()));
    }

    render() {
        const data = this.state.data?.length ? this.state.data : this.props.data;
        if ((!data || !data.length) && this.state.loading && !this.props.data) return <Loading />;

        const total = data?.reduce(
            (acc, curr) => {
                acc = {
                    device: 'TOTAL',
                    clicks: acc.clicks + curr.clicks,
                    clicksPrior: acc.clicksPrior + curr.clicksPrior,
                    impressions: acc.impressions + curr.impressions,
                    impressionsPrior: acc.impressionsPrior + curr.impressionsPrior,
                    avgCTR: acc.avgCTR + curr.avgCTR,
                    avgCTRPrior: acc.avgCTRPrior + curr.avgCTRPrior,
                    avgPosition: acc.avgPosition + curr.avgPosition,
                    avgPositionPrior: acc.avgPositionPrior + curr.avgPositionPrior,
                };

                return acc;
            },
            {
                device: '',
                clicks: 0,
                clicksPrior: 0,
                impressions: 0,
                impressionsPrior: 0,
                avgCTR: 0,
                avgCTRPrior: 0,
                avgPosition: 0,
                avgPositionPrior: 0,
            },
        );

        return !data || !data.length ? (
            <div>{noDataMessage}</div>
        ) : (
            <DataTable
                nodes={[total, ...data]}
                sortFns={() => {}}
                COLUMNS={COLUMNS}
                uniqKey={'device'}
                fileName="Overall Performance"
                searchFunction={this.searchFunction}
                onlyTable
                hasSort={false}
                forceRun={this.forceRun}
                additionalStyles={{
                    Table: `
                                --data-table-library_grid-template-columns : minmax(120px, 2fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) !important;
                               ${scrollForTable}
                            `,
                    BaseCell: ` font-family: 'Raleway';
                                font-weight: 500;
                                font-size: 16px;
                                line-height: 19px;
                                padding: 16px 5px;
                                color: #0D182C;
                                border-bottom: 1px solid rgba(13, 24, 44, 0.1);
                            
                                &.underline {
                                text-decoration: underline;
                                }`,
                }}
            />
        );
    }
}

export default OverallPerformance;
