import { Component } from 'react';
import { tags } from './keys';
import CategorySingleTopPages from '../CategorySingleTopPages';
import CategoryTopEntities from '../CategoryTopEntities';
import CategoryTopAuthors from '../CategoryTopAuthors';
import CategoryTimeline from '../CategoryTimeline';


class EntityContentContainer extends Component {
    constructor() {
        super();
        this.onClick = this.onClick.bind(this);
        this.addActive = this.addActive.bind(this);
        this.showElement = this.showElement.bind(this);

        this.state = {
            types: ['categories'],
            alreadeOpenedAuthors: false,
            alreadeOpenedEntities: false,
        };
    }


    showElement(id) {
        return !this.state.types.length || this.state.types.includes(id);
    }

    onClick(id) {
        if(id === 'authors') {
            return this.setState({
                types: [id],
                alreadeOpenedAuthors: true
            });
        }else if(id === 'entities'){
            return this.setState({
                types: [id],
                alreadeOpenedEntities: true
            });
        }
        return this.setState({
            types: [id],
        });
    }

    addActive(id) {
        return this.state.types.includes(id);
    }

    render() {
      
        return (
            <>
                <div className="row card-row" id="category-timeline">
                    <div className="col-12">
                        <div className="card rounded-main overflow-hidden">
                            <div className="card-body">
                                <CategoryTimeline content_id="category-timeline" parent_id="category-page" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="tags-container">
                    {tags.map((tag) => (
                        <span
                            className={`tab-item ${this.addActive(tag.id) && 'active-tag'}`}
                            key={tag.id}
                            onClick={() => this.onClick(tag.id)}
                        >
                            {tag.label}
                        </span>
                    ))}
                </div>
                <div id="category-page">
                    <div className="row card-row" id="category-top-pages" style={{
                        display: this.showElement('categories') ? 'flex' : 'none',
                    }}>
                        <div className="col-12">
                            <div className="card table-main rounded-main overflow-hidden">
                                <div className="card-header">
                                    <p className="card-title">Category Pages</p>
                                </div>
                                <div className="card-body">
                                    <CategorySingleTopPages default_image="{% static 'img/placeholder-img.png' %}" content_id="category-top-pages" parent_id="category-page"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.state.alreadeOpenedEntities && (
                        <div className="row card-row" id="entity_pages"   style={{
                                display: this.showElement('entities') ? 'flex' : 'none',
                            }}>
                            <div className="col-12">
                                <div className="card table-main rounded-main overflow-hidden">
                                    <div className="card-header">
                                        <div className="card-title text-dark fw-bold">Top Entities</div>
                                    </div>
                                    <div className="card-body">
                                        <CategoryTopEntities />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {this.state.alreadeOpenedAuthors && (
                        <div className="row card-row" id="entity_pages"   style={{
                                display: this.showElement('authors') ? 'flex' : 'none',
                            }}>
                            <div className="col-12">
                                <div className="card table-main rounded-main overflow-hidden">
                                    <div className="card-header">
                                        <div className="card-title text-dark fw-bold">Top Authors</div>
                                    </div>
                                    <div className="card-body">
                                        <CategoryTopAuthors />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </>
        );
    }
}
export default EntityContentContainer;
