export const getData = (endpoint_url) => {
    return fetch(`${endpoint_url}/tools/entities`);
};

export const onDisableEntityQuery = (endpoint_url, csrf_token, id, status) => {
    return fetch(`${endpoint_url}/tools/status-entity/${id}/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrf_token,
        },
        body: JSON.stringify({ status }),
    });
};

export const getCollectedEntities = (csrf_token) => 
 fetch(`/tools/collections`, {
    headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': csrf_token,
    },
});

export const deleteCollection = (csrf_token, id) => 
 fetch(`/tools/collections/delete/${id}/`, {
    method: 'DELETE',
    headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': csrf_token,
    },
});