import { Cell } from '@table-library/react-table-library/table';
import numeral from 'numeral';
import Sparkline from '../../components/Sparklines';

export const COLUMNS = (domains, isDark) => [
    {
        label: 'Domain',
        key: 'domain',
        data: 'domain',
        Component: Cell,
        style: (item) => {
            const searchColor = isDark ? '#1e2a35' : '#d3d3d3' 
            const discoverColor = isDark ? '#2a3142' : 'white' 
            const color = item.dataType === 'search' ? searchColor : discoverColor;
            return { backgroundColor: color };
        },
        renderCell: ({ data, item, csvDownload }) => {
            const domain = domains.find((item) => item.url === data);
            if (csvDownload) return domain?.name ?? data;

            const href =
                item.dataType === 'search'
                    ? `/domains/${domain?.id}/gsc-reports/`
                    : `/domains/${domain?.id}/`;

            return (
                <>
                    <strong>
                        {domain?.name?.includes('https') ? (
                            <img
                                src={`https://t2.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=${domain?.name}&size=32`}
                                alt="domain-img"
                                className="domain_img"
                                loading="lazy"
                            />
                        ) : (
                            <img
                                src={`https://t2.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=https://${domain?.name} &size=32`}
                                alt="domain-img"
                                className="domain_img"
                                loading="lazy"
                            ></img>
                        )}
                        <a href={href} className="d-inline-flex align-items-cente" rel="noreferrer">
                            {domain?.name}
                        </a>
                    </strong>
                    <div style={{ paddingLeft: '26px' }}>{item.domain}</div>
                </>
            );
        },
    },
    {
        label: 'Data Type',
        key: 'dataType',
        data: 'dataType',
        Component: Cell,
        style: (item) => {
            const searchColor = isDark ? '#1e2a35' : '#d3d3d3' 
            const discoverColor = isDark ? '#2a3142' : 'white' 
            const color = item.dataType === 'search' ? searchColor : discoverColor;
            return { backgroundColor: color };
        },
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return (
                <div>
                    <strong>{data}</strong>
                </div>
            );
        },
    },
    {
        label: 'Clicks',
        key: 'clicks',
        data: 'clicks',
        Component: Cell,
        style: (item) => {
            const searchColor = isDark ? '#1e2a35' : '#d3d3d3' 
            const discoverColor = isDark ? '#2a3142' : 'white' 
            const color = item.dataType === 'search' ? searchColor : discoverColor;
            return { backgroundColor: color };
        },
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return (
                <div>
                    <strong>{numeral(data).format('0.0a')}</strong>
                </div>
            );
        },
    },
    {
        label: 'Trend',
        key: 'clicks_sparkline',
        data: 'clicks_sparkline',
        Component: Cell,
        style: (item) => {
            const searchColor = isDark ? '#1e2a35' : '#d3d3d3' 
            const discoverColor = isDark ? '#2a3142' : 'white' 
            const color = item.dataType === 'search' ? searchColor : discoverColor;
            return { backgroundColor: color };
        },
        renderCell: ({ item, csvDownload }) => {
            if (csvDownload) return null;
            const dontChangeLabel = item.dataType === 'search';
            // const labels = dontChangeLabel ? item.weeksL : item.days?.map((x) => x.value);
            const labels = item.daysL?.map((x) => x.value);
            const titleType = !dontChangeLabel ? (date) => `DATE: ${date}` : null;
            return (
                <Sparkline
                    dataL={item.clicksL}
                    keyName="Clicks"
                    labels={labels}
                    dontChangeLabel={dontChangeLabel}
                    titleType={titleType}
                />
            );
        },
    },
    {
        label: 'Impressions',
        key: 'impressions',
        data: 'impressions',
        Component: Cell,
        style: (item) => {
            const searchColor = isDark ? '#1e2a35' : '#d3d3d3' 
            const discoverColor = isDark ? '#2a3142' : 'white' 
            const color = item.dataType === 'search' ? searchColor : discoverColor;
            return { backgroundColor: color };
        },
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return (
                <div>
                    <strong>{numeral(data).format('0.0a')}</strong>
                </div>
            );
        },
    },
    {
        label: 'CTR',
        key: 'avgCTR',
        data: 'avgCTR',
        Component: Cell,
        style: (item) => {
            const searchColor = isDark ? '#1e2a35' : '#d3d3d3' 
            const discoverColor = isDark ? '#2a3142' : 'white' 
            const color = item.dataType === 'search' ? searchColor : discoverColor;
            return { backgroundColor: color };
        },
        renderCell: ({ item, csvDownload }) => {
            const ctrValue = item.clicks/item.impressions;
            if (csvDownload) return ctrValue;

            return (
                <div>
                    <strong>{numeral(ctrValue).format('0.00%')}</strong>{' '}
                </div>
            );
        },
    },
    {
        label: 'Unique Pages',
        key: 'uniquePages',
        data: 'uniquePages',
        Component: Cell,
        style: (item) => {
            const searchColor = isDark ? '#1e2a35' : '#d3d3d3' 
            const discoverColor = isDark ? '#2a3142' : 'white' 
            const color = item.dataType === 'search' ? searchColor : discoverColor;
            return { backgroundColor: color };
        },
        renderCell: ({ data, csvDownload }) => {
            if (!data) return null;
            if (csvDownload) return data;

            return (
                <div>
                    <strong>{numeral(data).format('0.0a')}</strong>
                </div>
            );
        },
    },
    {
        label: 'Position',
        key: 'avgPosition',
        data: 'avgPosition',
        Component: Cell,
        style: (item) => {
            const searchColor = isDark ? '#1e2a35' : '#d3d3d3' 
            const discoverColor = isDark ? '#2a3142' : 'white' 
            const color = item.dataType === 'search' ? searchColor : discoverColor;
            return { backgroundColor: color };
        },
        renderCell: ({ data, csvDownload }) => {
            if (!data) return null;
            if (csvDownload) return data;

            return (
                <div>
                    <strong>{numeral(data).format('0.0a')}</strong>
                </div>
            );
        },
    },
];

export const EMPTY_DATA_KEYS = (domains, permission_errors) => [
    {
        label: 'Domain',
        key: 'domain',
        data: 'domain',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;
            const domainId = domains.find((item) => item.url === data)?.id;

            return (
                <strong>
                    {data.includes('https') ? (
                        <img
                            src={`https://t2.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=${data}&size=32`}
                            alt="domain-img"
                            className="domain_img"
                            loading="lazy"
                        />
                    ) : (
                        <img
                            src={`https://t2.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=https://${data} &size=32`}
                            alt="domain-img"
                            className="domain_img"
                            loading="lazy"
                        ></img>
                    )}
                    <a
                        href={`/domains/${domainId}/gsc-reports/`}
                        className="d-inline-flex align-items-cente"
                        rel="noreferrer"
                    >
                        {data}
                    </a>
                </strong>
            );
        },
    },
    {
        label: 'Status',
        key: 'status',
        data: 'status',
        Component: Cell,
        renderCell: ({item}) => {
            return <div className='wrap-text'>{permission_errors?.find(state => state.domain === item.domain)?.message ? <a href='/settings'>{permission_errors?.find(state => state.domain === item.domain)?.message}</a> : "No Data Yet, contact info@gddash.com if no data for 72 hours"}</div>;
        },
    },
];
