import { Cell } from '@table-library/react-table-library/table';
import numeral from 'numeral';
import Sparkline from '../../../components/Sparklines';

export const COLUMNS = (setCountry) => [
    {
        label: 'Country',
        key: 'country',
        data: 'country',
        Component: Cell,
        renderCell: ({ data, item, csvDownload }) => {
            if(csvDownload) return data;
            return (
                <button
                    className="country-btn"
                    onClick={() => setCountry(item)}
                    style={{
                        background: 'unset',
                        border: 'none',
                    }}
                >
                    {data}
                </button>
            );
        },
    },
    {
        label: 'Share',
        key: 'share',
        data: 'share',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if(csvDownload) return data;
            return <strong>{numeral(data).format('0.00%')}</strong>;
        },
    },
    {
        label: 'Clicks',
        key: 'clicks',
        data: 'clicks',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if(csvDownload) return data;
            return <strong>{numeral(data).format('0.0a')}</strong>;
        },
    },
    {
        label: 'Trend',
        key: 'clicks_sparkline',
        data: 'clicks_sparkline',
        Component: Cell,
        renderCell: ({ item, csvDownload }) => {
            if (csvDownload) return '-';

            return (
                <Sparkline
                    dataL={item.clicksL}
                    keyName="Clicks"
                    labels={item.dateL.map((x) => x.value)}
                />
            );
        },
    },
    {
        label: 'Impressions',
        key: 'impressions',
        data: 'impressions',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if(csvDownload) return data;
            return <strong>{numeral(data).format('0.0a')}</strong>;
        },
    },
    {
        label: 'Position',
        key: 'avgPosition',
        data: 'avgPosition',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if(csvDownload) return data;
            return <strong>{numeral(data).format('0.0a')}</strong>;
        },
    },
    {
        label: 'CTR',
        key: 'avgCTR',
        data: 'avgCTR',
        Component: Cell,
        renderCell: ({ item, csvDownload }) => {
            if(csvDownload) return item.clicks/item.impressions;
            return <strong>{numeral(item.clicks/item.impressions).format('0.00%')}</strong>;
        },
    },
];
