import React, { Component } from 'react';
import ReactSelect from '../../components/ReactSelect';

class ExtraBtns extends Component {
    constructor() {
        super();
    }

    render() {
        const options = [
            { label: 'All', value: '' },
            { label: 'PERSON', value: 'PERSON' },
            { label: 'ORGANIZATION', value: 'ORGANIZATION' },
            { label: 'EVENT', value: 'EVENT' },
            { label: 'NUMBER', value: 'NUMBER' },
            { label: 'WORK_OF_ART', value: 'WORK_OF_ART' },
            { label: 'CONSUMER_GOOD', value: 'CONSUMER_GOOD' },
            { label: 'DATE', value: 'DATE' },
            { label: 'PRICE', value: 'PRICE' },
            { label: 'ADDRESS', value: 'ADDRESS' },
            { label: 'PHONE_NUMBER', value: 'PHONE_NUMBER' },
            { label: 'Other', value: 'Other' },
        ];
        return (
            <ReactSelect
                options={options}
                isSearchable={false}
                value={options.find((item) => item.value === this.props.selectedType)}
                placeholder="Type"
                onChange={(value) => this.props.handleTypeChange(value)}
                blurInputOnSelect={true}
                classname="mr-10"
            />
        );
    }
}

export default ExtraBtns;
