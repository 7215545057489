export const modifiedText = (originalText) =>
    originalText.replace(/&#(\d+);/g, (match, dec) => String.fromCharCode(dec));

export function escape(htmlStr) {
    return htmlStr
        .replace(/&amp;/g, '&')
        .replace(/&lt;/g, '<')
        .replace(/&gt;/g, '>')
        .replace(/&quot;/g, '\\"')
        .replace(/&#39;/g, '\'')
        .replace(/&#x27;/g, '"');
}
