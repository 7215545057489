import React from 'react';
import Loading from '../../../components/Loading';

import { COLUMNS } from './keys';
import {query} from './query';
import { scrollForTable } from '../../../components/Datatable/utils';
import Component from '../../../components/Component';
import DataTable from '../../../components/Datatable';
import { noDataMessage } from '../../../helpers/noDataMessage';
import { getDifference } from '../../../helpers/showDelta';

class Entities extends Component {
    constructor(props) {
        super();
        if (!props.data) this.query = query(props.dates);
    }

    componentDidUpdate(prevProps, prevState) {
        if (JSON.stringify(prevProps.dates) !== JSON.stringify(this.props.dates)) {
            this.query = query(this.props.dates);
            this.query.bind(this.setState.bind(this));
            this.query.update();
            this.props.updateDates({ emptyAll: true });
        }
        if (this.state.data && JSON.stringify(this.state.data) !== JSON.stringify(prevState.data)) {
            this.props.updateDates({ key: 'entities', value: this.state.data });
        }
    }

    searchFunction(data, search) {
        return data.filter((item) => item.name.toLowerCase().includes(search.toLowerCase()));
    }
   

    render() {
        const data = this.state.data?.length ? this.state.data : this.props.data;
        if ((!data || !data.length) && this.state.loading && !this.props.data) return <Loading />;

        const sortFns = {
            name: (array) => array.sort((a, b) => a.name.localeCompare(b.name)),
            unique_pages: (array) =>
                array.sort((a, b) => {
                    return a.unique_pages - b.unique_pages;
                }),
            impressions: (array) => array.sort((a, b) => a.impressions - b.impressions),
            pages_cnt: (array) => array.sort((a, b) => a.pages_cnt - b.pages_cnt),
            median_impressions_per_entity: (array) => array.sort((a, b) => a.median_impressions_per_entity - b.median_impressions_per_entity),
            salience: (array) => array.sort((a, b) => a.salience - b.salience),
            avgCTR: (array) =>
                array.sort(
                    (a, b) => a.sum_clicks / a.impressions - b.sum_clicks / b.impressions,
                ),
            median_impressions_per_page: (array) =>
                array.sort((a, b) => a.median_impressions_per_page - b.median_impressions_per_page),
            impressions_delta: (array) =>
                array.sort(
                    (a, b) =>
                        getDifference(a.impressions, a.impressions_prior) -
                        getDifference(b.impressions, b.impressions_prior),
                ),
            pages_cnt_delta: (array) =>
                array.sort(
                    (a, b) =>
                        getDifference(a.pages_cnt, a.pages_cnt_prior) -
                        getDifference(b.pages_cnt, b.pages_cnt_prior),
                ),
            median_impressions_per_entity_delta: (array) =>
                array.sort(
                    (a, b) =>
                        getDifference(a.median_impressions_per_entity, a.median_impressions_per_entity_prior) -
                        getDifference(b.median_impressions_per_entity, b.median_impressions_per_entity_prior),
                ),
            salience_delta: (array) =>
                array.sort(
                    (a, b) =>
                        getDifference(a.salience, a.salience_prior) -
                        getDifference(b.salience, b.salience_prior),
                ),
        };


        return !data || !data.length ? (
            <div>{noDataMessage}</div>
        ) : (
            <DataTable
                nodes={data}
                sortFns={sortFns}
                COLUMNS={COLUMNS}
                fileName="Entities"
                searchFunction={this.searchFunction}
                onlyTable
                forceRun={this.forceRun}
                additionalStyles={{
                    Table: `
                                --data-table-library_grid-template-columns : minmax(120px, 2fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) !important;
                               ${scrollForTable}
                            `,
                    BaseCell: ` font-family: 'Raleway';
                                font-weight: 500;
                                font-size: 16px;
                                line-height: 19px;
                                padding: 16px 5px;
                                color: #0D182C;
                                border-bottom: 1px solid rgba(13, 24, 44, 0.1);
                            
                                &.underline {
                                text-decoration: underline;
                                }`,
                }}
            />
        );
    }
}

export default Entities;
