export const setApiKey = (data, csrf_token) => {
    return fetch('/commerces/semrush/set-key', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrf_token,
        },
        body: JSON.stringify(data),
    });
};
