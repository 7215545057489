import { Cell } from '@table-library/react-table-library/table';
import moment from 'moment';
import ActionButtons from './actionButtons';

export const COLUMNS = (current_url, csrf_token, onOpenModal) => [
    {
        label: 'Active Users',
        key: 'user',
        data: 'user',
        Component: Cell,
        pinLeft: true,
        renderCell: ({ data, item, csvDownload }) => {
            if(csvDownload) return data;
            return (
                <div className="table-main">
                    <div className="d-flex align-items-center">
                        <button
                            onClick={() => onOpenModal(data, item.import_keywords)}
                            className="word-break user-names-btn"
                        >
                            <strong className="table-url-strong">
                                <div className="word-break">{data}</div>
                            </strong>
                        </button>
                        <a href={`/customers/login_as/${item.userId}`}>
                            <i className="icofont-login"></i>
                        </a>
                    </div>
                </div>
            );
        },
    },
    {
        label: 'Domain',
        key: 'domain',
        data: 'domain',
        Component: Cell,
        pinLeft: true,
        renderCell: ({ data, csvDownload, item }) => {
            if (csvDownload) return data;

            return (
                <div className="table-main">
                    <div className="d-flex align-items-center">
                        <a
                            target="_blank"
                            className="link-website d-inline-flex align-items-center justify-content-center m-0 mr-10"
                            href={`/halubcnd/docs/log/?q=${encodeURIComponent(data)}`}
                            rel="noreferrer"
                            title="Open logs"
                        >
                            <i className="icon-external-link"></i>
                        </a>
                        <strong className="table-url-strong">
                            <a
                                href={`/domains/${item?.domain_id}/gsc-reports/`}
                                className="d-inline-flex align-items-cente"
                                rel="noreferrer"
                            >
                                {data}
                            </a>
                        </strong>
                    </div>
                    <div className="logs-buttons">
                        <a
                            href={`/halubcnd/docs/log/?q=${encodeURIComponent(data)}`}
                            target="_blank"
                            rel="noreferrer"
                        >
                            Logs
                        </a>
                        <a
                            href={`/halubcnd/docs/linkedaccountsgranterror/?q=${encodeURIComponent(
                                data,
                            )}`}
                            target="_blank"
                            rel="noreferrer"
                        >
                            Grants
                        </a>
                    </div>
                </div>
            );
        },
    },
    {
        label: 'Discover avg Daily Records',
        key: 'DavgDailyRecords',
        data: 'DavgDailyRecords',
        Component: Cell,
    },
    {
        label: 'DcollectionDays',
        key: 'DcollectionDays',
        data: 'DcollectionDays',
        Component: Cell,
    },
    {
        label: 'DlastCollection',
        key: 'DlastCollection',
        data: 'DlastCollection',
        Component: Cell,
        renderCell: ({ data }) => {
            return data?.value ?? null;
        },
    },
    {
        label: 'DlastDate',
        key: 'DlastDate',
        data: 'DlastDate',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if(csvDownload) return data;
            if (!data?.value) return null;
            let date = new Date();
            date.setDate(date.getDate() - 3);
            const dateModefied = moment(date).format('YYYY-MM-DD');
            const style = new Date(data?.value) < new Date(dateModefied) ? { color: 'red' } : {};
            return <div style={style}>{data?.value}</div>;
        },
    },
    {
        label: 'DlastRecordsCollected',
        key: 'DlastRecordsCollected',
        data: 'DlastRecordsCollected',
        Component: Cell,
    },
    {
        label: 'Drecords',
        key: 'Drecords',
        data: 'Drecords',
        Component: Cell,
    },
    {
        label: 'avgWeeklyRecords',
        key: 'avgWeeklyRecords',
        data: 'avgWeeklyRecords',
        Component: Cell,
    },
    {
        label: 'collectionWeeks',
        key: 'collectionWeeks',
        data: 'collectionWeeks',
        Component: Cell,
    },
    {
        label: 'firstDate',
        key: 'firstDate',
        data: 'firstDate',
        Component: Cell,
        renderCell: ({ data }) => {
            return data?.value ?? null;
        },
    },
    {
        label: 'lastCollection',
        key: 'lastCollection',
        data: 'lastCollection',
        Component: Cell,
        renderCell: ({ data }) => {
            return data?.value ?? null;
        },
    },
    {
        label: 'lastDate',
        key: 'lastDate',
        data: 'lastDate',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if(csvDownload) return data;
            if (!data?.value) return null;
            let date = new Date();
            date.setDate(date.getDate() - 8);
            const dateModefied = moment(date).format('YYYY-MM-DD');
            const style = new Date(data?.value) < new Date(dateModefied) ? { color: 'red' } : {};
            return <div style={style}>{data?.value}</div>;
        },
    },
    {
        label: 'lastRecordsCollected',
        key: 'lastRecordsCollected',
        data: 'lastRecordsCollected',
        Component: Cell,
    },
    {
        label: 'records',
        key: 'records',
        data: 'records',
        Component: Cell,
    },
    {
        label: 'Actions',
        key: 'actions',
        data: 'actions',
        Component: Cell,
        renderCell: ({ item, csvDownload }) => {
            if(csvDownload) return null;
            return (
                <ActionButtons
                    current_url={current_url}
                    csrf_token={csrf_token}
                    domain={item.domain}
                />
            );
        },
    },
];
