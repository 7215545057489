export const addItem = (currentData, item) => {
    let result = currentData;
    if (currentData) {
        let lastIndex = result.lastIndexOf(')');
        if (lastIndex !== -1) {
            result =
                result.substring(0, lastIndex) + `, '${item}')` + result.substring(lastIndex + 1);
        }
    } else {
        result = `('${item}')`;
    }

    return result;
};

export function getDeletedDomainsWithIds(data){
    if(typeof data !== 'object') return ''
    
    let arr = []
    
    for(let key in data){
        arr.push({...data[key], id: key})
    }

    return arr
}

export function addItemIndeletedList(data, cluster_id, keyword){
    const isExists = data.find(item => item.id === cluster_id)
    if(isExists){
        return data.map(item => {
            if(item.id === cluster_id){
                return ({...item, keywords: [...item.keywords, keyword]})
            }
            return {...item}
        })
    }

    const newCluster = {
        id: cluster_id,
        keywords: [keyword]
    }
    data.push(newCluster)
    return data;
}
export function addItemInDeletedListUrls(data, cluster_id, url){
    const isExists = data.find(item => item.id === cluster_id)
    if(isExists){
        return data.map(item => {
            if(item.id === cluster_id){
                return ({...item, urls: [...item.urls, url]})
            }
            return {...item}
        })
    }

    const newCluster = {
        id: cluster_id,
        urls: [url]
    }
    data.push(newCluster)
    return data;
}