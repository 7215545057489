import Component from '../../components/Component';
import { isTrue } from '../../helpers/isTrue';
import TopAuthors from '../TopAuthors';
import TopCelebrities from '../TopCelebrities';
import TopConcepts from '../TopConcepts';

class DomainsWidgetContainer extends Component {
    constructor() {
        super();
        this.state = { emptyEntities: false };
        this.setIsEmptyEntities = this.setIsEmptyEntities.bind(this);
    }

    setIsEmptyEntities(value) {
        this.setState({ emptyEntities: value });
    }

    render() {
        const isShowAuthors = !isTrue(this.props.report_permission_all_authors)
        return (
            <div className="row">
                <div
                    className={`col-12 col-xl-12 col-xxl-6`}
                    id="top-entities"
                    style={{ paddingRight: '15px' }}
                >
                    <div className="card rounded-main overflow-hidden">
                        <div className="card-header row">
                            <a href={`/domains/${window.info.id}/authors`}>
                                Top Entities (By Impressions) <i className="icon-arrow-right"></i>
                            </a>
                        </div>
                        <div className="card-body">
                            <TopCelebrities
                                setIsEmpty={this.setIsEmptyEntities}
                                emptyEntities={this.state.emptyEntities}
                                content_id="top-entities"
                                direct_parent_id="widgets"
                                parent_id="domain-page"
                            />
                        </div>
                    </div>
                </div>
                <div
                    className="col-12 col-xl-12 col-xxl-6 card rounded-main overflow-hidden"
                    style={{ maxHeight: '496px' }}
                    id={'top-authors'}
                >
                    <div className="card-header">
                        <a href={`/domains/${window.info.id}/authors`}>
                            Top Authors (By Impressions) <i className="icon-arrow-right"></i>
                        </a>
                    </div>
                    <div className="card-body">
                        {isShowAuthors ? 
                            <TopAuthors
                                plan_type={this.props.plan_type}
                                content_id={'top-authors'}
                                direct_parent_id={'widgets'}
                                parent_id="domain-page"
                            />
                        : 
                            "Top Authors data is disabled by main user." 
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default DomainsWidgetContainer;
