import React from 'react';
import ReactModal from '../Modal';

class SaveCollectionModal extends React.Component {
    constructor() {
        super();

        this.urlParams = new URLSearchParams(window.location.search);

        this.onSubmit = this.onSubmit.bind(this)
        this.onChange = this.onChange.bind(this)

        this.entity_ids = this.urlParams.get('entity_ids');

        this.state = {
            name: '',
            loading: false,
        }
    }

    onChange(e){
        this.setState({
            name: e.target.value
        })
    }

    async onSubmit(e){
        try{

            e.preventDefault();
    
            if(!this.state.name || !this.entity_ids) return;
            const data = {
                name: this.state.name,
                entities: this.props.entityIds
            }
            this.setState({
                loading: true
            })
            await fetch(`/tools/collections/add/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': this.props.csrf_token,
                },
                body: JSON.stringify(data),
            });
            this.props.onOpenMessage('success', 'Collection saved!')
            this.props.onClose()
        }catch(err){
            this.props.onOpenMessage('error', 'Something went wrong. Please try again.')
            console.log('err', err)
        }finally{
            this.setState({
                loading: false
            })
        }
    }


    render() {
        return (
            <>
                <ReactModal
                    modalState={this.props.isOpen}
                    className="width-sm"
                    onClose={this.props.onClose}
                >
                    <form onSubmit={this.onSubmit}>
                        <h2>Save Collection</h2>
                        <div className="pages-modal-container">
                            <div className="text-field mb-3">
                                <label className="form-label">
                                    Collection Name
                                </label>
                                <input
                                    className="form-control"
                                    onChange={this.onChange}
                                    value={this.state.name}
                                />
                            </div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <button
                                className="button button-filled button-primary width-sm btn"
                                style={{ lineHeight: 'unset', marginRight: '10px' }}
                                disabled={this.state.loading}
                            >
                                Save
                            </button>
                            <button
                                type="button"
                                className="button button-outline button-primary width-sm btn"
                                onClick={this.props.onClose}
                                style={{ lineHeight: 'unset' }}
                            >
                                Close
                            </button>
                        </div>
                    </form>
                </ReactModal>
            </>
        );
    }
}

export default SaveCollectionModal;
