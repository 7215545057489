import { Cell } from '@table-library/react-table-library/table';
import numeral from 'numeral';

export const COLUMNS = [
    {
        label: 'Name',
        key: 'name',
        data: 'name',
        Component: Cell,
        renderCell: ({ data, item, csvDownload }) => {
            if (csvDownload) return data;
            return data;
        },
    },
    {
        label: 'Type',
        key: 'type',
        data: 'type',
        Component: Cell,
        renderCell: ({ data, item, csvDownload }) => {
            if (csvDownload) return data;
            return data;
        },
    },
    {
        label: 'Salience',
        key: 'salience',
        data: 'salience',
        Component: Cell,
        renderCell: ({ data, item, csvDownload }) => {
            if (csvDownload) return data;
            return data;
        },
    },
    {
        label: 'Sentiment',
        key: 'sentiment',
        data: 'sentiment',
        Component: Cell,
        renderCell: ({ data, item, csvDownload }) => {
            if (csvDownload) return data;
            return data;
        },
    },
   
];
