export const getTopPerformingCategories = (data) => {
    if (!data || data.length === 0) return [];

    const firstLevelItems =  data
        .filter((item) => item.level.split('/').length === 3);

    const topCategoryImpression = firstLevelItems.reduce((acc, curr) => {
        if(curr.sum_impressions > acc){
            acc = curr.sum_impressions
        }
        return acc
    }, 0)
    const percent25 = topCategoryImpression * 25/100

    const items = firstLevelItems.filter(item => item.sum_impressions >=  percent25 && item.unique_pages >= 10)

    return items
}

export const getLeverageStrikingDistance = (data) => {
    if (!data || data.length === 0) return [];

    const firstLevelItems =  data
        .filter((item) => item.level.split('/').length === 3);

    const topCategoryImpression = firstLevelItems.reduce((acc, curr) => {
        if(curr.sum_impressions > acc){
            acc = curr.sum_impressions
        }
        return acc
    }, 0)
    const percent25 = topCategoryImpression * 25/100
    const percent49 = topCategoryImpression * 49/100

    const items = firstLevelItems.filter(item => item.sum_impressions >=  percent25 && item.sum_impressions <= percent49 && item.unique_pages >= 10)

    return items
}

export const getTopLevels = (data, length) => {
    if (!data || data.length === 0) return [];

    const levelItems =  data
    .filter((item) => item.level.split('/').length === length);
    const topCategoryImpression = levelItems.reduce((acc, curr) => {
        if(curr.median_impressions_per_page > acc){
            acc = curr.median_impressions_per_page
        }
        return acc
    }, 0)
    const percent15 = topCategoryImpression * 15/100

    const items = levelItems.filter(item => item.median_impressions_per_page >=  percent15 && item.unique_pages >= 10)

    return items
} 

export const getTopLevelsMediaImpressions = (data, length) => {
    if (!data || data.length === 0) return [];

    const levelItems =  data
    .filter((item) => item.level.split('/').length === length);

    const sorted = levelItems.sort((a, b) => b.median_impressions_per_page - a.median_impressions_per_page).filter(item => item.unique_pages >= 5 && item.unique_pages <= 9)

    if(sorted.length <= 10){
        return sorted
    }else{
        return sorted.slice(0, 10)
    }
} 

export const getTopLevelsMediaImpressionsHiger = (data, length) => {
    if (!data || data.length === 0) return [];

    const levelItems =  data
    .filter((item) => item.level.split('/').length === length);

    const sorted = levelItems.sort((a, b) => b.sum_impressions - a.sum_impressions).filter(item => item.unique_pages >= 10)

    if(sorted.length <= 10){
        return sorted
    }else{
        return sorted.slice(0, 10)
    }
} 

export const getTopCategoriesImpressions = (data, length) => {
    if (!data || data.length === 0) return [];

    const levelItems =  data
    .filter((item) => item.level.split('/').length === length);

    const sorted = levelItems.sort((a, b) => b.sum_impressions - a.sum_impressions).filter(item => item.unique_pages >= 10)

    if(sorted.length <= 10){
        return sorted
    }else{
        return sorted.slice(0, 10)
    }
} 

export const getTopCategoriesImpressionsLow = (data, length) => {
    if (!data || data.length === 0) return [];

    const levelItems =  data
    .filter((item) => item.level.split('/').length === length);

    const sorted = levelItems.sort((a, b) => b.sum_impressions - a.sum_impressions).filter(item => item.unique_pages >= 5 && item.unique_pages <= 9)

    if(sorted.length <= 10){
        return sorted
    }else{
        return sorted.slice(0, 10)
    }
} 

export const getDiversifyContent = (data) => {
    if (!data || data.length === 0) return [];

    const firstLevelItems =  data
        .filter((item) => item.level.split('/').length === 3);

    const topCategory = firstLevelItems.reduce((acc, curr) => {
        if(curr.sum_impressions > acc.sum_impressions){
            acc = curr
        }
        return acc
    }, {sum_impressions: 0})

    const levelItems =  data
    .filter((item) => item.level.split('/').length === 4 && item.level.startsWith(topCategory.level) && item.unique_pages < 10)
    .sort((a,b) => b.median_impressions_per_page - a.median_impressions_per_page)

    return {
        topCategory,
        items: levelItems.length <= 5 ? levelItems : levelItems.slice(0, 5)
    }
}

export const getSubCategories = (data) => {
    if (!data || data.length === 0) return [];

    const firstLevelItems =  data
        .filter((item) => item.level.split('/').length === 3);

    const topCategory = firstLevelItems.reduce((acc, curr) => {
        if(curr.sum_impressions > acc.sum_impressions){
            acc = curr
        }
        return acc
    }, {sum_impressions: 0})

    const levelItems =  data
    .filter((item) => item.level.split('/').length === 4 && item.level.startsWith(topCategory.level) && item.unique_pages >= 10)
    .sort((a,b) => b.median_impressions_per_page - a.median_impressions_per_page)

    return {
        topCategory,
        items: levelItems.length <= 5 ? levelItems : levelItems.slice(0, 5)
    }
}