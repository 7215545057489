import { Component } from "react";
import { changeSettings } from "./query";
import { isTrue } from "../../helpers/isTrue";

class AuthorSettings extends Component {
    constructor(props) {
        super();
        this.onChangeInput = this.onChangeInput.bind(this)
        this.state = {
            report_permission_all_authors: isTrue(props.report_permission_all_authors) ?? false,
            report_permission_individual_authors: isTrue(props.report_permission_individual_authors) ?? false
        }
    }

    onChangeInput(e){
        try{
            const data = {
                [e.target.name]: e.target.checked
            }
            this.setState({
                ...data
            })
            changeSettings(data, this.props.csrf_token)
        }catch(err){
            console.log(err)
        }
        
    }

    render() {
        return (
            <div className="card rounded-main table-main">
                <div className="card-header" style={{borderRadius: "10px"}}>
                    <div className="card-title text-dark fw-bold">
                        Author performance report
                    </div>
                </div>
                <div className="card-body">
                    <div className="settings-authors">
                        <input name="report_permission_all_authors" type="checkbox" id="all_authors" onChange={this.onChangeInput} checked={this.state.report_permission_all_authors} />
                        <label htmlFor="all_authors"><strong>Disable all authors report</strong></label>
                    </div>
                    <div className="settings-authors">
                        <input type="checkbox" name="report_permission_individual_authors" id="individual_authors" onChange={this.onChangeInput} checked={this.state.report_permission_individual_authors} />
                        <label htmlFor="individual_authors"><strong>Disable individual report</strong></label>
                    </div>
                </div>
            </div> 
        );
    }
}

export default AuthorSettings;
