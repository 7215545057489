import { Component } from 'react';
import Announcement from '../../components/Announcement';
import ConfirmationModal from '../../components/Modals/ConfirmationModal';
import { isTrue } from '../../helpers/isTrue';
import AuthorContainer from '../AuthorContainer';
import AuthorsContainer from '../AuthorsContainer';
import CompetitiveAnalysisContainer from '../CompetitiveAnalysisContainer';

class ComponentWithAnnouncement extends Component {
    constructor() {
        super();
        this.optIn = this.optIn.bind(this);
        this.createOptInBtn = this.createOptInBtn.bind(this);
        this.createButton = this.createButton.bind(this);
        this.createLink = this.createLink.bind(this);
        this.onOpenModal = this.onOpenModal.bind(this);
        this.onCloseModal = this.onCloseModal.bind(this);
        this.endTrial = this.endTrial.bind(this);

        this.state = {
            buttonName: '',
            isLink: false,
            href: '',
            isOpen: false,
            loading: false,
            onClick: () => {},
        };
    }

    componentDidMount() {
        if (
            this.props.children_type === 'CompetitiveAnalysis' &&
            !isTrue(this.props.show_competitive_analysis)
        )
            return this.createOptInBtn();

        if (this.props.plan_type === 'Trial') this.createButton();

        if (this.props.plan_type === 'Basic' || this.props.plan_type === 'Trial_Basic')
            this.createLink();
    }

    createOptInBtn() {
        this.setState({
            buttonName: 'Opt In',
            onClick: this.optIn,
        });
    }

    optIn() {
        this.setState({ loading: true });
        fetch(`${this.props.current_url}/customers/add-competitive-analysis/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': this.props.csrf_token,
            },
            body: JSON.stringify({ show_competitive_analysis: true }),
        })
            .then((res) => res.json())
            .then((data) => {
                window.location.reload();
            })
            .catch((err) => console.log(err))
            .finally(() => {
                this.setState({ loading: false });
            });
    }

    createButton() {
        this.setState({
            buttonName: 'Activate your Subscription',
            onClick: this.onOpenModal,
        });
    }

    endTrial() {
        this.setState({ loading: true });
        fetch(`${this.props.current_url}/customers/end-trial/?domain=${this.props.domain_id}`)
            .then((res) => res.json())
            .then((data) => {
                const redirect_url = data.redirect_url;
                window.location.pathname = redirect_url;
            })
            .catch((err) => console.log(err))
            .finally(() => {
                this.setState({ loading: false });
            });
    }

    createLink() {
        this.setState({
            buttonName:
                this.props.plan_type === 'Basic'
                    ? 'Upgrade your Subscription'
                    : 'Activate & Upgrade your subscription',
            isLink: true,
            href: `${this.props.current_url}/customers/change_plan/?without_basic=True`,
        });
    }

    onOpenModal() {
        this.setState({ isOpen: true });
    }

    onCloseModal() {
        this.setState({ isOpen: false });
    }

    render() {
        return (
            <>
                {this.props.message && (
                    <Announcement
                        image={this.props.announcement_image}
                        buttonName={this.state.buttonName}
                        onClick={this.state.onClick}
                        message={this.props.message}
                        isLink={this.state.isLink}
                        href={this.state.href}
                        announcement_bar_styles={this.props.announcement_bar_styles}
                        buttonStyles={this.props.button_styles ?? 'bg-purple'}
                        loading={this.state.loading}
                    />
                )}
                {this.props.children_type === 'CompetitiveAnalysis' && (
                    <CompetitiveAnalysisContainer
                        message={this.props.message}
                        default_image={this.props.default_image}
                        isLink={this.state.isLink}
                        href={this.state.href}
                        buttonName={this.state.buttonName}
                        onClick={this.state.onClick}
                        loading={this.state.loading}
                        image_strong={this.props.image_strong}
                        image_striking_distance={this.props.image_striking_distance}
                        image_weak={this.props.image_weak}
                    />
                )}
                {this.props.children_type === 'Authors' && (
                    <AuthorsContainer
                        message={this.props.message}
                        isLink={this.state.isLink}
                        href={this.state.href}
                        buttonName={this.state.buttonName}
                        onClick={this.state.onClick}
                        domain={this.props.domain}
                        plan_type={this.props.plan_type}
                        module_2_img={this.props.module_2_img}
                        module_3_img={this.props.module_3_img}
                        loading={this.state.loading}
                    />
                )}
                {this.props.children_type === 'Author' && (
                    <AuthorContainer
                        plan_type={this.props.plan_type}
                        domain={this.props.domain}
                        text_author_entity_timeline={this.props.text_author_entity_timeline}
                        text_domain_top_pages={this.props.text_domain_top_pages}
                        default_image={this.props.default_image}
                        author={this.props.author}
                    />
                )}

                <ConfirmationModal
                    isOpen={this.state.isOpen}
                    message={
                        'By "Activating" your plan, your trial period will stop and you will be charged for the first billing period immediately.'
                    }
                    onClose={this.onCloseModal}
                    onSubmit={this.endTrial}
                    buttonName="Activate"
                />
            </>
        );
    }
}
export default ComponentWithAnnouncement;
