export const getDiscoverResults = (entity, endpoint_url, searchType) => {
    let url = `${endpoint_url}/tools/google-discover-entity?s=${entity}&limit=100&indent=True`
    if(searchType == 'id') url = url + '&method=kgid' 
    return fetch(url);
};

export const saveEntitiy = (endpoint_url, data, csrf_token) => {
    return fetch(`${endpoint_url}/tools/google-discover-entity`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrf_token,
        },
        body: JSON.stringify(data),
    });
};

export const saveEntitiyAdmin = (endpoint_url, data, csrf_token) => {
    return fetch(`${endpoint_url}/tools/add-user-entity`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrf_token,
        },
        body: JSON.stringify(data),
    });
};
