import Loading from '../../components/Loading';
import LoadMoreComponent from '../../components/LoadMoreComponent';

import CheckData from '../../components/CatchError';
import DataTable from '../../components/Datatable';
import { linReg } from '../../lib/reduce';
import { COLUMNS } from './keys';
import query from './query';
import { scrollForTable } from '../../components/Datatable/utils';

class Summary extends LoadMoreComponent {
    constructor() {
        super();
        this.query = query({});

        this.loadMore = this.loadMore.bind(this);

        this.offsetStep = 100;
    }

    render() {
        if (this.state.loading && !this.state.loadMore) {
            return <Loading padding={10} />;
        }
        // if we get less than offsetStep it means we don't have more data
        const showLoadMoreBtn = this.state.data?.length === this.offsetStep;

        const attr = {
            query,
            offsetStep: this.offsetStep,
        };
        const sortFns = {
            attr_imp: (array) =>
                array.sort((a, b) => {
                    let a_imp = linReg(a.impressionsL);
                    let b_imp = linReg(b.impressionsL);
                    return a_imp.slope - b_imp.slope;
                }),
            name: (array) => array.sort((a, b) => a.name.localeCompare(b.name)),
            type: (array) => array.sort((a, b) => a.type.localeCompare(b.type)),
            pages: (array) => array.sort((a, b) => a.pages - b.pages),
            salience: (array) => array.sort((a, b) => a.salience - b.salience),
            impressionsL: (array) => array.sort((a, b) => a.impressions - b.impressions),
            impressions_per_page: (array) =>
                array.sort((a, b) => a.impressions / a.pages - b.impressions / b.pages),
            ctr: (array) => array.sort((a, b) => a.ctr - b.ctr),
        };

        return (
            <>
                <CheckData
                    content_id={this.props.content_id}
                    parent_id={this.props.parent_id}
                    data={this.state.allData}
                    loading={this.state.loading}
                    loadMore={this.state.loadMore}
                >
                    <DataTable
                        nodes={this.state.allData}
                        sortFns={sortFns}
                        COLUMNS={COLUMNS}
                        fileName="Concepts"
                        defaultSortKey="impressions"
                        forceRun={this.forceRun}
                        additionalStyles={{
                            Table: `
                                --data-table-library_grid-template-columns : minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) !important;
                                ${scrollForTable}
                            `,
                            BaseCell: `  font-family: 'Raleway';
                                    font-weight: 500;
                                    font-size: 16px;
                                    line-height: 19px;
                                    padding: 16px 5px;
                                    color: #0D182C;
                                    border-bottom: 1px solid rgba(13, 24, 44, 0.1);
                                
                                    &.underline {
                                    text-decoration: underline;
                                    }`,
                        }}
                    />
                </CheckData>
                {this.state.loadMore ? (
                    <Loading padding={10} />
                ) : (
                    showLoadMoreBtn && (
                        <div className="d-flex align-items-center justify-content-center pt-3 load-more-section">
                            <button
                                onClick={() => this.loadMore(attr)}
                                disabled={this.state.loading}
                                className="load-more-btn"
                            >
                                Load More
                            </button>
                        </div>
                    )
                )}
            </>
        );
    }
}

export default Summary;
