/* eslint-disable */
import { Component } from 'react';

import { COLUMNS, keysFunction } from './keys';
import List from '../../components/List';
import colors from '../../lib/colors';
import DataTable from '../../components/Datatable';
import { scrollForTable } from '../../components/Datatable/utils';

class Summary extends Component {
    constructor() {
        super();

        this.urlParams = new URLSearchParams(window.location.search);

        this.is_one = this.urlParams.get('is_one');
        this.entity_ids = this.urlParams.get('entity_ids');
    }

    searchFunction(data) {
        return data;
    }

    onCloseModal() {
        this.setState({
            isOpen: false,
            image: null,
        });
    }

    onOpen(image) {
        this.setState({
            isOpen: true,
            image,
        });
    }

    render() {
        const keys = keysFunction(this.props.default_image);
        return (
            <>
                <div className="card-body secondary">
                    {this.is_one
                        ? this.props.data?.map((item) => (
                              <List
                                  className="table-main"
                                  key={item.id}
                                  loading={false}
                                  data={[item]}
                                  parent_id={this.props.parent_id}
                                  content_id={this.props.content_id}
                                  keys={keys}
                                  forceRun={this.forceRun}
                                  color={colors[1]}
                                  header={false}
                              />
                          ))
                        : this.props.data &&
                          this.props.data?.length > 1 && (
                              <DataTable
                                  nodes={this.props.data}
                                  sortFns={{}}
                                  searchFunction={(data) => data}
                                  COLUMNS={COLUMNS}
                                  onlyTable
                                  hasSort={false}
                                  additionalStyles={{
                                      Table: `
                                    --data-table-library_grid-template-columns : minmax(100px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) !important;
                                    ${scrollForTable}
                                `,
                                      BaseCell: `  font-family: 'Raleway';
                                font-weight: 500;
                                font-size: 16px;
                                line-height: 19px;
                                padding: 16px 5px !important;
                                color: #0D182C;
                                border-bottom: 1px solid rgba(13, 24, 44, 0.1);
                            
                                &.underline {
                                    text-decoration: underline;
                                }
                                `,
                                  }}
                              />
                          )}
                </div>
            </>
        );
    }
}

export default Summary;
