import { Cell } from '@table-library/react-table-library/table';
import { CellTree } from '@table-library/react-table-library/tree';
import numeral from 'numeral';

export const COLUMNS = (images) => [
    {
        label: 'Category',
        key: 'category',
        data: 'name',
        className: 'underline',
        Component: CellTree,
        renderCell: ({ data, item, csvDownload }) => {
            if (csvDownload) return data;
            const path = item?.own_level?.slice(0, -1);
            return path ? (
                <a
                    href={
                        `/domains/${window.info.id}/categories/category?categories:in=` +
                        window.encodeURIComponent(path)
                    }
                >
                    {data}
                </a>
            ) : (
                data
            );
        },
    },
    {
        label: 'Own Pages',
        key: 'own_unique_pages',
        data: 'own_unique_pages',
        Component: Cell,
        renderCell: ({ item, data, csvDownload }) => {
            if (item.id === 'all_categories') return null;
            if (csvDownload) return data;
            return numeral(data).format('0,00');
        },
    },
    {
        label: 'Own Median Impressions',
        key: 'Own_impPerPage',
        data: 'Own_impPerPage',
        Component: Cell,
        renderCell: ({ item }) => {
            if (item.id === 'all_categories') return null;
            const impPerPage = item.own_sum_impressions;
            return numeral(impPerPage).format('0,00');
        },
    },
    {
        label: 'vs. Comps',
        key: 'percent-ownImp-compimp',
        data: '(Own_impPerPage-comp_impPerPage)/comp_impPerPage',
        info: 'Comparing your performance against the whole set in GDdash for that category or sub-category',
        Component: Cell,
        renderCell: ({ item, csvDownload }) => {
            if (item.id === 'all_categories') return null;

            const value = (item.own_sum_impressions - item.comp_sum_impressions) / item.comp_sum_impressions;
            
            if (csvDownload) return value;
            return showWithArrow(value);
        },
    },
    {
        label: 'Own Avg CTR',
        key: 'own_avg_ctr',
        data: 'own_avg_ctr',
        Component: Cell,
        renderCell: ({ item }) => {
            if (item.id === 'all_categories') return null;
            return numeral(item.own_avg_ctr).format('0,0%');
        },
    },
    {
        label: 'vs. Comps',
        key: 'Own_ctr-comp_ctr',
        data: 'Own_ctr-comp_ctr',
        info: 'Comparing your performance against the whole set in GDdash for that category or sub-category',
        Component: Cell,
        renderCell: ({ item, csvDownload }) => {
            if (item.id === 'all_categories') return null;

            const value = item.own_avg_ctr - item.comp_avg_ctr
            if (csvDownload) return value;
            return showWithArrow(value);
        },
    },
    {
        label: 'Own Median Clicks',
        key: 'Own_ClicksPerPage',
        data: 'Own_ClicksPerPage',
        Component: Cell,
        renderCell: ({ item, csvDownload }) => {
            if (item.id === 'all_categories') return null;
            const value = item.own_sum_clicks;
            if (csvDownload) return value;
            return numeral(value).format('0,00');
        },
    },
    {
        label: 'vs. Comps',
        key: 'percent-ownClick-compClick',
        data: '(Own_ClicksPerPage-comp_ClicksPerPage)/comp_ClicksPerPage',
        info: 'Comparing your performance against the whole set in GDdash for that category or sub-category',
        Component: Cell,
        renderCell: ({ item, csvDownload }) => {
            if (item.id === 'all_categories') return null;

            const value = (item.own_sum_clicks -  item.comp_sum_clicks) /  item.comp_sum_clicks;
            
            if (csvDownload) return value;
            return showWithArrow(value);
        },
    },
    {
        label: 'Confidence',
        key: 'confidence',
        data: 'confidence',
        info: 'Based on the total number of records in GDdash data set for that category/sub-category. The higher the data we have, the more confident we are in the analysis',
        Component: Cell,
        renderCell: ({ item, csvDownload }) => {
            if (csvDownload || item.id === 'all_categories') return null;
            const { title, count } = getConfidence(item.comp_unique_pages);
            const items = [];

            for (let i = 1; i <= 5; i++) {
                const item = (
                    <div
                        className="confidence-dots-item"
                        style={{ background: i <= count ? '#66de4c' : '#e6e6e6' }}
                        key={i}
                    ></div>
                );
                items.push(item);
            }

            return (
                <div className="confidence-dots" title={title}>
                    {items}
                </div>
            );
        },
    },
    {
        label: '',
        key: 'analysis',
        data: 'analysis',
        Component: Cell,
        renderCell: ({ item, csvDownload }) => {
            if (csvDownload || item.id === 'all_categories') return null;
            const path = item?.own_level?.slice(0, -1);
            return (
                <a
                    href={
                        `/domains/${window.info.id}/competitive-analysis/analyze?category=` +
                        window.encodeURIComponent(path)
                    }
                    className="analyze-btn"
                    target="_blank"
                    rel="noreferrer"
                >
                    Compare
                </a>
            );
        },
    },
    {
        label: 'Strength',
        key: 'strength',
        data: 'strength',
        Component: Cell,
        renderCell: ({ item, csvDownload }) => {
            if (csvDownload) return getStrength(item);
            return getStrength(item, false, images);
        },
    },
];

function getConfidence(pages) {
    switch (true) {
        case pages <= 300:
            return { count: 1, title: 'Very Low' };
        case pages > 300 && pages <= 500:
            return { count: 2, title: 'Low' };
        case pages > 500 && pages <= 1000:
            return { count: 3, title: 'Average' };
        case pages > 1000 && pages <= 2000:
            return { count: 4, title: 'High' };
        case pages > 2000:
            return { count: 5, title: 'Very High' };
        default:
            return { count: 0, title: '' };
    }
}

function showWithArrow(val) {
    const dir = val > 0 ? 'up' : 'down';

    const arrow =
        dir == 'up' ? (
            <i className="icofont-dotted-up"></i>
        ) : (
            <i className="icofont-dotted-down"></i>
        );

    return (
        <span className={'add-arrow ' + dir}>
            {arrow} {numeral(Math.abs(val)).format('0a%')}
        </span>
    );
}

export function getStrength(item, isReturnNumber = true, images = {}) {
    if (item.id === 'all_categories' || !item.haveStrength) return isReturnNumber ? 0 : null;
    const {
        own_sum_impressions,
        own_unique_pages,
        comp_sum_impressions,
        comp_unique_pages,
        own_avg_ctr,
        comp_avg_ctr,
    } = item;
    const own_impPerPage = own_sum_impressions / own_unique_pages;
    const comp_impPerPage = comp_sum_impressions / comp_unique_pages;

    if (own_impPerPage > comp_impPerPage && own_avg_ctr > comp_avg_ctr) {
        return isReturnNumber ? (
            3
        ) : (
            <div className="categories-strength" title="Strong Authority & CTR">
                <img src={images.strong} alt="strong" loading="lazy" />
            </div>
        );
    } else if (own_impPerPage > comp_impPerPage && own_avg_ctr < comp_avg_ctr) {
        return isReturnNumber ? (
            2
        ) : (
            <div className="categories-strength" title="Striking Distance">
                <img src={images.striking_distance} alt="image_striking_distance" loading="lazy" />
            </div>
        );
    } else if (own_impPerPage < comp_impPerPage) {
        return isReturnNumber ? (
            1
        ) : (
            <div className="categories-strength" title="Weak Authority">
                <img src={images.weak} alt="image_weak" loading="lazy" />
            </div>
        );
    }

    return isReturnNumber ? 0 : null;
}
