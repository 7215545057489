
export const changeSettings = (data, csrf_token) => {
    return fetch(`/customers/change-settings/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrf_token,
        },
        body: JSON.stringify(data),
    });
};