import { Cell } from '@table-library/react-table-library/table';
import numeral from 'numeral';

export const COLUMNS = (default_image) => [
    {
        label: 'Title',
        key: 'title',
        data: 'title',
        Component: Cell,
        renderCell: ({ item, csvDownload }) => {
            let title = item.title;

            if (csvDownload) return title;

            return (
                <div className="d-flex justify-content-between">
                    <div className="d-flex align-items-center">
                        <div style={{ float: 'left', marginRight: '10px', width: '100px' }}>
                            <img
                                onError={(event) => (event.target.src = default_image)}
                                src={item.image ?? default_image}
                                style={{
                                    width: '100px',
                                    display: 'inline-block',
                                    minHeight: '60px',
                                    background: '#BBB',
                                }}
                                alt="page_image"
                                loading="lazy"
                            />
                        </div>
                        <strong>
                            {title?.length > 64 ? title?.substr(0, 64) + '...' : title}
                        </strong>
                    </div>
                    <div className="d-flex align-items-center">
                        <a
                            target="_blank"
                            className="link-website d-inline-flex align-items-center justify-content-center"
                            href={item.title}
                            rel="noreferrer"
                        >
                            <i className="icon-external-link"></i>
                        </a>
                    </div>
                </div>
            );
        },
    },
    {
        label: 'Ogtitle',
        key: 'ogtitle',
        data: 'ogtitle',
        Component: Cell,
        renderCell: ({ data, item, csvDownload }) => {
            if (csvDownload) return data;
            return data;
        },
    },
    {
        label: 'Headlines',
        key: 'headline',
        data: 'headline',
        Component: Cell,
        renderCell: ({ data, item, csvDownload }) => {
            if (csvDownload) return data;
            return data;
        },
    },
    {
        label: 'Description',
        key: 'description',
        data: 'description',
        Component: Cell,
        renderCell: ({ data, item, csvDownload }) => {
            if (csvDownload) return data;
            return data;
        },
    },
    {
        label: 'Keywords',
        key: 'keywords',
        data: 'keywords',
        Component: Cell,
        renderCell: ({ data, item, csvDownload }) => {
            if (csvDownload) return data;
            return data;
        },
    },
    {
        label: 'Published',
        key: 'published',
        data: 'published',
        Component: Cell,
        renderCell: ({ data, item, csvDownload }) => {
            if (csvDownload) return data;
            return data;
        },
    },
    {
        label: 'Modified',
        key: 'modified',
        data: 'modified',
        Component: Cell,
        renderCell: ({ data, item, csvDownload }) => {
            if (csvDownload) return data;
            return data;
        },
    },
    {
        label: 'Type',
        key: 'type',
        data: 'type',
        Component: Cell,
        renderCell: ({ data, item, csvDownload }) => {
            if (csvDownload) return data;
            return data;
        },
    },
    {
        label: 'Author',
        key: 'author',
        data: 'author',
        Component: Cell,
        renderCell: ({ data, item, csvDownload }) => {
            if (csvDownload) return data;
            return data;
        },
    },
    {
        label: 'Words',
        key: 'words',
        data: 'words',
        Component: Cell,
        renderCell: ({ data, item, csvDownload }) => {
            if (csvDownload) return data;
            return data;
        },
    },
    {
        label: 'Sentiment',
        key: 'sentiment',
        data: 'sentiment',
        Component: Cell,
        renderCell: ({ data, item, csvDownload }) => {
            if (csvDownload) return data;
            return numeral(data).format('0.00');
        },
    },
    {
        label: 'Status',
        key: 'status',
        data: 'status',
        Component: Cell,
        renderCell: ({ data, item, csvDownload }) => {
            if (csvDownload) return data;
            return data;
        },
    },
];
