import Query from '../../../components/Report';

export const query = ({ user_domain_id, deleted_clusters, deleted_urls, deleted_gsc_url_dict_with_cluster }) =>
    new Query('search-urls-manual-cluster', {
        user_domain_id,
        deleted_clusters: deleted_clusters
            ? deleted_clusters
                  .split(',')
                  .map((item) => ' and cluster_id != ' + item)
                  .join(' ')
            : '',
        deleted_urls: createDeletedUrls(deleted_gsc_url_dict_with_cluster),
    });


function createDeletedUrls(deleted_gsc_url_dict_with_cluster) {
    if(!deleted_gsc_url_dict_with_cluster || deleted_gsc_url_dict_with_cluster?.length === 0) return ''

    const queryString = deleted_gsc_url_dict_with_cluster.map(item => `NOT ( cluster_id = ${item.id} and url_name IN (${item.urls.map(x => (`"` + x + `"`)).join(',')}) )`).join(' and ')

    return `and (${queryString})`
}