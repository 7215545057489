import { Cell } from '@table-library/react-table-library/table';
import numeral from 'numeral';
import { collectCell, getPercent } from '../../../../helpers/showDelta';
import colors, { labelColors } from '../../../../lib/colors';

export const COLUMNS = [
    {
        label: 'Device',
        key: 'device',
        data: 'device',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return <strong>{data}</strong>;
        },
    },
    {
        label: 'Impressions',
        key: 'impressions',
        data: 'impressions',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return <strong>{numeral(data).format('0.0a')}</strong>;
        },
    },
    {
        label: <span className="delta-sign">&#916;</span>,
        key: 'impressions_delta',
        data: 'impressions_delta',
        Component: Cell,
        renderCell: ({ item, csvDownload }) => {
            if (csvDownload) return getPercent(item.impressions, item.impressionsPrior);
            return collectCell(item.impressions, item.impressionsPrior);
        },
    },
    {
        label: 'Clicks',
        key: 'clicks',
        data: 'clicks',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return <strong>{numeral(data).format('0.0a')}</strong>;
        },
    },
    {
        label: <span className="delta-sign">&#916;</span>,
        key: 'clicks_delta',
        data: 'clicks_delta',
        Component: Cell,
        renderCell: ({ item, csvDownload }) => {
            if (csvDownload) return getPercent(item.clicks, item.clicksPrior);
            return collectCell(item.clicks, item.clicksPrior);
        },
    },
    {
        label: 'Position',
        key: 'avgPosition',
        data: 'avgPosition',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return <strong>{numeral(data).format('0.0a')}</strong>;
        },
    },
    {
        label: <span className="delta-sign">&#916;</span>,
        key: 'avgPosition_delta',
        data: 'avgPosition_delta',
        Component: Cell,
        renderCell: ({ item, csvDownload }) => {
            if (csvDownload) return getPercent(item.avgPosition, item.avgPositionPrior);
            return collectCell(item.avgPosition, item.avgPositionPrior);
        },
    },
    {
        label: 'CTR',
        key: 'avgCTR',
        data: 'avgCTR',
        Component: Cell,
        renderCell: ({ item, csvDownload }) => {
            const ctrValue = item.clicks/item.impressions;
            if (csvDownload) return ctrValue;

            return <strong>{numeral(ctrValue).format('0.00%')}</strong>;
        },
    },
    {
        label: <span className="delta-sign">&#916;</span>,
        key: 'avgCTR_delta',
        data: 'avgCTR_delta',
        Component: Cell,
        renderCell: ({ item, csvDownload }) => {
            if (csvDownload) return getPercent(item.avgCTR, item.avgCTRPrior);
            return collectCell(item.avgCTR, item.avgCTRPrior);
        },
    },
];

export const chart_keys = [
    {
        key: 'clicksL',
        label: 'Clicks',
        color: colors[3],
        labelColor: labelColors[3],
        icon: 'pagev-view',
    },
    {
        key: 'impressionsL',
        label: 'Impressions',
        color: colors[0],
        labelColor: labelColors[0],
        icon: 'search',
        hidden: true,
    },
    {
        key: 'ctrL',
        label: 'Avg. CTR',
        format: '0.00%',
        render: (data) => numeral(data * 100).format('0.00'),
        color: colors[1],
        labelColor: labelColors[1],
        icon: 'ctr',
        yAxisID: 'y1',
        hidden: true,
    },
    {
        label: 'Position',
        key: 'positionL',
        color: colors[2],
        labelColor: labelColors[2],
        yAxisID: 'y2',
        icon: 'clicks',
        hidden: true,
    },
];

export const TAGS = ['MOBILE', 'DESKTOP', 'TABLET'];
