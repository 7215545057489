import Loading from '../../components/Loading';
import LoadMoreComponent from '../../components/LoadMoreComponent';

import { scrollForTable } from '../../components/Datatable/utils';
import CheckData from '../../components/CatchError';
import DataTable from '../../components/Datatable';
import { COLUMNS, FIELD_FOR_DOWNLOADS } from './keys';
import query from './query';
import ImageModal from '../../components/Modals/ImageModal';

class Summary extends LoadMoreComponent {
    constructor() {
        super();

        this.urlParams = new URLSearchParams(window.location.search);

        this.entity_ids = this.urlParams.get('entity_ids');
        this.language = this.urlParams.get('language');
        this.country = this.urlParams.get('country');
        this.base_domain = this.urlParams.get('base_domain');

        this.query = query({
            offset: 0,
            entity_ids: this.entity_ids,
            country: this.country,
            language: this.language,
            base_domain: this.base_domain,
        });

        this.loadMore = this.loadMore.bind(this);
        this.onOpen = this.onOpen.bind(this);
        this.onCloseModal = this.onCloseModal.bind(this);

        this.offsetStep = 100;
    }

    searchFunction(data, search) {
        return data.filter(
            (item) =>
                item.headline.toLowerCase().includes(search.toLowerCase()) ||
                item.base_domain.toLowerCase().includes(search.toLowerCase()) ||
                item.url.toLowerCase().includes(search.toLowerCase()),
        );
    }

    onCloseModal() {
        this.setState({
            isOpen: false,
            image: null,
        });
    }

    onOpen(image) {
        this.setState({
            isOpen: true,
            image,
        });
    }

    render() {
        if (this.state.loading && !this.state.loadMore) {
            return <Loading padding={10} />;
        }
        // if we get less than offsetStep it means we don't have more data
        const showLoadMoreBtn = this.state.data?.length === this.offsetStep;

        const attr = {
            query,
            offsetStep: this.offsetStep,
            entity_ids: this.entity_ids,
            country: this.country,
            language: this.language,
            base_domain: this.base_domain,
        };

        const sortFns = {
            headline: (array) => array.sort((a, b) => a.headline.localeCompare(b.headline)),
            base_domain: (array) =>
                array.sort((a, b) => a.base_domain.localeCompare(b.base_domain)),
            uniqueHeadline: (array) => array.sort((a, b) => a.uniqueHeadline - b.uniqueHeadline),
            domainScore: (array) => array.sort((a, b) => a.domainScore - b.domainScore),
            avgPosition: (array) => array.sort((a, b) => a.avgPosition - b.avgPosition),
            appearances: (array) => array.sort((a, b) => a.appearances - b.appearances),
            hasVideos: (array) => array.sort((a, b) => a.hasVideos - b.hasVideos),
            videoSize: (array) => array.sort((a, b) => a.videoSize.localeCompare(b.videoSize)),
            videoWidth: (array) => array.sort((a, b) => a.videoWidth - b.videoWidth),
            lastSeen: (array) =>
                array.sort((a, b) => new Date(a.lastSeen.value) - new Date(b.lastSeen.value)),
            timeAGo: (array) => array.sort((a, b) => a.timeAGo.localeCompare(b.timeAGo)),
        };
        return (
            <>
                <CheckData
                    content_id={this.props.content_id}
                    parent_id={this.props.parent_id}
                    data={this.state.allData}
                    loading={this.state.loading}
                    loadMore={this.state.loadMore}
                >
                    <DataTable
                        nodes={this.state.allData}
                        sortFns={sortFns}
                        COLUMNS={COLUMNS(this.props.default_image, this.onOpen)}
                        fileName="Top Videos"
                        forceRun={this.forceRun}
                        searchFunction={this.searchFunction}
                        customColumnsForExport={[...COLUMNS(), ...FIELD_FOR_DOWNLOADS]}
                        additionalStyles={{
                            Table: `
                                --data-table-library_grid-template-columns :  minmax(300px, 3fr) minmax(300px, 3fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(160px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) !important;
                                ${scrollForTable}
                            `,
                            BaseCell: `  font-family: 'Raleway';
                                        font-weight: 500;
                                        font-size: 16px;
                                        line-height: 19px;
                                        padding: 16px 5px !important;
                                        color: #0D182C;
                                        border-bottom: 1px solid rgba(13, 24, 44, 0.1);
                                    
                                        &.underline {
                                            text-decoration: underline;
                                        }
                                        `,
                        }}
                    />
                </CheckData>
                {this.state.loadMore ? (
                    <Loading padding={10} />
                ) : (
                    showLoadMoreBtn && (
                        <div className="d-flex align-items-center justify-content-center pt-3 load-more-section">
                            <button
                                onClick={() => this.loadMore(attr)}
                                disabled={this.state.loading}
                                className="load-more-btn"
                            >
                                Load More
                            </button>
                        </div>
                    )
                )}
                <ImageModal
                    isOpen={this.state.isOpen}
                    onClose={this.onCloseModal}
                    image={this.state.image}
                />
            </>
        );
    }
}

export default Summary;
