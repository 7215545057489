import moment from 'moment';
import React, { Component } from 'react';
import Select from 'react-select';
import CustomDateModal from '../../components/Modals/CustomDate';

class FilterDropdown extends Component {
    constructor() {
        super();

        this.onOpenModal = this.onOpenModal.bind(this)
        this.onCloseModal = this.onCloseModal.bind(this)
        this.onChange = this.onChange.bind(this)

        this.state = {
            isOpen: false
        }
    }

    
    onOpenModal(){
        this.setState({
            isOpen:true
        })
    }

    onCloseModal(){
        this.setState({
            isOpen: false
        })
    }

    onChange(val){
        if(val.id === 'custom'){
            this.onOpenModal()
        }else{
            this.props.updateSelect(val)
        }
    }

    render() {
        const customFilter = {
            label: 'Custom',
            key: 'dates',
            id: 'custom',
            value: {
                start_date: null,
                end_date: null,
            },
        }
      
        const {start_date, end_date} = window?.filters?.filters?.dates?.values
        return (
            <div>
                <Select
                    options={[...this.props.options, customFilter]}
                    value={
                        this.props.dateFilterValue === 'custom'
                            ? {label: <div className="filters-custom-dates">
                                            {moment(start_date?.value).format('YYYY-MM-DD')} <span>&#8594;</span> {moment(end_date?.value).format('YYYY-MM-DD') }
                                        </div>, id:'custom_date'}
                            : this.props.options.find((x) => x.value === this.props.dateFilterValue)
                    }
                    menuPortalTarget={document.querySelector('body')}
                    menuPosition={'fixed'}
                    styles={{
                        container: (baseStyles) => ({
                            ...baseStyles,
                            width: '100%',
                        }),
                        control: (baseStyles) => ({
                            ...baseStyles,
                            boxShadow: 'none',
                            padding: '2px 5px',
                            fontSize: '13px',
                            minWidth: '295px',
                        }),
                        menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
                        menu: (provided) => ({ ...provided, zIndex: 9999 }),
                    }}
                    theme={(theme) => ({
                        ...theme,
                        height: '45px',
                        borderRadius: '7px',
                        borderColor: '#d9d9d9',
                        transition: 'all 0.2s',
                        colors: {
                            ...theme.colors,
                            primary: '#888adb',
                        },
                    })}
                    onChange={this.onChange}
                    placeholder="Select Date"
                    isSearchable={false}
                />
                <CustomDateModal onChange={this.props.updateSelect} gsc_report={this.props.gsc_report} isOpen={this.state.isOpen} onClose={this.onCloseModal} />
            </div>
        );
    }
}

export default FilterDropdown;
