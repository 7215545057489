export const getEntityPrice = (endpoint_url) => {
    return fetch(`${endpoint_url}/tools/get-additional-entity-price`);
};

export const getInformation = () => {
    return fetch(`/customers/get-additional-tires-price/`);
};

export const addEntities = (endpoint_url, data, csrf_token) => {
    return fetch(`${endpoint_url}/tools/purchase-additional-entities/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrf_token,
        },
        body: JSON.stringify(data),
    });
};

export const purchaseQuery = (data, csrf_token) => {
    return fetch(`/customers/purchase-additional/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrf_token,
        },
        body: JSON.stringify(data),
    });
}