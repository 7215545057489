import { Component } from 'react';

class ExtraButtons extends Component {
    constructor(props) {
        super();

        this.changeFirstPosition = this.changeFirstPosition.bind(this);
        this.changeSecondPosition = this.changeSecondPosition.bind(this);
        this.changeWeeks = this.changeWeeks.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onSubmitWeeks = this.onSubmitWeeks.bind(this);

        this.state = {
            first_position: props.first_position ?? 4,
            second_position: props.second_position ?? 11,
            error: '',
            weeks: props.weeks ?? 4
        };
    }

    openModal(e) {
        e.stopPropagation();
    }

    changeFirstPosition(e) {
        const value = e.target.value;
        this.setState({
            first_position: value,
            error: '',
        });
    }

    changeSecondPosition(e) {
        const value = e.target.value;
        this.setState({
            second_position: value,
            error: '',
        });
    }

    changeWeeks(e) {
        const value = e.target.value;
        this.setState({
            weeks: value,
        });
    }

    onSubmit(e) {
        e.preventDefault();
        if (this.state.second_position <= this.state.first_position) {
            return this.setState({
                error: 'Check the numbers: the first one must be less than the second one.',
            });
        }
        
        this.props.changePositions(this.state.first_position, this.state.second_position);
    }
    
    onSubmitWeeks(e){
        e.preventDefault();
        this.props.changeWeek(this.state.weeks);
        
    }

    render() {
        return (
            <>
                <div className="striking-extra-container">
                    <form 
                        onSubmit={this.onSubmit} 
                        className='striking-extra-container-position'
                    >
                        <strong>Ranking Position: </strong>
                        <input
                            onChange={this.changeFirstPosition}
                            value={this.state.first_position}
                            className="striking-extra-input"
                            type='number'
                            />
                        <span>to</span>
                        <input
                            onChange={this.changeSecondPosition}
                            value={this.state.second_position}
                            className="striking-extra-input"
                            type='number'
                        />
                        <button className="striking-extra-btn">Submit</button>
                    </form>
                    <form onSubmit={this.onSubmitWeeks}>
                        <strong>
                            Number of weeks: 
                                <i
                                    className="icon-info"
                                    title='Minimum number of weeks where a keyword has been consistently ranking'
                                ></i> 
                        </strong>
                        <input
                            type='numer'
                            onChange={this.changeWeeks}
                            value={this.state.weeks}
                            className="striking-extra-input"
                        />
                        <button className="striking-extra-btn">Submit</button>
                    </form>
                </div>
                {this.state.error && <div className="error-message">{this.state.error}</div>}
            </>
        );
    }
}

export default ExtraButtons;
