export const getClustersQuery = async (data, csrf_token) => {
    return fetch(`/domains/get-clusters/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrf_token,
        },
        body: JSON.stringify(data),
    });
};

export const addClusterQuery = async (endpoint_url, data, csrf_token) => {
    return fetch(`${endpoint_url}/domains/cluster-actions/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrf_token,
        },
        body: JSON.stringify(data),
    });
};

export const addClusterListQuery = async (endpoint_url, data, csrf_token) => {
    return fetch(`${endpoint_url}/domains/cluster-keyword-url/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrf_token,
        },
        body: JSON.stringify(data),
    });
};

export const deleteClusterQuery = async (endpoint_url, data, csrf_token) => {
    return fetch(`${endpoint_url}/domains/cluster-actions/`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrf_token,
        },
        body: JSON.stringify(data),
    });
};
