const escapeCsvCell = (cell) => {
    if (cell == null) {
        return '';
    }
    const sc = cell.toString().trim();

    if (sc === '' || sc === '""') {
        return sc;
    }

    if (sc.includes('"') || sc.includes(',') || sc.includes('\n') || sc.includes('\r')) {
        return '"' + sc.replace(/"/g, '""') + '"';
    }

    return sc;
};

const makeCsvData = (columns, data) => {
    return data.reduce((csvString, rowItem) => {
        return (
            csvString +
            columns.map(({ accessor }) => escapeCsvCell(accessor(rowItem))).join(',') +
            '\r\n'
        );
    }, columns.map(({ name }) => escapeCsvCell(name)).join(',') + '\r\n');
};

const downloadAsCsv = (columns, data, filename) => {
    const csvData = makeCsvData(columns, data);
    const csvFile = new Blob([csvData], { type: 'text/csv' });
    const downloadLink = document.createElement('a');

    downloadLink.display = 'none';
    downloadLink.download = filename;
    downloadLink.href = window.URL.createObjectURL(csvFile);
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
};

function getAllNodes(nodes, allNodes = []) {
    nodes.forEach((node) => {
        allNodes.push(node);
        if (node.nodes) getAllNodes(node.nodes, allNodes);
    });
    return allNodes;
}

export const downloadAllCsv = (nodes, fileName, columns) => {
    const formatedColumns = columns.map((column) => ({
        accessor: (item) =>
            column.renderCell
                ? column.renderCell({ data: item[column.data], item, csvDownload: true })
                : item[column.data],
        name: column.label,
    }));
    const allNodes = getAllNodes(nodes);
    downloadAsCsv(formatedColumns, allNodes, fileName);
};