import React, { Component } from 'react';
import Message from '../../modules/Message';
import Loading from '../Loading';
import CreateClusterModal from './createCluster';
import { addClusterListQuery, deleteClusterQuery, getClustersQuery } from './query';

class ManualClusterSelect extends Component {
    constructor() {
        super();

        this.handleInputChange = this.handleInputChange.bind(this);
        this.onOpenModal = this.onOpenModal.bind(this);
        this.onCloseModal = this.onCloseModal.bind(this);
        this.getClusters = this.getClusters.bind(this);
        this.deleteMessage = this.deleteMessage.bind(this);
        this.onDeleteCluster = this.onDeleteCluster.bind(this);
        this.addCluster = this.addCluster.bind(this);
        this.onSelectCluster = this.onSelectCluster.bind(this);

        this.state = {
            inputValue: '',
            items: [],
            searchedValue: [],
            isOpen: false,
            isLoading: false,
            message: '',
            message_tags: '',
            isLoadingCluster: [],
        };
    }

    getClusters() {
        this.setState({ isLoading: true });
        const data = {
            domain_url: window.filters.get().domain.value,
            cluster_type: this.props.cluster_type,
        };
        getClustersQuery(data, this.props.csrf_token)
            .then((res) => res.json())
            .then((data) => {
                if (data.status === 200) {
                    const items = data.clusters;
                    this.setState({ items, searchedValue: items });
                }
            })
            .catch((err) => console.log(err))
            .finally(() => {
                this.setState({ isLoading: false });
            });
    }

    componentDidMount() {
        this.getClusters();
    }

    handleInputChange(e) {
        this.setState({
            inputValue: e.target.value,
            searchedValue: this.state.items.filter((x) => x.name.includes(e.target.value)),
        });
    }

    onOpenModal(e) {
        e.stopPropagation();
        this.setState({ isOpen: true });
    }

    onCloseModal(e) {
        e.stopPropagation();
        this.setState({ isOpen: false });
    }

    deleteMessage() {
        this.setState({
            message: '',
            message_tags: '',
        });
    }

    onDeleteCluster(e, itemId) {
        e.stopPropagation();
        const data = {
            domain_url: window.filters.get().domain.value,
            cluster_type: this.props.cluster_type,
            cluster_id: itemId,
        };
        this.setState({
            isLoadingCluster: {
                ...this.isLoadingCluster,
                [itemId]: true,
            },
        });

        deleteClusterQuery(this.props.current_url, data, this.props.csrf_token)
            .then((res) => res.json())
            .then((data) => {
                if (data.status === 200) {
                    this.setState({
                        message: data?.message,
                        message_tags: 'success',
                        isLoadingCluster: {
                            ...this.isLoadingCluster,
                            [itemId]: false,
                        },
                    });
                    this.getClusters();
                } else {
                    this.setState({
                        message: data?.message,
                        message_tags: 'error',
                        isLoadingCluster: {
                            ...this.isLoadingCluster,
                            [itemId]: false,
                        },
                    });
                }
            })
            .catch((err) => console.log(err));
    }

    onSelectCluster(item){
        this.setState({
            selectedCluster: item.name
        })
        return this.props.setValues(item.id, (data) => this.addCluster(data, item.id))
    }

    onChooseCluster(e, itemId) {
        e.stopPropagation();
        const data = {
            domain_url: window.filters.get().domain.value,
            cluster_type: this.props.cluster_type,
            values: this.props.selectedItems?.map((x) => x[this.props.cluster_type]),
            cluster_id: itemId,
        };
      
        this.setState({
            isLoadingCluster: {
                ...this.isLoadingCluster,
                [itemId]: true,
            },
        });

        this.addCluster(data, itemId)
    }

    addCluster(data, itemId){
        addClusterListQuery(this.props.current_url, data, this.props.csrf_token)
            .then((res) => res.json())
            .then((data) => {
                if (data.status === 201) {
                    this.setState({
                        message: data?.message,
                        message_tags: 'success',
                        isLoadingCluster: {
                            ...this.isLoadingCluster,
                            [itemId]: false,
                        },
                    });
                    if (this.props.onRemoveAllSelected) this.props.onRemoveAllSelected(true);
                    const dropdown = document.getElementById('cluster-dropdown');
                    dropdown.classList.remove('show');
                    if (this.props.onSaveList) this.props.onSaveList();
                } else {
                    this.setState({
                        message: data?.message,
                        message_tags: 'error',
                        isLoadingCluster: {
                            ...this.isLoadingCluster,
                            [itemId]: false,
                        },
                    });
                }
            })
            .catch((err) => console.log(err));
    }

    render() {
        return (
            <>
                <div className="clusters-list dropdown">
                    <button
                        className="list-group-item-action add-cluster-btn"
                        id="dropdownClusterMenu"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        disabled={!this.props.selectedItems?.length}
                    >
                        {this.state.selectedCluster ?? this.props.header}
                    </button>
                    <div
                        className="dropdown-menu"
                        aria-labelledby="dropdownClusterMenu"
                        id="cluster-dropdown"
                    >
                        <div className="dropdown-menu-header">All Lists</div>
                        <div>
                            <input
                                type="text"
                                placeholder="Search"
                                className="form-control search-cluster"
                                onChange={this.handleInputChange}
                                value={this.state.inputValue}
                            />
                        </div>
                        <hr />
                        <div className="dropdown-cluster">
                            {this.state.isLoading ? (
                                <Loading />
                            ) : (
                                <>
                                    {!this.state.searchedValue.length && (
                                        <div className="cluster-empty-message">No Clusters</div>
                                    )}
                                    {this.state.searchedValue.map((item) => (
                                        <div className="dropdown-cluster-item" key={item.id}>
                                            <button
                                                className="col-10 dropdown-cluster-item-success"
                                                disabled={this.state.isLoadingCluster[item.id]}
                                                onClick={(e) => this.props.fromManualList ? this.onSelectCluster(item) : this.onChooseCluster(e, item.id)}
                                            >
                                                {item.name}
                                            </button>
                                            {this.state.isLoadingCluster[item.id] ? (
                                                <div className="col-2 dropdown-cluster-item-loading">
                                                    <Loading onlyPreloader />
                                                </div>
                                            ) : (
                                                <button
                                                    className="col-2 dropdown-cluster-item-delete"
                                                    onClick={(e) =>
                                                        this.onDeleteCluster(e, item.id)
                                                    }
                                                >
                                                    <i className="icon-close"></i>
                                                </button>
                                            )}
                                        </div>
                                    ))}
                                </>
                            )}
                        </div>
                        <hr />
                        <div className="create-new-btn">
                            <button onClick={this.onOpenModal}>
                                Create New
                                <i className="icon-add-circle"></i>
                            </button>
                        </div>
                    </div>
                </div>
                <CreateClusterModal
                    current_url={this.props.current_url}
                    csrf_token={this.props.csrf_token}
                    cluster_type={this.props.cluster_type}
                    isOpen={this.state.isOpen}
                    onClose={this.onCloseModal}
                    getClusters={this.getClusters}
                />
                {this.state.message && (
                    <div className="messages">
                        <Message
                            message_tags={this.state.message_tags}
                            message={this.state.message}
                            deleteMessage={this.deleteMessage}
                        />
                    </div>
                )}
            </>
        );
    }
}

export default ManualClusterSelect;
