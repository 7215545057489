import { Component } from 'react';
import { onDisableEntityQuery } from './query';
import Loading from '../../components/Loading';
import Message from '../Message';

class Actions extends Component {
    constructor(props) {
        super();

        this.onDisableEntity = this.onDisableEntity.bind(this);
        this.deleteMessage = this.deleteMessage.bind(this);

        this.state = {
            status: props.status,
        };
    }

    async onDisableEntity() {
        try {
            this.setState({ loading: true });
            const response = await onDisableEntityQuery(
                this.props.endpoint_url,
                this.props.csrf_token,
                this.props.itemId,
                !this.state.status,
            );
            const data = await response.json();
            if (data.status === 200) {
                if (this.state.status) {
                    this.props.onChangeLimits(false);
                } else {
                    this.props.onChangeLimits(true);
                }
                this.setState({
                    status: !this.state.status,
                    loading: false,
                });
            } else {
                let message = '';
                if (data.message) {
                    message = data.message;
                } else {
                    message = 'Something went wrong.';
                }
                this.setState({
                    loading: false,
                    message,
                });
            }
        } catch (err) {
            this.setState({
                loading: false,
            });
            console.log(err);
        }
    }

    deleteMessage() {
        this.setState({ message: '' });
    }

    render() {
        return (
            <>
                {this.state.loading ? (
                    <Loading onlyPreloader />
                ) : (
                    <button className="ignor-icon-btn" onClick={this.onDisableEntity} title={this.state.status ? 'Active' : 'Inactive'}>
                        <span
                        className={`switch-button ${
                            this.state.status ? 'active' : 'not-active'
                        }`}
                        style={{height: '12px'}}
                        >
                            <span className="switch-circle" />
                        </span>
                    </button>
                )}
                {this.state.message && (
                    <div className="messages">
                        <Message
                            message_tags={'error'}
                            message={this.state.message}
                            deleteMessage={this.deleteMessage}
                        />
                    </div>
                )}
            </>
        );
    }
}

export default Actions;
