/* eslint-disable */
import FiltersData from './components/FilterData.js';

import Announcement from './components/Announcement';
import HelpBubble from './components/HelpBubble';
import AsyncLink from './components/AsyncLink';
import Loading from './components/Loading.js';
import Tag from './components/Tag.js';
import MessageCustom from './components/MessageCustom';

import render from './lib/render';

import Filters from './modules/Filters';
import SummaryTimeline from './modules/SummaryTimeline';
import ContentLifespan from './modules/ContentLifespan';
import TopCategories from './modules/TopCategories';
import TopAuthors from './modules/TopAuthors';
import TopCelebrities from './modules/TopCelebrities';
import TopConcepts from './modules/TopConcepts/index.js';
import DomainsWidgetContainer from './modules/DomainsWidgetContainer';
import TopRelevany from './modules/TopRelevany';
import PerContentPerf from './modules/PerContentPerf';
import TopPages from './modules/TopPages';
import PagesView from './modules/PagesView';
import EntitiesView from './modules/EntitiesView';
import EntitiesViewContainer from './modules/EntitiesViewContainer';
import AppearancesView from './modules/AppearancesView';
import AppearancesChart from './modules/AppearancesChart';
import CountriesView from './modules/CountriesView';
import DataBackfill from './modules/DataBackfill';
import UserDomains from './modules/UserDomains';
import CategoriesView from './modules/CategoriesView';
import CountryTimeline from './modules/CountriesView/CountryTimeline';
import CategoryTimeline from './modules/CategoryTimeline';
import CategoryTopPages from './modules/CategoryTopPages';
import CategorySingleTopPages from './modules/CategorySingleTopPages';
import CategoryAnalyze from './modules/CategoryAnalyze';
import EntityTimeline from './modules/EntityTimeline';
import EntitySingleTimeline from './modules/EntitySingleTimeline';
import PageCard from './modules/PageCard';
import Authors from './modules/Authors';
import AuthorTimeline from './modules/AuthorTimeline';
import AuthorTopPages from './modules/AuthorTopPages';
import AuthorEntities from './modules/AuthorEntities';
import RecommendedPages from './modules/RecommendedPages';
import RecommendedConceptsContainer from './modules/RecommendedConceptsContainer';
import RecommendedTopicsContainer from './modules/RecommendedTopicsContainer';
import RecommendationsContainer from './modules/RecommendationsContainer';
import UserActivities from './modules/UserActivities';
import NewChart from './modules/ChartJs';
import CompetitiveAnalysisView from './modules/CompetitiveAnalysisView';
import CustomersDomainComponent from './modules/CustomersDomainComponent';

import AddDomain from './modules/AddDomain';
import Registration from './modules/Registration';
import Message from './modules/Message';
import CollectedPagesChart from './modules/CollectedPagesChart';
import SearchDropdown from './modules/SearchDropdown';
import Reactivate from './modules/Reactivate';
import ActivateAllDomains from './modules/ActivateDomain/AllDomains';
import ActivateDomain from './modules/ActivateDomain/Domain';
import AddPayment from './modules/AddPaymentMethod/index.js';
import PaymentMethods from './modules/PaymentMethods/index.js';
import StatsNewVsOldPages from './modules/StatsNewVsOldPages';
import StatsLifespan from './modules/StatsLifespan';
import StatsDiscover from './modules/StatsDiscover';
import AddLocation from './modules/AddLocation';
import EditMainInfo from './modules/EditMainInfo';
import LowCTR from './modules/LowCTR';
import ChangePlane from './modules/ChangePlan';
import ComponentWithAnnouncement from './modules/ComponentWithAnnouncement';
import SendEmail from './modules/SendEmail';
import DeviceDropdown from './modules/DeviceDropdown';
import BulkPlanChange from './modules/BulkPlanChange';
import GSCDataSettings from './modules/GSCDataSettings';
import Overview from './modules/Overview/index.js';
import OverviewMessages from './modules/Overview/messages.js';
import AdditionalCharges from './modules/AdditionalCharges';
import GSCDataSettingsIgnoredItems from './modules/GSCDataSettings/ignoredItems';
import GSCReportCollectedPagesChart from './modules/GSCReports/GSCReportCollectedPagesChart';
import GSCReportSummaryTimeline from './modules/GSCReports/GSCReportSummaryTimeline';
import GSCReportNewKeywordsNewUrls from './modules/GSCReports/GSCReportNewKeywordsNewUrls';
import GSCReportBranded from './modules/GSCReports/GSCReportBranded';
import GSCReportTopKeywords from './modules/GSCReports/GSCReportTopKeywords';
import GSCReportTopPages from './modules/GSCReports/GSCReportTopPages';
import GSCReportKeywordDetail from './modules/GSCReports/GSCReportKeywordDetail';
import GSCReportUrlDetail from './modules/GSCReports/GSCReportUrlDetail';
import GSCReportPositionDistribution from './modules/GSCReports/GSCReportPositionDistribution';
import GSCReportEvergreen from './modules/GSCReports/GSCReportEvergreen';
import GSCContainerWithWeeks from './modules/GSCReports/GSCContainerWithWeeks';
import GSCReportBest from './modules/GSCReports/GSCReportBest';
import GSCReportYear from './modules/GSCReports/GSCReportYear';
import GSCReportCtrPerPosition from './modules/GSCReports/GSCReportCtrPerPosition';
import GSCReportDirectories from './modules/GSCReports/GSCReportDirectories';
import GSCContainerWithTotalClicks from './modules/GSCReports/GSCContainerWithTotalClicks';
import GSCReportDirectoryDetail from './modules/GSCReports/GSCReportDirectoryDetail';
import GSCReportSubdomainDetail from './modules/GSCReports/GSCReportSubdomainDetail';
import GSCReportsKeywordCluster from './modules/GSCReports/GSCReportKeywordCluster';
import GSCReportKeywordClustersDetailChart from './modules/GSCReports/GSCReportKeywordClustersDetailChart';
import GSCReportKeywordClustersDetailTable from './modules/GSCReports/GSCReportKeywordClustersDetailTable';
import GSCReportKeywordClustersUrlsDetailTable from './modules/GSCReports/GSCReportKeywordClustersUrlsDetailTable';
import GSCReportManualUrlClustersDetailChart from './modules/GSCReports/GSCReportManualUrlClustersDetailChart';
import GSCReportManualUrlClustersDetailTable from './modules/GSCReports/GSCReportManualUrlClustersDetailTable';
import GSCReportQuestions from './modules/GSCReports/GSCReportQuestions';
import GSCReportComparison from './modules/GSCReports/GSCReportComparison';
import GSCReportAnnouncement from './modules/GSCReports/GSCReportAnnouncement';
import GSCReportSearchAppearance from './modules/GSCReports/GSCReportSearchAppearance';
import GSCReportCountries from './modules/GSCReports/GSCReportCountries';
import GSCReportsManualKeywordCluster from './modules/GSCReports/GSCReportsManualKeywordCluster';
import GSCReportsManualURLCluster from './modules/GSCReports/GSCReportsManualURLCluster';
import GSCReportAnonymoized from './modules/GSCReports/GSCReportAnonymoized';
import GSCTestData from './modules/GSCTestData';
import ScrapPagesTest from './modules/ScrapPagesTest';
import GSCReportSeasonalKeywords from './modules/GSCReports/GSCReportSeasonalKeywords';
import GSCReportGoogleShare from './modules/GSCReports/GSCReportGoogleShare';
import GSCReportTypePerformance from './modules/GSCReports/GSCReportTypePerformance';
import TrackGoogleDiscoverResults from './modules/TrackGoogleDiscoverResults';
import TrackGoogleDiscoverResultsAdd from './modules/TrackGoogleDiscoverResultsAdd';
import PurchaseMoreEntity from './modules/PurchaseMoreEntity/container.js';
import GSCReportStrikingDistance from './modules/GSCReports/GSCReportStrikingDistance';
import GSCReportDirectoryDetailChart from './modules/GSCReports/GSCReportDirectoryDetailChart';
import DeleteLinkedUser from './modules/DeleteLinkedUser';
import TrackedEntityReportDomains from './modules/TrackedEntityReport';
import TrackedEntityReportStories from './modules/TrackedEntityReportStories';
import TrackedEntityReportVideos from './modules/TrackedEntityReportVideos';
import TrackedEntityReportEntity from './modules/TrackedEntityReportEntity';
import TrackedEntityReports from './modules/TrackedEntityReports/index.js';
import ToolsAnnouncement from './modules/ToolsAnnouncement';
import CategoriesViewAll from './modules/CategoriesViewAll';
import TrackEntityTopDomainsTimeline from './modules/TrackEntityTopDomainsTimeline';
import SearchTopEntities from './modules/ConnectedEntities';
import OpportunitiesSearch from './modules/Opportunities/Search';
import ReportExplainer from './components/ReportExplainer';
import ReportExplainerTools from './components/ReportExplainer/specificOnes/tools.js';
import ReportExplainerConcepts from './components/ReportExplainer/specificOnes/concepts.js';
import ReportExplainerCompetitiveAnalysis from './components/ReportExplainer/specificOnes/competitive-analysis.js';
import AnalyzeContent from './modules/AnalyzeContent/index.js';
import ActivatePlanComponent from './modules/ActivatePlanComponent';
import UserCancelation from './modules/UserCancelation';
import ChangeDomainLinkAddress from './modules/ChangeDomainLinkAddress';
import UserCancelationBtn from './modules/UserCancelation/OnlyButton.js';
import AuthorSettings from './modules/AuthorSettings';
import EntityContentContainer from './modules/EntityContentContainer';
import CategoryContainer from './modules/CategoryContainer';
import Steps from './modules/StepsGoogleDiscoverOptimization';
import StepsAnalytics from './modules/StepsAnalytics';
import AlgoComparison from './modules/AlgoComparision/index.js';
import LiveUrlComponent from './modules/LiveUrlComponent/index.js';
import CommerceApiSettings from './modules/CommerceApiSettings';
import CommerceReport from './modules/CommerceReport';

render('[component=Filters]', Filters);
render('[component="Summary"]', SummaryTimeline);
render('[component="ContentLifespan"]', ContentLifespan);
render('[component="TopCategories"]', TopCategories);
render('[component="TopAuthors"]', TopAuthors);
render('[component="NewChart"]', NewChart);
render('[component="TopCelebrities"]', TopCelebrities);
render('[component="TopConcepts"]', TopConcepts);
render('[component="DomainsWidgetContainer"]', DomainsWidgetContainer);
render('[component="TopRelevany"]', TopRelevany);
render('[component="PerContentPerf"]', PerContentPerf);
render('[component="TopPages"]', TopPages);
render('[component="PagesView"]', PagesView);
render('[component="EntitiesView"]', EntitiesView);
render('[component="EntitiesViewContainer"]', EntitiesViewContainer);
render('[component="AppearancesView"]', AppearancesView);
render('[component="AppearancesChart"]', AppearancesChart);
render('[component="CountriesView"]', CountriesView);
render('[component="CountryTimeline"]', CountryTimeline);
render('[component="DataBackfill"]', DataBackfill);
render('[component="UserDomains"]', UserDomains);
render('[component="CategoriesView"]', CategoriesView);
render('[component="CategoryTimeline"]', CategoryTimeline);
render('[component="CategoryTopPages"]', CategoryTopPages);
render('[component="CategorySingleTopPages"]', CategorySingleTopPages);
render('[component="CategoryAnalyze"]', CategoryAnalyze);
render('[component="EntityTimeline"]', EntityTimeline);
render('[component="EntitySingleTimeline"]', EntitySingleTimeline);
render('[component="PageCard"]', PageCard);
render('[component="Authors"]', Authors);
render('[component="AuthorTimeline"]', AuthorTimeline);
render('[component="AuthorTopPages"]', AuthorTopPages);
render('[component="AuthorEntities"]', AuthorEntities);
render('[component="RecommendedPages"]', RecommendedPages);
render('[component="RecommendedTopicsContainer"]', RecommendedTopicsContainer);
render('[component="RecommendedConceptsContainer"]', RecommendedConceptsContainer);
render('[component="CollectedPagesChart"]', CollectedPagesChart);
render('[component="SearchDropdown"]', SearchDropdown);
render('[component="Reactivate"]', Reactivate);
render('[component="ActivateAllDomains"]', ActivateAllDomains);
render('[component="ActivateDomain"]', ActivateDomain);
render('[component="AddPayment"]', AddPayment);
render('[component="PaymentMethods"]', PaymentMethods);
render('[component="Message"]', Message);
render('[component="MessageCustom"]', MessageCustom);
render('[component="UserActivities"]', UserActivities);
render('[component="StatsNewVsOldPages"]', StatsNewVsOldPages);
render('[component="StatsLifespan"]', StatsLifespan);
render('[component="StatsDiscover"]', StatsDiscover);
render('[component="CompetitiveAnalysisView"]', CompetitiveAnalysisView);
render('[component="AddLocation"]', AddLocation);
render('[component="EditMainInfo"]', EditMainInfo);
render('[component="AddDomain"]', AddDomain);
render('[component="Registration"]', Registration);
render('[component="LowCTR"]', LowCTR);
render('[component="RecommendationsContainer"]', RecommendationsContainer);
render('[component="ChangePlane"]', ChangePlane);
render('[component="ComponentWithAnnouncement"]', ComponentWithAnnouncement);
render('[component="SendEmail"]', SendEmail);
render('[component="DeviceDropdown"]', DeviceDropdown);
render('[component="BulkPlanChange"]', BulkPlanChange);
render('[component="Overview"]', Overview);
render('[component="OverviewMessages"]', OverviewMessages);
render('[component="AdditionalCharges"]', AdditionalCharges);
render('[component="GSCDataSettings"]', GSCDataSettings);
render('[component="GSCReportCollectedPagesChart"]', GSCReportCollectedPagesChart);
render('[component="GSCReportSummaryTimeline"]', GSCReportSummaryTimeline);
render('[component="GSCReportNewKeywordsNewUrls"]', GSCReportNewKeywordsNewUrls);
render('[component="GSCReportBranded"]', GSCReportBranded);
render('[component="GSCReportTopKeywords"]', GSCReportTopKeywords);
render('[component="GSCReportTopPages"]', GSCReportTopPages);
render('[component="GSCReportPositionDistribution"]', GSCReportPositionDistribution);
render('[component="GSCReportKeywordDetail"]', GSCReportKeywordDetail);
render('[component="GSCReportUrlDetail"]', GSCReportUrlDetail);
render('[component="GSCReportEvergreen"]', GSCReportEvergreen);
render('[component="GSCContainerWithWeeks"]', GSCContainerWithWeeks);
render('[component="GSCReportBest"]', GSCReportBest);
render('[component="GSCReportYear"]', GSCReportYear);
render('[component="GSCReportCtrPerPosition"]', GSCReportCtrPerPosition);
render('[component="GSCReportDirectories"]', GSCReportDirectories);
render('[component="GSCContainerWithTotalClicks"]', GSCContainerWithTotalClicks);
render('[component="GSCReportDirectoryDetail"]', GSCReportDirectoryDetail);
render('[component="GSCReportSubdomainDetail"]', GSCReportSubdomainDetail);
render('[component="GSCReportsKeywordCluster"]', GSCReportsKeywordCluster);
render('[component="GSCReportKeywordClustersDetailChart"]', GSCReportKeywordClustersDetailChart);
render('[component="GSCReportKeywordClustersDetailTable"]', GSCReportKeywordClustersDetailTable);
render('[component="GSCReportQuestions"]', GSCReportQuestions);
render('[component="GSCDataSettingsIgnoredItems"]', GSCDataSettingsIgnoredItems);
render('[component="GSCReportComparison"]', GSCReportComparison);
render('[component="GSCReportAnnouncement"]', GSCReportAnnouncement);
render('[component="GSCReportSearchAppearance"]', GSCReportSearchAppearance);
render('[component="GSCReportCountries"]', GSCReportCountries);
render('[component="GSCReportsManualKeywordCluster"]', GSCReportsManualKeywordCluster);
render('[component="GSCReportsManualURLCluster"]', GSCReportsManualURLCluster);
render('[component="GSCTestData"]', GSCTestData);
render('[component="GSCReportStrikingDistance"]', GSCReportStrikingDistance);
render(
    '[component="GSCReportManualUrlClustersDetailTable"]',
    GSCReportManualUrlClustersDetailTable,
);
render(
    '[component="GSCReportManualUrlClustersDetailChart"]',
    GSCReportManualUrlClustersDetailChart,
);
render('[component="GSCReportAnonymoized"]', GSCReportAnonymoized);
render('[component="DeleteLinkedUser"]', DeleteLinkedUser);
render('[component="GSCReportDirectoryDetailChart"]', GSCReportDirectoryDetailChart);

render('[component="HelpBubble"]', HelpBubble);
render('[component="AsyncLink"]', AsyncLink);
render('[component="Loading"]', Loading);
render('[component="Tag"]', Tag);
render('[component="Announcement"]', Announcement);
render('[component="CustomersDomainComponent"]', CustomersDomainComponent);
render('[component="GSCReportSeasonalKeywords"]', GSCReportSeasonalKeywords);
render('[component="GSCReportGoogleShare"]', GSCReportGoogleShare);
render('[component="GSCReportTypePerformance"]', GSCReportTypePerformance);
render('[component="TrackGoogleDiscoverResults"]', TrackGoogleDiscoverResults);
render('[component="TrackGoogleDiscoverResultsAdd"]', TrackGoogleDiscoverResultsAdd);
render('[component="TrackedEntityReportDomains"]', TrackedEntityReportDomains);
render('[component="TrackedEntityReportStories"]', TrackedEntityReportStories);
render('[component="TrackedEntityReportVideos"]', TrackedEntityReportVideos);
render('[component="TrackedEntityReportEntity"]', TrackedEntityReportEntity);
render('[component="TrackedEntityReports"]', TrackedEntityReports);
render('[component="PurchaseMoreEntity"]', PurchaseMoreEntity);
render('[component="ToolsAnnouncement"]', ToolsAnnouncement);
render('[component="CategoriesViewAll"]', CategoriesViewAll);
render('[component="TrackEntityTopDomainsTimeline"]', TrackEntityTopDomainsTimeline);
render('[component="SearchTopEntities"]', SearchTopEntities);
render('[component="OpportunitiesSearch"]', OpportunitiesSearch);
render('[component="ReportExplainer"]', ReportExplainer);
render('[component="ReportExplainerTools"]', ReportExplainerTools);
render('[component="ReportExplainerConcepts"]', ReportExplainerConcepts);
render('[component="ReportExplainerCompetitiveAnalysis"]', ReportExplainerCompetitiveAnalysis);
render('[component="AnalyzeContent"]', AnalyzeContent);
render('[component="ActivatePlanComponent"]', ActivatePlanComponent);
render(
    '[component="GSCReportKeywordClustersUrlsDetailTable"]',
    GSCReportKeywordClustersUrlsDetailTable,
);
render('[component="UserCancelation"]', UserCancelation);
render('[component="ChangeDomainLinkAddress"]', ChangeDomainLinkAddress);
render('[component="UserCancelationBtn"]', UserCancelationBtn);
render('[component="AuthorSettings"]', AuthorSettings);
render('[component="EntityContentContainer"]', EntityContentContainer);
render('[component="CategoryContainer"]', CategoryContainer);
render('[component="Steps"]', Steps);
render('[component="StepsAnalytics"]', StepsAnalytics);
render('[component="ScrapPagesTest"]', ScrapPagesTest);
render('[component="AlgoComparison"]', AlgoComparison);
render('[component="LiveUrlComponent"]', LiveUrlComponent);
render('[component="CommerceApiSettings"]', CommerceApiSettings);
render('[component="CommerceReport"]', CommerceReport);
