import { Cell } from '@table-library/react-table-library/table';
import numeral from 'numeral';
import { collectCell, getPercent } from '../../../../helpers/showDelta';

export const COLUMNS = [
    {
        label: 'First directory',
        key: 'first_directory',
        data: 'first_directory',
        Component: Cell,
        renderCell: ({ data, item, csvDownload }) => {
            if (csvDownload) return data;

            return  <strong>
                        <a
                            href={`/domains/${window.info.id}/gsc-reports/directories/directory?directory=${data ?? '/'}&subdomain=${item.subdomain ?? '/'}`}
                            className="d-inline-flex align-items-center justify-content-center"
                            rel="noreferrer"
                        >
                            {data ?? '/'}
                        </a>
                    </strong>;
        },
    },
    {
        label: 'Subdomain',
        key: 'subdomain',
        data: 'subdomain',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return <strong>{data}</strong>;
        },
    },
    {
        label: 'Impressions',
        key: 'impressions',
        data: 'impressions',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return <strong>{numeral(data).format('0.0a')}</strong>;
        },
    },
    {
        label: <span className="delta-sign">&#916;</span>,
        key: 'impressions_delta',
        data: 'impressions_delta',
        Component: Cell,
        renderCell: ({ item, csvDownload }) => {
            if (csvDownload) return getPercent(item.impressions, item.impressionsPrior);
            return collectCell(item.impressions, item.impressionsPrior);
        },
    },
    {
        label: 'Clicks',
        key: 'clicks',
        data: 'clicks',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return <strong>{numeral(data).format('0.0a')}</strong>;
        },
    },
    {
        label: <span className="delta-sign">&#916;</span>,
        key: 'clicks_delta',
        data: 'clicks_delta',
        Component: Cell,
        renderCell: ({ item, csvDownload }) => {
            if (csvDownload) return getPercent(item.clicks, item.clicksPrior);
            return collectCell(item.clicks, item.clicksPrior);
        },
    },
    {
        label: 'Position',
        key: 'avgPosition',
        data: 'avgPosition',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return <strong>{numeral(data).format('0.0a')}</strong>;
        },
    },
    {
        label: <span className="delta-sign">&#916;</span>,
        key: 'avgPosition_delta',
        data: 'avgPosition_delta',
        Component: Cell,
        renderCell: ({ item, csvDownload }) => {
            if (csvDownload) return getPercent(item.avgPosition, item.avgPositionPrior);
            return collectCell(item.avgPosition, item.avgPositionPrior);
        },
    },
    {
        label: 'CTR',
        key: 'avgCTR',
        data: 'avgCTR',
        Component: Cell,
        renderCell: ({ item, csvDownload }) => {
            const ctrValue = item.clicks/item.impressions;
            if (csvDownload) return ctrValue;

            return <strong>{numeral(ctrValue).format('0.00%')}</strong>;
        },
    },
    {
        label: <span className="delta-sign">&#916;</span>,
        key: 'avgCTR_delta',
        data: 'avgCTR_delta',
        Component: Cell,
        renderCell: ({ item, csvDownload }) => {
            const ctrValue = item.clicks/item.impressions;
            if (csvDownload) return getPercent(ctrValue, item.avgCTRPrior);
            return collectCell(ctrValue, item.avgCTRPrior);
        },
    },
    {
        label: 'ranking weeks',
        key: 'weeks',
        data: 'weeks',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return <strong>{data}</strong>;
        },
    },
];
