import Loading from '../../components/Loading';
import LoadMoreComponent from '../../components/LoadMoreComponent';

import { COLUMNS } from './keys';
import query from './query';
import CheckData from '../../components/CatchError';
import DataTable from '../../components/Datatable';
import { shortNoDataMessage } from '../../helpers/noDataMessage';
import { scrollForTable } from '../../components/Datatable/utils';

class Summary extends LoadMoreComponent {
    constructor(props) {
        super();
        this.query = query({ offset: 0, domain: props.domain });

        this.loadMore = this.loadMore.bind(this);
        this.showMore = this.showMore.bind(this);
        this.offsetStep = 100;
        this.defaultCount = 10;
    }

    searchFunction(data, search) {
        return data.filter((item) => {
            const title = item.title || item.page;
            return title.toLowerCase().includes(search.toLowerCase());
        });
    }

    showMore() {
        this.setState({
            isShowMore: true,
        });
    }

    render() {
        const attr = {
            query,
            offsetStep: this.offsetStep,
            domain: this.props.domain,
        };

        const showLoadMoreBtn = this.state.data?.length === this.offsetStep;

        const sortFns = {
            page: (array) =>
                array.sort((a, b) => {
                    const a_title = a.title || a.page;
                    const b_title = b.title || b.page;
                    a_title.localeCompare(b_title);
                }),
            days: (array) => array.sort((a, b) => a.days - b.days),
            impressions: (array) => array.sort((a, b) => a.impressions - b.impressions),
            clicks: (array) => array.sort((a, b) => a.clicks - b.clicks),
            published: (array) =>
                array.sort((a, b) => new Date(a.published.value) - new Date(b.published.value)),
            last_modified: (array) =>
                array.sort((a, b) => new Date(a.modified.value) - new Date(b.modified.value)),
            clicks_change: (array) => array.sort((a, b) => a.clicks_change - b.clicks_change),
            impressions_change: (array) =>
                array.sort((a, b) => a.impressions_change - b.impressions_change),
        };

        return !this.state.allData.length ? (
            <div>{shortNoDataMessage}</div>
        ) : (
            <>
                <CheckData
                    content_id={this.props.content_id}
                    data={this.state.allData}
                    loading={this.state.loading}
                    loadMore={this.state.loadMore}
                >
                    <DataTable
                        nodes={this.state.allData}
                        sortFns={sortFns}
                        COLUMNS={COLUMNS(this.props.default_image)}
                        fileName="Pages"
                        forceRun={this.forceRun}
                        searchFunction={this.searchFunction}
                        defaultSize={this.defaultCount}
                        additionalStyles={{
                            Table: `
                                        --data-table-library_grid-template-columns : minmax(120px, 5fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) !important;
                                       ${scrollForTable}
                                    `,
                            BaseCell: `  font-family: 'Raleway';
                                        font-weight: 500;
                                        font-size: 16px;
                                        line-height: 19px;
                                        padding: 16px 5px;
                                        color: #0D182C;
                                        border-bottom: 1px solid rgba(13, 24, 44, 0.1);
                                    
                                        &.underline {
                                        text-decoration: underline;
                                        }`,
                        }}
                    />
                </CheckData>
                {this.state.loadMore ? (
                    <Loading padding={10} />
                ) : (
                    showLoadMoreBtn && (
                        <div className="d-flex align-items-center justify-content-center pt-3 load-more-section">
                            <button
                                onClick={() => this.loadMore(attr)}
                                disabled={this.state.loading}
                                className="load-more-btn"
                            >
                                Load More
                            </button>
                        </div>
                    )
                )}
            </>
        );
    }
}

export default Summary;
