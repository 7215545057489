import React from 'react';
import ActivateModalDomains from './modal';

class ActivateAllDomains extends React.Component {
    constructor() {
        super();

        this.onOpen = this.onOpen.bind(this);
        this.onClose = this.onClose.bind(this);

        this.state = {
            isOpen: false,
        };
    }

    onOpen() {
        this.setState({ isOpen: true });
    }

    onClose() {
        this.setState({ isOpen: false });
    }

    render() {
        return (
            <>
                <button
                    className="btn btn-sm text-white bg-purple d-inline-block text-capitalize table-button"
                    onClick={this.onOpen}
                    style={{ marginRight: '5px', fontSize: '16px' }}
                >
                    Activate Plan Domains now
                </button>
                <ActivateModalDomains
                    isOpen={this.state.isOpen}
                    onClose={this.onClose}
                    activationLink="/customers/end-trial/"
                    available_domains_count={this.props.available_domains_count}
                    submitBtn="Activate Plan"
                    message={`By "Activating" your plan, your trial period will stop and you will be charged for the first billing period immediately.`}
                />
            </>
        );
    }
}

export default ActivateAllDomains;
