import CheckData from '../../../components/CatchError';
import Component from '../../../components/Component';
import DataTable from '../../../components/Datatable';
import { scrollForTable } from '../../../components/Datatable/utils';
import Loading from '../../../components/Loading';
import Chart from './CountryTimeline';
import { COLUMNS } from './keys';
import query from './query';

class GSCReportCountries extends Component {
    constructor() {
        super();
        this.searchFunction = this.searchFunction.bind(this);
        this.setCountry = this.setCountry.bind(this);
        this.query = query;
    }

    componentDidUpdate(prevProps, prevState) {
        if (JSON.stringify(this.state.data) !== JSON.stringify(prevState.data)) {
            this.setState({ selectedItem: this.state.data?.[0] });
        }
    }

    searchFunction(data, search) {
        return data.filter((item) => item.country?.toLowerCase().includes(search.toLowerCase()));
    }

    setCountry(selectedItem) {
        this.setState({
            selectedItem,
        });
    }

    render() {
        if (this.state.loading && !this.state.loadMore) {
            return <Loading padding={10} />;
        }

        const sortFns = {
            country: (array) => array.sort((a, b) => a.country.localeCompare(b.country)),
            share: (array) => array.sort((a, b) => a.share - b.share),
            clicks: (array) => array.sort((a, b) => a.clicks - b.clicks),
            impressions: (array) => array.sort((a, b) => a.impressions - b.impressions),
            avgCTR: (array) =>
                array.sort((a, b) => a.clicks / a.impressions - b.clicks / b.impressions),
            avgPosition: (array) => array.sort((a, b) => a.avgPosition - b.avgPosition),
        };

        return (
            <>
                {this.state.selectedItem && (
                    <div className="row card-row d-flex">
                        <div className="col-12">
                            <div className="card rounded-main overflow-hidden " id="domain-summery">
                                <div className="card-header">
                                    <p className="card-title">{this.state.selectedItem.country}</p>
                                </div>
                                <div className="card-body">
                                    <Chart
                                        selectedItem={this.state.selectedItem}
                                        forceRun={this.forceRun}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <div className="row card-row">
                    <div className="col-12">
                        <div className="card table-main rounded-main overflow-hidden">
                            <div className="card-header">
                                <p className="card-title">Countries</p>
                            </div>
                            <div className="card-body" id="countries-table">
                                <CheckData
                                    content_id={this.props.content_id}
                                    parent_id={this.props.parent_id}
                                    data={this.state.data}
                                    loading={this.state.loading}
                                >
                                    <DataTable
                                        nodes={this.state.data}
                                        sortFns={sortFns}
                                        COLUMNS={COLUMNS(this.setCountry)}
                                        uniqKey={'country'}
                                        fileName="Countries"
                                        searchFunction={this.searchFunction}
                                        forceRun={this.forceRun}
                                        additionalStyles={{
                                            Table: `
                                                --data-table-library_grid-template-columns :  minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) !important;
                                               ${scrollForTable}
                                            `,
                                            BaseCell: `font-family: 'Raleway';
                                                        font-weight: 500;
                                                        font-size: 16px;
                                                        line-height: 19px;
                                                        padding: 16px 5px !important;
                                                        color: #0D182C;
                                                        border-bottom: 1px solid rgba(13, 24, 44, 0.1);
                                                    
                                                        &.underline {
                                                            text-decoration: underline;
                                                        }
                                                        `,
                                        }}
                                    />
                                </CheckData>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default GSCReportCountries;
