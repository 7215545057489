import { Component } from "react";
import ConfirmationModal from "../../components/Modals/ConfirmationModal";

class ActivatePlanComponent extends Component{
    constructor(){
        super()

        this.onOpen = this.onOpen.bind(this)
        this.onClose = this.onClose.bind(this)
        this.endTrial = this.endTrial.bind(this)
        this.state = {
            isOpen: false,
        }
    }
    
    onOpen(){
        this.setState({
            isOpen: true,
        })
    }

    onClose(){
        this.setState({
            isOpen: false,
        })
    }

    endTrial() {
        this.setState({ loading: true });
        fetch(`/customers/end-trial/?domain=${this.props.domain_id}`)
            .then((res) => res.json())
            .then((data) => {
                const redirect_url = data.redirect_url;
                window.location.pathname = redirect_url;
            })
            .catch((err) => console.log(err))
            .finally(() => {
                this.setState({ loading: false });
            });
    }

    render(){
        return (
            <>
                <button className="button button-filled button-primary width-sm btn d-flex align-items-center justify-content-center" onClick={this.onOpen} >{this.props.button_name || 'Activate Plan'}</button>
                <ConfirmationModal
                    isOpen={this.state.isOpen}
                    message={
                        'By "Activating" your plan, your trial period will stop and you will be charged for the first billing period immediately.'
                    }
                    onClose={this.onClose}
                    onSubmit={this.endTrial}
                    buttonName="Activate"
                />
            </>
        )
    }
}

export default ActivatePlanComponent