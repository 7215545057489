import React, { Component } from 'react';
import colors from '../../lib/colors';

import { avg, linReg } from '../../lib/reduce';
import { Chart } from 'react-chartjs-2';
import Metric from '../Metric';
import numeral from 'numeral';
import annotationPlugin from 'chartjs-plugin-annotation';

import {
    Chart as ChartJS,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController,
    BubbleController,
} from 'chart.js';
import { addToHidenElements } from '../../helpers/window';
import GoogleFeedsModal from './GoogleFeedsModal';
import { getGoogleFeeds } from './query';
import Loading from '../Loading';
import { getLabel } from '../../helpers/date';

ChartJS.register(
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController,
    BubbleController,
    annotationPlugin,
);

const backgroundImage = new Image();
backgroundImage.src = '/static/img/google-icon.png';
const logo = new Image();
logo.src = '/static/img/logo-colored.svg';
const logo_dark = new Image();
logo_dark.src = '/static/img/logo-primary.svg';

class Timeline extends Component {
    constructor() {
        super();

        this.getGoogleFeeds = getGoogleFeeds;
        this.showError = this.showError.bind(this);
        this.checkIsEmptyAll = this.checkIsEmptyAll.bind(this);
        this.onModalClose = this.onModalClose.bind(this);
        this.onOpenModal = this.onOpenModal.bind(this);
        this.toogleGoogleFeeds = this.toogleGoogleFeeds.bind(this);
        this.handleMoodeChange = this.handleMoodeChange.bind(this);

        const isDark = JSON.parse(localStorage.getItem('dark-mode'));

        this.state = {
            isOpenModal: false,
            selectedItems: null,
            data: [],
            loading: true,
            isToggledGoogleFeeds: true,
            isDark: !!isDark
        };
    }

    async componentDidMount() {
        if (this.props.showGoogleFeeds) {
            this.getGoogleFeeds.bind(this.setState.bind(this));
            this.getGoogleFeeds.update({ force: true });
        }

        document.addEventListener('changeMoodEvent', this.handleMoodeChange);
    }

    componentWillUnmount(){
        document.removeEventListener('changeMoodEvent', this.handleMoodeChange);
    }

    handleMoodeChange(event){
        const isDark = JSON.parse(localStorage.getItem('dark-mode'));
        this.setState({ redraw: true, isDark });

        if (this.timeoutID) clearTimeout(this.timeoutID);

        this.timeoutID = setTimeout(() => {
            this.setState({ redraw: false });
        }, 100);
    }

    checkIsEmptyAll(list) {
        for (let element of list) {
            if (!element.classList.contains('hide-component')) return false;
        }
        return true;
    }

    onModalClose() {
        this.setState({ isOpenModal: false, selectedItems: null });
    }

    onOpenModal(items) {
        this.setState({ selectedItems: items, isOpenModal: true });
    }

    toogleGoogleFeeds() {
        this.setState({ isToggledGoogleFeeds: !this.state.isToggledGoogleFeeds, redraw: true });

        if (this.timeoutID) clearTimeout(this.timeoutID);

        this.timeoutID = setTimeout(() => {
            this.setState({ redraw: false });
        }, 100);
    }

    showError(show = true) {
        if (this.props.content_id && this.props.parent_id) {
            const direct_parent = document.getElementById(this.props.direct_parent_id);
            const warningBar = document.getElementById('warning-container');
            const content = document.getElementById(this.props.content_id);
            const parent = document.getElementById(this.props.parent_id);

            const childrens = parent?.children;

            if (!show) {
                content?.classList?.remove('hide-component');
                warningBar.style.display = 'none';
                parent.style.display = 'block';

                if (direct_parent) direct_parent.classList.remove('hide-component');

                return;
            }

            content?.classList?.add('hide-component');
            addToHidenElements(content);

            if (direct_parent) {
                const childrens = direct_parent?.children;
                const isEmptyAll = this.checkIsEmptyAll(childrens);
                if (isEmptyAll) {
                    direct_parent.classList.add('hide-component');
                    addToHidenElements(direct_parent);
                }
            }

            const isEmptyAll = this.checkIsEmptyAll(childrens);

            if (isEmptyAll) {
                warningBar.style.display = 'flex';
                parent.style.display = 'none';
                addToHidenElements(parent);
            }
        }

        return <></>;
    }

    render() {
        const isDark = this.state.isDark

        if (this.state.loading && this.props.showGoogleFeeds) {
            return <Loading />;
        }

        if (!this.props.data || !this.props.data[0]) {
            return this.showError();
        } else {
            this.showError(false);
        }

        let metrics = [];
        let n = 0;

        const passedKeys = this.props.keys || Object.keys(this.props.data[0]);
        // get in descending order and change to ascending
        const passedData = [...this.props.data].sort(
            (a, b) => new Date(a?.date?.value ?? a?.date) - new Date(b?.date?.value ?? b?.date),
        );

        if (!this.props.dontShowMetrics) {
            for (let key of passedKeys) {
                if (key == 'date' || key.withoutMetric) {
                    continue;
                }

                let color = key.color || colors[n];
                let metricType = this.props.metricType;
                let icon = key.icon || '';
                let vx = passedData.map((d, i) => {
                    return i;
                });
                let vy = passedData.map((d) => {
                    return d?.[key.key || key];
                });

                let out = linReg(vx, vy);

                metrics.push(
                    <Metric
                        value={avg(passedData, key.key || key)}
                        change={out.slope}
                        metricType={metricType}
                        label={key.label || key}
                        format={key.format || '0.00a'}
                        color={color}
                        icon={icon}
                        key={key.key}
                        info={key.info}
                    />,
                );

                n++;
            }
        }
        let executed = false;
        const onOpenModal = this.onOpenModal;
        const googleFeeds = this.state.data;
        const xAxis = this.props.xAxisKey ?? 'date';
        const showGoogleFeeds = this.props.showGoogleFeeds;



        const collectedItems = googleFeeds.reduce((acc, item) => {
            const first = new Date(passedData[0]?.[xAxis]?.value || passedData[0]?.[xAxis]);
            const end = new Date(
                passedData[passedData.length - 1]?.[xAxis]?.value ||
                    passedData[passedData.length - 1]?.[xAxis],
            );
            const date = new Date(item.date);
            const rangeItem = passedData.find((state, i, arr) => {
                const first_date = new Date(state?.[xAxis]?.value || state?.[xAxis]);
                const next_date = new Date(arr[i + 1]?.[xAxis]?.value || arr[i + 1]?.[xAxis]);
                if (date < first) {
                    return false;
                } else if (date > end) {
                    return false;
                } else if (date >= first_date && date < next_date) {
                    return true;
                }
                return false;
            });
            if (rangeItem?.date?.value) {
                acc[rangeItem?.date?.value] = acc[rangeItem?.date?.value]
                    ? [...acc[rangeItem?.date?.value], item]
                    : [item];
            }
            return acc;
        }, {});
        let arrayFromCollectedItems = [];
        for (let key in collectedItems) {
            const item = {
                date: key,
                items: collectedItems[key],
            };
            arrayFromCollectedItems.push(item);
        }

        const logoImage = {
            id: 'logoImage',
            beforeDraw(chart, args, options){
                const {  
                    ctx,
                    chartArea: { top, botton, left, right, width, height }
                } = chart
                const logoWidth = 110 
                const logoHeight = 25
                
                ctx.save()
                ctx.globalAlpha = 0.5;
                if(isDark){
                    if(logo_dark.complete){
                        ctx.drawImage(logo_dark, ctx.canvas.offsetWidth - logoWidth - 60, 25, logoWidth, logoHeight)
                    }else{
                        logo_dark.onload = () => chart.draw()
                    }
                }else{
                    if(logo.complete){
                        ctx.drawImage(logo, ctx.canvas.offsetWidth - logoWidth - 60, 25, logoWidth, logoHeight)
                    }else{
                        logo.onload = () => chart.draw()
                    }
                }
                ctx.restore()
            }
        }

        const legendMargin = {
            id: 'legendMargin',
            beforeInit(chart, legend, options){
                const fitValue = chart.legend.fit;
                chart.legend.fit = function fit(){
                    fitValue.bind(chart.legend)();
                    return this.height += 25
                }
            }
        }

        const googleFeedsPoints = {
            id: 'googleFeedsPoints',
            afterDatasetsDraw(chart, args, plugins) {
                if (!showGoogleFeeds) return;

                const {
                    ctx,
                    data,
                    chartArea: { top, botton, left, right, width, height },
                    scales: { x, y },
                } = chart;
                if (!executed) {
                    executed = true;
                    chart.canvas.addEventListener('click', handleMouseClick);
                }
                ctx.save();

                const feedsWithPlaces = googleFeeds
                    .map((item) => {
                        const date = new Date(item.date);
                        const oneDay = 86400000; // 1000 * 3600 * 24 - one day per miliseconds;
                        let place = 0;
                        let out = false;
                        const step =
                            (new Date(passedData[1]?.[xAxis]?.value || passedData[1]?.[xAxis]) -
                                new Date(passedData[0]?.[xAxis]?.value || passedData[0]?.[xAxis])) /
                            oneDay;
                        
                        const first = new Date(
                            passedData[0]?.[xAxis]?.value || passedData[0]?.[xAxis],
                        );

                        const end = new Date(
                            passedData[passedData.length - 1]?.[xAxis]?.value ||
                                passedData[passedData.length - 1]?.[xAxis],
                        );

                        for (let i = 0; i < passedData.length; i++) {
                            const selected_date = new Date(
                                passedData[i]?.[xAxis]?.value || passedData[i]?.[xAxis],
                            );

                            if (date < first || date > end) {
                                out = true;
                                break;
                            } else if (date >= selected_date) {
                                const difference =
                                    x.getPixelForValue(i + 1) - x.getPixelForValue(i);
                                const diffBetwennSelected = (date - selected_date) / oneDay;
                                place =
                                    x.getPixelForValue(i) +
                                    (difference / step) * diffBetwennSelected;
                                break;
                            }
                        }

                        return { ...item, place, out };
                    })
                    .filter((x) => !x.out);
                const newWithMergedPlaces = feedsWithPlaces.reduce((acc, curr, i ,arr) => {
                    for(let key in acc){
                        if(curr.place > key - 10 && curr.place < 10 + +key  ){
                            acc[key] = {place: acc[key].place, items: [...acc[key].items, curr]}
                            return acc
                        }
                    } 
                    acc[curr.place] = {place: curr.place, items: [curr]}
                    return acc 
                }, {})
                function handleMouseClick(event) {
                    // Get the mouse position relative to the canvas
                    const rect = chart.canvas.getBoundingClientRect();
                    const mouseX = event.clientX - rect.left;
                    const mouseY = event.clientY - rect.top;

                    // used sessionStorage to have always fresh data
                    const values = JSON.parse(sessionStorage.getItem('googleFeeds'))

                    values?.forEach((item) => {
                        if (
                            mouseX > item.place &&
                            mouseX < item.place + 18 &&
                            mouseY > top + height - 10 &&
                            mouseY < top + height + 8
                            ) {
                                onOpenModal(item?.items);
                            }
                        });
                }

                const values =  Object.values(newWithMergedPlaces)
                sessionStorage.setItem('googleFeeds', JSON.stringify(values))
                values?.forEach((item) => {
                    ctx.beginPath();
                    ctx.fillStyle = 'white';
                    ctx.roundRect(item.place, top + height - 10, 18, 18, 18);
                    ctx.fill();

                    ctx.fillStyle = 'white';
                    ctx.drawImage(backgroundImage, item.place + 3, top + height - 7, 12, 12);
                });
            },
        };
        const options = {
            maintainAspectRatio: false,
            scales: {
                x: {
                    grid: {
                        display: false,
                    },
                    title: {
                        display: !!this.props.xAxisTitle,
                        text: this.props.xAxisTitle,
                    },
                    ticks: {
                        color: isDark ? '#959ca5' : '#777777',
                    }
                },
                y: {
                    grid: {
                        display: false,
                    },
                    title: {
                        display: !!this.props.leftAxisLabel,
                        text: this.props.leftAxisLabel,
                    },
                    ticks: {
                        callback: function(value) {
                            return numeral(value).format('0 a');
                        },
                        color: isDark ? '#959ca5' : '#777777',
                    }
                },
            },
            plugins: {
                legend: {
                    align: 'start',
                    labels: {
                        color: isDark ? '#959ca5' : '#777777',
                        usePointStyle: true,
                        pointStyle: 'rect',
                        padding: 20
                    },
                },
                tooltip: {
                    callbacks: {
                        label: (context) => {
                            const percentagesValues = this.props.percentagesValues ?? [];
                            if (
                                percentagesValues.includes(context.dataset.label) ||
                                context.dataset.label.toLowerCase().includes('ctr')
                            ) {
                                return `${context.dataset.label}: ${Number(context.raw).toFixed(
                                    2,
                                )} %`;
                            }
                            return `${context.dataset.label}: ${numeral(context.raw).format(
                                '0.0a',
                            )}`;
                        },
                    },
                },
            },
        };

        if (this.props.hasRightAxis) {
            options.scales.y1 = {
                display: true,
                position: 'right',

                grid: {
                    display: false,
                },
                title: {
                    display: true,
                    text: this.props.rightAxisLabel,
                },
                ticks: {
                    callback: function(value) {
                        return numeral(value).format('0 a');
                    },
                    color: isDark ? '#959ca5' : '#777777',
                }
            };
        }
        if (this.props.hasSecondRightAxis) {
            options.scales.y2 = {
                display: true,
                position: 'right',
                reverse: this.props.secondRightAxisReversed || false,
                grid: {
                    display: false,
                },
                title: {
                    display: true,
                    text: this.props.rightSecondAxisLabel,
                },
                ticks: {
                    callback: function(value) {
                        return numeral(value).format('0 a');
                    },
                    color: isDark ? '#959ca5' : '#777777',
                }
            };
        }
        if (this.props.hasLeftSecondAxis) {
            options.scales.y3 = {
                display: true,
                position: 'left',
                grid: {
                    display: false,
                },
                title: {
                    display: true,
                    text: this.props.leftSecondAxisLabel ?? '',
                },
                ticks: {
                    callback: function(value) {
                        return numeral(value).format('0 a');
                    },
                    color: this.state.isDark ? '#959ca5' : '#777777',
                }
            };
        }

        const yAxises = ['y','y1', 'y2', 'y3']
        const additionalAxises = this.props.keys?.filter(item => item.yAxisID && !yAxises.includes(item.yAxisID))?.map(x => x.yAxisID) ?? []
        additionalAxises?.forEach(item => {
            options.scales[item] = {
                display: false,
                position: 'right',
                grid: {
                    display: false,
                },
            };
        })

        const chartData = this.props.chartData ?? {
            labels: passedData.map((item) => this.props.createLabels ? this.props.createLabels(item) : getLabel(item?.[xAxis]?.value || item?.[xAxis])),
            datasets: passedKeys.map((item) => ({
                label: item?.label,
                type: item.type ?? 'line',
                data: passedData.map((data) =>
                    item.render ? item.render(data?.[item.key]) : data?.[item.key],
                ),
                backgroundColor: [item.labelColor, item.labelColor],
                borderColor: item.labelColor,
                borderDash: item?.borderDash ?? [0],
                yAxisID: item.yAxisID ?? 'y',
                hidden: item.hidden ?? false,
                borderRadius: 4,
                tension: 0.4,
            })),
        };

        return (
            <>
                <div
                    className="Timeline"
                    style={{ height: '100%', minHeight: '150px', position: 'relative' }}
                >
                    <div className="metrics mb-2">{metrics}</div>
                    <div className="dt-exports d-flex justify-content-end w-100">
                        {showGoogleFeeds && (
                            <>
                                <img src='/static/img/google-icon.png' className='google-feeds-icon' alt="Google photo" title='Turn On/Off Google Update notes on Chart' />
                                <span
                                    className={`switch-button mr-10 ${
                                        this.state.isToggledGoogleFeeds ? 'active' : 'not-active'
                                    }`}
                                    onClick={this.toogleGoogleFeeds}
                                    title="Show Google Feeds"
                                >
                                    <span className="switch-circle" />
                                </span>
                            </>

                        )}
                        {this.props.forceRun && (
                            <button
                                className="dt-exports__button with-refresh"
                                type="button"
                                onClick={this.props.forceRun}
                                title="Refresh and clear cache"
                            >
                                <i className="icon-refresh"></i>
                            </button>
                        )}
                    </div>
                    <div
                        style={{
                            height: this.props.chartSize === 'lg' ? '400px' : '300px',
                            position: 'relative',
                        }}
                    >
                        <Chart
                            data={chartData}
                            options={options}
                            plugins={this.state.isToggledGoogleFeeds ? [googleFeedsPoints, legendMargin, logoImage] : [legendMargin, logoImage]}
                            redraw={this.state.redraw}
                        />
                    </div>
                </div>
                <GoogleFeedsModal
                    isOpen={this.state.isOpenModal}
                    onClose={this.onModalClose}
                    items={this.state.selectedItems}
                />
            </>
        );
    }
}

export default Timeline;
