import { Component } from 'react';
import Loading from '../Loading';
import { addToHidenElements } from '../../helpers/window';

class CheckData extends Component {
    constructor() {
        super();

        this.showError = this.showError.bind(this);
        this.checkIsEmptyAll = this.checkIsEmptyAll.bind(this);
    }

    showError(show = true) {
        try {
            setTimeout(() => {
                if (this.props.content_id && this.props.parent_id) {
                    const direct_parent = document.getElementById(this.props.direct_parent_id);
                    const warningBar = document.getElementById('warning-container');
                    const content = document.getElementById(this.props.content_id);
                    const parent = document.getElementById(this.props.parent_id);

                    const childrens = parent?.children;

                    if (!show) {
                        content.classList.remove('hide-component');
                        warningBar.style.display = 'none';
                        if (parent) parent.style.display = 'block';

                        if (direct_parent) direct_parent.classList.remove('hide-component');

                        return;
                    }

                    content.classList.add('hide-component');
                    addToHidenElements(content);

                    if (direct_parent) {
                        const childrens = direct_parent?.children;
                        const isEmptyAll = this.checkIsEmptyAll(childrens);
                        if (isEmptyAll) {
                            direct_parent.classList.add('hide-component');
                            addToHidenElements(direct_parent);
                        }
                    }

                    const isEmptyAll = this.checkIsEmptyAll(childrens);
                    if (isEmptyAll) {
                        warningBar.style.display = 'flex';
                        if (parent) {
                            parent.style.display = 'none';
                            addToHidenElements(parent);
                        }
                    }
                }
            }, 0);
        } catch (err) {
            console.log(err);
        }

        return <></>;
    }

    checkIsEmptyAll(list) {
        for (let element of list) {
            if (!element.classList.contains('hide-component')) return false;
        }
        return true;
    }

    render() {
        if (this.props.loading && !this.props.loadMore) {
            return <Loading padding={10} />;
        }
        if (this.props.customCheck && this.props.customCheckCondition) return this.props.children;

        if (!this.props.data || !this.props.data[0]) {
            return this.showError();
        } else {
            this.showError(false);
        }

        return this.props.children;
    }
}

export default CheckData;
