import React from 'react';
import numeral from 'numeral';
import Chart from '../Chart';
import Loading from '../Loading';
import { addToHidenElements } from '../../helpers/window';

let get = function (key, d, data) {
    if (typeof key == 'function') {
        return key(d, data);
    }

    return d[key];
};

class List extends Chart {
    constructor(props) {
        super();

        this.sort = this.sort.bind(this);
        let defaultSort = props.noDefaultSort ? {} : props.keys.filter((d) => d.sort)[0] || {};

        this.state = {
            loading: true,
            sort: {
                key: defaultSort.key || null,
                asc: false,
            },
        };

        this.showError = this.showError.bind(this);
        this.checkIsEmptyAll = this.checkIsEmptyAll.bind(this);
    }

    sort(item, e) {
        let sortKey = item.sortKey || item.key;
        e && e.preventDefault && e.preventDefault();
        if (sortKey == this.state.sort.key) {
            this.state.sort.asc = !this.state.sort.asc;
        } else {
            this.state.sort.key = sortKey;
            this.state.sort.asc = false;
        }

        this.setState(this.state);
    }

    checkIsEmptyAll(list) {
        for (let element of list) {
            if (!element.classList.contains('hide-component')) return false;
        }
        return true;
    }

    showError(show = true) {
        if (this.props.content_id && this.props.parent_id) {
            const direct_parent = document.getElementById(this.props.direct_parent_id);
            const warningBar = document.getElementById('warning-container');
            const content = document.getElementById(this.props.content_id);
            const parent = document.getElementById(this.props.parent_id);

            const childrens = parent.children;

            if (!show) {
                content.classList.remove('hide-component');
                warningBar.style.display = 'none';
                parent.style.display = 'block';

                if (direct_parent) direct_parent.classList.remove('hide-component');

                return;
            }

            content.classList.add('hide-component');
            addToHidenElements(content);

            if (direct_parent) {
                const childrens = direct_parent.children;
                const isEmptyAll = this.checkIsEmptyAll(childrens);
                if (isEmptyAll) {
                    direct_parent.classList.add('hide-component');
                    addToHidenElements(direct_parent);
                }
            }

            const isEmptyAll = this.checkIsEmptyAll(childrens);

            if (isEmptyAll) {
                warningBar.style.display = 'flex';
                parent.style.display = 'none';
                addToHidenElements(parent);
            }
        }

        return <></>;
    }

    render() {
        let styles = {};
        let header = [];

        if (this.props.color) {
            styles.backgroundColor = this.props.color;
        }

        if (this.props.loading && !this.props.loadMore) {
            return <Loading padding={10} />;
        }

        if (this.props.header) {
            let hcols = [];
            for (let k of this.props.keys) {
                if (k.hidden) {
                    continue;
                }
                let classes = 'icon-down';

                if (this.state.sort.key == k.key) {
                    classes = 'sort ' + (this.state.sort.asc ? 'icon-down' : 'icon-up');
                }

                hcols.push(
                    <div key={k.key} className={k.classes + ' ' + classes + ' header-col'}>
                        <a href="" onClick={this.sort.bind(this, k)}>
                            {k.label || k.key}
                        </a>
                    </div>,
                );
            }

            header = <div className="header d-flex">{hcols}</div>;
        }

        let red = 0;
        let data = this.props.data;
        let sort = this.state.sort.key || null;

        if (sort) {
            data = this.props.data.sort((a, b) => {
                let ad = get(sort, a, this.props.data);
                let bd = get(sort, b, this.props.data);
                let dir = this.state.sort.asc ? -1 : 1;
                return ad < bd ? 1 * dir : -1 * dir;
            });
        }

        let renderRow = (d, i) => {
            let cols = [];
            let bar = null;

            for (let k of this.props.keys) {
                let val = get(k.key, d, data);
                let div = '';
                if (k.bar == true) {
                    red = 
                        k.percentReady 
                            ? get(k.key, d, data) 
                            : get(k.key, d, data) /
                                data.reduce((e, f) => {
                                    return e + get(k.key, f, data);
                                }, 0);
                    bar = <div className="list-bar" style={{ width: red * 100 + '%' }}></div>;
                }

                if (k.change == true) {
                    let dir = val > 0 ? 'up' : 'down';
                    let arrow =
                        dir == 'up' ? (
                            <i className="icofont-dotted-up"></i>
                        ) : (
                            <i className="icofont-dotted-down"></i>
                        );
                    val = (
                        <span className={'change ' + dir}>
                            {!k.disableArrow && arrow} {numeral(Math.abs(val)).format(k.format || '0a%')}
                        </span>
                    );
                }

                if (k.hidden == true) {
                    continue;
                }

                div = k.render ? (
                    <React.Fragment key={`${i}-${k.key}`}>{k.render(d, data)}</React.Fragment>
                ) : (
                    <div key={`${i}-${k.key}`} className={k.classes + ' list-col'}>
                        {k.format ? numeral(val).format(k.format) : val}
                    </div>
                );

                cols.push(div);
            }

            return (
                <div key={i} className="list-item align-items-center">
                    {cols}
                    {bar}
                </div>
            );
        };

        if (this.props.color) {
            styles.backgroundColor = this.props.color;
        }

        let content =
            this.props.loading && !this.props.loadMore ? (
                <Loading />
            ) : (
                <div className={'list-rows'}>{data.map(renderRow)}</div>
            );

        if (!this.props.data || !this.props.data[0]) {
            return this.showError();
        } else {
            this.showError(false);
        }

        let head = '';

        if (this.props.label) {
            head = (
                <div className="card-header" style={styles}>
                    {this.props.label}
                </div>
            );
        }

        return (
            <div className={'List ' + (this.props.className || '')}>
                {this.props.forceRun && (
                    <div
                        className="dt-exports d-flex justify-content-end w-100"
                        title="Refresh and clear cache"
                    >
                        <button
                            className="dt-exports__button with-refresh"
                            style={this.props.refreshCoustomStyles ?? {}}
                            type="button"
                            onClick={this.props.forceRun}
                        >
                            <i className="icon-refresh"></i>
                        </button>
                    </div>
                )}
                {header}
                {content}
            </div>
        );
    }
}

export default List;
