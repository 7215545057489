import { Cell } from '@table-library/react-table-library/table';
import numeral from 'numeral';
import { linReg } from '../../lib/reduce';

let get = function (key, d, data) {
    if (typeof key == 'function') {
        return key(d, data);
    }

    return d[key];
};

let getImpCtr = (d, data) => {
    let imp = d.impressions / data.reduce((e, f) => e + f.impressions, 0);
    let ctr = d.ctr / (data.reduce((e, f) => e + f.ctr, 0) / data.length);

    return (imp + ctr) / 2;
};

export const COLUMNS = (averages, images) => [
    {
        label: 'Entity',
        key: 'name',
        data: 'name',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return (
                <div>
                    <a
                        href={
                            `/domains/${window.info.id}/entities/entity?entities:in=` +
                            encodeURIComponent(data)
                        }
                    >
                        {data}
                    </a>
                </div>
            );
        },
    },
    {
        label: 'Type',
        key: 'type',
        data: 'type',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return <div style={{ fontSize: '12px', textWrap: "wrap" }}>{data}</div>;
        },
    },
    {
        label: 'No. of Pages',
        key: 'pages',
        data: 'pages',
        Component: Cell,
    },
    {
        label: 'RELEVANCY \n (0-1)',
        key: 'RELEVANCY',
        data: 'RELEVANCY',
        Component: Cell,
        renderCell: ({ item, csvDownload }) => {
            if (csvDownload) return item.salience;

            let c = linReg(item.salienceL);
            let cr = c.slope >= 0 ? '+' : '-';

            return (
                <div>
                    <strong>{numeral(item.salience).format('0.00')}</strong>
                    <br />
                    {cr}
                    {numeral(Math.abs(c.slope)).format('0.0')}%
                </div>
            );
        },
    },
    {
        label: 'Est. Total Impressions',
        key: 'impressionsL',
        data: 'impressionsL',
        infoLink: '/support/attributed-impressions',
        info: 'Attributed Impressions is own unique metric to measure entity authority on site. Click on the icon to find out more.',
        Component: Cell,
        renderCell: ({ data, item, csvDownload }) => {
            if (csvDownload) return item.impressions;

            let c = linReg(data);
            let cr = c.slope >= 0 ? '+' : '-';

            return (
                <div>
                    <strong>{numeral(item.impressions).format('0.0a')}</strong>
                    <br />
                    {cr}
                    {numeral(Math.abs(c.slope)).format('0.0')}%
                </div>
            );
        },
    },
    {
        label: 'Est. Median Impressions per Article, Entity',
        key: 'median_impressions_per_entity',
        data: 'median_impressions_per_entity',
        infoLink: '/support/attributed-impressions',
        info: 'Attributed Impressions is own unique metric to measure entity authority on site. Click on the icon to find out more.',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return (
                <div>
                    <strong>{numeral(data).format('0.0a')}</strong>
                </div>
            );
        },
    },
    {
        label: 'CTR \n (Engagement)',
        key: 'ctr',
        data: 'ctr',
        Component: Cell,
        renderCell: ({ data, item, csvDownload }) => {
            if (csvDownload) return data;

            let c = linReg(item.ctrL);
            let cr = c.slope >= 0 ? '+' : '-';
            return (
                <div>
                    <strong>{numeral(data).format('0.0%')}</strong>
                    <br />
                    {cr}
                    {numeral(Math.abs(c.slope)).format('0.0')}%
                </div>
            );
        },
    },
    {
        label: 'Strength',
        key: 'strength',
        data: 'strength',
        Component: Cell,
        renderCell: ({ item, csvDownload }) => {
            if (csvDownload) return getStrength(item, averages);
            return getStrength(item, averages, false, images);
        },
    },
    {
        label: 'Actions',
        key: 'actions',
        data: 'actions',
        Component: Cell,
        renderCell: ({ item, csvDownload }) => {
            if (csvDownload) return null;
            const trackingLink = item.mid
                ? `entity=${encodeURIComponent(item.mid)}&search_by=id`
                : `entity=${encodeURIComponent(item.name)}`;
            return (
                <div>
                    <a
                        href={`/tools/google-discover-data/add/?${trackingLink}`}
                        className="entity-disable-icon"
                        title="Track Entity"
                        style={{
                            background: 'green',
                        }}
                    >
                        Track
                    </a>
                    <a
                        title="See all connected entities"
                        href={`/domains/${window.info.id}/connected-entities/?entity=${item.name}`}
                        className="entity-disable-icon"
                        style={{ marginLeft: '12px', background: 'green' }}
                    >
                        Clusters
                    </a>
                </div>
            );
        },
    },
];

export const getStrength = (item, averages, isReturnNumber = true, images = {}) => {
    const { avgPages, avgImpressions, avgRelevancy, avgIpr, avgCTR } = averages;

    if (
        item.pages > avgPages &&
        item.salience > 0.25 &&
        item.impressions > avgImpressions &&
        item.ctr > avgCTR
    ) {
        return isReturnNumber ? (
            3
        ) : (
            <div className="categories-strength" title="Strong">
                <img src={images.strong} alt="strong" loading="lazy" />
            </div>
        );
    } else if (
        item.pages < avgPages &&
        (item.salience > 0.25 || item.impressions < avgImpressions || item.ctr > avgCTR)
    ) {
        return isReturnNumber ? (
            2
        ) : (
            <div className="categories-strength" title="Striking Distance">
                <img src={images.striking_distance} alt="image_striking_distance" loading="lazy" />
            </div>
        );
    } else if (
        item.pages < avgPages &&
        item.salience < 0.25 &&
        item.impressions < avgImpressions &&
        item.ctr > avgCTR
    ) {
        return isReturnNumber ? (
            1
        ) : (
            <div className="categories-strength" title="Weak">
                <img src={images.weak} alt="image_weak" loading="lazy" />
            </div>
        );
    }
    return isReturnNumber ? 0 : null;
};
