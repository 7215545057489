import { Cell } from '@table-library/react-table-library/table';
import { CellTree } from '@table-library/react-table-library/tree';
import numeral from 'numeral';

export const COLUMNS = (averages, images) => [
    {
        label: 'Category',
        key: 'category',
        data: 'name',
        className: 'underline',
        Component: CellTree,
        renderCell: ({ data, item, csvDownload }) => {
            if (csvDownload) return item?.level || data;
            const path = item.level?.slice(0, -1);
            return path ? (
                <a
                    href={
                        `/domains/${window.info.id}/categories/category?categories:in=` +
                        window.encodeURIComponent(path)
                    }
                    target="_blank"
                    rel="noreferrer"
                >
                    {data}
                </a>
            ) : (
                data
            );
        },
    },
    {
        label: 'Pages',
        key: 'unique_pages',
        data: 'unique_pages',
        Component: Cell,
        renderCell: ({ data, item }) => {
            if (item.id === 'all_categories') return null;
            return data;
        },
    },
    {
        label: 'Total Impressions',
        key: 'sum_impressions',
        data: 'sum_impressions',
        Component: Cell,
        renderCell: ({ data, csvDownload, item }) => {
            if (item.id === 'all_categories') return null;
            if (csvDownload) return data;
            return numeral(data).format('0.0a');
        },
    },
    {
        key: 'median_impressions_per_page',
        label: 'Median Impressions / Article ',
        data: 'median_impressions_per_page',
        Component: Cell,
        renderCell: ({ data, item, csvDownload }) => {
            if (item.id === 'all_categories') return null;
            if (csvDownload) return data;
            return numeral(data).format('0.0a');
        },
    },
    {
        label: 'Clicks',
        key: 'sum_clicks',
        data: 'sum_clicks',
        Component: Cell,
        renderCell: ({ data, csvDownload, item }) => {
            if (item.id === 'all_categories') return null;
            if (csvDownload) return data;
            return numeral(data).format('0.0a');
        },
    },
    {
        label: 'Ctr',
        key: 'avg_ctr',
        data: 'avg_ctr',
        Component: Cell,
        renderCell: ({ csvDownload, item }) => {
            const ctrValue = item.sum_clicks/item.sum_impressions;
            if (item.id === 'all_categories') return null;
            if (csvDownload) return ctrValue;
            return numeral(ctrValue).format('0,0%');
        },
    },
    {
        label: 'Strength',
        key: 'strength',
        data: 'strength',
        Component: Cell,
        renderCell: ({ item, csvDownload }) => {
            if (csvDownload) return getStrength(item, averages);
            return getStrength(item, averages, false, images);
        },
    },
];

export const getStrength = (item, averages, isReturnNumber = true, images = {}) => {
    const { avgPages, avgImpressions, avgClicks, avgCTR } = averages;
    if (item.id === 'all_categories' || !item.haveStrength) return isReturnNumber ? 0 : null;

    const pages = item.unique_pages;
    if (pages > avgPages && item.sum_impressions > avgImpressions && item.sum_clicks > avgClicks) {
        return isReturnNumber ? (
            3
        ) : (
            <div className="categories-strength" title="Strong">
                <img src={images.strong} alt="strong" loading="lazy" />
            </div>
        );
    } else if (
        (item.sum_impressions > avgImpressions && item.avg_ctr < avgCTR) ||
        (item.sum_impressions > avgImpressions && pages < avgPages)
    ) {
        return isReturnNumber ? (
            2
        ) : (
            <div className="categories-strength" title="Striking Distance">
                <img src={images.striking_distance} loading="lazy" alt="image_striking_distance" />
            </div>
        );
    } else if (
        pages > avgPages &&
        item.sum_impressions < avgImpressions &&
        item.sum_clicks < avgClicks &&
        item.avg_ctr < avgCTR
    ) {
        return isReturnNumber ? (
            1
        ) : (
            <div className="categories-strength" title="Weak">
                <img src={images.weak} alt="image_weak" loading="lazy" />
            </div>
        );
    }
    return isReturnNumber ? 0 : null;
};
