import { Component } from 'react';

class Tag extends Component {
    constructor(props) {
        super();
        this.onClick = props.onClick ?? (() => {})
    }

    render() {
        return (
            <div onClick={this.onClick}>
                <div className={'default-tag ' + (this.props.classname || '')}>
                    {this.props.title}
                </div>
            </div>
        );
    }
}

export default Tag;
