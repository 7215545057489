import { Cell } from '@table-library/react-table-library/table';
import Actions from './actions';
import moment from 'moment';
import { escape } from '../../helpers/symbols';
import DeleteButton from './deleteBtn';

export const COLUMNS = (
    default_image,
    endpoint_url,
    csrf_token,
    onChangeLimits,
    user_is_superuser,
    onOpenModal,
) => [
    {
        label: 'Name',
        key: 'entity',
        data: 'entity',
        Component: Cell,
        style: (item) => {
            const color = !item.status ? '#909090' : 'black';
            return { color: color };
        },
        renderCell: ({ data, item, csvDownload }) => {
            const title = escape(data);
            if(csvDownload) return title;
            return (
                <div>
                    <div className="d-flex align-items-center tracking-pages-name">
                        <div style={{ float: 'left', marginRight: '10px' }}>
                            <img
                                onError={(event) => (event.target.src = default_image)}
                                src={item.image_url ?? default_image}
                                alt="page_image"
                                loading="lazy"
                            />
                        </div>
                        <div>
                            <a
                                href={`/tools/google-discover-data/entity/?entity_name=${encodeURIComponent(
                                    data,
                                )}&entity_ids=${encodeURIComponent(item.kg_id)}&language=${
                                    item.language
                                }&country=${item.country}&is_one=true`}
                            >
                                <strong style={{ color: !item.status ? '#909090' : 'black' }}>
                                    {title}
                                </strong>
                            </a>
                            {user_is_superuser && (
                                <div
                                    className="cursor-pointer user-info-btn"
                                    onClick={() => onOpenModal(item.user)}
                                >
                                    {item.user}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            );
        },
    },
    {
        label: 'Type',
        key: 'entity_type',
        data: 'entity_type',
        Component: Cell,
        style: (item) => {
            const color = !item.status ? '#909090' : 'black';
            return { color: color };
        },
        renderCell: ({ data, item, csvDownload }) => {
            if(csvDownload) return data;
            return (
                <div>
                    <div className="wripe">{data}</div>
                    <div className="tracking-description">{item.description}</div>
                </div>
            );
        },
    },
    {
        label: 'Country - Language',
        key: 'country-language',
        data: 'country-language',
        Component: Cell,
        style: (item) => {
            const color = !item.status ? '#909090' : 'black';
            return { color: color };
        },
        renderCell: ({ item }) => {
            return `${item.country} - ${item.language}`;
        },
    },
    {
        label: 'Created',
        key: 'created_at',
        data: 'created_at',
        Component: Cell,
        style: (item) => {
            const color = !item.status ? '#909090' : 'black';
            return { color: color };
        },
        renderCell: ({ data }) => {
            if (!data || data === 'None') return 'No data found';
            return moment(data).format('YYYY-MM-DD');
        },
    },
    {
        label: 'Crawled at',
        key: 'last_crawl_date_time',
        data: 'last_crawl_date_time',
        Component: Cell,
        style: (item) => {
            const color = !item.status ? '#909090' : 'black';
            return { color: color };
        },
        renderCell: ({ data }) => {
            if (!data || data === 'None') return 'No data found';
            return moment(data).format('YYYY-MM-DD');
        },
    },
    {
        label: 'Status',
        key: 'action',
        data: 'action',
        Component: Cell,
        style: (item) => {
            const color = !item.status ? '#909090' : 'black';
            return { color: color };
        },
        renderCell: ({ item, csvDownload }) => {
            if(csvDownload) return null;
            return (
                <Actions
                    itemId={item.id}
                    status={item.status}
                    endpoint_url={endpoint_url}
                    csrf_token={csrf_token}
                    onChangeLimits={onChangeLimits}
                />
            );
        },
    },
];


export const COLLECTED_COLUMNS = (csrf_token, deleteItem) => [
    {
        label: 'Name',
        key: 'name',
        data: 'name',
        Component: Cell,
        renderCell: ({data, item}) => {
            return <div>
                <strong>
                    <a
                        href={`/tools/google-discover-data/entity/?collection=${item.name}&entity_ids=${item?.entities?.map(item => encodeURIComponent(item.kg_id))}&language=${
                            item?.entities?.[0]?.language
                        }&country=${item?.entities?.[0]?.country}`}

                    >
                        {data}
                    </a>
                </strong>
            </div>
        }
    },
    {
        label: 'Entity Names',
        key: 'entity-name',
        data: 'entity-name',
        Component: Cell,
        renderCell: ({item}) => {
            return item?.entities?.map(x => x.entity).join(',')
        }
    },
    {
        label: 'Country - Language',
        key: 'country-language',
        data: 'country-language',
        Component: Cell,
        style: (item) => {
            const color = !item.status ? '#909090' : 'black';
            return { color: color };
        },
        renderCell: ({ item }) => {
            return `${item?.entities?.[0]?.country} - ${item?.entities?.[0]?.language}`;
        },
    },
    {
        label: 'Actions',
        key: 'actions',
        data: 'actions',
        Component: Cell,
        renderCell: ({ item }) => {
            return <DeleteButton csrf_token={csrf_token} deleteItem={deleteItem} itemId={item.id} />;
        },
    },
]