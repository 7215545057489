import React, { Component } from 'react';
import DataTable from '../../components/Datatable';
import { scrollForTable } from '../../components/Datatable/utils';
import { COLLECTED_COLUMNS, COLUMNS } from './keys';
import Loading from '../../components/Loading';
import { getCollectedEntities, getData } from './query';
import { isTrue } from '../../helpers/isTrue';
import Message from '../Message';
import UserInfoModal from '../../components/Modals/UserInfoModal';

class TrackGoogleDiscoverResults extends Component {
    constructor() {
        super();

        this.onChangeLimits = this.onChangeLimits.bind(this);
        this.checkPurchaseButton = this.checkPurchaseButton.bind(this);
        this.deleteMessage = this.deleteMessage.bind(this);
        this.onOpenModal = this.onOpenModal.bind(this);
        this.onCloseModal = this.onCloseModal.bind(this);
        this.onSelect = this.onSelect.bind(this);
        this.onRemoveAllSelected = this.onRemoveAllSelected.bind(this);
        this.checkSelect = this.checkSelect.bind(this);
        this.addActive = this.addActive.bind(this);
        this.onChangeTab = this.onChangeTab.bind(this);
        this.deleteItem = this.deleteItem.bind(this);

        this.urlParams = new URLSearchParams(window.location.search);

        this.tab = this.urlParams.get('tab');

        this.defaultTab = this.tab ?? 'entities';

        this.state = {
            data: {},
            entity: '',
            country: null,
            loading: false,
            selectedItem: null,
            isOpenModal: false,
            selectedUser: null,
            selectedItems: [],
            types: [this.defaultTab],
        };
    }

    async componentDidMount() {
        try {
            this.setState({ loading: true });
            const response = await getData(this.props.current_url);
            const data = await response.json();

            const collectedEntities = await getCollectedEntities(this.props.csrf_token);
            const dataEntities = await collectedEntities.json();
            this.setState({ loading: false, data, dataEntities });
        } catch (err) {
            console.log(err);
            this.setState({ loading: false });
        }
    }

    searchFunction(data, search) {
        return data.filter((item) => item.entity?.toLowerCase().includes(search.toLowerCase()));
    }

    deleteMessage() {
        this.setState({ message: '' });
    }

    onChangeLimits(activate) {
        this.setState({
            data: {
                ...this.state.data,
                remaining_count: activate
                    ? this.state.data.remaining_count - 1
                    : this.state.data.remaining_count + 1,
                active_count: activate
                    ? this.state.data.active_count + 1
                    : this.state.data.active_count - 1,
            },
        });
    }

    checkPurchaseButton() {
        if (isTrue(this.props.user_is_viewer)) {
            this.setState({
                message: `Please contact the main account user ${this.props.account_owner_email} to purchase the additional entities using there account.`,
            });
        }
    }

    onOpenModal(user) {
        this.setState({
            isOpenModal: true,
            selectedUser: user,
        });
    }

    onCloseModal() {
        this.setState({ isOpenModal: false });
    }

    onSelect(action, state) {
        if (action.type === 'REMOVE_ALL') return this.setState({ selectedItems: [] });
        this.setState({ selectedItems: state.ids, removeAllSelected: false });
    }

    onRemoveAllSelected(bool) {
        this.setState({
            removeAllSelected: bool,
            selectedItems: [],
        });
    }

    checkSelect(selectedItems, isDisabled) {
        if (selectedItems.length < 1) return;

        if (selectedItems.length > 5)
            return this.setState({
                message: 'You can select no more than 5 items.',
            });

        if (isDisabled)
            this.setState({
                message: 'You need to choose items with the same language and country',
            });
    }

    onChangeTab(id) {
        this.url = new URL(window.location);
        this.url.searchParams.set('tab', id);
        history.pushState({}, '', this.url);

        this.setState({
            types: [id],
        });
    }

    addActive(id) {
        return this.state.types.includes(id);
    }

    deleteItem(id) {
        this.setState({
            dataEntities: {
                ...this.state.dataEntities,
                results: this.state.dataEntities?.results?.filter((item) => item.id !== id),
            },
        });
    }

    render() {
        const sortFns = {
            entity: (array) => array.sort((a, b) => a.entity.localeCompare(b.entity)),
            entity_type: (array) =>
                array.sort((a, b) => a.entity_type.localeCompare(b.entity_type)),
            description: (array) =>
                array.sort((a, b) => a.description.localeCompare(b.description)),
            created_at: (array) =>
                array.sort((a, b) => new Date(a.created_at) - new Date(b.created_at)),
            last_crawl_date_time: (array) =>
                array.sort(
                    (a, b) => new Date(a.last_crawl_date_time) - new Date(b.last_crawl_date_time),
                ),
            'country-language': (array) =>
                array.sort((a, b) =>
                    `${a.country} - ${a.language}`.localeCompare(`${b.country} - ${b.language}`),
                ),
        };

        const selectedItems = this.state.data?.results?.filter((item) =>
            this.state.selectedItems.includes(item.id),
        );
        const countryAndLanguage = selectedItems?.reduce(
            (acc, curr) => {
                if (!acc.country.includes(curr.country)) acc.country.push(curr.country);
                if (!acc.language.includes(curr.language)) acc.language.push(curr.language);
                return acc;
            },
            { country: [], language: [] },
        );

        const isDisabled =
            !selectedItems ||
            selectedItems?.length < 1 ||
            countryAndLanguage?.country?.length > 1 ||
            countryAndLanguage?.language?.length > 1 ||
            selectedItems?.length > 5;
        return (
            <>
                <div className="card table-main rounded-main ">
                    <div className="card-header d-flex justify-content-between">
                        <div>Discover Rankings</div>
                        <div className="discover-rankings-btns">
                            {!isTrue(this.props.user_is_superuser) && (
                                <div onClick={this.checkPurchaseButton}>
                                    <a
                                        href="/tools/purchase-more-entity/?part=user_entity"
                                        className={
                                            'button button-filled button-primary width-sm btn d-flex align-items-center justify-content-center'
                                        }
                                        style={
                                            isTrue(this.props.user_is_viewer)
                                                ? { pointerEvents: 'none' }
                                                : {}
                                        }
                                    >
                                        Purchase More Entities Tracking
                                    </a>
                                </div>
                            )}
                            <a
                                href="/tools/google-discover-data/add/"
                                className={
                                    'button button-filled button-primary width-sm btn d-flex align-items-center justify-content-center' +
                                    (!this.state.data?.remaining_count &&
                                    !isTrue(this.props.user_is_superuser)
                                        ? ' disabled-item'
                                        : '')
                                }
                                style={
                                    !this.state.data?.remaining_count &&
                                    !isTrue(this.props.user_is_superuser)
                                        ? { pointerEvents: 'none' }
                                        : {}
                                }
                            >
                                Track New Entity
                            </a>
                        </div>
                    </div>{' '}
                    <div className="card-header d-flex justify-content-between">
                        <div className="tags-container">
                            <span
                                className={`tab-item ${
                                    this.addActive('entities') ? 'active-tag' : 'not-active'
                                }`}
                                onClick={() => this.onChangeTab('entities')}
                            >
                                Entities
                            </span>
                            <span
                                className={`tab-item  ${
                                    this.addActive('collected-entities')
                                        ? 'active-tag'
                                        : 'not-active'
                                }`}
                                onClick={() => this.onChangeTab('collected-entities')}
                            >
                                Collected Entities
                            </span>
                        </div>
                        <div
                            onClick={() => this.checkSelect(selectedItems, isDisabled)}
                            title="Select more than two entities below with the same country and language."
                        >
                            <a
                                href={`/tools/google-discover-data/entity/?entity_ids=${selectedItems?.map(
                                    (item) => encodeURIComponent(item.kg_id),
                                )}&language=${selectedItems?.[0]?.language}&country=${
                                    selectedItems?.[0]?.country
                                }`}
                                className={
                                    'button button-filled button-primary width-sm btn d-flex align-items-center justify-content-center combine-report-btn' +
                                    (isDisabled ? ' disabled-item' : '')
                                }
                                style={isDisabled ? { pointerEvents: 'none' } : {}}
                            >
                                Create Combined Report
                            </a>
                        </div>
                    </div>
                    <div className="card-body">
                        {!isTrue(this.props.user_is_superuser) && (
                            <div
                                className="discover-rankings-information"
                                style={{ backgroundImage: `url(${this.props.background_img})` }}
                            >
                                <div>
                                    <div className="discover-border">
                                        <div className="discover-box d-flex">
                                            <div className="discover-rankings-information-header">
                                                TOTAL ENTITIES
                                            </div>{' '}
                                            <span className="bold">
                                                {this.state.data?.total_count ?? 0}
                                            </span>
                                        </div>
                                        {this.state.data?.total_count !==
                                            this.state.data?.plan_entities && (
                                            <div className="additional-charges">
                                                Entities included in Plan:{' '}
                                                <span className="bold">
                                                    {this.state.data?.plan_entities}
                                                </span>
                                                <span className="ml-10">
                                                    Additional Purchased Entities:{' '}
                                                    <span className="bold">
                                                        {this.state.data?.purchased_entities}
                                                    </span>
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                    <div className="discover-box discover-border">
                                        <div className="discover-rankings-information-header">
                                            ACTIVE TRACKED ENTITIES
                                        </div>{' '}
                                        <span className="bold">
                                            {this.state.data?.active_count ?? 0}
                                        </span>
                                    </div>
                                    <div className="discover-box">
                                        <div className="discover-rankings-information-header">
                                            {' '}
                                            AVAILABLE ENTITIES TO TRACK
                                        </div>{' '}
                                        <span className="bold">
                                            {this.state.data?.remaining_count ?? 0}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        )}
                        {this.state.types.includes('collected-entities') &&
                            (this.state.dataEntities?.results?.length > 0 ? (
                                <DataTable
                                    nodes={this.state.dataEntities?.results}
                                    sortFns={sortFns}
                                    searchFunction={(data) => data}
                                    COLUMNS={COLLECTED_COLUMNS(
                                        this.props.csrf_token,
                                        this.deleteItem,
                                    )}
                                    additionalStyles={{
                                        Table: `
                                --data-table-library_grid-template-columns : minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(80px, 0.8fr) !important;
                                ${scrollForTable}
                            `,
                                        BaseCell: `  font-family: 'Raleway';
                                        font-weight: 500;
                                        font-size: 16px;
                                        line-height: 19px;
                                        padding: 16px 5px !important;
                                        color: #0D182C;
                                        border-bottom: 1px solid rgba(13, 24, 44, 0.1);
                                    
                                        &.underline {
                                            text-decoration: underline;
                                        }
                                        `,
                                    }}
                                />
                            ) : (
                                'There is no data'
                            ))}
                        {this.state.loading && <Loading padding={10} />}
                        {!this.state.loading &&
                            this.state.data?.results?.length > 0 &&
                            this.state.types.includes('entities') && (
                                <DataTable
                                    nodes={this.state.data?.results}
                                    sortFns={sortFns}
                                    searchFunction={this.searchFunction}
                                    COLUMNS={COLUMNS(
                                        this.props.default_image,
                                        this.props.current_url,
                                        this.props.csrf_token,
                                        this.onChangeLimits,
                                        this.props.user_is_superuser,
                                        this.onOpenModal,
                                    )}
                                    onSelect={this.onSelect}
                                    removeAllSelected={this.state.removeAllSelected}
                                    hasSelect={true}
                                    additionalStyles={{
                                        Table: `
                                --data-table-library_grid-template-columns : minmax(60px, 0.5fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(80px, 1fr) minmax(120px, 1fr) minmax(90px, 0.5fr) !important;
                                ${scrollForTable}
                            `,
                                        BaseCell: `  font-family: 'Raleway';
                                        font-weight: 500;
                                        font-size: 16px;
                                        line-height: 19px;
                                        padding: 16px 5px !important;
                                        color: #0D182C;
                                        border-bottom: 1px solid rgba(13, 24, 44, 0.1);
                                    
                                        &.underline {
                                            text-decoration: underline;
                                        }
                                        `,
                                    }}
                                />
                            )}
                        {!this.state.loading && this.state.data?.results?.length === 0 && (
                            <div className="py-3 d-flex justify-content-center">
                                <a
                                    href="/tools/google-discover-data/add/"
                                    className={
                                        'button button-filled button-primary width-sm btn d-flex align-items-center justify-content-center' +
                                        (!this.state.data?.remaining_count &&
                                        !isTrue(this.props.user_is_superuser)
                                            ? ' disabled-item'
                                            : '')
                                    }
                                    style={
                                        !this.state.data?.remaining_count &&
                                        !isTrue(this.props.user_is_superuser)
                                            ? { pointerEvents: 'none' }
                                            : {}
                                    }
                                >
                                    Track New Entity
                                </a>
                            </div>
                        )}
                    </div>{' '}
                </div>
                {this.state.message && (
                    <div className="messages">
                        <Message
                            message_tags={'info'}
                            message={this.state.message}
                            deleteMessage={this.deleteMessage}
                        />
                    </div>
                )}
                {this.state.isOpenModal && (
                    <UserInfoModal
                        isOpen={this.state.isOpenModal}
                        onClose={this.onCloseModal}
                        selectedUser={this.state.selectedUser}
                        hasImportKeywords={false}
                        current_url={this.props.current_url}
                        csrf_token={this.props.csrf_token}
                    />
                )}
            </>
        );
    }
}

export default TrackGoogleDiscoverResults;
