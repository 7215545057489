import numeral from 'numeral';
import colors, { labelColors } from '../../../lib/colors';

const keys = [
    {
        key: 'clicks',
        label: 'Avg. Clicks',
        color: colors[3],
        labelColor: labelColors[3],
        icon: 'clicks',
    },
    {
        key: 'impressions',
        label: 'Avg. Impressions',
        color: colors[0],
        labelColor: labelColors[0],
        icon: 'search',
    },
    {
        key: 'position',
        label: 'Avg. Position',
        color: colors[5],
        labelColor: labelColors[5],
        icon: 'pagev-view',
        yAxisID: 'y1',
    },
    {
        key: 'ctr',
        label: 'Avg. CTR',
        format: '0.00%',
        yAxisID: 'y1',
        render: (data, item) => {
            const ctr = item?.clicks / item?.impressions;
            return numeral(ctr * 100).format('0.00');
        },
        color: colors[1],
        labelColor: labelColors[1],
        icon: 'ctr',
    },
];

export const tooltips = {
    "ALL": '',
    "DISCOVER":'Discover results', 
    "GOOGLENEWS": `Results from news.google.com and the Google News app on Android and iOS. Doesn't include results from the "News" tab in Google Search.`,
    "IMAGE": 'Search results from the "Image" tab in Google Search.',
    "VIDEO": 'Video search results',
    "NEWS": 'Search results from the "News" tab in Google Search.',
    "WEB": ' results to the combined ("All") tab in Google Search. Does not include Discover or Google News results.'
}

export default keys;
