import React from 'react';

import LoadMoreComponent from '../../../components/LoadMoreComponent';
import { query, setBestTranslationQuery } from './query';
import CheckData from '../../../components/CatchError';
import DataTable from '../../../components/Datatable';
import Actions from '../../../components/GSCActions';
import Loading from '../../../components/Loading';
import { COLUMNS } from './keys';
import ManualClusterSelect from '../../../components/manualClusterModal';
import Search from '../../../components/Search';
import { scrollForTable } from '../../../components/Datatable/utils';

class GSCReportBest extends LoadMoreComponent {
    constructor(props) {
        super();

        this.best_translation = JSON.parse(props.best_translation);
        this.ignored_keywords = JSON.parse(props.ignored_keywords)?.map((x) => x.keyword);

        if (props.best_translation)
            this.query = query({
                offset: 0,
                best: this.best_translation,
                ignored_keywords: this.ignored_keywords,
                search: null,
            });

        this.offsetStep = 1000;

        this.getData = this.getData.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.deleteIgnoredItem = this.deleteIgnoredItem.bind(this);
        this.onSelect = this.onSelect.bind(this);
        this.onRemoveAllSelected = this.onRemoveAllSelected.bind(this);
        this.onSearch = this.onSearch.bind(this);
    }

    getData(best_translation_list, search) {
        this.query = query({
            best: best_translation_list,
            ignored_keywords: this.ignored_keywords,
            search: search ?? this.state.search,
        });
        this.query.bind(this.setState.bind(this));
        this.query.update();
        this.setState({ allData: [] });
    }

    searchFunction(data, search) {
        return data.filter((item) => item.keyword.toLowerCase().includes(search.toLowerCase()));
    }

    onSubmit(e, best_translation) {
        e.preventDefault();

        const params = {
            domain_url: window.filters.get().domain.value,
            best_translation: best_translation,
        };

        this.setState({ isLoadingBestTranslation: true });

        setBestTranslationQuery(this.props.endpoint_url, params, this.props.csrf_token)
            .then((res) => res.json())
            .then((data) => {
                const best_translation_list = data.best_translation_list;

                this.setState({ best_translation_list });
                this.getData(best_translation_list);
            })
            .catch((err) => console.log(err))
            .finally(() => {
                this.setState({ isLoadingBestTranslation: false });
            });
    }

    deleteIgnoredItem(keyword) {
        this.setState({
            allData: this.state.allData.filter((x) => x.keyword !== keyword),
        });
    }

    onSelect(action, state) {
        if (action.type === 'REMOVE_ALL') return this.setState({ selectedItems: [] });
        this.setState({ selectedItems: state.ids, removeAllSelected: false });
    }

    onRemoveAllSelected(bool) {
        this.setState({
            removeAllSelected: bool,
            selectedItems: [],
        });
    }

    onSearch(value) {
        this.setState({
            search: value,
        });
        this.getData(this.state.best_translation_list || this.best_translation, value);
    }

    render() {
        const attr = {
            query,
            offsetStep: this.offsetStep,
            best: this.state.best_translation_list || this.best_translation,
            ignored_keywords: this.ignored_keywords,
            search: this.state.search,
        };

        const sortFns = {
            keyword: (array) => array.sort((a, b) => a.keyword.localeCompare(b.keyword)),
            seen: (array) =>
                array.sort((a, b) => new Date(a.firstSeen?.value) - new Date(b.firstSeen?.value)),
            maxClicks: (array) => array.sort((a, b) => a.maxClicks - b.maxClicks),
            avgClicks: (array) => array.sort((a, b) => a.avgClicks - b.avgClicks),
            avgURLs: (array) => array.sort((a, b) => a.avgURLs - b.avgURLs),
            avgPosition: (array) => array.sort((a, b) => a.avgPosition - b.avgPosition),
            impressions: (array) => array.sort((a, b) => a.impressions - b.impressions),
            avgCTR: (array) => array.sort((a, b) => a.avgClicks/a.impressions - a.avgClicks/a.impressions),
        };

        const showLoadMoreBtn =
            this.state.data?.length === this.offsetStep && !this.state.isLoadingBestTranslation;

        const dontExistsWord =
            !this.best_translation?.length && !this.state.best_translation_list?.length;

        const customeSearch = (
            <Search
                onSubmit={this.onSearch}
                placeholder="Search for items"
                defaultValue={this.state.search}
            />
        );
        return (
            <>
                <div className="actions-btns">
                    <div>
                        <ManualClusterSelect
                            current_url={this.props.endpoint_url}
                            csrf_token={this.props.csrf_token}
                            selectedItems={this.state.selectedItems?.map((id) => ({
                                id,
                                keyword: this.state.allData.find((item) => item.id === id).keyword,
                            }))}
                            cluster_type="keyword"
                            header="Add To Keyword List"
                            onRemoveAllSelected={this.onRemoveAllSelected}
                        />
                    </div>
                    <a href="/domains/get-user-domains/">See Ignored Keywords</a>
                    <Actions
                        onChange={this.onSubmit}
                        header={'"Best" Translation'}
                        defaultValue={this.best_translation.join(',')}
                        loading={
                            dontExistsWord
                                ? false
                                : this.state.loading || this.state.isLoadingBestTranslation
                        }
                        inputStyles={{ width: '150px' }}
                        showOpened={dontExistsWord}
                    />
                </div>
                {!dontExistsWord && (
                    <>
                        <CheckData
                            content_id={this.props.content_id}
                            parent_id={this.props.parent_id}
                            data={this.state.allData}
                            loading={this.state.loading || this.state.isLoadingBestTranslation}
                            loadMore={this.state.loadMore}
                            customCheck
                            customCheckCondition={true}
                        >
                            <DataTable
                                nodes={this.state.allData}
                                sortFns={sortFns}
                                COLUMNS={COLUMNS({
                                    endpoint_url: this.props.endpoint_url,
                                    csrf_token: this.props.csrf_token,
                                    deleteIgnoredItem: this.deleteIgnoredItem,
                                })}
                                fileName="List Keywords"
                                header={'"List" Keywords'}
                                defaultSortKey="maxClicks"
                                searchFunction={this.searchFunction}
                                removeAllSelected={this.state.removeAllSelected}
                                hasSelect={true}
                                onSelect={this.onSelect}
                                customeSearch={customeSearch}
                                forceRun={this.forceRun}
                                additionalStyles={{
                                    Table: `
                                            --data-table-library_grid-template-columns :minmax(60px, 0.5fr) minmax(200px, 3fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) !important;
                                           ${scrollForTable}
                                        `,
                                    BaseCell: ` font-family: 'Raleway';
                                                font-weight: 500;
                                                font-size: 16px;
                                                line-height: 19px;
                                                padding: 16px 5px !important;
                                                color: #0D182C;
                                                border-bottom: 1px solid rgba(13, 24, 44, 0.1);
                                            
                                                &.underline {
                                                    text-decoration: underline;
                                                }
                                                `,
                                }}
                            />
                        </CheckData>
                        {this.state.loadMore ? (
                            <Loading padding={10} />
                        ) : (
                            showLoadMoreBtn && (
                                <div className="d-flex align-items-center justify-content-center pt-3 load-more-section">
                                    <button
                                        onClick={() => this.loadMore(attr)}
                                        disabled={this.state.loading}
                                        className="load-more-btn"
                                    >
                                        Load More
                                    </button>
                                </div>
                            )
                        )}
                    </>
                )}
            </>
        );
    }
}

export default GSCReportBest;
