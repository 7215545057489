import Component from '../../../components/Component';
import Loading from '../../../components/Loading';

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { getRandomColor, labelColors } from '../../../lib/colors';
import CheckData from '../../../components/CatchError';
import DataTable from '../../../components/Datatable';
import { COLUMNS, getPercentForChart, getSum, webNodesTypes } from './keys';
import { query } from './query';
import { Pie } from 'react-chartjs-2';
import numeral from 'numeral';
import { scrollForTable } from '../../../components/Datatable/utils';

ChartJS.register(ArcElement, Tooltip, Legend);

class GSCReportGoogleShare extends Component {
    constructor() {
        super();

        this.query = query();
        this.searchFunction = this.searchFunction.bind(this);
    }

    searchFunction(data) {
        return data;
    }

    getKewords(data) {
        return data.map((item) => `keyword like '%${item}%'`).join(' or ');
    }

    render() {
        if (this.state.loading) {
            return <Loading />;
        }

        const options = {
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    display: false,
                },
                title: {
                    display: true,
                    text: 'Google Surfaces',
                    padding: {
                        top: 10,
                        bottom: 10,
                    },
                },
                tooltip: {
                    callbacks: {
                        label: (context) => {
                            const percent = `${getPercentForChart(
                                context.dataset.data,
                                context.parsed,
                            ).toFixed(2)}%`;
                            return `${context.label} : ${numeral(context.parsed).format(
                                '0.0a',
                            )} Click${context.parsed > 1 ? 's' : ''} (${percent})`;
                        },
                    },
                },
            },
        };

        const chartData = {
            labels: this.state.data?.map((item) => item.searchtype),
            datasets: [
                {
                    label: 'Clicks',
                    data: this.state.data?.map((item) => item.clicks),
                    backgroundColor: this.state.data?.map(
                        (item, i) => labelColors[i] ?? getRandomColor(),
                    ),
                    borderRadius: 4,
                    tension: 0.4,
                },
            ],
        };

        const sortFns = {
            searchtype: (array) => array.sort((a, b) => a.searchtype.localeCompare(b.searchtype)),
            clicks: (array) => array.sort((a, b) => a.clicks - b.clicks),
            impressions: (array) => array.sort((a, b) => a.impressions - b.impressions),
            share: (array) =>
                array.sort((a, b) => {
                    const clickSum = getSum(this.state.data);
                    const dataA = a.clicks / clickSum;
                    const dataB = b.clicks / clickSum;
                    return dataA - dataB;
                }),
            ctr: (array) =>
                array.sort((a, b) => {
                    const dataA = a.clicks / a.impressions;
                    const dataB = b.clicks / b.impressions;
                    return dataA - dataB;
                }),
            position: (array) => array.sort((a, b) => a.position - b.position),
        };

        let data = []

        if(this.state.data){
            const nodes = this.state.data.reduce((acc, curr) => {
                if(webNodesTypes.includes(curr?.searchtype?.toUpperCase()))
                    acc.push({...curr, id: curr.searchtype})
                
                return acc
            }, []) 
            const webSummary = nodes.reduce((acc, curr) => {
                if(!acc){
                    acc = {
                        clicks: 0,
                        impressions: 0,
                        position: 0,
                    }
                }
                acc = {
                    clicks: acc.clicks + curr.clicks,
                    impressions: acc.impressions + curr.impressions,
                    position: acc.position + curr.position,
                }
                return acc;
            }, null)
            const web = this.state.data.find(item => item.searchtype === 'WEB')
            const webOther = {
                searchtype: 'WEB OTHER', 
                device: '', 
                clicks: web.clicks - webSummary.clicks,
                impressions: web.impressions - webSummary.impressions,
                position: null, 
            }
            nodes.push(webOther)

            data = this.state.data.reduce((acc, curr) => {
                if(curr.searchtype === 'WEB')
                    curr.nodes = nodes
                
                if(!webNodesTypes.includes(curr?.searchtype?.toUpperCase()))
                    acc.push({...curr, id: curr.searchtype})
                
                return acc
            }, []) 
            
        }

        return (
            <>
                <CheckData
                    content_id={this.props.content_id}
                    parent_id={this.props.parent_id}
                    data={this.state.data}
                    loading={this.state.loading}
                >
                    <div
                        className="Timeline"
                        style={{ height: '100%', minHeight: '150px', position: 'relative' }}
                    >
                        <h3 className="font-weight-bold">Google Surfaces Share</h3>
                        <small className="d-block card-sub-title text-muted fs-7  py-2">
                            This is the Clicks Share for all Google Surfaces reported in GSC. Please
                            note that News is the Search News tab, which Google News is
                            news.google.com. Also, both Image and Video are the search tabs.
                        </small>
                        <div className="row">
                            <div
                                className="col-4"
                                style={{ height: '400px', position: 'relative' }}
                            >
                                <Pie data={chartData} options={options} />
                            </div>
                            <div className="col-8">
                                <div className="row">
                                    <div className="col">
                                        <DataTable
                                            nodes={data}
                                            sortFns={sortFns}
                                            COLUMNS={COLUMNS}
                                            openedItemIds={['WEB']}
                                            fileName="Brandes vs Non-branded"
                                            searchFunction={this.searchFunction}
                                            forceRun={this.forceRun}
                                            hasTree
                                            onlyTable
                                            uniqKey={'searchtype'}
                                            additionalStyles={{
                                                Table: `
                                                --data-table-library_grid-template-columns :  minmax(160px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(160px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) !important;
                                               ${scrollForTable}
                                            `,
                                                BaseCell: `  font-family: 'Raleway';
                                                        font-weight: 500;
                                                        font-size: 16px;
                                                        line-height: 19px;
                                                        padding: 16px 5px !important;
                                                        color: #0D182C;
                                                        border-bottom: 1px solid rgba(13, 24, 44, 0.1);
                                                        &.underline {
                                                            text-decoration: underline;
                                                        }
                                                        `,
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </CheckData>
            </>
        );
    }
}

export default GSCReportGoogleShare;
