import { Component } from 'react';
import { deleteCollection } from './query';
import Loading from '../../components/Loading';

class DeleteButton extends Component {
    constructor() {
        super();

        this.onDelete = this.onDelete.bind(this);

        this.state = {
            loading: false,
        };
    }

    async onDelete() {
        try {
            this.setState({ loading: true });
            await deleteCollection(this.props.csrf_token, this.props.itemId) 
            this.props.deleteItem(this.props.itemId)
        } catch (err) {
            console.log(err);
        } finally {
            this.setState({
                loading: false,
            });
        }
    }

    render() {
        return (
            <div>
                {this.state.loading ? (
                    <Loading onlyPreloader padding={10} />
                ) : (
                    <button
                        className="ignor-icon-btn text-danger"
                        onClick={this.onDelete}
                        title="Delete Cluster "
                    >
                        <i className="icon-delete"></i>
                    </button>
                )}
            </div>
        );
    }
}

export default DeleteButton;
