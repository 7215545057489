import { Component } from 'react';

import { tableColumns } from './keys';
import CheckData from '../../../components/CatchError';
import DataTable from '../../../components/Datatable';
import { scrollForTable } from '../../../components/Datatable/utils';

class PositionModule2 extends Component {
    constructor() {
        super();
    }

    searchFunction(data, search) {
        return data.filter((item) =>
            item.searchappearance.toLowerCase().includes(search.toLowerCase()),
        );
    }

    render() {
        const sortFns = {
            searchappearance: (array) =>
                array.sort((a, b) => a.searchappearance.localeCompare(b.searchappearance)),
            avgPosition: (array) => array.sort((a, b) => a.avgPosition - b.avgPosition),
            avgCTR: (array) => array.sort((a, b) => a.clicks/a.impressions - b.clicks/b.impressions),
            clicks: (array) => array.sort((a, b) => a.clicks - b.clicks),
            impressions: (array) => array.sort((a, b) => a.impressions - b.impressions),
        };

        return (
            <>
                <CheckData
                    content_id={this.props.content_id}
                    parent_id={this.props.parent_id}
                    data={this.props.data}
                    loading={this.props.loading}
                    loadMore={this.props.loadMore}
                >
                    <DataTable
                        nodes={this.props.data}
                        sortFns={sortFns}
                        COLUMNS={tableColumns}
                        searchFunction={this.searchFunction}
                        fileName="Search Appearances"
                        defaultSortKey="clicks"
                        forceRun={this.props.forceRun}
                        uniqKey={'searchappearance'}
                        additionalStyles={{
                            Table: `
                                --data-table-library_grid-template-columns : minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) !important;
                               ${scrollForTable}
                            `,
                            BaseCell: `  font-family: 'Raleway';
                            font-weight: 500;
                            font-size: 16px;
                            line-height: 19px;
                            padding: 16px 5px;
                            color: #0D182C;
                            border-bottom: 1px solid rgba(13, 24, 44, 0.1);
                        
                            &.underline {
                              text-decoration: underline;
                            }`,
                        }}
                    />
                </CheckData>
            </>
        );
    }
}

export default PositionModule2;
