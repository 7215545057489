import React, { useEffect, useRef } from 'react';
import { Pagination, Exports, Search, TreeIcon, SortIcon } from './components';
import { customStyles } from './utils';
import {
    Table,
    Header,
    HeaderRow,
    Body,
    Row,
    HeaderCell,
} from '@table-library/react-table-library/table';

import { useTheme } from '@table-library/react-table-library/theme';

import { useSort, HeaderCellSort } from '@table-library/react-table-library/sort';

import { useTree } from '@table-library/react-table-library/tree';

import { usePagination } from '@table-library/react-table-library/pagination';
import { showData } from '../../helpers/isEmpty';
import {
    HeaderCellSelect,
    CellSelect,
    useRowSelect,
    SelectClickTypes,
    SelectTypes,
} from '@table-library/react-table-library/select';

export default function DataTable({
    nodes = [],
    searchFunction,
    onlyTable,
    sortFns,
    header,
    COLUMNS,
    customColumnsForExport,
    searchWithTree = false,
    hasSort = true,
    additionalStyles,
    uniqKey,
    fileName,
    openedItemIds = [],
    extraBtns = () => null,
    hasTree,
    defaultSize,
    defaultSortKey,
    onlyPagination = false,
    onSelect = () => null,
    hasSelect,
    removeAllSelected = false,
    customeSearch,
    forceRun,
    isSingleSelect,
    pdfColumnSize
}) {
    const [search, setSearch] = React.useState('');
    const [data, setData] = React.useState({ nodes: [] });
    useEffect(() => {
        if (searchWithTree) {
            const filtered = nodes
                .map((item) => {
                    let childrens = [];

                    if (item.nodes) {
                        childrens = item.nodes.filter((children) =>
                            children.name.toLowerCase().includes(search.toLowerCase()),
                        );
                    }

                    if (childrens.length) {
                        return { ...item, nodes: childrens };
                    }

                    return null;
                })
                .filter((x) => x);

            setData({ nodes: filtered });
        } else {
            const data = {
                nodes: searchFunction
                    ? searchFunction(nodes, search)
                    : nodes.filter((item) =>
                          item?.name?.toLowerCase().includes(search.toLowerCase()),
                      ),
            };
            setData(data);
        }

        pagination.fns.onSetPage(0);
    }, [search, nodes]);

    useEffect(() => {
        if (removeAllSelected) {
            select.fns.onRemoveAll();
        }
    }, [removeAllSelected]);

    const printRef = React.useRef();

    const pagination = usePagination(data, {
        state: {
            page: 0,
            size: defaultSize ?? 25,
        },
    });

    const sizes = defaultSize ? [defaultSize, 25, 50, 100] : [25, 50, 100];

    const select = useRowSelect(
        data,
        {
            onChange: onSelect,
        },
        {
            clickType: SelectClickTypes.ButtonClick,
            rowSelect: isSingleSelect ? SelectTypes.SingleSelect : SelectTypes.MultiSelect,
            buttonSelect: isSingleSelect ? SelectTypes.SingleSelect : SelectTypes.MultiSelect,
        },
    );

    const styles = additionalStyles ? { ...customStyles, ...additionalStyles } : customStyles;
    const theme = useTheme(styles);

    const sortState = {
        ids: ['1'],
    };

    if (defaultSortKey) {
        sortState.sortKey = defaultSortKey;
        sortState.reverse = true;
    }
    const sort = useSort(
        data,
        {
            state: sortState,
        },
        {
            sortIcon: {
                margin: '0px',
                iconDefault: <SortIcon />,
                iconUp: <SortIcon state="up" />,
                iconDown: <SortIcon state="down" />,
            },
            sortFns,
        },
    );

    const tree = useTree(
        data,
        {
            state: {
                ids: openedItemIds,
            },
        },
        {
            // clickType: TreeExpandClickTypes.ButtonClick,
            treeIcon: {
                margin: '12px',
                backgroundColor: 'red',
                noIconMargin: '36px',
                iconRight: <TreeIcon size="md" />,
                iconDown: <TreeIcon size="sm" active={true} type="down" />,
            },
            sortFns,
        },
    );

    return (
        <div className="card-block">
            <div className="dt-actions__bar mb-2">
                {header && <h3>{header}</h3>}
                {extraBtns(hasTree, tree)}
                {!onlyTable && (
                    <>
                        {customeSearch ?? (
                            <Search
                                setSearch={setSearch}
                                value={search}
                                placeholder="Search for items"
                            />
                        )}
                        <Exports
                            pdfColumnSize={pdfColumnSize}
                            data={data}
                            printRef={printRef}
                            COLUMNS={customColumnsForExport ?? COLUMNS}
                            fileName={fileName}
                        />
                    </>
                )}
                {forceRun && (
                    <div
                        className={`dt-exports d-flex justify-content-end ${
                            !header && onlyTable ? 'w-100' : ''
                        }`}
                        title="Refresh and clear cache"
                    >
                        <button
                            className="dt-exports__button with-refresh"
                            type="button"
                            onClick={forceRun}
                        >
                            <i className="icon-refresh"></i>
                        </button>
                    </div>
                )}
            </div>
            <div ref={printRef}>
                <Table
                    data={data}
                    theme={theme}
                    layout={{ fixedHeader: true, custom: true, horizontalScroll: true }}
                    sort={sort}
                    tree={tree}
                    pagination={(!onlyTable || onlyPagination) && pagination}
                    select={select}
                >
                    {(tableList) => (
                        <>
                            <Header>
                                <HeaderRow>
                                    {hasSelect && <HeaderCellSelect />}
                                    {hasSort
                                        ? COLUMNS.map((row) => (
                                            <HeaderCellSort
                                                sortKey={row.key}
                                                key={row.key}
                                                pinLeft={row.pinLeft ?? false}
                                                onClick={e => {
                                                    if(row.infoLink && e.target.id === 'infoIcon'){
                                                        window.open(row.infoLink)
                                                    }
                                                }}
                                            >
                                                {row.label}
                                                    {row.infoLink ? (
                                                                <i id="infoIcon"
                                                                title={row.info}
                                                                className="icon-info cursor-pointer"
                                                                style={{ marginLeft: '7px' }}
                                                            ></i>
                                                ) : row.info && (
                                                    <i
                                                        title={row.info}
                                                        className="icon-info cursor-pointer"
                                                        style={{ marginLeft: '7px' }}
                                                    ></i>
                                                )}
                                            </HeaderCellSort>
                                          ))
                                        : COLUMNS.map((row) => (
                                              <HeaderCell
                                                  key={row.key}
                                                  pinLeft={row.pinLeft ?? false}
                                                  onClick={e => {
                                                    if(row.infoLink && e.target.id === 'infoIcon'){
                                                        window.open(row.infoLink)
                                                    }
                                                }}
                                              >
                                                {row.label}
                                                        {row.infoLink ? (
                                                                    <i id="infoIcon"
                                                                    title={row.info}
                                                                    className="icon-info cursor-pointer"
                                                                    style={{ marginLeft: '7px' }}
                                                                ></i>
                                                    ) : row.info && (
                                                        <i
                                                            title={row.info}
                                                            className="icon-info cursor-pointer"
                                                            style={{ marginLeft: '7px' }}
                                                        ></i>
                                                    )}
                                            </HeaderCell>
                                          ))}
                                </HeaderRow>
                            </Header>

                            <Body>
                                {tableList.map((item) => (
                                    <Row key={item[uniqKey ?? 'id']} item={item}>
                                        {hasSelect && <CellSelect item={item} />}
                                        {COLUMNS.map((column) => {
                                            return (
                                                <column.Component
                                                    className={column.className}
                                                    item={item}
                                                    key={`${column.key}-${item.id}`}
                                                    data-label={column.label}
                                                    style={column.style ? column.style(item) : {}}
                                                    pinLeft={column.pinLeft ?? false}
                                                >
                                                    {column.renderCell
                                                        ? column.renderCell({
                                                              data: item[column.data],
                                                              item,
                                                              allData: data?.nodes,
                                                          })
                                                        : showData(item[column.data])}
                                                </column.Component>
                                            );
                                        })}
                                    </Row>
                                ))}
                            </Body>
                        </>
                    )}
                </Table>
                {(!onlyTable || onlyPagination) && (
                    <Pagination pagination={pagination} sizes={sizes} nodes={nodes} data={data} />
                )}
            </div>
        </div>
    );
}
