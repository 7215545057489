export default [
    {
        key: (d) => {
            return (
                <div className="col-12 no-wrap ms-3">
                    <a
                        href={
                            `/domains/${window.info.id}/categories/category?categories:in=` +
                            window.encodeURIComponent(d.name)
                        }
                        title={d.name}
                    >
                        {d.name}
                    </a>
                </div>
            );
        },
        label: 'Category',
        type: 'label',
        classes: 'no-wrap col-10',
    },
    {
        key: 'relevancy',
        label: 'Relevancy',
        format: '0a%',
        classes: 'col-2 d-flex justify-content-end',
    },
    {
        key: (d, data) => {
            const max = getMax(data);
            const percent = d.relevancy / max;
            return percent;
        },
        label: 'Rank',
        bar: true,
        sort: true,
        hidden: true,
        percentReady: true,
    },
];

function getMax(data) {
    return data?.reduce((acc, curr) => {
        if (curr.relevancy > acc) acc = curr.relevancy;
        return acc;
    }, 0);
}
