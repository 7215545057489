import Component from '../../components/Component';
import List from '../../components/List';

import keys from './keys';
import query from './query';
import colors from '../../lib/colors';

class Summary extends Component {
    constructor(props) {
        super();
        this.query = query(props.page);
    }

    render() {
        return (
            <List
                loading={this.state.loading}
                noItems="No categories available for this content."
                data={this.state.data}
                label={this.props.label || 'Recommended Topics'}
                keys={keys}
                color={colors[1]}
                forceRun={this.forceRun}
                content_id={this.props.content_id}
                parent_id={this.props.parent_id}
                direct_parent_id={this.props.direct_parent_id}
                refreshCoustomStyles={{ fontSize: '16px', padding: '5px 7px' }}
            />
        );
    }
}

export default Summary;
