import { Component } from 'react';
import { setApiKey } from './query';
import Message from '../Message';

class CommerceApiSettings extends Component {
    constructor() {
        super();
        this.onChangeInput = this.onChangeInput.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.deleteMessage = this.deleteMessage.bind(this);

        this.state = {
            api_key: '',
            password: '',
            loading: false,
            message: '',
            message_tags: '',
        };
    }

    onChangeInput(e) {
        this.setState({
            [e.target.name]: e.target.value,
        });
    }

    async onSubmit() {
        try {
            this.setState({
                loading: true,
            });
            const body = {
                api_key: this.state.api_key,
                password: this.state.password,
            };

            const response = await setApiKey(body, this.props.csrf_token);
            const data = await response.json();
            if (response.status === 200 || response.status === 201) {
                this.setState({
                    message_tags: 'success',
                    message: data?.message ?? 'API key added successfully',
                    loading: false,
                    api_key: '',
                    password: '',
                });
            } else {
                const errorMessage = data.message;
                this.setState({
                    message_tags: 'error',
                    message: errorMessage,
                    loading: false,
                });
            }
        } catch (err) {
            this.setState({
                loading: false,
                message_tags: 'error',
                message: err?.message ?? 'Something went wrong',
            });
            console.log(err);
        }
    }

    deleteMessage() {
        this.setState({ message: '' });
    }

    render() {
        return (
            <>
                <div className="card rounded-main table-main">
                    <div className="card-header" style={{ borderRadius: '10px' }}>
                        <div className="card-title text-dark fw-bold">Commerce semrush api key</div>
                    </div>
                    <div className="card-body">
                        <div className="settings-commerce">
                            <label htmlFor="api_key">
                                <strong>Enter Key</strong>
                            </label>
                            <input
                                name="api_key"
                                type="text"
                                id="api_key"
                                onChange={this.onChangeInput}
                                value={this.state.api_key}
                            />
                            <label htmlFor="password">
                                <strong>Password</strong>
                            </label>
                            <input
                                name="password"
                                type="text"
                                id="password"
                                onChange={this.onChangeInput}
                                value={this.state.password}
                            />
                        </div>
                        <div className="d-flex justify-content-end">
                            <button
                                className="btn btn-sm text-white bg-purple d-inline-block text-capitalize table-button"
                                onClick={this.onSubmit}
                                disabled={
                                    !this.state.api_key ||
                                    !this.state.password ||
                                    this.state.loading
                                }
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </div>
                {this.state.message && (
                    <div className="messages">
                        <Message
                            message_tags={this.state.message_tags}
                            message={this.state.message}
                            deleteMessage={this.deleteMessage}
                        />
                    </div>
                )}
            </>
        );
    }
}

export default CommerceApiSettings;
