import Query from '../../components/Report';

export const query_get_top_domains = async ({entity_ids, country, language}) =>
    new Query(
        'discover-entity-tracking-top-5-domains',
        {
            entity_id : `entity_id in (${entity_ids.split(',').map(item => `'${item}'`)})`,
            country,
            language,
        },
        { get_tools_dates: true },
    );

export const query_get_values = async ({entity_ids, country, language, domains, base_domain}) =>
    new Query(
        'discover-entity-tracking-top-stories',
        {
            entity_id : `entity_id in (${entity_ids.split(',').map(item => `'${item}'`)})`,
            country,
            language,
            base_domain: base_domain ? '(\'' + base_domain + '\')' : `(${domains})`,
        },
        { get_tools_dates: true },
    );
