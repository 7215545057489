import React, { Component } from 'react';
import Select from 'react-select';

class SearchDropdown extends Component {
    constructor(props) {
        super();

        this.handleSelect = this.handleSelect.bind(this);
        this.handleChangeVisibility = this.handleChangeVisibility.bind(this);
        this.state = {
            value: {
                label:
                <span className='domain-name'>
                    {JSON.parse(props.domains).find((domain) => domain.name === props.domain)
                        ?.url ?? 'Select Domain'}
                </span>,
                value: JSON.parse(props.domains).find((domain) => domain.name === props.domain)?.id,
            },
            isHidden: false,
        };
    }

    componentDidMount() {
        this.handleChangeVisibility()
        document.addEventListener('changeDomainVisibilityEvent', this.handleChangeVisibility);
    }

    componentWillUnmount(){
        document.removeEventListener('changeDomainVisibilityEvent', this.handleChangeVisibility);
    }

    handleChangeVisibility(){
        const isHidden = JSON.parse(localStorage.getItem('hide-domain'));
        this.setState({ isHidden });
    
    }

    handleSelect(e) {
        const pathName = window.location.pathname.split('/');
        pathName.splice(2, 1, e.value);
        const newPath = pathName.join('/');
        window.location.pathname = newPath;
    }

    render() {
        const domains = JSON.parse(this.props.domains);
        const optionList = domains.map((domain) => ({ value: domain?.id, label: domain.url }));
        return (
            <div className="dropdown border primary no-padding">
                <i className="icofont-globe domain-dropdown-icon"></i>
                <Select
                    options={optionList}
                    defaultValue={this.state.value}
                    className='domains-select'
                    menuPortalTarget={document.querySelector('body')}
                    menuPosition={'fixed'}
                    styles={{
                        control: (baseStyles) => ({
                            ...baseStyles,
                            zIndex: 11,
                            minWidth: '295px',
                            boxShadow: 'none',
                        }),
                        menuPortal: (provided) => ({ ...provided, zIndex: 10000, WebkitTextSecurity: this.state.isHidden ? "disc" : 'none' }),
                        menu: (provided) => ({ ...provided, zIndex: 10000 }),
                    }}
                    theme={(theme) => ({
                        ...theme,
                        borderRadius: '0 4px 4px 0',
                        zIndex: 11,
                        colors: {
                            ...theme.colors,
                            primary: '#888adb',
                        },
                    })}
                    onChange={this.handleSelect}
                    isSearchable={true}
                    placeholder="Select domain"
                    onFocus={(e) => {
                        const parent = e.nativeEvent.target.parentNode.parentNode;
                        const selectedValue = parent.children[0];
                        selectedValue.style.display = 'none';
                    }}
                    onBlur={(e) => {
                        const parent = e.nativeEvent.target.parentNode.parentNode;
                        const selectedValue = parent.children[0];
                        selectedValue.style.display = 'inline-grid';
                    }}
                    blurInputOnSelect={true}
                />
            </div>
        );
    }
}

export default SearchDropdown;
