import Component from '../../components/Component';
import Timeline from '../../components/Timeline';
import Loading from '../../components/Loading';

import query from './query';
import keys from './keys';

class Summary extends Component {
    constructor(props) {
        super();
        this.url = new URL(window.location);
        this.entity = this.url.searchParams.get('entities:in');
        this.query = query({ entity:this.entity, domain: props.domain });
    }

    render() {
        if (this.state.loading) {
            return <Loading />;
        }

        if (this.props.from_gsc && (!this.state.data || !this.state.data.length))
            return <div>Not found in Google Discover</div>;

        return (
            <Timeline
                data={this.state.data}
                keys={keys}
                forceRun={this.forceRun}
                content_id={this.props.content_id}
                parent_id={this.props.parent_id}
                hasRightAxis
                rightAxisLabel="CTR (%)"
                hasLeftSecondAxis
                leftSecondAxisLabel="Clicks"
                leftAxisLabel="Impressions"
            />
        );
    }
}

export default Summary;
