import { Cell } from '@table-library/react-table-library/table';
import numeral from 'numeral';
import { collectCell, getPercent } from '../../../../helpers/showDelta';
import colors, { labelColors } from '../../../../lib/colors';

export const COLUMNS = [
    {
        label: 'Position',
        key: 'position',
        data: 'position',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return <strong>{numeral(data).format('0a')}</strong>;
        },
    },
    {
        label: 'avg. CTR',
        key: 'avgCTR',
        data: 'avgCTR',
        Component: Cell,
        renderCell: ({ item, csvDownload }) => {
            const ctrValue = item.clicks/item.impressions;
            if (csvDownload) return ctrValue;

            return <strong>{numeral(ctrValue).format('0.00%')}</strong>;
        },
    },
    {
        label: <span className="delta-sign">&#916;</span>,
        key: 'avgCTR_delta',
        data: 'avgCTR_delta',
        Component: Cell,
        renderCell: ({ item, csvDownload }) => {
            const ctrValue = item.clicks/item.impressions;
            if (csvDownload) return getPercent(ctrValue, item.avgCTRPrior);
            return collectCell(ctrValue * 100, item.avgCTRPrior * 100);
        },
    },
    {
        label: 'Impressions',
        key: 'impressions',
        data: 'impressions',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return <strong>{numeral(data).format('0.0a')}</strong>;
        },
    },
    {
        label: <span className="delta-sign">&#916;</span>,
        key: 'impressions_delta',
        data: 'impressions_delta',
        Component: Cell,
        renderCell: ({ item, csvDownload }) => {
            if (csvDownload) return getPercent(item.impressions, item.impressionsPrior);
            return collectCell(item.impressions, item.impressionsPrior);
        },
    },
    {
        label: 'Clicks',
        key: 'clicks',
        data: 'clicks',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return <strong>{numeral(data).format('0.0a')}</strong>;
        },
    },
    {
        label: <span className="delta-sign">&#916;</span>,
        key: 'clicks_delta',
        data: 'clicks_delta',
        Component: Cell,
        renderCell: ({ item, csvDownload }) => {
            if (csvDownload) return getPercent(item.clicks, item.clicksPrior);
            return collectCell(item.clicks, item.clicksPrior);
        },
    },
    {
        label: 'Keywords',
        key: 'keywords',
        data: 'keywords',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return <strong>{numeral(data).format('0.0a')}</strong>;
        },
    },
    {
        label: <span className="delta-sign">&#916;</span>,
        key: 'keywords_delta',
        data: 'keywords_delta',
        Component: Cell,
        renderCell: ({ item, csvDownload }) => {
            if (csvDownload) return getPercent(item.keywords, item.keywordsPrior);
            return collectCell(item.keywords, item.keywordsPrior);
        },
    },
];

export const CHART_KEYS = [
    {
        key: 'avgCTR',
        label: 'avg. CTR',
        format: '0.00%',
        color: colors[0],
        labelColor: labelColors[0],
        render: (data) => {
            return 100 * data;
        },
    },
    {
        key: 'avgCTRPrior',
        label: 'avg. CTRPrior',
        color: colors[1],
        labelColor: labelColors[1],
        borderDash: [10, 10],
        render: (data) => {
            return 100 * data;
        },
    },
];
