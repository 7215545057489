import { Component } from 'react';
import Loading from '../Loading';

class CheckData extends Component {
    constructor() {
        super();

        this.checkIsEmptyAll = this.checkIsEmptyAll.bind(this);
    }

    checkIsEmptyAll(list) {
        for (let element of list) {
            if (!element.classList.contains('hide-component')) return false;
        }
        return true;
    }

    render() {
        if (this.props.loading && !this.props.loadMore) {
            return <Loading padding={10} />;
        }

        return this.props.children;
    }
}

export default CheckData;
