import numeral from 'numeral';

export function getPercent(newData, priorData) {
    if (!priorData && newData) return Infinity;
    if(!newData && priorData) return -100;

    return (newData / priorData - 1) * 100;
}

function showWithArrow(newData, priorData) {
    if(newData && !priorData){
        return (
            <span className={'add-arrow up'}>
                <i className="icofont-dotted-up"></i>
                &#8734;
            </span>
        );
    }

    if (!newData && priorData)
        return (
            <div className={'delta-number add-arrow down'}>
                <strong className="difference-container">
                    {numeral(priorData).format('0.00a')}
                </strong>
                <br />
                <div className={'difference-percentage extra-options'}>
                    <span className={`change down`}>
                        <i className="icofont-dotted-down"></i>
                        100 %
                    </span>
                </div>
            </div>
        );

    const val = getPercent(newData, priorData);


    let dir = '' 
    let arrow = null
    
    if(val !== 0){   
        dir = val > 0 ? 'up' : 'down';
        arrow =
        dir == 'up' ? (
            <i className="icofont-dotted-up"></i>
            ) : (
                <i className="icofont-dotted-down"></i>
                );
    }else{
        dir = 'zero';
    }

    return (
        <div className={'delta-number add-arrow ' + dir}>
            <strong className="difference-container">
                {numeral(newData - (priorData || 0)).format('0.00a')}
            </strong>
            <br />
            <div className={'difference-percentage extra-options'}>
                <span className={`change ${dir}`}>
                    {arrow} {numeral(Math.abs(val)).format('0.0')}%
                </span>
            </div>
        </div>
    );
}

export function collectCell(newData, priorData) {
    const delta = showWithArrow(newData, priorData);

    return <div>{delta}</div>;
}

export function getDifference(newData, priorData) {
    if (!newData) return -Infinity;
    if (!priorData) return newData;
    return newData - priorData;
}

export function getPoorPercent(sum, number) {
    const percent = (100 * number) / sum;
    return percent;
}
