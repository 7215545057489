import React from 'react';
import Select from 'react-select';
import { isTrue } from '../../helpers/isTrue';
import { checkValues } from './validation';
import _ from 'lodash';
import ReactSelect from '../../components/ReactSelect';

class EditMainInfo extends React.Component {
    constructor(props) {
        super();

        this.onChangeCheckBox = this.onChangeCheckBox.bind(this);
        this.updateTaxIdTypes = this.updateTaxIdTypes.bind(this)
        this.updateCountries = this.updateCountries.bind(this)
        this.changeView = this.changeView.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this)
        this.onFocus = this.onFocus.bind(this)

        this.state = {
            isOpen: false,
            showEditMode: false,
            name: props.name ?? '',
            organization: props.organization ?? '',
            show_competitive_analysis: isTrue(props.show_competitive_analysis),
            geo_location : props.geo_location_value,
            tax_id: props.tax_id ?? '',
            tax_id_type: props.tax_id_type ?? '',
            company_address1: props.company_address1 ?? '',
            company_address2: props.company_address2 ?? '',
            billing_country: props.billing_country ?? '',
            billing_city: props.billing_city ?? '',
            billing_postal_code: props.billing_postal_code ?? '',
            errors: {}
        };
    }

    changeView(isEditMode) {
        this.setState({ showEditMode: isEditMode });
    }

    updateCountries(value) {
        this.setState({geo_location: value});
    }

    updateTaxIdTypes(value) {
        this.setState({tax_id_type: value});
    }

    onChange(event){
        const name = event.target.name 
        const value = event.target.value
        this.setState({[name] : value})
    }
    
    onChangeCheckBox(){
        this.setState({show_competitive_analysis : !this.state.show_competitive_analysis})

    }

    onSubmit(e){
        // if we have no errors, the form from html will work
        const errors = checkValues(this.state)
        
        if(!_.isEmpty(errors)){
            e.preventDefault()
            this.setState({errors})
        }
    }

    onFocus(e){
        const key = e.target.name;
        const errors = {...this.state.errors}
        delete errors[key]

        this.setState({errors})
    }

    render() {
        const geo_locations = JSON.parse(this.props.geo_locations)
        const tax_id_types = JSON.parse(this.props.tax_id_types)
        const optionList = geo_locations.map(item => ({value: item.geo, label: item.location}))
        
        // add none type in options
        optionList.unshift({value: '', label: 'None'})
        tax_id_types.unshift({value: '', label: 'None'})
        
        const defaultLocation = this.props.geo_location_value ? {value: this.props.geo_location_value, label: this.props.geo_location} : {value: '', label: 'None'}
        const defaultTaxIdType = tax_id_types.find(x => x.value === this.props.tax_id_type) ?? {value: '', label: 'None'}

        return (
            <>
                {/* form defined in html */}
                {this.state.showEditMode ? (
                    <>
                        <div className="edit-form inline" id="name-edit-inputs">
                            <div className="text-field mb-3" style={{ marginRight: '10px' }}>
                                <label htmlFor="name" className="form-label">
                                    Name
                                </label>
                                <input
                                    name="name"
                                    type="text"
                                    id="name"
                                    className="form-control"
                                    onChange={this.onChange}
                                    value={this.state.name}
                                />
                            </div>
                            <div className="text-field mb-3">
                                <label htmlFor="organization" className="form-label">
                                    Company name
                                </label>
                                <input
                                    name="organization"
                                    type="text"
                                    id="organization"
                                    className="form-control"
                                    onChange={this.onChange}
                                    value={this.state.organization}
                                />
                            </div>
                        </div>
                        <div className="edit-form inline" id="name-edit-inputs">
                            <div className="text-field mb-3 edit-location" style={{ marginRight: '10px' }}>
                                <label htmlFor="organization" className="form-label">
                                    Set Your location
                                </label>
                                <input type="hidden" name="geo_location" value={this.state.geo_location} />
                                <ReactSelect
                                    options={optionList}
                                    onChange={this.updateCountries}
                                    isSearchable={true}
                                    blurInputOnSelect={true}
                                    placeholder="Select Location"
                                    defaultValue={defaultLocation}
                                    classname='m-0'
                                    controlStyles={{height: '45px'}}
                                />
                            </div>
                            <div className="text-field mb-3">
                                <label htmlFor="show_competitive_analysis" className="form-label">
                                    Opt In Competitive Data Set
                                </label>
                                <div style={{ height: '45px', display: 'flex', alignItems: 'center' }}>
                                    <input
                                        name="show_competitive_analysis"
                                        type="checkbox"
                                        id="show_competitive_analysis"
                                        onChange={this.onChangeCheckBox}
                                        checked={this.state.show_competitive_analysis}
                                        style={{display: 'none'}}
                                    />
                                    <button
                                        className={`switch-button ${
                                            this.state.show_competitive_analysis ? 'active' : 'not-active'
                                        }`}
                                        style={{ border: 'none' }}
                                        onClick={this.onChangeCheckBox}
                                        type="button"
                                    >
                                        <span className="switch-circle"></span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        {!isTrue(this.props.is_superuser) && (
                            <>
                                <div className="edit-form inline" id="name-edit-inputs">
                                    <div className="text-field mb-3" style={{ marginRight: '10px' }}>
                                        <label htmlFor="vat-tax-id" className="form-label">
                                            Vat/Tax Id 
                                        </label>
                                        <input
                                            name="tax_id"
                                            type="text"
                                            id="vat-tax-id"
                                            className="form-control"
                                            onChange={this.onChange}
                                            value={this.state.tax_id}
                                            onFocus={this.onFocus}
                                        />
                                        {this.state.errors["tax_id"] && <div className="error-message">{this.state.errors["tax_id"]}</div>}
                                    </div>
                                    <div className="text-field mb-3">
                                        <label htmlFor="organization" className="form-label">
                                            Tax ID Type
                                        </label>
                                        <input type="hidden" name="tax_id_type" value={this.state.tax_id_type} />
                                        <ReactSelect
                                            options={tax_id_types}
                                            onChange={this.updateTaxIdTypes}
                                            isSearchable={true}
                                            blurInputOnSelect={true}
                                            defaultValue={defaultTaxIdType}
                                            classname='m-0'
                                            controlStyles={{height: '45px'}}
                                            onFocus={() => this.onFocus({ target: {name: 'tax_id_type'}})}
                                        />
                                        {this.state.errors["tax_id_type"] && <div className="error-message">{this.state.errors["tax_id_type"]}</div>}
                                    </div>
                                </div>
                                <div className="edit-form inline" id="name-edit-inputs">
                                    <div className="text-field mb-3" style={{ marginRight: '10px' }}>
                                        <label htmlFor="company_address1" className="form-label">
                                            Company address 1
                                        </label>
                                        <input
                                            name="company_address1"
                                            type="text"
                                            id="company_address1"
                                            className="form-control"
                                            onChange={this.onChange}
                                            value={this.state.company_address1}
                                            onFocus={this.onFocus}
                                        />
                                            {this.state.errors["company_address1"] && <div className="error-message">{this.state.errors["company_address1"]}</div>}
                                    </div>
                                    <div className="text-field mb-3">
                                        <label htmlFor="company_address2" className="form-label">
                                            Company Address 2
                                        </label>
                                        <input
                                            name="company_address2"
                                            type="text"
                                            id="company_address2"
                                            className="form-control"
                                            onChange={this.onChange}
                                            value={this.state.company_address2}
                                        />
                                    </div>
                                </div>
                                <div className="edit-form inline" id="name-edit-inputs">
                                    <div className="text-field mb-3" style={{ marginRight: '10px' }}>
                                        <label htmlFor="Billing Country" className="form-label">
                                            Billing Country
                                        </label>
                                        <input
                                            name="billing_country"
                                            type="text"
                                            id="billing_country"
                                            className="form-control"
                                            onChange={this.onChange}
                                            value={this.state.billing_country}
                                            onFocus={this.onFocus}
                                        />
                                            {this.state.errors["billing_country"] && <div className="error-message">{this.state.errors["billing_country"]}</div>}
                                    </div>
                                    <div className="text-field mb-3">
                                        <label htmlFor="billing_city" className="form-label">
                                            Billing City
                                        </label>
                                        <input
                                            name="billing_city"
                                            type="text"
                                            id="billing_city"
                                            className="form-control"
                                            onChange={this.onChange}
                                            value={this.state.billing_city}
                                            onFocus={this.onFocus}
                                        />
                                            {this.state.errors["billing_city"] && <div className="error-message">{this.state.errors["billing_city"]}</div>}
                                    </div>
                                </div>
                                <div className="edit-form inline" id="name-edit-inputs">
                                    <div className="text-field mb-3" style={{ marginRight: '10px' }}>
                                        <label htmlFor="billing_postal_code" className="form-label">
                                            Billing Postal Code
                                        </label>
                                        <input
                                            name="billing_postal_code"
                                            type="text"
                                            id="billing_postal_code"
                                            className="form-control"
                                            onChange={this.onChange}
                                            value={this.state.billing_postal_code}
                                            onFocus={this.onFocus}
                                        />
                                            {this.state.errors["billing_postal_code"] && <div className="error-message">{this.state.errors["billing_postal_code"]}</div>}
                                    </div>
                                    <div className="text-field mb-3" style={{ marginRright: '10px' }}>
                                    </div>
                                </div>
                            </>
                        )}
                    </>
                ) : (
                    <div className="user-details" id="name-user-details">
                        <div className="user-info row mb-2">
                            <strong className="col-md-4">Name</strong>
                            <span className="col">{this.props.name || '-'}</span>
                        </div>
                        <div className="user-info row">
                            <strong className="col-md-4">COMPANY NAME</strong>
                            <span className="col">{this.props.organization || '-'}</span>
                        </div>
                        <div className="user-info row">
                            <strong className="col-md-4">Opt In Competitive Data Set</strong>
                            <span className="col">
                                {isTrue(this.props.show_competitive_analysis) ? 
                                    <i className="icon-check-icon" style={{color: "green", fontSize: "22px"}}></i> 
                                :   
                                    <i className="icon-close" style={{ color: 'red', fontSize: '22px' }}></i> 
                                }
                            </span>
                        </div>
                        <div className="user-info row">
                            <strong className="col-md-4">Location</strong>
                            <span className="col">
                               {this.props.geo_location || '-'}
                            </span>
                        </div>
                        {!isTrue(this.props.is_superuser) && (
                            <>
                                <div className="user-info row mb-2">
                                    <strong className="col-md-4">Tax Id</strong>
                                    <span className="col">{this.props.tax_id || '-'}</span>
                                </div>
                                <div className="user-info row mb-2">
                                    <strong className="col-md-4">Tax Id Type</strong>
                                    <span className="col">{defaultTaxIdType?.label || '-'}</span>
                                </div>
                                <div className="user-info row mb-2">
                                    <strong className="col-md-4">Company Address 1</strong>
                                    <span className="col">{this.props.company_address1 || '-'}</span>
                                </div>
                                <div className="user-info row mb-2">
                                    <strong className="col-md-4">Company Address 2</strong>
                                    <span className="col">{this.props.company_address2 || '-'}</span>
                                </div>
                                <div className="user-info row mb-2">
                                    <strong className="col-md-4">Billing Country</strong>
                                    <span className="col">{this.props.billing_country || '-'}</span>
                                </div>
                                <div className="user-info row mb-2">
                                    <strong className="col-md-4">Billing City</strong>
                                    <span className="col">{this.props.billing_city || '-'}</span>
                                </div>
                                <div className="user-info row mb-2">
                                    <strong className="col-md-4">Billing Postal Code</strong>
                                    <span className="col">{this.props.billing_postal_code || '-'}</span>
                                </div>
                            </>
                        )}
                    </div>
                )}

                <div className="buttons-wrapper">
                    {this.state.showEditMode ? (
                        <span id="name-edit-btns">
                            <button
                                type="button"
                                className="button button-outline button-primary button-icon"
                                onClick={() => this.changeView(false)}
                            >
                                <i className="icon-close"></i>
                            </button>
                            <button
                                type="submit"
                                className="button button-filled button-primary button-icon"
                                onClick={this.onSubmit}
                            >
                                <i className="icon-check-icon"></i>
                            </button>
                        </span>
                    ) : (
                        <button
                            type="button"
                            className="button button-filled button-primary button-icon"
                            id="name-edit-btn"
                            onClick={() => this.changeView(true)}
                        >
                            <i className="icon-edit"></i>
                        </button>
                    )}
                </div>
            </>
        );
    }
}

export default EditMainInfo;
