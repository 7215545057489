import React from 'react';
import Loading from '../../components/Loading';
import LoadMoreComponent from '../../components/LoadMoreComponent';

import CheckData from '../../components/CatchError';
import DataTable from '../../components/Datatable';
import { linReg } from '../../lib/reduce';
import { COLUMNS, getStrength } from './keys';
import query from './query';
import ExtraBtns from './extraBtns';
import { scrollForTable } from '../../components/Datatable/utils';

class Summary extends LoadMoreComponent {
    constructor(props) {
        super();
        this.query = query({});

        this.offsetStep = 500;

        this.getAverages = this.getAverages.bind(this);
        this.handleTypeChange = this.handleTypeChange.bind(this);
        this.onToggle = this.onToggle.bind(this);
        this.onToggleWiki = this.onToggleWiki.bind(this);
        this.showData = this.showData.bind(this);

        this.images = {
            strong: props.image_strong,
            striking_distance: props.image_striking_distance,
            weak: props.image_weak,
            type: '',
            toggleWiki: false,
        };
    }

    handleTypeChange(value) {
        this.setState({
            type: value,
        });
        this.query = query({ type: value });
        this.setState({ allData: [] });
        this.query.bind(this.setState.bind(this));
        this.query.update();
    }

    getAverages() {
        const data = this.state.allData;
        const length = data?.length ?? 1;
        const sumAll = this.state.allData.reduce((acc, curr, i) => {
            if (i === 0) {
                acc = {
                    sumPages: curr.pages,
                    sumRelevancy: curr.salience,
                    sumImpressions: curr.impressions,
                    sumIpr: curr.ipr,
                    sumCTR: curr.ctr,
                };
            } else {
                acc = {
                    sumPages: acc.sumPages + curr.pages,
                    sumRelevancy: acc.sumRelevancy + curr.salience,
                    sumImpressions: acc.sumImpressions + curr.impressions,
                    sumIpr: acc.sumIpr + curr.ipr,
                    sumCTR: i < 100 ? acc.sumCTR + curr.ctr : acc.sumCTR,
                };
            }
            return acc;
        }, {});
        const averages = {
            avgPages: sumAll.sumPages / length,
            avgImpressions: sumAll.sumImpressions / length,
            avgRelevancy: sumAll.sumRelevancy / length,
            avgIpr: sumAll.sumIpr / length,
            avgCTR: length < 100 ? sumAll.sumCTR / length : sumAll.sumCTR / 100,
        };
        return averages;
    }

    onToggle(value) {
        if (value === 'all') {
            this.setState({
                sortedByToggle: false,
                sortedData: [],
            });
        } else if (value === 'striking-distance') {
            this.setState({
                sortedByToggle: true,
                sortedData: this.state.allData.filter((item) => item.pages >= 5 && item.pages <= 9),
            });
        } else {
            this.setState({
                sortedByToggle: true,
                sortedData: this.state.allData.filter((item) => item.pages > 10),
            });
        }
        const sortKeys = {
            'relative-authority': 'impressionsL',
            'content-production': 'pages',
            engagement: 'ctr',
            '"striking-distance"': 'pages',
        };
        const key = sortKeys[value];

        this.setState({
            toggle: value,
            sortKey: key,
        });
    }

    showData() {
        let data = [];
        if (this.state.sortedByToggle) {
            data = this.state.sortedData;
        } else {
            data = this.state.allData;
        }

        if (this.state.toggleWiki) data = data.filter((item) => item.wiki);

        return data;
    }

    onToggleWiki() {
        this.setState({
            toggleWiki: !this.state.toggleWiki,
        });
    }

    render() {
        // if we get less than offsetStep it means we don't have more data
        const showLoadMoreBtn = this.state.data?.length === this.offsetStep;

        const attr = {
            query,
            offsetStep: this.offsetStep,
            type: this.state.type,
        };

        const averages = this.getAverages();
        const sortFns = {
            attr_imp: (array) =>
                array.sort((a, b) => {
                    let a_imp = linReg(a.impressionsL);
                    let b_imp = linReg(b.impressionsL);
                    return a_imp.slope - b_imp.slope;
                }),
            name: (array) => array.sort((a, b) => a.name.localeCompare(b.name)),
            type: (array) => array.sort((a, b) => a.type.localeCompare(b.type)),
            pages: (array) => array.sort((a, b) => a.pages - b.pages),
            action: (array) => array.sort((a, b) => a.salience - b.salience),
            impressionsL: (array) => array.sort((a, b) => a.impressions - b.impressions),
            median_impressions_per_entity: (array) =>
                array.sort(
                    (a, b) => a.median_impressions_per_entity - b.median_impressions_per_entity,
                ),
            ctr: (array) => array.sort((a, b) => a.ctr - b.ctr),
            strength: (array) =>
                array.sort((a, b) => getStrength(a, averages) - getStrength(b, averages)),
        };

        const extraBtns = () => (
            <ExtraBtns
                handleTypeChange={this.handleTypeChange}
                selectedType={this.state.type}
                toggleWiki={this.state.toggleWiki}
                onToggleWiki={this.onToggleWiki}
            />
        );

        const data = this.showData();

        return (
            <>
                <div className="card table-main rounded-main overflow-hidden">
                    <div className="card-header">
                        <p className="card-title">Oppoprtunities</p>
                    </div>
                    <div className="card-body">
                        <div className="entity-toggle-container">
                            <div className="toggle-Invalid-items right-border">
                                <span>All</span>
                                <span
                                    className={`switch-button mr-10 ${
                                        !this.state.toggle || this.state.toggle === 'all'
                                            ? 'active'
                                            : 'not-active'
                                    }`}
                                    onClick={() => this.onToggle('all')}
                                >
                                    <span className="switch-circle" />
                                </span>
                            </div>
                            <div
                                className="toggle-Invalid-items right-border"
                                title="These are the top entities that have high total impression, continue writing about them"
                            >
                                <span>Top Entities by Median Impressions</span>
                                <span
                                    className={`switch-button mr-10 ${
                                        this.state.toggle === 'relative-authority'
                                            ? 'active'
                                            : 'not-active'
                                    }`}
                                    onClick={() => this.onToggle('relative-authority')}
                                    title="Displaying Top entities that have 10 pages or more ordered by 'Relative Authority'"
                                >
                                    <span className="switch-circle" />
                                </span>
                            </div>
                            <div
                                className="toggle-Invalid-items right-border"
                                title="These are the top entities that you write about, examine which ones work for you best"
                            >
                                <span>Top Entities by No. of Pages</span>
                                <span
                                    className={`switch-button mr-10 ${
                                        this.state.toggle === 'content-production'
                                            ? 'active'
                                            : 'not-active'
                                    }`}
                                    onClick={() => this.onToggle('content-production')}
                                    title="Displaying Top entities that have 10 pages or more ordered by 'No. of Pages'"
                                >
                                    <span className="switch-circle" />
                                </span>
                            </div>
                            <div
                                className="toggle-Invalid-items right-border"
                                title="These are the top entities that get the highest CTR"
                            >
                                <span>Top Entities by Engagement</span>
                                <span
                                    className={`switch-button mr-10 ${
                                        this.state.toggle === 'engagement' ? 'active' : 'not-active'
                                    }`}
                                    onClick={() => this.onToggle('engagement')}
                                    title="Displaying Top entities that have 10 pages or more ordered by 'CTR (Engagement)'"
                                >
                                    <span className="switch-circle" />
                                </span>
                            </div>
                            <div
                                className="toggle-Invalid-items"
                                title="These are the top entities that have high total impression but low number of articles, continue writing about them, test writing about them"
                            >
                                <span>Striking Distance</span>
                                <span
                                    className={`switch-button mr-10 ${
                                        this.state.toggle === 'striking-distance'
                                            ? 'active'
                                            : 'not-active'
                                    }`}
                                    onClick={() => this.onToggle('striking-distance')}
                                    title="Displaying Top entities that have 10 pages or more ordered by 'CTR (Engagement)'"
                                >
                                    <span className="switch-circle" />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card table-main rounded-main overflow-hidden">
                    <div className="card-header">
                        <p className="card-title">Entities</p>
                    </div>
                    <div className="card-body">
                        {this.state.loading && !this.state.loadMore ? (
                            <Loading padding={10} />
                        ) : (
                            <>
                                <CheckData
                                    content_id={this.props.content_id}
                                    parent_id={this.props.parent_id}
                                    data={this.state.allData}
                                    loading={this.state.loading}
                                    loadMore={this.state.loadMore}
                                    customCheck
                                    customCheckCondition={!!this.state.type}
                                >
                                    <>
                                        <DataTable
                                            nodes={data}
                                            sortFns={sortFns}
                                            COLUMNS={COLUMNS(averages, this.images)}
                                            fileName="Entities"
                                            extraBtns={extraBtns}
                                            defaultSortKey={this.state.sortKey ?? 'impressionsL'}
                                            forceRun={this.forceRun}
                                            additionalStyles={{
                                                Table: `
                                                --data-table-library_grid-template-columns : minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) !important;
                                                ${scrollForTable}
                                            `,
                                                BaseCell: `  font-family: 'Raleway';
                                                font-weight: 500;
                                                font-size: 16px;
                                                line-height: 19px;
                                                padding: 16px 5px;
                                                color: #0D182C;
                                                border-bottom: 1px solid rgba(13, 24, 44, 0.1);
                                            
                                                &.underline {
                                                text-decoration: underline;
                                                }`,
                                            }}
                                        />
                                    </>
                                </CheckData>
                                {this.state.loadMore ? (
                                    <Loading padding={10} />
                                ) : (
                                    showLoadMoreBtn && (
                                        <div className="d-flex align-items-center justify-content-center pt-3 load-more-section">
                                            <button
                                                onClick={() => this.loadMore(attr)}
                                                disabled={this.state.loading}
                                                className="load-more-btn"
                                            >
                                                Load More
                                            </button>
                                        </div>
                                    )
                                )}
                            </>
                        )}
                    </div>
                </div>
            </>
        );
    }
}

export default Summary;
