import React, { Component } from 'react';
import ReactSelect from '../../components/ReactSelect';
import Loading from '../../components/Loading';
import { changePlan } from './query';
import _ from 'lodash';

class BulkPlanChange extends Component {
    constructor(props) {
        super();

        this.plan_names = JSON.parse(props.plan_names);
        this.options = this.plan_names.map((plan) => ({
            label: `${plan.name} - ${plan.interval} - ${plan.amount}$`,
            value: plan.plan_id,
        }));

        this.handleSelect = this.handleSelect.bind(this);
        this.checkErrors = this.checkErrors.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onFocus = this.onFocus.bind(this);

        this.state = {
            loading:false,
            old_plan: null,
            new_plan: null,
            errors: {},
            message: ''
        };
    }

    handleSelect(key, value) {
        this.setState({ [key]: value });
    }

    checkErrors(params) {
        const errors = {};

        for (let key in params) {
            if (!params[key]) {
                errors[key] = 'This field id required.';
            }
        }

        return errors;
    }

    onSubmit() {
        const params = {
            old_plan: this.state.old_plan,
            new_plan: this.state.new_plan,
        };
        const errors = this.checkErrors(params);

        if (!_.isEmpty(errors)) return this.setState({ errors });
        
        this.setState({loading: true})

        changePlan(this.props.endpoint_url, params, this.props.csrf_token)
            .then(res => res.json())
            .then(data => {
                if(data.status === 200)
                    return window.location.reload()
                
                if(data.message)
                    this.setState({message: data.message})
                
            })
            .catch(err => console.log(err))
            .finally(() => this.setState({loading: false}))
    }

    onFocus(key){
        const errors = {...this.state.errors}
        delete errors[key]

        this.setState({errors})
    }

    render() {
        return (
            <>
                <div className="d-flex justify-content-center py-5">
                    <div>
                        <div className="d-flex">
                            <div>
                                <ReactSelect
                                    options={this.options}
                                    onChange={(value) => this.handleSelect('old_plan', value)}
                                    isSearchable={true}
                                    blurInputOnSelect={true}
                                    placeholder="Select old plan"
                                    label="Old Plan Type"
                                    onFocus={() => this.onFocus('old_plan')}
                                />
                                {this.state.errors.old_plan && (
                                    <div className="error-message mx-15">
                                        {this.state.errors.old_plan}
                                    </div>
                                )}
                            </div>
                            <div>
                                <ReactSelect
                                    options={this.options}
                                    onChange={(value) => this.handleSelect('new_plan', value)}
                                    isSearchable={true}
                                    blurInputOnSelect={true}
                                    placeholder="Select new plan"
                                    label="New Plan Type"
                                    onFocus={() => this.onFocus('new_plan')}
                                />
                                {this.state.errors.new_plan && (
                                    <div className="error-message mx-15">
                                        {this.state.errors.new_plan}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div style={{ margin: '15px' }} className="d-flex justify-content-end">
                            {
                                this.state.loading 
                                ? 
                                    <Loading padding={10} />
                                :
                                    <button
                                        className="button button-filled button-primary px-4"
                                        onClick={this.onSubmit}
                                    >
                                        Submit
                                    </button>
                            }
                        </div>
                        {this.state.message &&  
                            <div className="error-message mx-15 text-right">
                                {this.state.message}
                            </div>
                        }
                    </div>
                </div>
            </>
        );
    }
}

export default BulkPlanChange;
