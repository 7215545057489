import { loadStripe } from '@stripe/stripe-js';
import React from 'react';
import Select from 'react-select';

class CardDetails extends React.Component {
    constructor() {
        super();

        this.state = {
            card: {
                complete: false,
            },
        };
    }

    async componentDidUpdate(prevProps, prevState) {
        if (prevProps.addNewCard !== this.props.addNewCard && this.props.addNewCard) {
            this.stripe = await loadStripe(window._config.stripe_public_key);
            this.elements = this.stripe.elements();
            this.card = this.elements.create('card');
            this.card.mount('#card');
            this.props.updateCard(this.card, this.stripe);

            this.card.on('change', (v) => {
                this.props.onEmptyCardError();
                this.setState({
                    card: v,
                });
            });
        }
    }

    render() {
        const optionList = this.props.paymentMethods?.map((item) => ({
            value: item.token,
            label: (
                <span style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <span>
                        **** **** **** {item.last_4}{' '}
                        {this.props.default_payment_method === item.token && (
                            <span className="default-selected">default</span>
                        )}
                    </span>
                    {item.expired && 
                        (<div className='d-flex align-items-start'>
                            <span className="default-selected expired" title="Expired">expired</span>
                        </div>)
                    }
                </span>
            ),
        }));
        return (
            <div className="col-xxl-5">
                <div className="card-body change-plan-payment-methods">
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            fontWeight: '600',
                            margin: '17px 0',
                        }}
                    >
                        Choose Existing Payment Method
                    </div>
                    <Select
                        options={optionList}
                        defaultValue={optionList.find(
                            (x) => x.value === this.props.default_payment_method,
                        )}
                        styles={{
                            control: (baseStyles) => ({
                                ...baseStyles,
                                boxShadow: 'none',
                                padding: '1px 3px',
                                fontSize: '13px',
                                minWidth: '295px',
                                zIndex: 10,
                            }),
                        }}
                        theme={(theme) => ({
                            ...theme,
                            height: '45px',
                            borderRadius: '7px',
                            borderColor: '#d9d9d9',
                            transition: 'all 0.2s',
                            zIndex: 10,
                            colors: {
                                ...theme.colors,
                                primary: '#888adb',
                            },
                        })}
                        onChange={this.props.onSelectPaymentMethode}
                        placeholder="Select Card"
                        isSearchable={false}
                        isDisabled={this.props.addNewCard}
                    />
                    {this.props.errors['selected-card'] && (
                        <div className="error-message">
                            {this.props.errors['selected-card']}
                        </div>
                    )}
                </div>
                <div className="card-body">
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            fontWeight: '600',
                            margin: '17px 0',
                        }}
                    >
                        Or Add New Card
                    </div>
                    <span
                        className={`switch-button ${
                            this.props.addNewCard ? 'active' : 'not-active'
                        }`}
                        onClick={this.props.handlePaymentMethod}
                    >
                        <span className="switch-circle" />
                    </span>
                </div>
                {this.props.addNewCard && <>
                        <div className="card-header" style={{ borderRadius: '10px' }}>
                            <div className="card-title text-dark fw-bold">Fill card details</div>
                        </div>
                        <div className="card-body">
                            <div className="text-field mb-3">
                                <label htmlFor="first_name" className="form-label">
                                    Billing Information
                                </label>
                                <div id="card" />
                                {this.props.cardError && (
                                    <div className="error-message">{this.props.cardError}</div>
                                )}
                            </div>
                            <div className="text-field mb-3">
                                <label htmlFor="full_name" className="form-label">
                                    Card Holder Name
                                </label>
                                <input
                                    name="full_name"
                                    id="full_name"
                                    className="form-control"
                                    placeholder="John Snow"
                                    value={this.props.cardInfo.full_name}
                                    onChange={(e) =>
                                        this.props.updateCardInfo('full_name', e.target.value)
                                    }
                                    onFocus={this.props.onFocus}
                                />
                                {this.props.errors['full_name'] && (
                                    <div className="error-message">
                                        {this.props.errors['full_name']}
                                    </div>
                                )}
                            </div>
                        </div>
                    </>}
            </div>
        );
    }
}

export default CardDetails;
