import { Cell } from '@table-library/react-table-library/table';
export const COLUMNS = [
    {
        label: 'Category',
        key: 'name',
        data: 'name',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;
            return data;
        },
    },
];
