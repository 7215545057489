import Component from '../../../components/Component';
import DataTable from '../../../components/Datatable';
import { scrollForTable } from '../../../components/Datatable/utils';
import Loading from '../../../components/Loading';
import PagesModal from '../../../components/Modals/PagesModal';
import { COLUMNS } from './keys';
import query from './query';

class Pairs extends Component {
    constructor() {
        super();
        this.query = query();

        this.onOpenModal = this.onOpenModal.bind(this);
        this.onCloseModal = this.onCloseModal.bind(this);
    }

    searchFunction(data, search) {
        return data.filter(
            (item) =>
                item.entity1.toLowerCase().includes(search.toLowerCase()) ||
                item.entity2.toLowerCase().includes(search.toLowerCase()),
        );
    }

    onOpenModal(pages) {
        this.setState({
            isOpen: true,
            selectedPages: pages,
        });
    }

    onCloseModal() {
        this.setState({
            isOpen: false,
        });
    }

    render() {
        if (this.state.loading) return <Loading />;
        const sortFns = {
            entity1: (array) => array.sort((a, b) => a.entity1.localeCompare(b.entity1)),
            entity2: (array) => array.sort((a, b) => a.entity2.localeCompare(b.entity2)),
            avg_pair_relevancy: (array) => array.sort((a, b) => a.avg_pair_relevancy - b.avg_pair_relevancy),
            pair_count: (array) => array.sort((a, b) => a.pair_count - b.pair_count),
            pages: (array) => array.sort((a, b) => a.pages?.[0].localeCompare(b.pages?.[0])),
        };
        return (
            <>
                <DataTable
                    nodes={this.state.data ?? []}
                    sortFns={sortFns}
                    COLUMNS={COLUMNS(this.props.onSelect, this.onOpenModal)}
                    fileName="Entitiy Pairs"
                    searchFunction={this.searchFunction}
                    additionalStyles={{
                        Table: `
                --data-table-library_grid-template-columns :  minmax(150px, 1.5fr) minmax(150px, 1.5fr) minmax(120px, 1fr) minmax(150px, 1fr) minmax(150px, 1.5fr) !important;
                ${scrollForTable}
            `,
                        BaseCell: `  font-family: 'Raleway';
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 19px;
                        padding: 16px 5px !important;
                        color: #0D182C;
                        border-bottom: 1px solid rgba(13, 24, 44, 0.1);
                    
                        &.underline {
                            text-decoration: underline;
                        }
                        `,
                    }}
                />
                <PagesModal
                    pages={this.state.selectedPages}
                    isOpen={this.state.isOpen}
                    onClose={this.onCloseModal}
                />
            </>
        );
    }
}

export default Pairs;
