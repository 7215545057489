import React, { Component } from 'react';
import ReportExplainer from '../index' 

class ReportExplainerConcepts extends Component {
    constructor(props) {
        super();

        this.state = {
            showMore: false
        }
    }

    render() {
        const text = (
            <div className="report-explainer-custom">
                <div>
                    <span className="report-subtitle">What are concepts</span>{" "}In Google Discover, Concepts are identified as topics or ideas that are relevant to users' interests. 
                    While Google recognizes them as entities, they differ from traditional entities in that they may not have dedicated Wikipedia pages or be confirmed by a knowledge base like Wikidata.
                </div>
                {this.state.showMore ? <>
                    <div>
                        <span className="report-subtitle">Examples of Concepts:</span>{" "}"Eye" or "Flag": These are common words with broad meanings and multiple interpretations. Google might identify them as concepts relevant to various topics (e.g., "eye health," "national flags").
                    </div>
                    <div>
                        <span className="report-subtitle">Entities vs. Concepts:</span>The primary focus for optimization should always be on established Entities. These are well-defined topics with clear Wikipedia pages or entries in knowledge bases. Examples include:
                        <ul>
                            <li>People: "Taylor Swift," "Elon Musk"</li>
                            <li>Places: "New York City," "Eiffel Tower"</li>
                            <li>Organizations: "Apple Inc.," "World Health Organization"</li>
                            <li>Events: "Oscars," "Super Bowl"</li>
                        </ul>
                        Concepts, while interesting, are less concrete and can be more challenging to target directly. However, they can still provide valuable insights into broader user interests and potential content themes.
                    </div>
                    <div>
                        <span className="report-subtitle">How Concepts Can Help in Discover:</span>{" "}Content Inspiration: Analyzing the concepts associated with your brand or niche can spark ideas for new content angles or topics.
    Audience Understanding: Understanding the concepts that resonate with your audience can help you tailor your content to their interests.
                    </div>
                    <div>
                        <span className="report-subtitle">Key Takeaway:</span>{" "}Prioritize optimizing your content for established Entities, as they offer a more direct path to visibility in Google Discover. Concepts can serve as a supplementary tool for understanding your audience and exploring content opportunities.
                    </div>
                </> : 
                <div className='show-more-btn'>
                    <button onClick={() => this.setState({showMore: true})} >Show More</button>
                </div>
                }
            </div>
        )
        return(
            <ReportExplainer isNotText textcontent={text} descriptionHidden={false} title="What are concepts?"/>
        );
    }
};

export default ReportExplainerConcepts;