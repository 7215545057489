import React, { Component } from 'react';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { query, gsc_report_query } from './query';
import DatePicker from 'react-datepicker'

import "react-datepicker/dist/react-datepicker.css";

export default class CustomPicker extends Component {
    constructor(props) {
        super();
        this.query = props.gsc_report ? gsc_report_query : query;
        
        this.handleChange = this.handleChange.bind(this);
        this.onToggle = this.onToggle.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        
        const filters = window?.filters?.filters;
        const filterId = filters?.dates?.id;
        const defaultValues = {
            start:
                filterId === 'custom'
                    ? new Date(filters?.dates?.values?.start_date?.value)
                    : new Date(),
            end:
                filterId === 'custom'
                    ? new Date(filters?.dates?.values?.end_date?.value)
                    : new Date(),
        };

        this.state = {
            data: null,
            isOpen: false,
            values: [defaultValues.start, defaultValues.end],
            dates: {
                startDate: defaultValues.start,
                endDate: defaultValues.end,
            }
        };
    }

    async componentDidMount() {
        if (this.query) {
            this.query.bind(this.setState.bind(this));
            await this.query.update();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.dateFilterValue === 'custom' && this.props.dateFilterValue !== 'custom') {
            this.setState({ values: [null, null] });
        }
    }

    handleChange = (values) => {
        this.setState({ values });

        const choice = {
            label: 'custom',
            key: 'dates',
            id: 'custom',
            values: {
                start_date: { value: values[0] },
                end_date: { value: values[1] },
            },
        };

        this.props.onChange(choice);
    };

    handleSelect(date, key){
        this.setState({
            dates:{
                ...this.state.dates,
                [key]: date
            }
        })
        if(key === 'endDate'){
            const choice = {
                label: 'custom',
                key: 'dates',
                id: 'custom',
                values: {
                    start_date: { value: this.state.dates.startDate },
                    end_date: { value: this.state.dates.startDate },
                },
            };
            this.props.onChange(choice);
            document.body.click()
        }
    }

    onToggle(){
        this.setState({
            isOpen: !this.state.isOpen
        })
    }

    render() {
        let [maxdate, mindate] = [null, null];
        const dates = this.state.data?.[0];

        if (dates) {
            maxdate = dates.maxdate ? new Date(dates.maxdate.value) : null;
            mindate = dates.mindate ? new Date(dates.mindate.value) : null;
        }
        const filters = window?.filters?.filters;
        return (
            <div>
                <div
                    className={`dropdown-item custom-picker ${
                        filters?.dates?.id === 'custom' && 'activeFilter'
                    }` }
                    style={{ margin: '0px 10px 10px 0px' }}
                >
                    <button
                        className="list-group-item-action calendar-filter-icon"
                        id="dropdowncalendars"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        >
                            <i className="icon-calendar calendar-icon"></i>
                    </button>
                    <div
                        className="dropdown-menu"
                        aria-labelledby="dropdowncalendars"
                        id="calendar-dropdown"
                        onClick={e => {
                            e.stopPropagation()
                            e.preventDefault()
                        }}
                    >
                        <div className='d-flex'>
                            <div >
                                <div>From :</div>
                                <DatePicker
                                    selected={this.state.dates.startDate}
                                    onChange={(date) => this.handleSelect(date, 'startDate')}
                                    selectsStart
                                    startDate={this.state.dates.startDate}
                                    endDate={this.state.dates.endDate}
                                    maxDate={maxdate}
                                    minDate={mindate}                
                                    inline
                                    />
                            </div>
                            <div>
                                <div>To :</div>
                                <DatePicker
                                    selected={this.state.dates.endDate}
                                    onChange={(date) => this.handleSelect(date, 'endDate')}
                                    selectsEnd
                                    startDate={this.state.dates.startDate}
                                    endDate={this.state.dates.endDate}
                                    minDate={this.state.dates.startDate || mindate}
                                    maxDate={maxdate}
                                    inline
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
