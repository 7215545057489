import React from 'react';

import LoadMoreComponent from '../../../components/LoadMoreComponent';
import { query, setQuestionsTranslationQuery } from './query';
import CheckData from '../../../components/CatchError';
import DataTable from '../../../components/Datatable';
import Loading from '../../../components/Loading';
import { COLUMNS } from './keys';
import Actions from '../../../components/GSCActions';
import ManualClusterSelect from '../../../components/manualClusterModal';
import Search from '../../../components/Search';
import { scrollForTable } from '../../../components/Datatable/utils';

class GSCReportQuestions extends LoadMoreComponent {
    constructor(props) {
        super();
        this.questions_translation = JSON.parse(props.questions_translation);
        if (this.questions_translation?.length)
            this.query = query({
                offset: 0,
                questions_translation: this.questions_translation,
                search: null,
            });

        this.offsetStep = 1000;

        this.getData = this.getData.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onSelect = this.onSelect.bind(this);
        this.onRemoveAllSelected = this.onRemoveAllSelected.bind(this);
        this.onSearch = this.onSearch.bind(this);
    }

    getData(questions_translation, search) {
        this.query = query({ questions_translation, search: search ?? this.state.search });
        this.query.bind(this.setState.bind(this));
        this.setState({ allData: [] });
        this.query.update();
    }

    searchFunction(data, search) {
        return data.filter((item) => item.keyword.toLowerCase().includes(search.toLowerCase()));
    }

    onSubmit(e, questions_translation) {
        e.preventDefault();

        const params = {
            domain_url: window.filters.get().domain.value,
            questions: questions_translation,
        };

        this.setState({ isLoadingQuestionTranslation: true });

        setQuestionsTranslationQuery(this.props.endpoint_url, params, this.props.csrf_token)
            .then((res) => res.json())
            .then((data) => {
                const questions_list = data.questions_list;
                this.setState({ questions_list });
                this.getData(questions_list);
            })
            .catch((err) => console.log(err))
            .finally(() => {
                this.setState({ isLoadingQuestionTranslation: false });
            });
    }
    onRemoveAllSelected(bool) {
        this.setState({
            removeAllSelected: bool,
            selectedItems: [],
        });
    }

    onSelect(action, state) {
        if (action.type === 'REMOVE_ALL') return this.setState({ selectedItems: [] });
        this.setState({ selectedItems: state.ids, removeAllSelected: false });
    }

    onSearch(value) {
        this.setState({
            search: value,
        });
        this.getData(this.state.questions_list || this.questions_translation, value);
    }

    render() {
        const attr = {
            query,
            offsetStep: this.offsetStep,
            questions_translation: this.state.questions_list || this.questions_translation,
            search: this.state.seach,
        };

        const sortFns = {
            keyword: (array) => array.sort((a, b) => a.keyword.localeCompare(b.keyword)),
            seen: (array) =>
                array.sort((a, b) => new Date(a.firstSeen?.value) - new Date(b.firstSeen?.value)),
            maxClicks: (array) => array.sort((a, b) => a.maxClicks - b.maxClicks),
            avgClicks: (array) => array.sort((a, b) => a.avgClicks - b.avgClicks),
            avgURLs: (array) => array.sort((a, b) => a.avgURLs - b.avgURLs),
            avgPosition: (array) => array.sort((a, b) => a.avgPosition - b.avgPosition),
            impressions: (array) => array.sort((a, b) => a.impressions - b.impressions),
            avgCTR: (array) => array.sort((a, b) => a.avgClicks/a.Impressions - b.avgClicks/b.Impressions),
        };

        const showLoadMoreBtn =
            this.state.data?.length === this.offsetStep && !this.state.isLoadingQuestionTranslation;

        const dontExistsWord =
            !this.questions_translation?.length && !this.state.questions_list?.length;

        const customeSearch = (
            <Search
                onSubmit={this.onSearch}
                placeholder="Search for items"
                defaultValue={this.state.search}
            />
        );
        return (
            <div className="card table-main rounded-main overflow-hidden">
                <div className="col-12">
                    <div className="card-header header-with-options">
                        <div>
                            <p className="card-title">"Questions" Keywords</p>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <div>
                                <ManualClusterSelect
                                    current_url={this.props.endpoint_url}
                                    csrf_token={this.props.csrf_token}
                                    selectedItems={this.state.selectedItems?.map((id) => ({
                                        id,
                                        keyword: this.state.allData.find((item) => item.id === id)
                                            .keyword,
                                    }))}
                                    cluster_type="keyword"
                                    header="Add To Keyword List"
                                    onRemoveAllSelected={this.onRemoveAllSelected}
                                />
                            </div>
                            <Actions
                                onChange={this.onSubmit}
                                header={'"Questions" Translation'}
                                defaultValue={this.questions_translation.join(',')}
                                loading={
                                    dontExistsWord
                                        ? false
                                        : this.state.loading ||
                                          this.state.isLoadingQuestionTranslation
                                }
                                inputStyles={{ width: '235px' }}
                                showOpened={dontExistsWord}
                            />
                        </div>
                    </div>
                    <div className="card-body">
                        {!dontExistsWord && (
                            <>
                                <CheckData
                                    content_id={this.props.content_id}
                                    parent_id={this.props.parent_id}
                                    data={this.state.allData}
                                    loading={
                                        this.state.loading ||
                                        this.state.isLoadingQuestionTranslation
                                    }
                                    loadMore={this.state.loadMore}
                                    customCheck
                                    customCheckCondition={true}
                                >
                                    <DataTable
                                        nodes={this.state.allData}
                                        sortFns={sortFns}
                                        COLUMNS={COLUMNS}
                                        fileName="Questions Keywords"
                                        defaultSortKey="maxClicks"
                                        searchFunction={this.searchFunction}
                                        removeAllSelected={this.state.removeAllSelected}
                                        hasSelect={true}
                                        forceRun={this.forceRun}
                                        customeSearch={customeSearch}
                                        onSelect={this.onSelect}
                                        additionalStyles={{
                                            Table: `
                                                    --data-table-library_grid-template-columns :minmax(60px, 0.5fr) minmax(200px, 3fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) !important;
                                                   ${scrollForTable}
                                                `,
                                            BaseCell: ` font-family: 'Raleway';
                                                        font-weight: 500;
                                                        font-size: 16px;
                                                        line-height: 19px;
                                                        padding: 16px 5px !important;
                                                        color: #0D182C;
                                                        border-bottom: 1px solid rgba(13, 24, 44, 0.1);
                                                    
                                                        &.underline {
                                                            text-decoration: underline;
                                                        }
                                                        `,
                                        }}
                                    />
                                </CheckData>
                                {this.state.loadMore ? (
                                    <Loading padding={10} />
                                ) : (
                                    showLoadMoreBtn && (
                                        <div className="d-flex align-items-center justify-content-center pt-3 load-more-section">
                                            <button
                                                onClick={() => this.loadMore(attr)}
                                                disabled={this.state.loading}
                                                className="load-more-btn"
                                            >
                                                Load More
                                            </button>
                                        </div>
                                    )
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default GSCReportQuestions;
