import { CHART_KEYS, COLUMNS } from './keys';
import { query } from './query';
import DataTable from '../../../../components/Datatable';
import Component from '../../../../components/Component';
import { getDifference } from '../../../../helpers/showDelta';
import { noDataMessage } from '../../../../helpers/noDataMessage';
import Loading from '../../../../components/Loading';
import Timeline from '../../../../components/Timeline';
import { scrollForTable } from '../../../../components/Datatable/utils';

class CTR extends Component {
    constructor(props) {
        super();
        if (!props.data) this.query = query(props.dates);
    }

    searchFunction(data, search) {
        return data;
    }

    componentDidUpdate(prevProps, prevState) {
        if (JSON.stringify(prevProps.dates) !== JSON.stringify(this.props.dates)) {
            this.query = query(this.props.dates);
            this.query.bind(this.setState.bind(this));
            this.query.update();
            this.props.updateDates({ emptyAll: true });
        }
        if (this.state.data && JSON.stringify(this.state.data) !== JSON.stringify(prevState.data)) {
            this.props.updateDates({ key: 'ctr', value: this.state.data });
        }
    }

    render() {
        const data = this.state.data?.length ? this.state.data : this.props.data;
        if ((!data || !data.length) && this.state.loading && !this.props.data) return <Loading />;

        const sortFns = {
            impressions: (array) => array.sort((a, b) => a.impressions - b.impressions),
            avgCTR: (array) => array.sort((a, b) => a.clicks/a.impressions - b.clicks/b.impressions),
            clicks: (array) => array.sort((a, b) => a.clicks - b.clicks),
            keywords: (array) => array.sort((a, b) => a.keywords - b.keywords),
            impressions_delta: (array) =>
                array.sort(
                    (a, b) =>
                        getDifference(a.impressions, a.impressionsPrior) -
                        getDifference(b.impressions, b.impressionsPrior),
                ),
            clicks_delta: (array) =>
                array.sort(
                    (a, b) =>
                        getDifference(a.clicks, a.clicksPrior) -
                        getDifference(b.clicks, b.clicksPrior),
                ),
            keywords_delta: (array) =>
                array.sort(
                    (a, b) =>
                        getDifference(a.keywords, a.keywordsPrior) -
                        getDifference(b.keywords, b.keywordsPrior),
                ),
            avgCTR_delta: (array) =>
                array.sort(
                    (a, b) =>
                        getDifference(a.avgCTR, a.avgCTRPrior) -
                        getDifference(b.avgCTR, b.avgCTRPrior),
                ),
        };

        const chartData = {
            labels: data?.map((x) => x.position),
            datasets: CHART_KEYS.map((item) => ({
                label: item.label,
                type: 'line',
                data: data?.map((state) =>
                    item.render ? item.render(state[item.key]) : state[item.key],
                ),
                backgroundColor: item.labelColor,
                borderColor: item.labelColor,
                borderDash: item.borderDash ?? [0],
                borderRadius: 4,
                tension: 0.4,
            })),
        };

        return !data || !data.length ? (
            <div>{noDataMessage}</div>
        ) : (
            <div id="comparision-ctr">
                <div className="row card-row" id="comparision-ctr-chart">
                    <div className="col-12">
                        <div className="card table-main rounded-main overflow-hidden">
                            <div className="card-body">
                                <Timeline
                                    chartSize="lg"
                                    dontShowMetrics
                                    data={data}
                                    error={this.state.error}
                                    keys={CHART_KEYS}
                                    xAxis="position"
                                    chartData={chartData}
                                    forceRun={this.forceRun}
                                    leftAxisLabel="CTR (%)"
                                    xAxisTitle="Position"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row card-row" id="comparision-ctr-chart-table">
                    <div className="col-12">
                        <div className="card table-main rounded-main overflow-hidden">
                            <div className="card-header">
                                <p className="card-title">CTR</p>
                            </div>
                            <div className="card-body">
                                <DataTable
                                    nodes={data}
                                    sortFns={sortFns}
                                    COLUMNS={COLUMNS}
                                    uniqKey={'position'}
                                    fileName="CTR"
                                    searchFunction={this.searchFunction}
                                    forceRun={this.forceRun}
                                    onlyTable
                                    onlyPagination
                                    additionalStyles={{
                                        Table: `
                                            --data-table-library_grid-template-columns : minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(150px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(150px, 1fr) minmax(120px, 1fr) !important;
                                           ${scrollForTable}
                                            `,
                                        BaseCell: ` font-family: 'Raleway';
                                                    font-weight: 500;
                                                    font-size: 16px;
                                                    line-height: 19px;
                                                    padding: 16px 5px;
                                                    color: #0D182C;
                                                    border-bottom: 1px solid rgba(13, 24, 44, 0.1);
                                                
                                                    &.underline {
                                                    text-decoration: underline;
                                                    }`,
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CTR;
