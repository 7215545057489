import { Cell } from '@table-library/react-table-library/table';
import moment from 'moment';
import numeral from 'numeral';

export const COLUMNS = (default_image, onOpen) => [
    {
        label: 'Domain',
        key: 'base_domain',
        data: 'base_domain',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;
            return (
                <div>
                    <div className="d-flex align-items-center">
                        <div style={{ float: 'left', marginRight: '10px' }}>
                            <img
                                src={`https://www.google.com/s2/favicons?domain=${data}`}
                                onError={(event) => (event.target.src = default_image)}
                                loading="lazy"
                                sizes="16px"
                                decoding="async"
                                height="16"
                            />
                        </div>
                        <strong className="wripe">
                            <a
                                href={`${window.location.href}&base_domain=${encodeURIComponent(
                                    data,
                                )}`}
                            >
                                {data}
                            </a>
                        </strong>
                    </div>
                </div>
            );
        },
    },
    {
        label: 'Headline',
        key: 'headline',
        data: 'headline',
        Component: Cell,
        renderCell: ({ data, item, csvDownload }) => {
            if (csvDownload) return data;
            const title = data;
            return (
                <div className="d-flex">
                    <div className="d-flex align-items-center tracking-pages-name mr-10">
                        <div style={{ float: 'left', marginRight: '10px' }}>
                            <img
                                onError={(event) => (event.target.src = default_image)}
                                src={item.image ?? default_image}
                                loading="lazy"
                                alt="page_image"
                                onClick={() => onOpen(item.image ?? default_image)}
                                className="cursor-pointer"
                            />
                        </div>
                        <strong>{title.length > 64 ? title.substr(0, 64) + '...' : title}</strong>
                    </div>
                    <div className="d-flex align-items-center">
                        <a
                            target="_blank"
                            className="link-website d-inline-flex align-items-center justify-content-center"
                            href={item.url}
                            rel="noreferrer"
                        >
                            <i className="icon-external-link"></i>
                        </a>
                    </div>
                </div>
            );
        },
    },
    {
        label: 'Unique Headlines',
        key: 'uniqueHeadline',
        data: 'uniqueHeadline',
        Component: Cell,
        renderCell: ({ data, item, csvDownload }) => {
            if (csvDownload) return data;
            return (
                <span title={item.headlines?.join(' , ')} className="cursor-pointer">
                    {data}
                </span>
            );
        },
    },
    {
        label: 'Score',
        key: 'domainScore',
        data: 'domainScore',
        info: 'Score measures how often and prominently a domain appears in Discover based on Number of Appearances, Image Size and position in Google Discover feeds. Higher scores often mean better visibility and potential user engagement.',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return Math.floor(data);
        },
    },
    {
        label: 'avg. Position',
        key: 'avgPosition',
        data: 'avgPosition',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return Math.floor(data);
        },
    },
    {
        label: 'appearances',
        key: 'appearances',
        data: 'appearances',
        info: "Appearances is The number of times a domain's content appears in the Google Discover feed within the selected time period. More appearances generally indicate higher visibility",
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return Math.floor(data);
        },
    },
    {
        label: 'Video Size',
        key: 'videoSize',
        data: 'videoSize',
        Component: Cell,
    },
    {
        label: 'Video Width',
        key: 'videoWidth',
        data: 'videoWidth',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return Math.floor(data);
        },
    },
    {
        label: 'Last Seen',
        key: 'lastSeen',
        data: 'lastSeen',
        Component: Cell,
        renderCell: ({ data }) => {
            return moment(data?.value).format('YYYY-MM-DD');
        },
    },
    {
        label: 'Published',
        key: 'timeAGo',
        data: 'timeAGo',
        Component: Cell,
        renderCell: ({ data }) => {
            return data?.[0];
        },
    },
];

export const FIELD_FOR_DOWNLOADS = [
    {
        label: 'Url',
        key: 'url',
        data: 'url',
        Component: Cell,
    },
    {
        label: 'Headlines',
        key: 'headlines',
        data: 'headlines',
        Component: Cell,
        renderCell: ({ data }) => {
            return Array.isArray(data) ? data.join(' , ') : data;
        },
    },
];
