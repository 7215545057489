import { Cell } from '@table-library/react-table-library/table';
import numeral from 'numeral';
import { collectCell, getPercent } from '../../../helpers/showDelta';
import { CellTree } from '@table-library/react-table-library/tree';

export const COLUMNS = [
    {
        label: 'Categories',
        key: 'name',
        data: 'name',
        Component: CellTree,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return <strong>{data}</strong>;
        },
    },
    {
        label: 'Impressions',
        key: 'sum_impressions',
        data: 'sum_impressions',
        Component: Cell,
        renderCell: ({ data,item, csvDownload }) => {
            if (csvDownload) return data;
            if (item.id === 'all_categories') return null;
            return <strong>{numeral(data).format('0.0a')}</strong>;
        },
    },
    {
        label: <span className="delta-sign">&#916;</span>,
        key: 'impressions_delta',
        data: 'impressions_delta',
        Component: Cell,
        renderCell: ({ item, csvDownload }) => {
            if (item.id === 'all_categories') return null;
            if (csvDownload) return getPercent(item.sum_impressions, item.sum_impressions_prior);
            return collectCell(item.sum_impressions, item.sum_impressions_prior);
        },
    },
    {
        label: 'Clicks',
        key: 'sum_clicks',
        data: 'sum_clicks',
        Component: Cell,
        renderCell: ({ data, item, csvDownload }) => {
            if (item.id === 'all_categories') return null;
            if (csvDownload) return data;

            return <strong>{numeral(data).format('0.0a')}</strong>;
        },
    },
    {
        label: <span className="delta-sign">&#916;</span>,
        key: 'clicks_delta',
        data: 'clicks_delta',
        Component: Cell,
        renderCell: ({ item, csvDownload }) => {
            if (item.id === 'all_categories') return null;
            if (csvDownload) return getPercent(item.sum_clicks, item.sum_clicks_prior);
            return collectCell(item.sum_clicks, item.sum_clicks_prior);
        },
    },
    {
        label: 'Unique pages',
        key: 'unique_pages',
        data: 'unique_pages',
        Component: Cell,
        renderCell: ({ data, item, csvDownload }) => {
            if (item.id === 'all_categories') return null;
            if (csvDownload) return data;

            return <strong>{numeral(data).format('0.0a')}</strong>;
        },
    },
    {
        label: <span className="delta-sign">&#916;</span>,
        key: 'uniqe_pages_delta',
        data: 'uniqe_pages_delta',
        Component: Cell,
        renderCell: ({ item, csvDownload }) => {
            if (item.id === 'all_categories') return null;
            if (csvDownload) return getPercent(item.unique_pages, item.unique_pages_prior);
            return collectCell(item.unique_pages, item.unique_pages_prior);
        },
    },
    {
        label: 'Median impressions per page',
        key: 'median_impressions_per_page',
        data: 'median_impressions_per_page',
        Component: Cell,
        renderCell: ({ data, csvDownload, item }) => {
            if (csvDownload) return data;
            if (item.id === 'all_categories') return null;
            return <strong>{numeral(data).format('0.0a')}</strong>;
        },
    },
    {
        label: <span className="delta-sign">&#916;</span>,
        key: 'median_impressions_per_page_delta',
        data: 'median_impressions_per_page_delta',
        Component: Cell,
        renderCell: ({ item, csvDownload }) => {
            if (item.id === 'all_categories') return null;
            if (csvDownload) return getPercent(item.median_impressions_per_page, item.median_impressions_per_page_prior);
            return collectCell(item.median_impressions_per_page, item.median_impressions_per_page_prior);
        },
    },
    {
        label: 'CTR',
        key: 'avgCTR',
        data: 'avgCTR',
        Component: Cell,
        renderCell: ({ item, csvDownload }) => {
            if (item.id === 'all_categories') return null;

            const ctrValue = item.sum_clicks/item.sum_impressions;
            if (csvDownload) return ctrValue;

            return <strong>{numeral(ctrValue).format('0.00%')}</strong>;
        },
    },
    {
        label: <span className="delta-sign">&#916;</span>,
        key: 'avgCTR_delta',
        data: 'avgCTR_delta',
        Component: Cell,
        renderCell: ({ item, csvDownload }) => {
            if (item.id === 'all_categories') return null;

            const ctrValue = item.sum_clicks/item.sum_impressions;
            const ctrValuePrior = item.sum_clicks_prior/item.sum_impressions_prior;
            if (csvDownload) return getPercent(ctrValue, ctrValuePrior);
            return collectCell(ctrValue, ctrValuePrior);
        },
    },
];
