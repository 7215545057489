import { Component } from "react";

/*
    Message types

    info -  alert-info
    danger - alert-danger
    success - alert-success
    warning - alert-warning


*/

class Message extends Component{
    constructor(){
        super()

    }
    
    render(){
        return (
                <div className={`custom-message alert ${ this.props.message_type ?? 'alert-danger'} alert-dismissible fade show  mb-3`} role="alert">
                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close">
                    </button>
                    <div>
                        {this.props.header && <strong>{this.props.header}:</strong>} {this.props.message}
                    </div> 
                    {this.props.link && (
                        <a href={this.props.link}>
                            {this.props.link_name}
                        </a>
                        )
                    }
                </div>
        )
    }
}

export default Message;