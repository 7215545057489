import Loading from '../../components/Loading';

import CheckData from '../../components/CatchError';
import DataTable from '../../components/Datatable';
import Component from '../../components/Component';
import { COLUMNS } from './keys';
import query from './query';
import { scrollForTable } from '../../components/Datatable/utils';

class Summary extends Component {
    constructor() {
        super();
        this.searchFunction = this.searchFunction.bind(this);
        this.query = query;
    }

    searchFunction(data, search) {
        return data.filter((item) => item.appearance.toLowerCase().includes(search.toLowerCase()));
    }

    render() {
        if (this.state.loading && !this.state.loadMore) {
            return <Loading padding={10} />;
        }

        const sortFns = {
            appearance: (array) => array.sort((a, b) => a.appearance.localeCompare(b.appearance)),
            sumImpressions: (array) => array.sort((a, b) => a.sumImpressions - b.sumImpressions),
            sumClicks: (array) => array.sort((a, b) => a.sumClicks - b.sumClicks),
            avgCTR: (array) => array.sort((a, b) => a.avgCTR - b.avgCTR),
        };

        return (
            <>
                <CheckData
                    content_id={this.props.content_id}
                    parent_id={this.props.parent_id}
                    data={this.state.data}
                    loading={this.state.loading}
                >
                    <DataTable
                        nodes={this.state.data}
                        sortFns={sortFns}
                        COLUMNS={COLUMNS}
                        uniqKey={'appearance'}
                        fileName="Appearances"
                        forceRun={this.forceRun}
                        searchFunction={this.searchFunction}
                        additionalStyles={{
                            Table: `
                                --data-table-library_grid-template-columns :  minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) !important;
                                ${scrollForTable}
                            `,
                            BaseCell: `  font-family: 'Raleway';
                                        font-weight: 500;
                                        font-size: 16px;
                                        line-height: 19px;
                                        padding: 16px 5px !important;
                                        color: #0D182C;
                                        border-bottom: 1px solid rgba(13, 24, 44, 0.1);
                                    
                                        &.underline {
                                            text-decoration: underline;
                                        }
                                        `,
                        }}
                    />
                </CheckData>
            </>
        );
    }
}

export default Summary;
