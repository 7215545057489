import React from 'react';

import LoadMoreComponent from '../../../components/LoadMoreComponent';
import { query } from './query';
import CheckData from '../../../components/CatchError';
import DataTable from '../../../components/Datatable';
import Loading from '../../../components/Loading';
import { COLUMNS, DEFAULT_YEAR } from './keys';
import ExtraButtons from './ExtraBtns';
import ManualClusterSelect from '../../../components/manualClusterModal';
import Search from '../../../components/Search';
import { scrollForTable } from '../../../components/Datatable/utils';

class GSCReportYear extends LoadMoreComponent {
    constructor() {
        super();
        this.query = query({ offset: 0, search: null });
        this.offsetStep = 1000;

        this.getData = this.getData.bind(this);
        this.onSelect = this.onSelect.bind(this);
        this.onRemoveAllSelected = this.onRemoveAllSelected.bind(this);
        this.onSearch = this.onSearch.bind(this);
    }

    searchFunction(data, search) {
        return data.filter((item) => item.keyword.toLowerCase().includes(search.toLowerCase()));
    }

    async getData(value, search) {
        this.query = query({
            offset: 0,
            year: value,
            search: search ?? this.state.search,
        });
        this.query.bind(this.setState.bind(this));
        this.setState({ year: value, allData: [] });

        await this.query.update();
    }

    onRemoveAllSelected(bool) {
        this.setState({
            removeAllSelected: bool,
            selectedItems: [],
        });
    }

    onSelect(action, state) {
        if (action.type === 'REMOVE_ALL') return this.setState({ selectedItems: [] });
        this.setState({ selectedItems: state.ids, removeAllSelected: false });
    }

    onSearch(value) {
        this.setState({
            search: value,
        });
        this.getData(this.state.year, value);
    }

    render() {
        const attr = {
            query,
            offsetStep: this.offsetStep,
            year: this.state.year,
            search: this.state.search,
        };

        const sortFns = {
            keyword: (array) => array.sort((a, b) => a.keyword.localeCompare(b.keyword)),
            seen: (array) =>
                array.sort((a, b) => new Date(a.firstSeen?.value) - new Date(b.firstSeen?.value)),
            maxClicks: (array) => array.sort((a, b) => a.maxClicks - b.maxClicks),
            avgClicks: (array) => array.sort((a, b) => a.avgClicks - b.avgClicks),
            avgURLs: (array) => array.sort((a, b) => a.avgURLs - b.avgURLs),
            avgPosition: (array) => array.sort((a, b) => a.avgPosition - b.avgPosition),
            impressions: (array) => array.sort((a, b) => a.impressions - b.impressions),
            avgCTR: (array) => array.sort((a, b) => a.avgCTR - b.avgCTR),
        };

        const showLoadMoreBtn = this.state.data?.length === this.offsetStep;

        const extraBtns = () => <ExtraButtons year={this.state.year} getData={this.getData} />;

        const customeSearch = (
            <Search
                onSubmit={this.onSearch}
                placeholder="Search for items"
                defaultValue={this.state.search}
            />
        );
        return (
            <>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <div>
                        <ManualClusterSelect
                            current_url={this.props.current_url}
                            csrf_token={this.props.csrf_token}
                            selectedItems={this.state.selectedItems?.map((id) => ({
                                id,
                                keyword: this.state.allData.find((item) => item.id === id).keyword,
                            }))}
                            header="Add To Keyword List"
                            cluster_type="keyword"
                            onRemoveAllSelected={this.onRemoveAllSelected}
                        />
                    </div>
                </div>
                <CheckData
                    content_id={this.props.content_id}
                    parent_id={this.props.parent_id}
                    data={this.state.allData}
                    loading={this.state.loading}
                    loadMore={this.state.loadMore}
                    customCheck
                    customCheckCondition={
                        (!!this.state.year && this.state.year !== DEFAULT_YEAR) ||
                        !!this.state.search
                    }
                >
                    <DataTable
                        nodes={this.state.allData}
                        sortFns={sortFns}
                        COLUMNS={COLUMNS}
                        fileName="Year Keywords"
                        header={'"Year" Keywords'}
                        searchFunction={this.searchFunction}
                        defaultSortKey="maxClicks"
                        extraBtns={extraBtns}
                        forceRun={this.forceRun}
                        removeAllSelected={this.state.removeAllSelected}
                        hasSelect={true}
                        customeSearch={customeSearch}
                        onSelect={this.onSelect}
                        additionalStyles={{
                            Table: `
                                    --data-table-library_grid-template-columns : minmax(60px, 0.5fr) minmax(200px, 3fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) !important;
                                   ${scrollForTable}
                                `,
                            BaseCell: ` font-family: 'Raleway';
                                        font-weight: 500;
                                        font-size: 16px;
                                        line-height: 19px;
                                        padding: 16px 5px !important;
                                        color: #0D182C;
                                        border-bottom: 1px solid rgba(13, 24, 44, 0.1);
                                    
                                        &.underline {
                                            text-decoration: underline;
                                        }
                                        `,
                        }}
                    />
                </CheckData>
                {this.state.loadMore ? (
                    <Loading padding={10} />
                ) : (
                    showLoadMoreBtn && (
                        <div className="d-flex align-items-center justify-content-center pt-3 load-more-section">
                            <button
                                onClick={() => this.loadMore(attr)}
                                disabled={this.state.loading}
                                className="load-more-btn"
                            >
                                Load More
                            </button>
                        </div>
                    )
                )}
            </>
        );
    }
}

export default GSCReportYear;
