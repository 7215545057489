import React, { Component } from 'react';
import PurchaseMoreEntity from '.';
import { getInformation } from './query';
import Loading from '../../components/Loading';

class PurchaseMoreEntityContainer extends Component {
    constructor() {
        super();

        this.showOpened = this.showOpened.bind(this);
        this.onClickOnStep = this.onClickOnStep.bind(this);

        this.urlParams = new URLSearchParams(window.location.search);
        this.part = this.urlParams.get('part');

        this.state = {
            allData: [],
            isLoading: true,
            selectedCollapse: this.part ?? null,
        };
    }

    async componentDidMount() {
        try {
            const res = await getInformation();
            const data = await res.json();
            this.setState({
                allData: data.result,
                isLoading: false,
                selectedCollapse: this.state.selectedCollapse ?? data.result[0]?.object_name,
                is_viewer: data.is_viewer,
                main_user_email: data?.main_user_email
            });
        } catch (err) {
            console.log(err);
        } finally {
            this.setState({
                isLoading: false,
            });
        }
    }

    onClickOnStep(name) {
        this.setState({
            selectedCollapse: name,
        });
    }

    showOpened(name, value) {
        return this.state.selectedCollapse === name ? value ?? 'active' : '';
    }

    render() {
        return (
            <div className="card table-main rounded-main purchase-container">
                <div className="card-header d-flex justify-content-between">
                    <div>Purchase Additional Add-ons</div>
                </div>
                <div className="card-body">
                    {this.state.isLoading ? (
                        <Loading />
                    ) : (
                        <>  
                            <strogn>Current Plane - {this.state.allData?.[0]?.plan}</strogn>
                            <div id="entity-steps">
                                {this.state.is_viewer 
                                ? 
                                    <div className='additional-purchases-empty-message'>
                                        Buying additional add-ons is available only to the main user of the account <strong>{this.state.main_user_email}</strong>, please contact the main user to buy the additional add-ons or contact us and main user via email and we can do it for you.
                                    </div>
                                :   this.state.allData?.map((item, i) => {
                                        return (
                                            <div key={item.object_name}>
                                                <div className="header-button-container">
                                                    <button
                                                        id={`step${i}`}
                                                        className={`collapsed header-buttons panel-heading ${this.showOpened(
                                                            item.object_name,
                                                        )}`}
                                                        data-bs-toggle="collapse"
                                                        data-bs-target={`#step${i}Content`}
                                                        aria-expanded="false"
                                                        aria-controls={`step${i}Content`}
                                                        onClick={() => this.onClickOnStep(item.object_name)}
                                                    >
                                                        <span className="collaps-btn">
                                                            {item?.object_title}
                                                        </span>
                                                    </button>
                                                </div>
                                                <div
                                                    id={`step${i}Content`}
                                                    className={`collapse panel-collapse ${this.showOpened(
                                                        item.object_name,
                                                        'show',
                                                    )}`}
                                                    aria-labelledby={`headingStep${i}`}
                                                    data-bs-parent="#entity-steps"
                                                >
                                                    <PurchaseMoreEntity item={item} csrf_token={this.props.csrf_token} />
                                                </div>
                                            </div>
                                        );
                                })}
                            </div>
                        </>
                    )}
                </div>
            </div>
        );
    }
}

export default PurchaseMoreEntityContainer;
