import { Component } from 'react';
import { analyze, getContentAnalyzeStatus, getList } from './query';
import Markdown from 'react-markdown';
import moment from 'moment';
import Loading from '../../components/Loading';
import { isTrue } from '../../helpers/isTrue';
import AnalyzeContentDateModal from '../../components/Modals/AnalyzeContentDateModal';

class AnalyzeContent extends Component {
    constructor(props) {
        super();
        this.onAnalyze = this.onAnalyze.bind(this);
        this.onOpenModal = this.onOpenModal.bind(this);
        this.onCloseModal = this.onCloseModal.bind(this);
        this.showMore = this.showMore.bind(this);

        const reportNameFromUrl = window.location.href?.split('/')?.slice(5)?.join('/');
        this.report_name = props.report_name ?? reportNameFromUrl;
        this.checkIsInProgress = this.checkIsInProgress.bind(this);
        this.getItems = this.getItems.bind(this);

        this.state = {
            data: [],
            firstFive: [],
            isInProgress: true,
            loading: true,
            total_number_of_available: 0,
            active_ai_count: 0,
            remaining_count: 0,
            isOpen: false,
        };
    }

    async componentDidMount() {
        try {
            this.getItems();
            this.checkIsInProgress();
        } catch (err) {
            console.log(err);
        }
    }

    onOpenModal() {
        this.setState({
            isOpen: true,
        });
    }
    onCloseModal() {
        this.setState({
            isOpen: false,
        });
    }

    async onAnalyze({ start_date, end_date }) {
        try {
            this.setState({
                isInProgress: true,
            });

            const data = {
                domain_uuid: this.props.domain_id,
                belongs_to: this.report_name,
                start_date: moment(start_date).format('YYYY-MM-DD'),
                end_date: moment(end_date).format('YYYY-MM-DD'),
            };

            if (this.props.category) data.category = this.props.category;

            await analyze(this.props.csrf_token, data);

            this.getItems();
            this.checkIsInProgress();
        } catch (err) {
            this.setState({
                isInProgress: false,
            });
            console.log(err);
        }
    }

    isActive(results) {
        if (!results || results?.length === 0) return false;
        let lastPublished = results[0]?.updated_at;
        for (let i = 1; i < results.length; i++) {
            if (new Date(results[i].updated_at) > new Date(lastPublished)) {
                lastPublished = results[i].updated_at;
            }
        }
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        return new Date(lastPublished) > new Date(yesterday);
    }

    async getItems() {
        try {
            this.setState({
                loading: true,
            });
            const data = !this.props.all ? { belongs_to: this.report_name } : {};
            const list = await getList(this.props.csrf_token, this.props.domain_id, data);
            const listData = await list.json();

            this.isActive(listData?.results);

            this.setState({
                data: listData?.results ?? [],
                firstFive: listData?.results?.length > 5 ? listData?.results.slice(0, 5) : [],
                total_number_of_available: listData?.total_number_of_available,
                active_ai_count: listData?.active_ai_count,
                remaining_count: listData?.remaining_count,
                loading: false,
                isInProgress: this.isActive(listData?.results),
            });
        } catch (err) {
            this.setState({
                loading: false,
            });
            console.log(err);
        }
    }

    async checkIsInProgress() {
        try {
            const res = await getContentAnalyzeStatus(
                this.props.csrf_token,
                this.props.domain_id,
                this.report_name,
            );
            const data = await res.json();
            if (data.is_in_progress === true) {
                this.setState({
                    isInProgress: true,
                });
                setTimeout(() => this.checkIsInProgress(), 6000);
            } else {
                this.getItems();
            }
        } catch (err) {
            console.log(err);
        }
    }

    onClickOnStep(e) {
        const items = document.querySelectorAll('.analyze-collapsed');

        for (let item of items) {
            if (item.id === e.target.id) {
                if (item.classList.contains('active')) {
                    item.classList.remove('active');
                } else {
                    item.classList.add('active');
                }
            } else {
                item.classList.remove('active');
            }
        }
    }

    showMore() {
        this.setState({
            firstFive: [],
        });
    }

    showTitle(item) {
        const secondPart =
            item?.start_date && item?.start_date !== 'None' ? (
                <span className="content-analyze-dates">
                    {item.start_date} <span className="analyze-arrow">&#187;</span> {item.end_date}
                </span>
            ) : (
                <span className="content-analyze-dates">
                    {moment(item.updated_at).format('YYYY-MM-DD')}
                </span>
            );

        let firstPart = '';
        if (item.page === 'pages') {
            firstPart = 'Top Pages Analysis:';
        } else if (item.page.includes('categories')) {
            const title = item.page.split(':in=')?.[1];
            firstPart = `[${decodeURIComponent(title)}] Analysis: `;
        } else {
            firstPart = 'Analysis:';
        }

        return (
            <span>
                {firstPart} {secondPart}
            </span>
        );
    }

    render() {
        const data = this.state.firstFive.length ? this.state.firstFive : this.state.data;
        const showAll = !window.location.href.includes('/opportunities/recommendations/')
        return (
            <>
                <div className="row card-row d-flex">
                    <div className="col-12">
                        <div className="card table-main rounded-main overflow-hidden">
                            <div className="card-header">
                                <p className="card-title d-flex justify-content-between">
                                    <div className="hasAttachement feature_beta">
                                        <div className="hasAttachement feature_new">
                                            <div>
                                                Content Patterns and Themes
                                                <div className="d-block card-sub-title text-muted fs-7 text-capitalize py-2">
                                                    {this.state.remaining_count} available out of{' '}
                                                    {this.state.total_number_of_available} Monthly
                                                    Analysis.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="gpt-analyze">
                                        <button
                                            id="analyze-content-btn"
                                            className="gpt-analyze-btn"
                                            onClick={this.onOpenModal}
                                            disabled={
                                                !isTrue(this.props.is_superuser) &&
                                                (this.state.isInProgress ||
                                                    this.state.remaining_count === 0)
                                            }
                                        >
                                            Analyze Content
                                        </button>
                                    </div>
                                </p>
                            </div>
                            <div className="card-body">
                                {this.state.loading &&
                                    (!this.state.data || this.state.data === 0) && <Loading />}
                                <div>
                                    <div id="analysis-component">
                                        {data &&
                                            data?.map((item, index) => {
                                                return (
                                                    <div key={index}>
                                                        <div className="header-button-container">
                                                            <button
                                                                id={'step' + index}
                                                                className="analyze-collapsed collapsed header-buttons panel-heading collaps-btn"
                                                                data-bs-toggle="collapse"
                                                                data-bs-target={`#step${index}Content`}
                                                                aria-expanded="false"
                                                                aria-controls={`step${index}Content`}
                                                                onClick={this.onClickOnStep}
                                                            >
                                                                {this.showTitle(item)}
                                                            </button>
                                                        </div>
                                                        <div
                                                            id={`step${index}Content`}
                                                            className="collapse panel-collapse"
                                                            aria-labelledby={'headingStep' + index}
                                                            data-bs-parent="#analysis-component"
                                                        >
                                                            {item.content ? (
                                                                <Markdown>{item.content}</Markdown>
                                                            ) : (
                                                                'In Progress'
                                                            )}
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        {!this.state.data && this.state.loading && 'No data'}
                                    </div>
                                </div>
                                {!!this.state.firstFive?.length && (
                                    <div className="d-flex align-items-center justify-content-center pt-3 load-more-section">
                                        <button
                                            onClick={this.showMore}
                                            disabled={this.state.loading}
                                            className="load-more-btn"
                                        >
                                            Show More
                                        </button>
                                    </div>
                                )}
                                {showAll && <div className="d-flex align-items-center justify-content-center pt-3 load-more-section">
                                    <a
                                        href={`/domains/${window.info.id}/opportunities/recommendations/`}
                                        disabled={this.state.loading}
                                        className="load-more-btn"
                                    >
                                        View All
                                    </a>
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
                <AnalyzeContentDateModal
                    isOpen={this.state.isOpen}
                    onClose={this.onCloseModal}
                    onSubmit={this.onAnalyze}
                />
            </>
        );
    }
}
export default AnalyzeContent;
