import Loading from '../../../components/Loading';

import { query } from './query';
import { COLUMNS } from './keys';
import CheckData from '../../../components/CatchError';
import DataTable from '../../../components/Datatable';
import LoadMoreComponent from '../../../components/LoadMoreComponent';
import { scrollForTable } from '../../../components/Datatable/utils';

class GSCReportManualUrlClustersDetailTable extends LoadMoreComponent {
    constructor(props) {
        super();
        this.query = query({
            offset: 0,
            cluster_id: props.cluster_id,
            deleted_urls: props.deleted_urls,
            user_domain_id: props.user_domain_id,
        });
        this.offsetStep = 1000;
    }

    searchFunction(data, search) {
        return data.filter((item) => item.url.toLowerCase().includes(search.toLowerCase()));
    }

    render() {
        const attr = {
            query,
            offsetStep: this.offsetStep,
            cluster: this.props.cluster,
        };
        const sortFns = {
            url: (array) => array.sort((a, b) => a.url.localeCompare(b.url)),
            clicks: (array) => array.sort((a, b) => a.clicks - b.clicks),
            impressions: (array) => array.sort((a, b) => a.impressions - b.impressions),
            avgPosition: (array) => array.sort((a, b) => a.avgPosition - b.avgPosition),
            weekly_keywords: (array) => array.sort((a, b) => a.weekly_keywords - b.weekly_keywords),
            avgCTR: (array) => array.sort((a, b) => a.avgCTR - b.avgCTR),
        };

        const showLoadMoreBtn = this.state.data?.length === this.offsetStep;

        return (
            <>
                <CheckData
                    content_id={this.props.content_id}
                    parent_id={this.props.parent_id}
                    data={this.state.allData}
                    loading={this.state.loading}
                    loadMore={this.state.loadMore}
                >
                    <DataTable
                        nodes={this.state.allData}
                        sortFns={sortFns}
                        COLUMNS={COLUMNS}
                        fileName="URLs"
                        header="URLs"
                        searchFunction={this.searchFunction}
                        forceRun={this.forceRun}
                        defaultSortKey="clicks"
                        additionalStyles={{
                            Table: `
                                    --data-table-library_grid-template-columns : minmax(250px, 2fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) !important;
                                   ${scrollForTable}
                                `,
                            BaseCell: ` font-family: 'Raleway';
                                        font-weight: 500;
                                        font-size: 16px;
                                        line-height: 19px;
                                        padding: 16px 5px !important;
                                        color: #0D182C;
                                        border-bottom: 1px solid rgba(13, 24, 44, 0.1);
                                    
                                        &.underline {
                                            text-decoration: underline;
                                        }
                                        `,
                        }}
                    />
                </CheckData>
                {this.state.loadMore ? (
                    <Loading padding={10} />
                ) : (
                    showLoadMoreBtn && (
                        <div className="d-flex align-items-center justify-content-center pt-3 load-more-section">
                            <button
                                onClick={() => this.loadMore(attr)}
                                disabled={this.state.loading}
                                className="load-more-btn"
                            >
                                Load More
                            </button>
                        </div>
                    )
                )}
            </>
        );
    }
}

export default GSCReportManualUrlClustersDetailTable;
