import Component from '../../../components/Component';
import Loading from '../../../components/Loading';

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { getRandomColor, labelColors } from '../../../lib/colors';
import CheckData from '../../../components/CatchError';
import DataTable from '../../../components/Datatable';
import { COLUMNS, getPercentForChart } from './keys';
import { isTrue } from '../../../helpers/isTrue';
import { query, setDeviceQuery } from './query';
import { Pie } from 'react-chartjs-2';
import numeral from 'numeral';
import { getPoorPercent } from '../../../helpers/showDelta';
import { scrollForTable } from '../../../components/Datatable/utils';
import Actions from '../../../components/GSCActions';

ChartJS.register(ArcElement, Tooltip, Legend);

class Summary extends Component {
    constructor(props) {
        super();
        this.branded_terms = JSON.parse(props.branded_terms);

        this.searchFunction = this.searchFunction.bind(this);
        this.updateInput = this.updateInput.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

        if (this.branded_terms?.length) {
            const branded_terms_for_query = this.getKewords(this.branded_terms);
            this.query = query(branded_terms_for_query);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            JSON.stringify(prevState.branded_terms_list) !==
            JSON.stringify(this.state.branded_terms_list)
        ) {
            const branded_terms_for_query = this.getKewords(this.state.branded_terms_list);
            this.query = query(branded_terms_for_query);
            this.query.bind(this.setState.bind(this));
            this.query.update();
        }
    }

    searchFunction(data) {
        return data;
    }

    getKewords(data) {
        return data.map((item) => `keyword like '%${item}%'`).join(' or ');
    }

    onSubmit(e, value) {
        e.preventDefault();

        const params = {
            domain_url: window.filters.get().domain.value,
            branded_terms: value ?? this.state.branded_terms,
        };

        this.setState({ isLoadingBrands: true });

        setDeviceQuery(this.props.endpoint_url, params, this.props.csrf_token)
            .then((res) => res.json())
            .then((data) => {
                const branded_terms_list = data.branded_terms_list;
                this.setState({ branded_terms_list: branded_terms_list });
            })
            .catch((err) => console.log(err))
            .finally(() => {
                this.setState({ isLoadingBrands: false });
            });
    }

    updateInput(e) {
        this.setState({ branded_terms: e.target.value });
    }
    render() {
        if (
            (!this.branded_terms?.length && !this.state.branded_terms_list?.length) ||
            this.state.branded_terms_list?.length === 0
        ) {
            return (
                <div
                    className="Timeline"
                    style={{ height: '100%', minHeight: '150px', position: 'relative' }}
                >
                    <h3 className="font-weight-bold">Branded vs Unbranded Keywords</h3>
                    <small className="d-block card-sub-title text-muted fs-7 text-capitalize py-2">
                        Enter your branded terms - comma separated, no spaces - to view report
                    </small>
                    <form onSubmit={this.onSubmit}>
                        <div>
                            <div className="text-field mb-3">
                                <label htmlFor="exampleInputEmail1" className="form-label">
                                    Branded Terms
                                </label>
                                <input
                                    name="branded_terms"
                                    className="form-control"
                                    onChange={this.updateInput}
                                    value={this.state.branded_terms ?? ''}
                                />
                            </div>
                            <button
                                className="button button-filled button-primary width-sm btn btn-with-loader"
                                disabled={this.state.isLoadingBrands}
                            >
                                Save
                                {this.state.isLoadingBrands && <Loading onlyPreloader />}
                            </button>
                        </div>
                    </form>
                </div>
            );
        }

        if (this.state.loading) {
            return <Loading />;
        }

        const options = {
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    display: false,
                },
                title: {
                    display: true,
                    text: 'Keywords',
                    padding: {
                        top: 10,
                        bottom: 10,
                    },
                },
                tooltip: {
                    callbacks: {
                        label: (context) => {
                            const percent = `${getPercentForChart(
                                context.dataset.data,
                                context.parsed,
                            ).toFixed(2)}%`;
                            return `${context.label} : ${numeral(context.parsed).format(
                                '0.0a',
                            )} Click${context.parsed > 1 ? 's' : ''} (${percent})`;
                        },
                    },
                },
            },
        };

        const branded = this.state.data?.find((item) => item.type === 'branded') ?? {};
        const all = this.state.data?.find((item) => item.type === 'all') ?? {};
        const unbranded = {
            type: 'non-branded',
            domain: all.domain || branded.domain,
            impressions: all.impressions - branded.impressions || 0,
            keywords: all.keywords - branded.keywords || 0,
            percent: getPoorPercent(all.keywords, all.keywords - branded.keywords),
            position: all.position - branded.position || 0,
            clicks: all.clicks - branded.clicks || 0,
            ctr: all.ctr - branded.ctr || 0,
        };

        const tableData = [
            { ...all, percent: 100 },
            { ...branded, percent: 100 - unbranded.percent },
            unbranded,
        ];
        const chartData = [branded, unbranded];

        const data = {
            labels: chartData.map((item) => item.type),
            datasets: [
                {
                    label: 'Clicks',
                    data: chartData.map((item) => item.clicks),
                    backgroundColor: chartData.map((item, i) => labelColors[i] ?? getRandomColor()),
                    borderRadius: 4,
                    tension: 0.4,
                },
            ],
        };

        const sortFns = {
            type: (array) => array.sort((a, b) => a.type.localeCompare(b.type)),
            keywords: (array) => array.sort((a, b) => a.keywords - b.keywords),
            percent: (array) => array.sort((a, b) => a.percent - b.percent),
            impressions: (array) => array.sort((a, b) => a.impressions - b.impressions),
            clicks: (array) => array.sort((a, b) => a.clicks - b.clicks),
            ctr: (array) => array.sort((a, b) => a.clicks/a.impressions - b.clicks/b.impressions),
            position: (array) => array.sort((a, b) => a.position - b.position),
        };

        return (
            <>
                <div className="actions-btns">
                    <Actions
                        onChange={this.onSubmit}
                        header={'"Branded" Translation'}
                        defaultValue={this.state.branded_terms_list ?? this.branded_terms}
                        loading={this.state.isLoadingBrands}
                        inputStyles={{ width: '150px' }}
                    />
                </div>
                <CheckData
                    content_id={this.props.content_id}
                    parent_id={this.props.parent_id}
                    data={this.state.data}
                    loading={this.state.loading}
                >
                    <div
                        className="Timeline"
                        style={{ height: '100%', minHeight: '150px', position: 'relative' }}
                    >
                        <h3 className="font-weight-bold">Branded vs Non-branded Keywords</h3>
                        <div className="row">
                            <div
                                className="col-6"
                                style={{ height: '400px', position: 'relative' }}
                            >
                                <Pie data={data} options={options} />
                            </div>
                            <div className="col-6">
                                <div className="row">
                                    <div className="col">
                                        <DataTable
                                            nodes={tableData}
                                            sortFns={sortFns}
                                            COLUMNS={COLUMNS}
                                            uniqKey={'type'}
                                            fileName="Brandes vs Non-branded"
                                            searchFunction={this.searchFunction}
                                            forceRun={this.forceRun}
                                            onlyTable
                                            additionalStyles={{
                                                Table: `
                                                --data-table-library_grid-template-columns :  minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) !important;
                                               ${scrollForTable}
                                            `,
                                                BaseCell: `  font-family: 'Raleway';
                                                        font-weight: 500;
                                                        font-size: 16px;
                                                        line-height: 19px;
                                                        padding: 16px 5px !important;
                                                        color: #0D182C;
                                                        border-bottom: 1px solid rgba(13, 24, 44, 0.1);
                                                        &.underline {
                                                            text-decoration: underline;
                                                        }
                                                        `,
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </CheckData>
            </>
        );
    }
}

export default Summary;
