export const analyze = async (csrf_token, data) => {
    return fetch('/domains/add-content-analysis/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrf_token,
        },
        body: JSON.stringify(data),
    });
};

export const getList = async (csrf_token, domain_id, data) => {
    return fetch(`/domains/content-analysis-reports/${domain_id}/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrf_token,
        },
        body: JSON.stringify(data),
    });
};

export const getContentAnalyzeStatus = async (csrf_token, domain_id, report_name) => {
    return fetch(`/domains/content-analyze-status/${domain_id}/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrf_token,
        },
        body: JSON.stringify({ belongs_to: report_name }),
    });
};
