import { Cell } from '@table-library/react-table-library/table';
import numeral from 'numeral';

export const COLUMNS = (plan, onOpenModal) => [
    {
        label: 'Author',
        key: 'author',
        data: 'author',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return (
                <div className="col">
                    {data === 'NA' ? (
                        <span>N/A</span>
                    ) : plan === 'Basic' || plan === 'Trial' || plan === 'Trial_Basic' ? (
                        <button
                            onClick={onOpenModal}
                            style={{ background: 'unset', border: 'none' }}
                        >
                            {data}
                        </button>
                    ) : (
                        <a
                            style={{ fontWeight: 600 }}
                            href={`/domains/${window.info.id}/authors/author?author=` + data}
                        >
                            {data}
                        </a>
                    )}
                </div>
            );
        },
    },
    {
        label: 'Pages',
        key: 'pages',
        data: 'pages',
        Component: Cell,
    },
    {
        label: 'Avg. Entities/Article',
        key: 'entities',
        data: 'entities',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return Math.round(data);
        },
    },
    {
        label: 'Lifespan',
        key: 'lifespan',
        data: 'lifespan',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return <div>{numeral(data).format('0,00')} Day</div>;
        },
    },
    {
        label: 'Total Impressions',
        key: 'impressions',
        data: 'impressions',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return numeral(data).format('0.0a');
        },
    },
    {
        label: 'Median Impressions / Article ',
        key: 'median_impressions_per_author',
        data: 'median_impressions_per_author',
        Component: Cell,
        renderCell: ({ item, data, csvDownload }) => {
            if (csvDownload) return data;

            return numeral(data).format('0.0a');
        },
    },
    {
        label: 'Clicks',
        key: 'clicks',
        data: 'clicks',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return numeral(data).format('0.0a');
        },
    },
    {
        label: 'Avg. Words/Article',
        key: 'avgWords',
        data: 'avgWords',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return Math.round(data);
        },
    },
    {
        label: 'Avg CTR',
        key: 'avgctr',
        data: 'avgctr',
        Component: Cell,
        renderCell: ({ data, item, csvDownload }) => {
            const ctrValue = item.clicks/item.impressions;
            if (csvDownload) return ctrValue;

            return numeral(ctrValue).format('0.00%');
        },
    },
];
