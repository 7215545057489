import { Cell } from '@table-library/react-table-library/table';
import numeral from 'numeral';
import { collectCell, getPercent } from '../../../../helpers/showDelta';

export const COLUMNS = [
    {
        label: 'URLs',
        key: 'url',
        data: 'url',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return (
                <strong>
                    <a
                        href={`/domains/${
                            window.info.id
                        }/gsc-reports/keyword-clusters/cluster?cluster=${encodeURIComponent(data)}`}
                        className="d-inline-flex align-items-center justify-content-center"
                        rel="noreferrer"
                    >
                        {data}
                    </a>
                </strong>
            );
        },
    },
    {
        label: 'Impressions',
        key: 'impressions_2',
        data: 'impressions_2',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return <strong>{numeral(data).format('0.0a')}</strong>;
        },
    },
    {
        label: <span className="delta-sign">&#916;</span>,
        key: 'impressions_delta',
        data: 'impressions_delta',
        Component: Cell,
        renderCell: ({ item, csvDownload }) => {
            if (csvDownload) return getPercent(item.impressions_2, item.impressions_1);
            return collectCell(item.impressions_2, item.impressions_1);
        },
    },
    {
        label: 'Clicks',
        key: 'clicks_2',
        data: 'clicks_2',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return <strong>{numeral(data).format('0.0a')}</strong>;
        },
    },
    {
        label: <span className="delta-sign">&#916;</span>,
        key: 'clicks_delta',
        data: 'clicks_delta',
        Component: Cell,
        renderCell: ({ item, csvDownload }) => {
            if (csvDownload) return getPercent(item.clicks_2, item.clicks_1);
            return collectCell(item.clicks_2, item.clicks_1);
        },
    },
    {
        label: 'Position',
        key: 'position_2',
        data: 'position_2',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return <strong>{numeral(data).format('0.0a')}</strong>;
        },
    },
    {
        label: <span className="delta-sign">&#916;</span>,
        key: 'avgPosition_delta',
        data: 'avgPosition_delta',
        Component: Cell,
        renderCell: ({ item, csvDownload }) => {
            if (csvDownload) return getPercent(item.position_2, item.position_1);
            return collectCell(item.position_2, item.position_1);
        },
    },
    {
        label: 'CTR',
        key: 'ctr_period2',
        data: 'ctr_period2',
        Component: Cell,
        renderCell: ({ item, csvDownload }) => {
            const ctrValue = item.ctr_period2/item.impressions_2;
            if (csvDownload) return ctrValue;

            return <strong>{numeral(ctrValue).format('0.00%')}</strong>;
        },
    },
    {
        label: <span className="delta-sign">&#916;</span>,
        key: 'avgCTR_delta',
        data: 'avgCTR_delta',
        Component: Cell,
        renderCell: ({ item, csvDownload }) => {
            const ctrValue_2 = item.clicks_2/item.impressions_2;
            const ctrValue_1 = item.clicks_1/item.impressions_1;
            if (csvDownload) return getPercent(ctrValue_2, ctrValue_1);
            return collectCell(ctrValue_2, ctrValue_1);
        },
    },
];
