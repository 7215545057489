import request from '../lib/request';
import Mustache from 'mustache';
import getDataRange from '../helpers/getDataRange';
import moment from 'moment';

class Query {
    constructor(q, opts, creds = {}) {
        this.query = q;

        this.cache = {
            query: null,
            response: null,
        };

        this.creds = creds;

        Object.assign(this, opts);
        Object.assign(this, { customValues: opts ?? {} });
        this.update = this.update.bind(this);

        this.filters = window.filters;
        this.fns = [];
    }

    bind(fn) {
        this.fns.push(fn);
        return this.fns.length - 1;
    }

    async update(opts) {
        const filters = this.filters;
        const {
            force = false,
            alreadyInQueue = false,
            dontUpdateExistingData = false,
            updateOnlyData = false,
        } = opts ?? {};

        if (!this.offset && !alreadyInQueue) {
            this.filters.bind(this.update);
        }

        if (filters?.filters?.dates?.label !== 'custom') {
            const filterDates = filters?.filters?.dates;
            const filterValues = getDataRange(filterDates);
            if (filterValues) filters.filters.dates.values = filterValues;
        }

        if (this['get_prior_dates']) {
            const response = this['get_prior_dates'](window.filters.get());

            this.customValues['prior_start_date'] = response.prior_start_date;
            this.customValues['prior_end_date'] = response.prior_end_date;
        }   
        if (this.creds['get_tools_dates'] && filters?.filters?.dates.id !== 'current_day') {
            let startDate = window.filters.get()?.dates?.values?.start_date?.value
            let endDate = window.filters.get()?.dates?.values?.end_date?.value
            filters.filters.dates.values.start_date.value = moment(startDate).add(2, 'days').format('YYYY-MM-DD')
            filters.filters.dates.values.end_date.value = moment(endDate).add(2, 'days').format('YYYY-MM-DD')
        }

        this.sql = this.filters.sql.bind(filters, this.filtersMap || undefined);

        let merged = Mustache.render(this.query, this);
        let formatted = merged.replace(/\n/g, ' ').replace(/\s\s+/g, ' ');
        let results = [];

        // hide for updating summary-query
        // if (this.cache.query == formatted) {
        //   return
        // }
        if (!dontUpdateExistingData) this.fns && this.fns.forEach((fn) => fn({ loading: true }));

        if (this.offset !== null) filters.offset = this.offset;

        let response = await request(`/reports/${this.query}.json`, {
            ...filters,
            force,
            ...this.customValues,
        });

        const error = response?.error;
        response = response.data;
        if (response && response[0]) {
            results = this.transformRow ? response?.map(this.transformRow) : response;
            results = this.transform ? this.transform(results) : results;
        }

        this.cache = { query: formatted, results };
        if (!dontUpdateExistingData || updateOnlyData) {
            this.fns &&
                this.fns.forEach((fn) => fn({ loading: false, data: this.cache.results, error }));
        }

        return this.cache.results;
    }

    async sort(field, dir) {
        let data = await this.get();
        dir = dir || 1;

        return data.sort((a, b) => {
            return a[field] < b[field] ? dir : -dir;
        });
    }
}

export default Query;
