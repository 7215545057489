import { Cell } from '@table-library/react-table-library/table';
import Sparkline from '../../../components/Sparklines';
import numeral from 'numeral';
import IgnoreButton from './ignoreButton';
import { collectCell } from '../../../helpers/showPriorData';

export const COLUMNS = ({ endpoint_url, csrf_token, deleteIgnoredItem }) => [
    {
        label: 'Keyword',
        key: 'keyword',
        data: 'keyword',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return (
                <strong>
                    <a
                        href={`/domains/${
                            window.info.id
                        }/gsc-reports/keywords/keyword?keyword=${encodeURIComponent(data)}`}
                        className="link-website d-inline-flex align-items-center justify-content-center"
                        rel="noreferrer"
                    >
                        {data}
                    </a>
                </strong>
            );
        },
    },
    {
        label: 'First Seen -> Last Seen',
        key: 'seen',
        data: 'seen',
        Component: Cell,
        renderCell: ({ item, csvDownload }) => {
            if (csvDownload) return `${item.firstSeen.value} -> ${item.firstSeen.value}`;

            return (
                <div>
                    <strong>{item.firstSeen.value}</strong>
                    <div className="delta-number">{item.lastSeen.value}</div>
                </div>
            );
        },
    },
    {
        label: 'Max Clicks',
        key: 'maxClicks',
        data: 'maxClicks',
        Component: Cell,
        renderCell: ({ data, csvDownload }) => {
            if (csvDownload) return data;

            return <strong>{numeral(data).format('0.0a')}</strong>;
        },
    },
    {
        label: 'Clicks',
        key: 'avgClicks',
        data: 'avgClicks',
        Component: Cell,
        renderCell: ({ data, item, csvDownload }) => {
            if (csvDownload) return data;

            return collectCell(data, item.clicksL);
        },
    },
    {
        label: 'Trend',
        key: 'sparkline',
        data: 'sparkline',
        Component: Cell,
        renderCell: ({ item, csvDownload }) => {
            if (csvDownload) return '-';

            return <Sparkline dataL={item.clicksL} keyName="Clicks" />;
        },
    },
    {
        label: 'Urls',
        key: 'avgURLs',
        data: 'avgURLs',
        Component: Cell,
        renderCell: ({ data, item, csvDownload }) => {
            if (csvDownload) return data;

            return collectCell(data, item.urlsL);
        },
    },
    {
        label: 'Position',
        key: 'avgPosition',
        data: 'avgPosition',
        Component: Cell,
        renderCell: ({ data, item, csvDownload }) => {
            if (csvDownload) return data;

            return collectCell(data, item.positionL);
        },
    },
    {
        label: 'Impressions',
        key: 'impressions',
        data: 'impressions',
        Component: Cell,
        renderCell: ({ data, item, csvDownload }) => {
            if (csvDownload) return data;

            return collectCell(data, item.impressionsL);
        },
    },
    {
        label: 'CTR',
        key: 'avgCTR',
        data: 'avgCTR',
        Component: Cell,
        renderCell: ({ item, csvDownload }) => {
            const ctrValue = item.avgClicks/item.impressions;
            if (csvDownload) return ctrValue;

            return collectCell(ctrValue, item.ctrL, '0.00%');
        },
    },
    {
        label: 'Actions',
        key: 'actions',
        data: 'actions',
        Component: Cell,
        renderCell: ({ item, csvDownload }) => {
            if (csvDownload) return null;

            return (
                <IgnoreButton
                    keyword={item.keyword}
                    endpoint_url={endpoint_url}
                    csrf_token={csrf_token}
                    deleteIgnoredItem={deleteIgnoredItem}
                />
            );
        },
    },
];
