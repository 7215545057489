import Component from '../../components/Component';

class StepsAnalytics extends Component {
    constructor(props) {
        super();
        console.log(props.url);
    }

    render() {
        return (
            <div className="col-12">
                <div className="card table-main rounded-main overflow-hidden " id="google-discover">
                    <div className="card-header">
                        <div className="card-title text-dark fw-bold">GDdash Analytics</div>
                    </div>
                    <div>
                        <div className="card-body row">
                            <div className="col">
                                <div className="row">
                                    <div className="col-6 google-discover-steps-container">
                                        <div>
                                            <img src="/static/img/step-3_1.svg" />
                                        </div>
                                        <div className="google-discover-steps-container-link">
                                            <a
                                                target="_blank"
                                                href={`${this.props.url}/`}
                                                rel="noreferrer"
                                            >
                                                <strong>Google Discover Analytics</strong>
                                            </a>
                                            <div className="google-discover-steps-container-description">
                                                Identify top Authors, Top Categories, Top Entities
                                                and Content Characteristics, and more
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6 google-discover-steps-container">
                                        <div>
                                            <img src="/static/img/step_3_2.svg" />
                                        </div>
                                        <div className="google-discover-steps-container-link">
                                            <a
                                                target="_blank"
                                                href={`${this.props.url}/gsc-reports/`}
                                                rel="noreferrer"
                                            >
                                                <strong>Google Search Analytics</strong>
                                            </a>
                                            <div className="google-discover-steps-container-description">
                                                Keyword automatic clustering, evergreen content
                                                detection, algo comparison, and more
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6 google-discover-steps-container">
                                        <div>
                                            <img src="/static/img/step_3_3.svg" />
                                        </div>
                                        <div className="google-discover-steps-container-link">
                                            <a
                                                target="_blank"
                                                href={'/tools/google-discover-data/'}
                                                rel="noreferrer"
                                            >
                                                <strong>Google Discover Entity Tracking</strong>
                                            </a>
                                            <div className="google-discover-steps-container-description">
                                                Track any entity in any location and uncover the top
                                                stories appearing in Google Discover in near
                                                real-time
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default StepsAnalytics;
