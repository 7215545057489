import { Component } from 'react';
import Select from 'react-select';

class AddLocation extends Component {
    constructor() {
        super();

        this.onSave = this.onSave.bind(this)
        this.updateCountries = this.updateCountries.bind(this)

        this.state = {
            geo_location: ''
        }
    }

    onSave(){
        fetch(`${this.props.current_url}/customers/add-geo-location/?geo_location=${this.state.geo_location}`)
            .then(res => res.json())
            .then(res => {
                if(res.reload){
                    window.location.reload()
                }
            })
            .catch(err => console.log(err))
    }

    updateCountries(data) {
        this.setState({geo_location: data.value});
    }

    render() {
        const geo_locations = JSON.parse(this.props.geo_locations)
        const optionList = geo_locations.map(item => ({value: item.geo, label: item.location}))
        optionList.unshift({value: '', label: 'None'})

        return (
            <div style={{ zIndex: '10', position: 'relative', display: 'flex', margin: '15px 0', justifyContent: 'flex-end' }}>
                <div style={{display: 'flex', alignItems: 'center', fontWeight: '600'}}>Set Your Location</div>
                <Select
                    options={optionList}
                    styles={{
                        control: (baseStyles) => ({
                            ...baseStyles,
                            boxShadow: 'none',
                            padding: '1px 3px',
                            fontSize: '13px',
                            margin: '0 15px',
                            minWidth: '295px',
                            zIndex: 10
                        }),
                    }}
                    theme={(theme) => ({
                        ...theme,
                        height: '45px',
                        borderRadius: '7px',
                        borderColor: '#d9d9d9',
                        transition: 'all 0.2s',
                        zIndex: 10,
                        colors: {
                            ...theme.colors,
                            primary: '#888adb',
                        },
                    })}
                    onChange={this.updateCountries}
                    placeholder="Select Location"
                    isSearchable={true}
                    onFocus={(e) => {
                        const parent = e.nativeEvent.target.parentNode.parentNode
                        const selectedValue = parent.children[0]
                        selectedValue.style.display = 'none';
                    }}
                    onBlur={(e) => {
                        const parent = e.nativeEvent.target.parentNode.parentNode
                        const selectedValue = parent.children[0]
                        selectedValue.style.display = 'inline-grid';
                    }}
                    blurInputOnSelect={true}
                />
                <div style={{textAlign: 'end', }}>
                    <button className="button button-filled button-primary width-sm" onClick={this.onSave}>
                        Save
                    </button>
                </div>
            </div>
        );
    }
}

export default AddLocation;
