import Component from '../../components/Component';
import Loading from '../../components/Loading';

import query from './query';
import { Pie } from 'react-chartjs-2';
import { getRandomColor, labelColors } from '../../lib/colors';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { COLUMNS, getPercent, getPercentForChart } from './keys';
import DataTable from '../../components/Datatable';
import { scrollForTable } from '../../components/Datatable/utils';

ChartJS.register(ArcElement, Tooltip, Legend);

class Summary extends Component {
    constructor(props) {
        super();
        this.query = query;
        this.searchFunction = this.searchFunction.bind(this);
    }

    searchFunction(data, search) {
        return data;
    }

    render() {
        if (this.state.loading) {
            return <Loading />;
        }

        const options = {
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    display: false,
                },
                title: {
                    display: true,
                    text: 'Lifespan In Days',
                    padding: {
                        top: 10,
                        bottom: 10,
                    },
                },
                tooltip: {
                    callbacks: {
                        label: (context) => {
                            const percent = `${getPercentForChart(
                                context.dataset.data,
                                context.parsed,
                            ).toFixed(2)}%`;
                            return `${context.label} Day${context.label > 1 ? 's' : ''} ${
                                context.parsed
                            } Page${context.parsed > 1 ? 's' : ''} (${percent})`;
                        },
                    },
                },
            },
        };

        const data = this.state.data ?? [];

        const chartData = {
            labels: data.map((item) => item.lifespan),
            datasets: [
                {
                    label: 'Pages',
                    data: data.map((item) => item.pages),
                    backgroundColor: data.map((item, i) => labelColors[i] ?? getRandomColor()),
                    borderRadius: 4,
                    tension: 0.4,
                },
            ],
        };

        const sortFns = {
            lifespan: (array) => array.sort((a, b) => a.lifespan - b.lifespan),
            pages: (array) => array.sort((a, b) => a.pages - b.pages),
            percent: (array) =>
                array.sort((a, b) => getPercent(array, a.pages) - getPercent(array, b.pages)),
        };

        return (
            <div
                className="Timeline"
                style={{ height: '100%', minHeight: '150px', position: 'relative' }}
            >
                <h3 className="font-weight-bold">
                    What is the lifespan of your pages in Google Discover ?
                </h3>
                <small className="d-block card-sub-title text-muted fs-7 text-capitalize py-2">
                    For how many days your pages was shown in average in Google Discover during the
                    selectes period ?
                </small>
                {this.state.data[0] ? (
                    <div className="row">
                        <div className="col-6" style={{ height: '400px', position: 'relative' }}>
                            <Pie data={chartData} options={options} />
                        </div>
                        <div className="col-6">
                            <div className="row">
                                <div className="col">
                                    <DataTable
                                        nodes={this.state.data}
                                        sortFns={sortFns}
                                        COLUMNS={COLUMNS}
                                        uniqKey={'lifespan'}
                                        fileName="Lifespan in days"
                                        searchFunction={this.searchFunction}
                                        defaultSize={5}
                                        onlyTable
                                        onlyPagination
                                        forceRun={this.forceRun}
                                        additionalStyles={{
                                            Table: `
                                                --data-table-library_grid-template-columns : minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) !important;
                                               ${scrollForTable}
                                            `,
                                            BaseCell: `  font-family: 'Raleway';
                                                    font-weight: 500;
                                                    font-size: 16px;
                                                    line-height: 19px;
                                                    padding: 16px 5px !important;
                                                    color: #0D182C;
                                                    border-bottom: 1px solid rgba(13, 24, 44, 0.1);
                                                    &.underline {
                                                        text-decoration: underline;
                                                    }
                                                    `,
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div>There is no data</div>
                )}
            </div>
        );
    }
}

export default Summary;
