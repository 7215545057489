import query from './query';
import { COLUMNS } from './keys';
import DataTable from '../../../components/Datatable';
import CheckData from '../../../components/CatchError';
import Component from '../../../components/Component';
import { scrollForTable } from '../../../components/Datatable/utils';

class GSCReportDirectories extends Component {
    constructor() {
        super();
        this.query = query;
    }

    searchFunction(data, search) {
        return data.filter((item) =>
            search ? item.first_directory?.toLowerCase().includes(search.toLowerCase()) : item,
        );
    }

    render() {
        const sortFns = {
            first_directory: (array) =>
                array.sort((a, b) => a.first_directory.localeCompare(b.first_directory)),
            subdomain: (array) => array.sort((a, b) => a.subdomain.localeCompare(b.subdomain)),
            traffic_share: (array) =>
                array.sort(
                    (a, b) =>
                        a.clicks / this.props.total_clicks - b.clicks / this.props.total_clicks,
                ),
            urls: (array) => array.sort((a, b) => a.urls - b.urls),
            clicks: (array) => array.sort((a, b) => a.clicks - b.clicks),
            keywords: (array) => array.sort((a, b) => a.keywords - b.keywords),
            impressions: (array) => array.sort((a, b) => a.impressions - b.impressions),
            position: (array) => array.sort((a, b) => a.position - b.position),
            ctr: (array) => array.sort((a, b) => a.clicks/a.impressions - b.clicks/b.impressions),
        };

        return (
            <>
                <CheckData
                    content_id={this.props.content_id}
                    parent_id={this.props.parent_id}
                    data={this.state.data}
                    loading={this.state.loading}
                >
                    <DataTable
                        nodes={this.state.data}
                        sortFns={sortFns}
                        COLUMNS={COLUMNS(this.props.total_clicks)}
                        uniqKey={'first_directory'}
                        fileName="Directory"
                        header="Directories"
                        defaultSortKey="clicks"
                        searchFunction={this.searchFunction}
                        forceRun={this.forceRun}
                        additionalStyles={{
                            Table: `
                                --data-table-library_grid-template-columns : minmax(200px, 2fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(150px, 1.5fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) !important;
                               ${scrollForTable}
                            `,
                            BaseCell: `  font-family: 'Raleway';
                                        font-weight: 500;
                                        font-size: 16px;
                                        line-height: 19px;
                                        padding: 16px 5px !important;
                                        color: #0D182C;
                                        border-bottom: 1px solid rgba(13, 24, 44, 0.1);
                                    
                                        &.underline {
                                            text-decoration: underline;
                                        }
                                        `,
                        }}
                    />
                </CheckData>
            </>
        );
    }
}

export default GSCReportDirectories;
